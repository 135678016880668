import React, {
    useMemo,
    useState,
    useEffect,
} from 'react'
import ModalFaceRecognitionDetail from './components/ModalFaceRecognitionDetail'
import ModalFaceRecognitionDelete from './components/ModalFaceRecognitionDelete'
import ModalEditNip from './components/ModalEditNip'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { teamDetailApi } from '../../apis'
import {
    JTable,
    JTypography,
    JButton,
    JGoogleIcon,
} from '../../components'
import {
    Flex,
    Skeleton,
    useDisclosure,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Stack,
    Divider,
    Center,
    Heading,
    TableContainer,
    Tr,
    Thead,
    Table,
    Th,
    Tbody,
    Td,
    Box,
    Tooltip,
} from '@chakra-ui/react'
import ModalSwitchTracking from './components/ModalSwitchTracking'
import { format } from 'date-fns'
import ModalSwitchActive from './components/ModalSwitchActive'
import ModalAksesMenu from './components/ModalAksesMenu'
import ModalAksesMember from './components/ModalAksesMember'
import { useSelector } from 'react-redux'

interface MasterMemberTableProps {
    data: any;
    metadata: any;
    isLoading: boolean;
    handlePrevious: any;
    handleNext: any;
    handleClickDevice: any;
    handleSortName: any;
    configGetMember: any;
    refetch: any;
    resetDevice: any
}

const MasterMemberTable: React.FC<MasterMemberTableProps> = (props: MasterMemberTableProps) => {
    const {
        data,
        metadata,
        isLoading,
        handlePrevious,
        handleNext,
        handleClickDevice,
        handleSortName,
        configGetMember,
        refetch,
        resetDevice
    } = props
    const { t } = useTranslation()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const modalDetailFaceRecogDisclosure = useDisclosure()
    const modalDeleteFaceRecogDisclosure = useDisclosure()
    const modalEditNipDisclosure = useDisclosure()
    const modalLiveTrackingDisclosure = useDisclosure()
    const modalAksesMenu = useDisclosure()
    const modalAksesMember = useDisclosure()
    const modalSwitchActive = useDisclosure()
    const currentTeam = useMutation(teamDetailApi)

    const menuPrivilage = useSelector((state: any) => state.menuPrivilage)


    const tableData = useMemo(() => data && [...data], [data])
    const [geofanceInfo, setGeofanceInfo] = useState<any>(null)
    const [configEditNip, setConfigEditNip] = useState<any>(null)
    const [configOnOffTracking, setConfigOnOffTracking] = useState<any>(null)
    const [configMember, setConfigMember] = useState<any>(null)
    const [mode, setMode] = useState("AKTIF");
    const [active, setActive] = useState<any>(null)
    const [faceRecogData, setFaceRecogData] = useState<any>(null)

    interface IHeaders {
        title: any,
        info?: any,
        sort?: any,
        access?: any
    }

    const headers: IHeaders[] = useMemo(() => [
        {
            title: t('master_anggota_tabel_nama'),
            access: 0,
            sort: true
        },
        {
            title: t('master_anggota_kode'),
            access: 0
        },
        {
            title: t('master_anggota_tabel_telepon'),
            access: 0
        },
        {
            title: t('master_anggota_NIP'),
            access: 0
        },
        {
            title: t('master_anggota_device'),
            info: t('master_anggota_popover_device'),
            access: 0
        },
        {
            title: t('anggota_versi_aplikasi'),
            access: 0
        },
        {
            title: t("anggota_geofance_aktif"),
            info: t('master_anggota_popover_geofance'),
            access: 0
        },
        {
            title: t("master_anggota_face_recognition"),
            info: t('master_anggota_face_recognition_popover'),
            access: 0
        },
        {
            title: "Live Tracking",
            info: t('master_anggota_popover_live_tracking'),
            access: 0
        },
        {
            title: t("master_anggota_akses_menu"),
            access: 3
        },
        {
            title: t("master_anggota_akses_anggota"),
            access: 4
        },
        {
            title: t("master_anggota_join_date"),
            access: 0
        },
        {
            title: t("master_anggota_leave_date"),
            access: 0
        },
        {
            title: t("master_anggota_status"),
            access: 0
        },
    ], [t])

    useEffect(() => {
        currentTeam?.mutate()
    }, [])

    return (
        <Flex direction={"column"} gap={"0px"}>
            <Flex direction='column'>
                <TableContainer
                    overflowX='auto'
                    overflowY='auto'
                    maxWidth='calc(100vw - var(--sidebarWidth) - 48px)'
                    sx={{
                        '::-webkit-scrollbar': {
                            height: '8px !important',
                        },
                        '::-webkit-scrollbar-track': {
                            backgroundColor: 'var(--colorSurface1)',
                        },
                        '::-webkit-scrollbar-thumb': {
                            borderRadius: '4px',
                            backgroundColor: 'var(--colorPrimary)',
                    },
                    }}
                >
                    <Table variant='j-simple'>
                        <Thead>
                            <Tr>
                                {headers.map((head: any, i: number) => {
                                    return (
                                        head.access === 0 || menuPrivilage.data?.find((x: any) => x.id === 2).menu_action?.find((x: any) => x.id === head.access) ? (
                                            <Th
                                                key={i}
                                                position={i === 0 ? 'sticky' : 'unset'}
                                                left={i === 0 ? '0px' : 'unset'}
                                                zIndex={i === 0 ? 1 : 0}
                                            >
                                                <Heading size='chakra_title_small'>
                                                    {head.title}
                                                    {head?.info ?
                                                        <Popover trigger='hover' placement='top-end' >
                                                            <PopoverTrigger>
                                                                <Button
                                                                    backgroundColor='transparent'
                                                                    size={'24px'}
                                                                    padding='0 5px'
                                                                    _hover={{}}
                                                                    _focus={{}}
                                                                    _active={{}}
                                                                >
                                                                    <JGoogleIcon name='help' fontSize='20px' />
                                                                </Button>
                                                            </PopoverTrigger>
                                                            <PopoverContent
                                                                backgroundColor='surfacePlus1'
                                                                border='none'
                                                                width='500px'
                                                                padding='16px'
                                                            >
                                                                <PopoverBody padding='0px' textTransform='none'>
                                                                    <JTypography size='body-medium' whiteSpace='break-spaces'>
                                                                        {head?.info}
                                                                    </JTypography>
                                                                </PopoverBody>
                                                            </PopoverContent>
                                                        </Popover>
                                                        : head.sort ?
                                                            <Tooltip
                                                                label={
                                                                    configGetMember?.order_by === "user.created_at" ? "Back"
                                                                        : configGetMember?.order_by === "user.display_name" ? "First Member"
                                                                            : "Sort by Alpha"
                                                                } placement='bottom'>
                                                                <Button
                                                                    backgroundColor='transparent'
                                                                    size={'24px'}
                                                                    padding='0 5px'
                                                                    _hover={{}}
                                                                    _focus={{}}
                                                                    _active={{}}
                                                                    onClick={() => handleSortName(configGetMember.order_by)}
                                                                >
                                                                    {configGetMember?.order_by === "user.created_at" ?
                                                                        <JGoogleIcon marginLeft={'6px'} fontSize='20px' name="cancel" /> :
                                                                        configGetMember?.order_by === "user.display_name" ?
                                                                            <JGoogleIcon marginLeft={'6px'} fontSize='20px' name="update" /> :
                                                                            <JGoogleIcon marginLeft={'6px'} fontSize='20px' name="sort_by_alpha" />
                                                                    }
                                                                </Button>
                                                            </Tooltip>
                                                            : null}
                                                </Heading>
                                            </Th>) : null
                                    )
                                })}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {isLoading || currentTeam?.isLoading || !data || !metadata ?
                                [...Array(10)]?.map((_, i: number) => {
                                    return (
                                        <Tr key={i}>
                                            {
                                                [...Array(headers?.length)]?.map((_, i: number) => {
                                                    return (
                                                        <Td key={i}><Skeleton height='20px' /></Td>
                                                    )
                                                })
                                            }
                                        </Tr>
                                    )
                                })
                                : <>
                                    {data && tableData.map((data: any, i: any) => {
                                        function opa() {
                                            return (data?.is_active !== 1 ? '0.2' : '1')
                                        }
                                        function disable() {
                                            return (data?.is_active !== 1 ? true : false)
                                        }
                                        return (
                                            <Tr key={i} >
                                                <Td position='sticky' left='0px' zIndex={1} >
                                                    <Box
                                                        width="max-content"
                                                        overflowX="auto"
                                                        overflowY="hidden"
                                                        whiteSpace="nowrap"
                                                        maxWidth='200px'
                                                        opacity={opa()}
                                                    >
                                                        {data?.user.display_name}
                                                    </Box>
                                                </Td>
                                                <Td>
                                                    <Box maxWidth='200px' overflowX='auto' opacity={opa()}>
                                                        {data?.user.code}
                                                    </Box>
                                                </Td>
                                                <Td>
                                                    <Box maxWidth='200px' overflowX='auto' opacity={opa()}>
                                                        {data?.user.phone}
                                                    </Box>
                                                </Td>
                                                <Td>
                                                    {data?.user.nip === null ?
                                                        <Box maxWidth='200px' overflowX='auto' >
                                                            <JButton
                                                                p={0}
                                                                onClick={() => {
                                                                    modalEditNipDisclosure.onOpen()
                                                                    setConfigEditNip(data?.user)
                                                                }}
                                                                size="normal"
                                                                variant="j-text"
                                                                isDisabled={disable()}
                                                            >
                                                                <JTypography size='body-medium'>
                                                                    {t("master_anggota_device_no_nip")}
                                                                </JTypography>
                                                            </JButton>
                                                        </Box>
                                                        : <Box maxWidth='200px' overflowX='auto'>
                                                            <JButton
                                                                p={0}
                                                                onClick={() => {
                                                                    modalEditNipDisclosure.onOpen()
                                                                    setConfigEditNip(data?.user)
                                                                }}
                                                                size="normal"
                                                                variant="j-text"
                                                                isDisabled={disable()}
                                                            >
                                                                <JTypography size="body-medium" >
                                                                    {data?.user?.nip}
                                                                </JTypography>
                                                            </JButton>
                                                        </Box>
                                                    }
                                                </Td>
                                                <Td>
                                                    <Box maxWidth='200px' overflowX='auto'>
                                                        {data?.user.device === null ?
                                                            <JButton
                                                                p={0}
                                                                // isDisabled={data.user.device === null || disable()}
                                                                onClick={() => handleClickDevice(data)}
                                                                size="normal"
                                                                variant="j-text"
                                                            >
                                                                <JTypography size='body-medium' color='onBackground'>
                                                                    {t('master_anggota_device_not_available')}
                                                                </JTypography>
                                                            </JButton>
                                                            : <JButton
                                                                p={0}
                                                                // isDisabled={data.user.device === null || disable()}
                                                                onClick={() => handleClickDevice(data)}
                                                                size="normal"
                                                                variant="j-text"
                                                            >
                                                                {t('master_anggota_device_available')}
                                                            </JButton>
                                                        }
                                                    </Box>
                                                </Td>
                                                <Td>
                                                    <Box maxWidth='200px' overflowX='auto' overflowY='hidden'>
                                                        {data?.user.version_name ?
                                                            <JTypography
                                                                size='body-medium'
                                                                opacity={opa()}
                                                                color={data?.user.version_name ? 'onSurface' : 'onSurfaceVariant'}>
                                                                {data?.user.version_name ?? t('master_anggota_device_not_available')}
                                                            </JTypography>
                                                            : <JButton
                                                                p={0}
                                                                isDisabled={true || disable()}
                                                                size="normal"
                                                                variant="j-text"
                                                            >
                                                                <JTypography size='body-medium' color='onBackground'>
                                                                    {t('master_anggota_device_not_available')}
                                                                </JTypography>
                                                            </JButton>
                                                        }
                                                    </Box>
                                                </Td>
                                                <Td>
                                                    <Box maxWidth='200px' overflowX='auto'>
                                                        {data?.active_geofence === null ?
                                                            <JButton
                                                                p={0}
                                                                isDisabled={true || disable()}
                                                                size="normal"
                                                                variant="j-text"
                                                            >
                                                                <JTypography size='body-medium' color='onBackground'>
                                                                    {t('master_anggota_device_not_available')}
                                                                </JTypography>
                                                            </JButton>
                                                            : <JButton
                                                                p={0}
                                                                onClick={() => {
                                                                    onOpen()
                                                                    setGeofanceInfo(data)
                                                                }}
                                                                isDisabled={disable()}
                                                                size="normal"
                                                                variant="j-text"
                                                            >{t('master_anggota_device_available')}</JButton>
                                                        }
                                                    </Box>
                                                </Td>
                                                <Td>
                                                    <Box maxWidth='200px' overflowX='auto'>
                                                        {data?.user.have_face_recognition === 1 && currentTeam?.data?.face_recognition === 1
                                                            ? <JButton
                                                                p={0}
                                                                size="normal"
                                                                variant="j-text"
                                                                isDisabled={disable()}
                                                                onClick={() => {
                                                                    setFaceRecogData(data)
                                                                    modalDetailFaceRecogDisclosure.onOpen()
                                                                }}
                                                            >
                                                                {t('master_anggota_face_recognition_available')}
                                                            </JButton>
                                                            : <JButton
                                                                p={0}
                                                                isDisabled={true || disable()}
                                                                size="normal"
                                                                variant="j-text"
                                                            >
                                                                <JTypography size='body-medium' color='onBackground'>
                                                                    {
                                                                        currentTeam?.data?.face_recognition === 1
                                                                            ?
                                                                            t('master_anggota_face_recognition_unavailable')
                                                                            :
                                                                            t('master_anggota_face_recognition_inactive')
                                                                    }
                                                                </JTypography>
                                                            </JButton>
                                                        }
                                                    </Box>
                                                </Td>
                                                <Td>
                                                    <Box maxWidth='200px' overflowX='auto'>
                                                        {data?.user.is_geofance === "1" ?
                                                            <JButton
                                                                p={0}
                                                                size="normal"
                                                                variant="j-text"
                                                                isDisabled={disable()}
                                                                onClick={() => {
                                                                    modalLiveTrackingDisclosure.onOpen();
                                                                    setConfigOnOffTracking((prev: any) => ({ ...prev, mode: "TIDAK AKTIF", id: data?.user.id, name: data?.user.display_name }))
                                                                    setMode("TIDAK AKTIF");
                                                                }}
                                                            >{t('master_anggota_live_tracking_aktif')}</JButton>
                                                            :
                                                            <JButton
                                                                p={0}
                                                                size="normal"
                                                                variant="j-text"
                                                                isDisabled={disable()}
                                                                onClick={() => {
                                                                    modalLiveTrackingDisclosure.onOpen();
                                                                    setConfigOnOffTracking((prev: any) => ({ ...prev, mode: "AKTIF", id: data?.user.id, name: data?.user.display_name }))
                                                                    setMode("AKTIF");
                                                                }}
                                                            >{t('master_anggota_live_tracking_inaktif')}</JButton>
                                                        }
                                                    </Box>
                                                </Td>
                                                {menuPrivilage.data?.find((x: any) => x.id === 2).menu_action.find((x: any) => x.id === headers.find((y: any) => y.title === t("master_anggota_akses_menu"))?.access) ?
                                                    <Td>
                                                        <Box maxWidth='200px' overflowX='auto'>
                                                            <JButton
                                                                variant='j-text'
                                                                size='normal'
                                                                leftIcon={<JGoogleIcon name="edit" />}
                                                                isDisabled={disable()}
                                                                onClick={() => {
                                                                    modalAksesMenu.onOpen()
                                                                    setConfigMember(() => ({ id: data?.user.id, name: data?.user.display_name, photo: data?.user.photo_url }))
                                                                }}
                                                            >{t('master_anggota_akses_edit')}</JButton>
                                                        </Box>
                                                    </Td> : null}
                                                {menuPrivilage.data?.find((x: any) => x.id === 2).menu_action?.find((x: any) => x.id === headers.find((y: any) => y.title === t("master_anggota_akses_anggota"))?.access) ?
                                                    <Td>
                                                        <Box maxWidth='200px' overflowX='auto'>
                                                            <JButton
                                                                variant='j-text'
                                                                size='normal'
                                                                leftIcon={<JGoogleIcon name="edit" />}
                                                                isDisabled={disable()}
                                                                onClick={() => {
                                                                    modalAksesMember.onOpen()
                                                                    setConfigMember(() => ({ id: data?.user.id, name: data?.user.display_name, photo: data?.user.photo_url }))
                                                                }}
                                                            >{t('master_anggota_akses_edit')}</JButton>
                                                        </Box>
                                                    </Td> : null}
                                                <Td>
                                                    <Box maxWidth='200px' overflowX='auto' opacity={opa()}>
                                                        <Heading size='chakra_body_medium'>{
                                                            data?.join_date !== null
                                                                ? format(new Date(data?.join_date), 'dd MMM yyyy')
                                                                : '-'
                                                        }</Heading>
                                                    </Box>
                                                </Td>
                                                <Td>
                                                    <Box maxWidth='200px' overflowX='auto' opacity={opa()}>
                                                        <Heading size='chakra_body_medium'>
                                                            {
                                                                data?.leave_date !== null
                                                                    ? format(new Date(data?.leave_date), 'dd MMM yyyy')
                                                                    : '-'
                                                            }
                                                        </Heading>
                                                    </Box>
                                                </Td>
                                                <Td>
                                                    <JButton variant='j-text' size='normal' isDisabled={data?.is_active === 0}
                                                        onClick={
                                                            () => {
                                                                modalSwitchActive.onOpen()
                                                                setActive((prev: any) => ({ ...prev, mode: data?.is_active, id: data?.user.id, name: data?.user.display_name }))
                                                            }
                                                        }>
                                                        {data?.is_active === 0 ? t('master_anggota_status_inactive') : t('master_anggota_status_active')}
                                                    </JButton>
                                                </Td>
                                            </Tr>
                                        )
                                    })}
                                </>
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </Flex>

            <Modal isOpen={isOpen} onClose={onClose} size='3xl'>
                <ModalOverlay />
                <ModalContent backgroundColor='surfacePlus1'>
                    <ModalBody padding='24px'>
                        <Stack spacing='24px'>
                            <Flex justifyContent='space-between' alignItems='center'>
                                <JTypography size='title-medium'>
                                    {t("anggota_geofance_aktif")} ({geofanceInfo?.user?.display_name})
                                </JTypography>
                                <JGoogleIcon
                                    name='close'
                                    onClick={onClose}
                                    cursor='pointer'
                                />
                            </Flex>
                            <Flex justifyContent='space-between'>
                                <Stack
                                    alignItems='center'
                                    width='50%'
                                    spacing='9px'
                                    paddingRight='24px'
                                    borderRightWidth='1px'
                                    borderRightColor='outline'
                                >
                                    <Flex alignItems='center' gap='5px'>
                                        <JTypography size='body-large'>
                                            {t('master_anggota_geofence_pengaturan_anggota')}
                                        </JTypography>
                                        {
                                            geofanceInfo?.active_geofence?.tracking_geofence === null || geofanceInfo?.active_geofence?.tracking_geofence?.length === 0
                                                ? <Center
                                                    padding='2px 10px'
                                                    backgroundColor='onError'
                                                    borderRadius='50px'
                                                >
                                                    <JTypography size='label-medium' color='error'>
                                                        {t('master_anggota_device_not_available')}
                                                    </JTypography>
                                                </Center>
                                                : <Center
                                                    padding='2px 10px'
                                                    backgroundColor='onPrimary'
                                                    borderRadius='50px'
                                                >
                                                    <JTypography size='label-medium' color='primary'>
                                                        {t('master_anggota_device_available')}
                                                    </JTypography>
                                                </Center>
                                        }
                                    </Flex>
                                    <Stack
                                        spacing='9px'
                                        paddingTop='5px'
                                        height='100%'
                                        width='100%'
                                        alignItems='center'
                                        justifyContent='center'
                                    >
                                        {geofanceInfo?.active_geofence?.tracking_geofence?.map((v: any, i: number) => {
                                            return (
                                                <>
                                                    <JTypography key={i} size='body-medium'>
                                                        {v}
                                                    </JTypography>
                                                    <Divider borderColor='outline' width='75%' />
                                                </>
                                            )
                                        })}

                                        {
                                            geofanceInfo?.active_geofence?.tracking_geofence === null || geofanceInfo?.active_geofence?.tracking_geofence?.length === 0
                                                ? <JTypography size='body-medium'>
                                                    {t('master_anggota_data_kosong')}
                                                </JTypography>
                                                : null
                                        }
                                    </Stack>
                                </Stack>
                                <Stack alignItems='center' width='50%' spacing='9px' paddingLeft='24px'>
                                    <Flex alignItems='center' gap='5px'>
                                        <JTypography size='body-large'>
                                            {t('master_anggota_geofence_pengaturan_absen')}
                                        </JTypography>
                                        {
                                            geofanceInfo?.active_geofence?.absence_geofence === null || geofanceInfo?.active_geofence?.absence_geofence?.length === 0
                                                ? <Center
                                                    padding='2px 10px'
                                                    backgroundColor='onError'
                                                    borderRadius='50px'
                                                >
                                                    <JTypography size='label-medium' color='error'>
                                                        {t('master_anggota_device_not_available')}
                                                    </JTypography>
                                                </Center>
                                                : <Center
                                                    padding='2px 10px'
                                                    backgroundColor='onPrimary'
                                                    borderRadius='50px'
                                                >
                                                    <JTypography size='label-medium' color='primary'>
                                                        {t('master_anggota_device_available')}
                                                    </JTypography>
                                                </Center>
                                        }
                                    </Flex>
                                    <Stack
                                        spacing='9px'
                                        paddingTop='5px'
                                        height='100%'
                                        width='100%'
                                        alignItems='center'
                                        justifyContent='center'
                                    >
                                        {geofanceInfo?.active_geofence?.absence_geofence?.map((v: any, i: number) => {
                                            return (
                                                <>
                                                    <JTypography key={i} size='body-medium'>
                                                        {v}
                                                    </JTypography>
                                                    <Divider borderColor='outline' width='75%' />
                                                </>
                                            )
                                        })}

                                        {
                                            geofanceInfo?.active_geofence?.absence_geofence === null || geofanceInfo?.active_geofence?.absence_geofence?.length === 0
                                                ? <JTypography size='body-medium'>
                                                    {t('master_anggota_data_kosong')}
                                                </JTypography>
                                                : null
                                        }
                                    </Stack>
                                </Stack>
                            </Flex>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <ModalFaceRecognitionDetail
                disclosure={modalDetailFaceRecogDisclosure}
                modalDeleteDisclosure={modalDeleteFaceRecogDisclosure}
                data={faceRecogData}
            />

            <ModalFaceRecognitionDelete
                disclosure={modalDeleteFaceRecogDisclosure}
                modalDetailDisclosure={modalDetailFaceRecogDisclosure}
                data={faceRecogData}
                refetch={refetch}
            />
            <ModalEditNip
                disclosure={modalEditNipDisclosure}
                data={configEditNip}
                setData={setConfigEditNip}
                refetch={refetch}
            />
            <ModalSwitchTracking
                disclosure={modalLiveTrackingDisclosure}
                data={configOnOffTracking}
                setData={setConfigOnOffTracking}
                refetch={refetch}
                mode={mode}
            />
            <ModalAksesMenu disclosure={modalAksesMenu} member={configMember} />
            <ModalAksesMember disclosure={modalAksesMember} member={configMember} />
            <ModalSwitchActive
                disclosure={modalSwitchActive}
                data={active}
                refetch={refetch}
            />
        </Flex>
    );
};

export default MasterMemberTable;
