import http from "./http"
import httpNotif from "./httpNotif"
import urlApi from "./url"

export interface INotification {
    firebase_token: string
}

export interface IGetNotification {
    page: number
    is_read?: 1 | 0
}

export interface IReadNotification {
    id: any
}

export async function updateFcmTokenApi(props: INotification) {
    try {
        const myData = new FormData()
        myData.set('firebase_token', props.firebase_token)
        myData.set('platform', 'cms')
        const response: any = await http(true).post(urlApi.notification.update.token, myData)
        return response.data
    } catch (error) {
        throw 'error update token fcm'
    }
}

export async function getNotificationApi(props: IGetNotification) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            request_from: 'cms',
            ...props
        }
        const response: any = await httpNotif(true).post(urlApi.notification.get.notifications, payload)
        return {
            data: response.data,
            metadata: response.metadata,
        }
    } catch (error) {
        throw 'error get notifications'
    }
}

export async function readNotificationApi(props: IReadNotification) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await httpNotif(true).post(urlApi.notification.read.notification, payload)
        return response.data
    } catch (error) {
        throw 'error read notification'
    }
}

export async function readAllNotificationApi() {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
        }
        const response: any = await httpNotif(true).post(urlApi.notification.readAll.notification, payload)
        return response.data
    } catch (error) {
        throw 'error read all notification'
    }
}

export async function getUnreadCountNotificationApi() {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            request_from: 'cms',
        }
        const response: any = await httpNotif(true).post(urlApi.notification.get.unreadCount, payload)
        return response.data
    } catch (error) {
        throw 'error get unread count notification'
    }
}