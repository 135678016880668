import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { t } from "i18next";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

interface IAttendanceListTable {
  attendance: any;
}

const AttendanceListTable: React.FC<IAttendanceListTable> = (
  props: IAttendanceListTable
) => {

  const { attendanceMonitor } = useSelector((state: any) => state.attendance);

  const tableConfigHeader = [
    { name: t("monitoring_absensi_tabel_nama"), key: "" },
    { name: t("monitoring_absensi_tabel_absen_masuk"), key: "" },
    { name: t("monitoring_absensi_tabel_tanggal_masuk"), key: "" },
    { name: t("monitoring_absensi_tabel_lokasi_absen_masuk"), key: "" },
    { name: t("monitoring_absensi_tabel_absen_keluar"), key: "" },
    { name: t("monitoring_absensi_tabel_tanggal_keluar"), key: "" },
    { name: t("monitoring_absensi_tabel_lokasi_absen_keluar"), key: "" },
    { name: t("monitoring_absensi_tabel_total_jam_kerja"), key: "" },
  ];
  function workTime(up: any, down: any): any {
    const hourWork = Math.floor(moment(up).diff(moment(down), "hours"));
    const minuteWork = Math.floor(
      moment(up).diff(moment(down), "minutes") % 60
    );
    const secondWork = Math.floor(
      moment(up).diff(moment(down), "seconds") % 60
    );

    if (hourWork <= 0) {
      return `${minuteWork} ${t("menit_kerja")} ${secondWork} ${t(
        "detik_kerja"
      )}`;
    } else if (hourWork > 0) {
      return `${hourWork} ${t("jam_kerja")} ${minuteWork} ${t("menit_kerja")}`;
    }
  }

  return (
    <Flex direction="column" marginBottom="-16px">
      <TableContainer
        overflowX="auto"
        overflowY="auto"
        maxWidth="calc(100vw - var(--sidebarWidth) - 48px)"
      >
        <Table variant="j-simple">
          <Thead>
            <Tr>
              {tableConfigHeader.map((config: any, i: number) => (
                <Th
                  key={i}
                  position={i === 0 ? "sticky" : "unset"}
                  left={i === 0 ? "0px" : "unset"}
                >
                  {config.name}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {attendanceMonitor?.map((attendance: any) => (
              <Tr>
                <Td position="sticky" left="0px">
                  <Box
                    width="max-content"
                    overflowX="auto"
                    overflowY="hidden"
                    whiteSpace="nowrap"
                  >
                    {attendance?.user?.display_name ?? "-"}
                  </Box>
                </Td>
                <Td>
                  <Box>
                    {attendance?.clock_in?.datetime.split(" ")[1] ?? "-"}
                  </Box>
                </Td>
                <Td>
                  <Box>
                    {attendance?.clock_in?.datetime.split(" ")[0] ?? "-"}
                  </Box>
                </Td>
                <Td>
                  <Box
                    maxWidth="200px"
                    overflowX="auto"
                    overflowY="hidden"
                    whiteSpace="nowrap"
                  >
                    {attendance?.clock_in?.address ?? "-"}
                  </Box>
                </Td>
                <Td>
                  <Box>
                    {attendance?.clock_out?.datetime.split(" ")[1] ?? "-"}
                  </Box>
                </Td>

                <Td>
                  <Box>
                    {attendance?.clock_out?.datetime.split(" ")[0] ?? "-"}
                  </Box>
                </Td>
                <Td>
                  <Box
                    maxWidth="200px"
                    overflowX="auto"
                    overflowY="hidden"
                    whiteSpace="nowrap"
                  >
                    {attendance?.clock_out?.address ?? "-"}
                  </Box>
                </Td>
                <Td>
                  {workTime(
                    attendance?.clock_out !== null
                      ? new Date(attendance?.clock_out?.datetime)
                      : new Date(),
                    attendance?.clock_in?.datetime
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export default AttendanceListTable;
