const urlApi = {
    auth: '/v1_4/auth/googlelogincms',
    team_list: '/v1_4/team/list',
    team_get: '/v1_4/team/get',
    team_edit: '/v1_4/team/edit',
    menu_list: '/v1_4/menuprivilege/list',
    member_privilage_list: '/v1_4/memberprivilege/list',
    last_location_list: '/v1_4/tracking/lastlocation',

    menu:{
        privilege: {
            apply: '/v1_4/menuprivilege/apply',
            member_list: '/v1_4/menuprivilege/memberlist'
        },
        list: '/v1_4/menu/list'
    },

    // memberj journey
    member_journey: '/v1_4/tracking/memberjourney',
    member_journey_straight_distance: '/v1_4/tracking/straightdistance',
    member_journey_elapsed_time: '/v1_4/tracking/elapsedtime',

    // attendace
    attendance_edit_config: '/v1_4/absence/configuration/edit',
    attendance_monitor: '/v1_4/absence/monitor',

    attendanceSetting: {
        get: {
            memberList: '/v1_4/absence/location/member/list',
            areaList: '/v1_4/absence/location/list',
            areaDetail: '/v1_4/absence/location/detail',
            configuration: '/v1_4/team/list',
        },
        create: {
            area: '/v1_4/absence/location/new',
        },
        update: {
            status: '/v1_4/absence/location/configuration/edit',
            configuration: '/v1_4/absence/configuration/edit',
            area: '/v1_4/absence/location/edit',
            areaStatus: '/v1_4/absence/location/edit/status',
        },
        delete: {
            area: '/v1_4/absence/location/delete',
        },
        import: '/v1_4/absence/location/import'
    },

    // member
    member: '/v1_4/member/list',
    memberInvite: '/v1_4/member/memberinvitation',
    memberKick: 'v1_4/member/kick',
    memberCms: '/v1_4/member/cms/list',
    memberVersion: '/v1_4/member/cms/listversion',
    nip: {
        update: 'v1_4/member/edit'
    },
    tracking:{
        update: 'v1_4/member/geofanceactivate'
    },
    device: {
        delete: '/v1_4/member/devicereset'
    },
    memberGroup: {
        post: {
            create: '/v1_4/groupmember/new',
            groups: '/v1_4/groupmember/list',
            members: '/v1_4/member/list',
            group: '/v1_4/groupmember/detail',
            updateGroup: '/v1_4/groupmember/edit',
            groupAccess: '/v1_4/groupmember/access/list',
            updateGroupAccess: '/v1_4/groupmember/access/multipleaccess',
        },
        get: {
            customers: '/v1_4/customer/list',
            customerGroup: '/v1_4/groupmember/customer/list'
        },
        update: {
            customerGroup: '/v1_4/groupmember/customer/apply'
        }
    },

    memberList: {
        get: {
            members: '/v1_4/memberprivilege/list',
            taskTypes: '/v1_4/tasktype/list',
            targetVisit: '/v1_4/action/target/list',
        },
        export: {
            members: '/v1_4/member/export',
        },
        update: {
            targetVisit: '/v1_4/action/target/set',
        },
        privilage:{
            list:'/v1_4/memberprivilege/memberlist',
            apply:'/v1_4/memberprivilege/apply'
        },
    },

    liveTracking: {
        get: {
            status: '/v1_4/team/list',
            memberList: '/v1_4/tracking/live/member/list',
            areaList: '/v1_4/tracking/live/location/list',
        },
        create: {
            area: '/v1_4/tracking/live/location/new',
        },
        update: {
            status: '/v1_4/tracking/live/configuration/status/edit',
            area: '/v1_4/tracking/live/location/edit',
            areaStatus: '/v1_4/tracking/live/location/edit/status',
        },
        delete: {
            area: '/v1_4/tracking/live/location/delete',
        },
        import: '/v1_4/tracking/live/location/import'
    },

    // task
    taskTypeId: '/v1_4/tasktype/get',
    taskType: '/v1_4/tasktype/list',
    taskSettingToggleCheckIn: '/v1_4/tracking/configuration/status/edit',
    taskSettingEditRadius: '/v1_4/tracking/configuration/radius/edit',
    taskResult: 'v1_4/result/task/list',
    taskHistory: 'v1_4/task/history',
    createTask: 'v1_4/task/create',
    editTask: 'v1_4/task/edit',
    taskAssignment: 'v1_4/task/assignment/get',
    createTaskApi:{
        create:{
            createTask: 'v1_4/task/cms/create'
        },
        template:{
            createTemplate: 'v1_4/task_template/create',
            getTemplate: 'v1_4/task_template/get'
        },
        upload:{
            document: 'v1_4/task_template/upload/document',
            image: 'v1_4/task_template/upload/image',
            signature: 'v1_4/task_template/upload/signature'
        }
    },
    editTaskApi:{
        edit:{
            editTask:'v1_4/task/cms/edit'
        },
        template:{
            editTemplate: 'v1_4/task/cms/edit/action'
        },
        upload:{
            document: 'v1_4/task/cms/edit/upload/document',
            image: 'v1_4/task/cms/edit/upload/image',
            signature: 'v1_4/task/cms/edit/upload/signature'
        }
    },
    lookup: {
        get: {
            lookups: '/v1_4/lookup/list',
            lookupValues: '/v1_4/lookupvalue/list',
        },
    },
    dashboardItem: {
        get: {
            revenue: 'v1_4/item/dashboard/revenue',
            sold: 'v1_4/item/dashboard/sold',
            order: 'v1_4/item/dashboard/order',
            topCustomer: 'v1_4/item/statistics/buying_customer',
            topItem: 'v1_4/item/statistics/sold'
        }
    },
    analytics:{
        heatMap: '/v1_4/task/completed/heatmap',
        summaryTask: '/v1_4/task/summarytaskanalyticcount',
        averageTask: '/v1_4/task/getavgtasktype',
    },

    addItem: {
        get: {
            categories: '/v1_4/itemcategory/list'
        },
        create: {
            category: '/v1_4/itemcategory/addcategory',
            item: '/v1_4/item/additem',
        },
    },

    itemList: {
        get: {
            categories: '/v1_4/itemcategory/list',
            items: '/v1_4/item/list'
        },
        delete: {
            category: '/v1_4/itemcategory/delete',
            item: '/v1_4/item/delete',
        },
        update: {
            category: '/v1_4/itemcategory/edit',
            item: '/v1_4/item/edit',
            itemStatus: '/v1_4/item/setactivation',
        },
        create: {
            category: '/v1_4/itemcategory/addcategory',
        },
        download: {
            template: '/v1_4/item/template',
        },
        upload: {
            item: '/v1_4/item/import',
        },
    },

    cashAdvancedMaster: {
        get: {
            members: '/v1_4/memberprivilege/list',
            history: '/v1_4/cash_advance/history',
        },
        update: {
            additionalBalance: '/v1_4/cash_advance/edit',
        },
        export: {
            member: '/v1_4/cash_advance/export',
        },
        import: {
            member: '/v1_4/cash_advance/import',
        },
    },

    cashAdvancedTransaction: {
        get: {
            transactions: '/v1_4/cash_advance/approval/list',
            transactionDetail: '/v1_3/result/detail',
        },
        approve: {
            transaction: '/v1_4/cash_advance/approval/approve',
        },
        reject: {
            transaction: '/v1_4/cash_advance/approval/reject',
        },
    },

    report: {
        get: {
            reports: '/v1_4/report/list',
            detail: '/v1_4/report/parameter/list',
            members: '/v1_4/report/parameter/lookup',
            options: '/v1_4/report/parameter/lookup',
            customers: '/v1_4/report/parameter/lookup',
            parameterLookup: '/v1_4/report/parameter/lookup',
        },
        preview: {
            report: '/v1_4/report/preview',
        },
        export: {
            report: '/v1_4/report/export',
            reportAbsense: '/v1_4/report/export/absence',
        },
    },

    builderReport: {
        get: {
            reports: '/v1_4/report/builder/list',
            parameter: '/v1_4/report/builder/parameter',
            format: '/v1_4/report/builder/format',
            column: '/v1_4/report/builder/column',
        },
        preview: {
            report: '/v1_4/report/builder/preview',
        },
        create: {
            report: '/v1_4/report/builder/new',
        },
        update: {
            report: '/v1_4/report/builder/new',
        },
        delete: {
            report: '/v1_4/report/builder/delete',
        },
    },

    customer: {
        get: {
            customerTemplate: '/v1_4/customertemplate/list',
            customerList: '/v1_4/customer/list',
            customerAddress: '/v1_4/customer/addresstype',
            customerHistory: 'v1_4/customer/history',
            customerResult: '/v1_4/result/detail',
            customer: '/v1_4/customer/get'
        },
        update: {
            editCustomer: '/v1_4/customer/cms/edit',
            editActivation: '/v1_4/customer/activation',
        },
        create: {
            customer: '/v1_4/customer/new'
        },
        download: {
            template: '/v1_4/customer/export'
        },
        upload: {
            custUpload: '/v1_4/customer/import',
        },
        address:{
            edit: '/v1_4/customer/cms/editaddress',
            delete: '/v1_4/customer/cms/deleteaddress',
            add: '/v1_4/customer/cms/addaddress',
            chart:'/v1_4/customer/cms/countaddresstype',
            available: '/v1_4/customer/addresstype',
            read: '/v1_4/customer/cms/customermap',
        },
        new :'/v1_4/customer/cms/latestcutomer',
        expire: '/v1_4/customer/cms/nearcontract',
        pinned: {
            not : '/v1_4/customer/cms/countwithoutaddress',
            list :  {
                with : '/v1_4/customer/cms/withaddress',
                without : '/v1_4/customer/cms/withoutaddress',
            },
            detail: {
                with: '/v1_4/customer/cms/detailwithaddress',
                without: '/v1_4/customer/cms/detailwithoutaddress'
            },
        },
        request:{
            memberOption:'/v1_4/customerrequest/memberoption',
            notif:{
                activate: '/v1_4/customerrequest/notification/activate',
                setting: '/v1_4/customerrequest/notification/setting',
            }
        },
        increase: '/v1_4/customer/cms/increasecustomer',    
        vs:'/v1_4/customer/cms/activevsinactive',
        top:'/v1_4/customer/cms/listtopcustomer',
    },

    taskDiscussion: {
        get: {
            chats: '/v1_4/chat/list',
            chat: '/v1_4/chat/message',
            unread: '/v1_4/chat/unread',
            chatDetail: '/v1_4/chat/get',
        },
        read: {
            chat: '/v1_4/chat/read',
        },
        delete: {
            chat: '/v1_4/chat/delete',
        },
        send: {
            chat: '/v1_4/chat/send',
        },
    },

    notification: {
        update: {
            token: '/v1_4/user/edit/firebase-token',
        },
        get: {
            notifications: '/v1_4/notification/list',
            unreadCount: '/v1_4/notification/unread/count',
        },
        read: {
            notification: '/v1_4/notification/read',
        },
        readAll: {
            notification: '/v1_4/notification/mark-read',
        }
    },

    taskMonitoring: {
        get: {
            taskMonitoring: '/v1_4/task/monitoring',
        },
    },

    dashboard: {
        get: {
            taskPlanning: '/v1_4/task/planning',
            taskType: '/v1_4/tasktype/list',
            customer: '/v1_4/customer/list',
            memberPrivilage: '/v1_4/memberprivilege/list',
            completedTaskAssignment: '/v1_4/task/assignment/completed',

            dashboardNumber: '/v1_4/dashboard/number/get',
            newsFeed: '/v1_4/newsfeed/list',
            dashboard: '/v1_4/dashboard/get',
            customerCount: '/v1_4/customer/count',
            memberCount: '/v1_4/member/count',
            taskPlanningCount: '/v1_4/task/planning/count',
            completedActionCount: '/v1_4/action/completed/count',

            memberActive:'/v1_4/member/cms/countmemberactive',
            memberAttendance: '/v1_4/absence/countabsencemember',
            statComplateTask: '/v1_4/task/assignment/completed/count',
            statEffectiveDay: '/v1_4/task/assignment/completed/effectiveday',
            topMember: '/v1_4/task/assignment/topmember',
            mapPin: '/v1_4/task/maptask',
            detailMapPin: '/v1_4/task/detailmaptask',
            SummaryTask: '/v1_4/task/summarytaskanalytic',
            lastActivity: '/v1_4/newsfeed/list/lastactivity',
            
        },
    },

    dashboardBuilder: {
        get: {
            dashboards: '/v1_4/dashboard/list',
            dashboardNumberPreview: '/v1_4/dashboard/number/preview',
            dashboardBarPreview: '/v1_4/dashboard/barchart/preview',
            dashboardPiePreview: '/v1_4/dashboard/piechart/preview',
            dashboardGaugePreview: '/v1_4/dashboard/gauge/preview',
            dashboardBarDetail: '/v1_4/dashboard/barchart/detail',
            dashboardPieDetail: '/v1_4/dashboard/piechart/detail',
            dashboardGaugeDetail: '/v1_4/dashboard/gauge/detail',
            groupMember: '/v1_4/groupmember/list',
            taskTypes: '/v1_4/tasktype/list',
            taskType: '/v1_4/tasktype/get',
        },
        edit: {
            dashboardInactive: '/v1_4/dashboard/activation/inactive',
            dashboardActive: '/v1_4/dashboard/activation/active',
            dashboardBar: '/v1_4/dashboard/barchart/edit',
            dashboardPie: '/v1_4/dashboard/piechart/edit',
            dashboardGauge: '/v1_4/dashboard/gauge/edit',
        },
        create: {
            dashboardPie: '/v1_4/dashboard/piechart/add',
            dashboardBar: '/v1_4/dashboard/barchart/add',
            dashboardGauge: '/v1_4/dashboard/gauge/add',
            dashboardNumber: '/v1_4/dashboard/number/add',
        }
    },

    masterTask: {
        get: {
            taskList: '/v1_4/task/planningrevamp',
            member: '/v1_4/member/list',
            taskType: '/v1_4/tasktype/list',
            taskMonth: '/v1_4/task/month',
            taskCount: '/v1_4/task/planningrevamp/count',
            taskDetail: '/v1_4/task/cms/getdetail'
        },
        post: {
            reassignTask: '/v1_4/task/reassign',
            cancelTask: '/v1_4/task/cancel',
        },
        bulk: {
            reassign: '/v1_4/task/bulk/reassign',
            delete: '/v1_4/task/bulk/delete',
        }
    },

    memberJourney: {
        post: {
            getMembers: '/v1_4/memberprivilege/list',
            getJournies: '/v1_4/tracking/memberjourney',
            getStraightDistances: '/v1_4/tracking/straightdistance',
            getElapsedTimes: '/v1_4/tracking/elapsedtime',
            getLiveTrackingAreas: '/v1_4/tracking/live/location/member',
            getCustomers: '/v1_4/customer/assigned/list',
        }
    },

    invitation: {
        getInvitations: '/v1_4/user/invitation',
        rejectInvitation: '/v1_4/member/decline',
        acceptInvitation: '/v1_4/member/accept',
    },

    memberMaster: {
        deleteFaceRecog: '/v1_4/facerecognition/delete'
    },

    feedback: {
        generate: '/v1_4/feedback/generate',
        formData: '/v1_4/feedback/feedbackdatas?slug=:slug',
        submitForm: '/v1_4/feedback/feedbackrating?slug=:slug',
        list: '/v1_4/feedback/list',
        detail: '/v1_4/feedback/getmember',
    },
    feedbackMaster: {
        toggle: '/v1_4/feedback/activatefeedback',
        create: '/v1_4/feedback/createmasterfeedback',
        inactive: '/v1_4/feedback/inactive'
    },

    template: {
        privilege: '/v1_4/privilegetemplate/list',
    },

    licence: {
        list: '/v1_4/license/list',
    },

    user: {
        search: '/v1_4/user/search',
    },
}

export default urlApi

