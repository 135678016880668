import http from "./http"
import urlApi from "./url"
import { generateErrorMessage } from "../helpers"

interface IGenerateFeebackApi {
    user_id: number
    task_assignment_id: number
}

interface IFeedbackFormDataApi {
    slug: string
}

interface IFeedbackSubmitFormApi {
    slug: string
    rating: number
    description: string
    chip?: any
}

interface IFeedbackListApi {
    search: string
    page: number
}

interface IFeedbackDetailApi {
    user_id: number
    filter_rating?: number
    filter_task_type?: number
    page: number
}

interface IFeedbackMasterApi {
    is_master_feedback: string
}
interface IFeedbackMasterDeleteApi {
    master_feedback_id: string
}
interface IFeedbackCreateApi{
    rating: string
    description: string
}

export async function generateFeedbackApi(props: IGenerateFeebackApi) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi?.feedback?.generate, payload)
        if (response.code !== '200') throw generateErrorMessage(response?.message, 'Error generate feedback')
        return response.data
    } catch (error) {
        throw 'Error generate feedback'
    }
}

export async function feedbackFormDataApi(props: IFeedbackFormDataApi) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi?.feedback?.formData?.replace(':slug', payload?.slug))
        if (response.code !== '200') throw generateErrorMessage(response?.message, 'Error get form data')
        return response.data
    } catch (error) {
        throw 'Error get form data'
    }
}

export async function feedbackSubmitFormApi(props: IFeedbackSubmitFormApi) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi?.feedback?.submitForm?.replace(':slug', payload?.slug), { rating: props?.rating, description: props?.description, chip: props?.chip })
        if (response.code !== '200') throw generateErrorMessage(response?.message, 'Error submit form feedback')
        return response.data
    } catch (error) {
        throw 'Error submit form feedback'
    }
}

export async function feedbackListApi(props: IFeedbackListApi) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi?.feedback?.list, payload)
        if (response.code !== '200') throw generateErrorMessage(response?.message, 'Error get feedback list')
        return {
            data: response.data,
            metadata: response.metadata,
        }
    } catch (error) {
        throw 'Error get feedback list'
    }
}

export async function feedbackDetailApi(props: IFeedbackDetailApi) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            show: 10,
            ...props,
        }
        const response: any = await http(true).post(urlApi?.feedback?.detail, payload)
        if (response.code !== '200') throw generateErrorMessage(response?.message, 'Error get feedback detail')
        return {
            data: response.data,
            metadata: response.metadata,
        }
    } catch (error) {
        throw 'Error get feedback detail'
    }
}

export async function feedbackMasterApi(props: IFeedbackMasterApi) {
    try{
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props,
        };
        const response: any = await http(true).post(urlApi?.feedbackMaster?.toggle, payload);
        if(response.code !== '200') throw generateErrorMessage(response?.message, 'Error Master Feedback')
        return {
            data: response.data,
            metadata: response.metadata
        }
    } catch(error){
        throw error
    }
}
export async function feedbackMasterDeleteApi(props: IFeedbackMasterDeleteApi) {
    try{
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props,
        };
        const response: any = await http(true).post(urlApi?.feedbackMaster?.inactive, payload);
        if(response.code !== '200') throw generateErrorMessage(response?.message, 'Error Master Feedback')
        return {
            data: response.data,
            metadata: response.metadata
        }
    } catch(error){
        throw error
    }
}
export async function feedbackCreateApi(props: IFeedbackCreateApi) {
    try{
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props,
        };
        const response: any = await http(true).post(urlApi?.feedbackMaster?.create, payload);
        if(response.code !== '200') throw generateErrorMessage(response?.message, 'Error Master Feedback')
        return {
            data: response.data,
            metadata: response.metadata
        }
    } catch(error){
        throw error
    }
}