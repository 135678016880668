import React from 'react'
import { JGoogleIcon } from '../../../components'
import { 
    Button, 
    Heading, 
    Stack,
} from '@chakra-ui/react'

const DesignSystemButtons: React.FC = () => {
    return (
        <Stack>
            <Stack direction='row' alignItems='center'>
                <Heading minWidth='100px' size='chakra_label_large'>Primary</Heading>
                <Button 
                    leftIcon={<JGoogleIcon name='add' />} 
                    size='normal' 
                    variant='j-primary'
                >
                    Button
                </Button>
                <Button 
                    leftIcon={<JGoogleIcon name='add' />} 
                    size='normal' 
                    variant='j-primary' 
                    isDisabled
                >
                    Button
                </Button>
                <Button 
                    leftIcon={<JGoogleIcon name='add' />} 
                    size='small' 
                    variant='j-primary'
                >
                    Button
                </Button>
                <Button 
                    leftIcon={<JGoogleIcon name='add' />} 
                    size='small' 
                    variant='j-primary' 
                    isDisabled
                >
                    Button
                </Button>
            </Stack>
            <Stack direction='row' alignItems='center'>
                <Heading minWidth='100px' size='chakra_label_large'>Outline</Heading>
                <Button 
                    leftIcon={<JGoogleIcon name='add' />} 
                    size='normal' 
                    variant='j-outline'
                >
                    Button
                </Button>
                <Button 
                    leftIcon={<JGoogleIcon name='add' />} 
                    size='normal' 
                    variant='j-outline' 
                    isDisabled
                >
                    Button
                </Button>
                <Button 
                    leftIcon={<JGoogleIcon name='add' />} 
                    size='small' 
                    variant='j-outline'
                >
                    Button
                </Button>
                <Button 
                    leftIcon={<JGoogleIcon name='add' />} 
                    size='small' 
                    variant='j-outline' 
                    isDisabled
                >
                    Button
                </Button>
            </Stack>
            <Stack direction='row' alignItems='center'>
                <Heading minWidth='100px' size='chakra_label_large'>Text</Heading>
                <Button 
                    leftIcon={<JGoogleIcon name='add' />} 
                    size='normal' 
                    variant='j-text'
                >
                    Button
                </Button>
                <Button 
                    leftIcon={<JGoogleIcon name='add' />} 
                    size='normal' 
                    variant='j-text' 
                    isDisabled
                >
                    Button
                </Button>
                <Button 
                    leftIcon={<JGoogleIcon name='add' />} 
                    size='small' 
                    variant='j-text'
                >
                    Button
                </Button>
                <Button 
                    leftIcon={<JGoogleIcon name='add' />} 
                    size='small' 
                    variant='j-text' 
                    isDisabled
                >
                    Button
                </Button>
            </Stack>
            <Stack direction='row' alignItems='center'>
                <Heading minWidth='100px' size='chakra_label_large'>Tonal</Heading>
                <Button 
                    leftIcon={<JGoogleIcon name='add' />} 
                    size='normal' 
                    variant='j-tonal'
                >
                    Button
                </Button>
                <Button 
                    leftIcon={<JGoogleIcon name='add' />} 
                    size='normal' 
                    variant='j-tonal' 
                    isDisabled
                >
                    Button
                </Button>
                <Button 
                    leftIcon={<JGoogleIcon name='add' />} 
                    size='small' 
                    variant='j-tonal'
                >
                    Button
                </Button>
                <Button 
                    leftIcon={<JGoogleIcon name='add' />} 
                    size='small' 
                    variant='j-tonal' 
                    isDisabled
                >
                    Button
                </Button>
            </Stack>
        </Stack>
    )
}

export default DesignSystemButtons