import URL_API from '../../apis/urlApi'
import { http } from '../../apis'
import { handleErrorMessage } from '../helpers'
import { IAuthEmailPasswordSigninApiProps, IAuthGoogleSigninApiProps } from './authService.interface'

export async function authGoogleSigninApi(props: IAuthGoogleSigninApiProps) {
    try {
        const response: any = await http(false).post(URL_API.AUTH_GOOGLE_LOGIN, props)
        if (response.code !== '200') throw handleErrorMessage(response?.message, 'An error occurred while logging in with Google')
        return {
            data: response?.data,
            token: response?.token,
        }
    } catch (error: any) {
        if (typeof(error) == 'string') throw error
        else throw error?.status ? Number(error?.status) : 'UNKNOWN ERROR'
    }
}

export async function authEmailPasswordSigninApi(props: IAuthEmailPasswordSigninApiProps) {
    try {
        const response: any = await http(false).post(URL_API.AUTH_EMAIL_PASSWORD, props)
        if (response.code !== '200') throw handleErrorMessage(response?.message, 'An error occurred while logging in with email and password')
        return {
            data: response?.data,
            token: response?.token,
        }
    } catch (error: any) {
        if (typeof(error) == 'string') throw error
        else throw error?.status ? Number(error?.status) : 'UNKNOWN ERROR'
    }
}