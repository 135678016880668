import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import {
    CreateLiveTrackingAreaApi,
    DeleteLiveTrackingAreaApi,
    GetLiveTrackingListAreaApi,
    GetLiveTrackingListMemberApi,
    GetLiveTrackingStatusApi,
    UpdateLiveTrackingAreaApi,
    UpdateLiveTrackingAreaStatusApi,
    UpdateLiveTrackingStatusApi,
} from '../apis'
import { useCustomToast } from '../hooks'
interface CreateMemberSettingAreaProps {
    centerPoint: string
    centerPointLatLng: {
        lat: number
        lng: number
    }
    centerPointAddress: string
    radius: number
    isRadiusTolranceOn: boolean
    radiusTolerance: number
    members: any[]
    notify: any[]
    label: string
    startTime: string
    endTime: string
}

export function useGetMemberSettingStatus() {
    return useQuery(
        'member-setting-status',
        async () => {
            const response = await GetLiveTrackingStatusApi()
            const currentTeam = response?.data?.find((teams: any) => teams.id === Number(localStorage.getItem('current_team')))
            return currentTeam.profile_mobileconfig.is_live_tracking_strict_active === 1 ? true : false
        },
        {
            refetchOnWindowFocus: false,
        },
    )
}

export function useGetMemberSettingAreas(config: any, setMemberSettingArea: any) {
    const currentTeam = localStorage.getItem('current_team')
    return useQuery(
        'member-setting-areas',
        () => GetLiveTrackingListAreaApi({ profile_id: Number(currentTeam), page: config?.page}),
        {onSuccess(data) {
                setMemberSettingArea((prev: any[]) => {
                    return [
                        ...prev,
                        ...data?.data
                    ]
                })
            },
            // enabled: false,
            refetchOnWindowFocus: false,
        }
    )
}
export function useSearchMemberSettingArea(config:any){
    const currentTeam = localStorage.getItem('current_team')
    return useQuery(
        'search-member-setting-areas',
        () => GetLiveTrackingListAreaApi({profile_id: Number(currentTeam), search: config}),
        {enabled: config.length>0}
    )
}
export function useGetMemberSettingMemberList() {
    const { t } = useTranslation()
    return useQuery(
        'member-setting-member-list',
        async () => {
            const currentTeam = localStorage.getItem('current_team')
            const response = await GetLiveTrackingListMemberApi({ profile_id: Number(currentTeam), })
            const data: any = []
            response.data.map((g: any) => {
                const group: any = {}
                group.id = g.id
                group.checked = false
                group.checked_notify = false
                group.display_name = g.type === 'group' ? g.display_name : t('pengaturan_anggota_lainnya')
                const members: any = []
                g.members.map((m: any) => {
                    const member: any = {}
                    member.checked = false
                    member.checked_notify = false
                    member.id = g.type === 'group' ? m.user_mapping_profile.id : m.id
                    member.display_name = g.type === 'group' ? m.user_mapping_profile.user.display_name : m.user.display_name
                    member.photo_url = g.type === 'group' ? m.user_mapping_profile.user.photo_url : m.user.photo_url
                    member.username = g.type === 'group' ? m.user_mapping_profile.user.username : m.user.username
                    members.push(member)
                })
                group.members = members
                data.push(group)
            })
            return data
        },
        {
            refetchOnWindowFocus: false,
        },
    )
}

export function useGetMemberSettingMemberListForNotify() {
    const { t } = useTranslation()
    return useQuery(
        'member-setting-member-list-for-notify',
        async () => {
            const currentTeam = localStorage.getItem('current_team')
            const response = await GetLiveTrackingListMemberApi({ profile_id: Number(currentTeam) })
            const data: any = []
            response.data.map((g: any) => {
                const group: any = {}
                group.id = g.id
                group.checked = false
                group.display_name = g.type === 'group' ? g.display_name : t('pengaturan_anggota_lainnya')
                const members: any = []
                g.members.map((m: any) => {
                    const member: any = {}
                    member.checked = false
                    member.id = g.type === 'group' ? m.user_mapping_profile.id : m.id
                    member.display_name = g.type === 'group' ? m.user_mapping_profile.user.display_name : m.user.display_name
                    member.photo_url = g.type === 'group' ? m.user_mapping_profile.user.photo_url : m.user.photo_url
                    member.username = g.type === 'group' ? m.user_mapping_profile.user.username : m.user.username
                    members.push(member)
                })
                group.members = members
                data.push(group)
            })
            return data
        },
        {
            refetchOnWindowFocus: false,
        },
    )
}

export function useCreateMemberSettingArea(area: any) {
    return useQuery(
        'create-member-setting-area',
        async () => {
            const currentTeam = localStorage.getItem('current_team')
            let tempMembers: any = []
            let tempNotify: any = []
            area.members.map((group: any) => {
                group.members.map((member: any) => {
                    if (member.checked && !tempMembers.includes(member.id)) tempMembers.push(member.id)
                })
            })
            area.members.map((group: any) => {
                group.members.map((member: any) => {
                    if (member.checked_notify === true) tempNotify?.push(member.id)
                })
            })
            const payload = {
                profile_id: Number(currentTeam),
                label: area.label,
                radius: area.radius,
                is_radius_tolerance_active: area.isRadiusTolranceOn === true ? 1 : 0,
                radius_tolerance: area.radiusTolerance,
                latitude: area.centerPointLatLng.lat,
                longitude: area.centerPointLatLng.lng,
                address: area.centerPointAddress,
                start_date: area.startTime,
                end_date: area.endTime,
                member_profile_notify_id: JSON.stringify(tempNotify),
                member_profile_id: JSON.stringify(tempMembers),
            }
            const response = await CreateLiveTrackingAreaApi(payload)
            return response
        },
        {
            enabled: false,
        },
    )
}

export function useEditMemberSettingStatus(status: boolean) {
    return useQuery(
        'edit-member-setting-status',
        async () => {
            const currentTeam = localStorage.getItem('current_team')
            const payload = {
                profile_id: Number(currentTeam),
                is_live_tracking_strict_active: status === true ? 0 : 1,
            }
            const response = await UpdateLiveTrackingStatusApi(payload)
            return response
        },
        {
            enabled: false,
        },
    )
}

export function useEditMemberSettingStatusArea(area: any) {
    return useQuery(
        'edit-member-setting-status-area',
        async () => {
            const currentTeam = localStorage.getItem('current_team')
            const payload = {
                profile_id: Number(currentTeam),
                live_tracking_location_id: Number(area.id),
                is_active: area.is_active === 1 ? 0 : 1,
            }
            const response = await UpdateLiveTrackingAreaStatusApi(payload)
            return response
        },
        {
            enabled: false,
        },
    )
}

export function useEditMemberSettingArea(area: any) {
    const { showToast } = useCustomToast()
    const { t } = useTranslation()
    return useQuery(
        'edit-member-setting-area',
        async () => {
            const currentTeam = localStorage.getItem('current_team')
            const tempMembers: any = []
            let tempNotify: any = []
            area.members.map((group: any) => {
                group.members.map((member: any) => {
                    if (member.checked && !tempMembers.includes(member.id)) tempMembers.push(member.id)
                })
            })
            area.members.map((group: any) => {
                group.members.map((member: any) => {
                    if (member.checked_notify === true) tempNotify?.push(member.id)
                })
            })
            const payload = {
                live_tracking_location_id: area.id || 0,
                profile_id: Number(currentTeam),
                label: area.label,
                radius: area.radius,
                is_radius_tolerance_active: area.isRadiusTolranceOn === true ? 1 : 0,
                radius_tolerance: area.radiusTolerance,
                latitude: area.centerPointLatLng.lat,
                longitude: area.centerPointLatLng.lng,
                address: area.centerPointAddress,
                start_date: area.startTime,
                end_date: area.endTime,
                member_profile_notify_id: JSON.stringify(tempNotify),
                member_profile_id: JSON.stringify(tempMembers),
            }
            const response = await UpdateLiveTrackingAreaApi(payload)
            return response
        },
        {
            onSuccess: () => {
                showToast({
                    status: 'success',
                    description: t('pengaturan_anggota_sukses_update_alt')
                })
            },
            enabled: false,
        },
    )
}

export function useDeleteMemberSettingArea(area: any) {
    return useQuery(
        'delete-member-setting-status-area',
        async () => {
            const currentTeam = localStorage.getItem('current_team')
            const payload = {
                profile_id: Number(currentTeam),
                live_tracking_location_id: area.id,
            }
            const response = await DeleteLiveTrackingAreaApi(payload)
            return response
        },
        {
            enabled: false,
        },
    )
}
