import URL_API from '../apis/urlApi'
import { http } from '../apis'
import { handleErrorMessage, handleGetTeamId } from './helpers'

interface UpdateThemeLogoApiProps {
    logo_cms: any
    theme_id: string
    is_reset?: boolean
}

export async function getTeamDetailApi() {
    try {
        const payload = { 
            profile_id: handleGetTeamId(), 
        }
        const response: any = await http(true).post(URL_API.TEAM_DETAIL, payload)
        if (response.code !== '200') throw handleErrorMessage(response?.message, 'error get team detail')
        return response?.data
    } catch (error) {
        throw error
    }
}

export async function updateThemeLogoApi(props: UpdateThemeLogoApiProps) {
    try {
        const formData = new FormData()
        formData.set('profile_id', handleGetTeamId()??'')
        formData.set('logo_cms', props?.logo_cms)
        formData.set('theme_id', props?.theme_id)
        formData.set('is_reset', props?.is_reset ? '1' : '0')
        const response: any = await http(true).post(URL_API.TEAM_UPDATE_THEME_LOGO, formData)
        if (response.code !== '200') throw handleErrorMessage(response?.message, 'error update theme and logo')
        return response?.data
    } catch (error) {
        throw error
    }
}