import { Avatar, Button, Center, Flex, Heading, Switch, useColorMode } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { JButton, JGoogleIcon, JTypography } from '../../../components'

const Area = (props: any) => {
    const { t } = useTranslation()

    const { colorMode } = useColorMode()

    return (
        <Flex direction='column'>
            <Flex
                borderRadius={'12px'}
                direction={'column'}
                gap={'12px'}
                marginBottom={'16px'}
                overflow='hidden'
                boxShadow='0px 2px 4px 0px #00000040'
                marginX='4px'
                backgroundColor='surfacePlus2'
            >
                <Flex
                    justifyContent={'space-between'}
                    padding='16px 24px'
                    // backgroundColor={props?.is_active ? '#0079D2' : '#BA1A1A'}
                    bg={colorMode == 'dark' ? 'linear-gradient(90deg, rgba(0, 62, 108, 1), rgba(37, 42, 48, 1))' : 'linear-gradient(90deg, rgba(0, 121, 210, 1), rgba(0, 62, 108, 1))'}
                >
                    <Heading color='white' size={'chakra_title_medium'}>{props.label}</Heading>
                    <Flex
                        borderRadius='4px'
                        height='24px'
                        minWidth='128px'
                        backgroundColor='#fafafa'
                        overflow='hidden'
                        onClick={props.onClickSwitch}
                        cursor='pointer'
                    >
                        {props?.is_active
                            ?   <Center bg='linear-gradient(180deg, #006D35 0%, #00D367 100%)' width='64px' height='24px'>
                                    <Heading size='chakra_label_medium' color='white'>
                                        Active
                                    </Heading>
                                </Center>
                            :   <Flex width='64px' height='24px'>
                                </Flex>
                        }

                        {!props?.is_active
                            ?   <Center bg='linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 100%)' width='64px' height='24px'>
                                    <Heading size='chakra_label_medium' color='#43474E'>
                                        In Active
                                    </Heading>
                                </Center>
                            :   <Flex width='64px' height='24px'>
                                </Flex>
                        }
                    </Flex>
                </Flex>

                <Flex padding='16px 24px' direction='column' gap='16px'>
                    <Flex alignItems='center' gap='8px'>
                        <JGoogleIcon name='location_on' color='primary' />
                        <Heading w={'100%'} size={'chakra_body_medium'}>{props.address}</Heading>
                    </Flex>

                    <Flex alignItems='center' gap='8px'>
                        <JGoogleIcon name='settings_accessibility' color='primary' />
                        <Heading size={'chakra_body_medium'}>Radius: {props.radius} meter</Heading>
                    </Flex>

                    <Flex
                        gap={'12px'}
                        alignItems={'center'}
                    >
                        <Flex>
                            {props.members.map((member: any, index: number) => {
                                if (index <= 2)
                                    return (
                                        <Avatar
                                            key={index}
                                            size='sm'
                                            width={'28px'}
                                            height={'28px'}
                                            name={member.user.display_name}
                                            src={member.user.photo_url}
                                            marginLeft={index > 0 ? '-5px' : '0px'}
                                        />
                                    )
                            })}
                        </Flex>
                        <Heading size={'chakra_body_medium'}>
                            {t('pengaturan_anggota_area_jumlah_anggota', { total: props.members.length })}
                        </Heading>
                    </Flex>
                    <Flex gap={'16px'} justifyContent={'space-between'}>
                        <Flex gap='16px'>
                            <Button
                                size={'normal'}
                                variant={'j-primary'}
                                leftIcon={<JGoogleIcon name={'edit'} />}
                                onClick={props.onClickUpdate}
                                disabled={!props.status}
                            >
                                {t('edit_button_modal')}
                            </Button>
                            <Button
                                size={'normal'}
                                variant={'j-outline'}
                                leftIcon={<JGoogleIcon name={'delete'} />}
                                onClick={props.onClickDelete}
                                disabled={!props.status}
                            >
                                {t('pengaturan_anggota_hapus')}
                            </Button>
                        </Flex>
                        <Flex>
                            <Button
                                size={'normal'}
                                variant={'j-text'}
                                leftIcon={<JGoogleIcon name={'info'} />}
                                onClick={props.onClickDetail}
                                disabled={!props.status}
                            >
                                {t('pengaturan_anggota_detail')}
                            </Button>
                        </Flex>

                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Area
