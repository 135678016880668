import React from 'react'
import { useSelector } from 'react-redux'
import { 
    Avatar, 
    Flex, 
    Heading,
    useColorMode, 
} from '@chakra-ui/react'

const TeamInformation = () => {
    const BACKGROUND_COLOR: any = {
        'Default': '#017ad2',
        'Grey-vis': '#f0f4fb',
        'Green-vis': '#96c45e',
        'Yellow-vis': '#f1b801',
        'Red-vis': '#e03d3d',
    }
    
    const teamV2: any = useSelector((state: any) => state?.teamV2)
    const sidebar = useSelector((state: any) => state?.sidebar)
    const { colorMode } = useColorMode()

    const teamDataString = localStorage.getItem('current_team_data') || ''
    const teamData = JSON.parse(teamDataString)
    const backgroundColor = colorMode === 'dark' 
        ?   'surfacePlus1' 
        :   teamV2?.detail?.theme_object
            ?   BACKGROUND_COLOR[teamV2?.detail?.theme_object?.code]
            :   'primary'

    return (
        <Flex
            padding='10px'
            borderRadius='16px'
            borderWidth='1px'
            borderColor='surfaceVariant'
            gap='16px'
            alignItems='center'
            height='44px'
            backgroundColor={backgroundColor}
            width='100%'
        >
            <Avatar
                name={teamData.name}
                src={teamData.logo?.logo}
                width='24px !important'
                height='24px !important'
            />
            <Heading
                size='chakra_title_medium'
                fontWeight='500'
                color={colorMode === 'light'
                    ?   teamV2?.detail?.theme_object?.code === 'Grey-vis' ? 'black' : '#FDFCFF'
                    :   'unset'
                }
                display={sidebar?.isReveal ? 'block' : 'none'}
            >
                {teamData.name}
            </Heading>
        </Flex>
    )
}

export default React.memo(TeamInformation)