import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Heading,
    Flex,
    Button,
    OrderedList,
    ListItem,
} from '@chakra-ui/react'
import { JGoogleIcon } from '../../../components'

interface IModalResult {
    modalResultDisclosure: any
    modalUploadDiscloseure: any
    upload: any
    file: any
}

const ModalResult: React.FC<IModalResult> = (props: IModalResult) => {
    const { 
        modalResultDisclosure,
        modalUploadDiscloseure, 
        upload, 
        file, 
    } = props

    const { t } = useTranslation()

    function handleGenerateInformation(row: any, key: any, error_type: any) {
        switch(error_type) {
            case 'not_found' :
                return t('pengaturan_anggota_error_type_not_found', { row, key })
            case 'not_provided' :
                return t('pengaturan_anggota_error_type_not_provided', { row, key })
            case 'duplicate' :
                return t('pengaturan_anggota_error_type_duplicate', { row, key })
            case 'format_invalid' :
                return t('pengaturan_anggota_error_type_format_invalid', { row, key })
            case 'value_invalid_greater' :
                return t('pengaturan_anggota_error_type_value_invalid_greater', { row, key })
            case 'value_invalid_lower' :
                return t('pengaturan_anggota_error_type_value_invalid_lower', { row, key })
            case 'max_member_notify' :
                return t('pengaturan_anggota_error_type_max_member_notify', { row, key })
        }
    }

    return (
        <Modal 
            isCentered
            isOpen={modalResultDisclosure?.isOpen} 
            onClose={modalResultDisclosure?.onClose}
            size='xl'
        >
            <ModalOverlay />
            <ModalContent backgroundColor='background' margin='0px'>
                <ModalHeader>
                    <Heading size='chakra_title_large'>
                        {t('pengaturan_anggota_modal_unggah_hasil_judul')}
                    </Heading>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody padding='16px 24px'>
                    <Flex direction='column'>
                        <Flex 
                            direction='column' 
                            marginTop='13px'
                            marginBottom='45px' 
                            gap='34px'
                        >
                            <Flex 
                                alignItems='center' 
                                justifyContent='center' 
                                gap='16px'
                            >
                                <JGoogleIcon name='task' color='success' />
                                <Heading size='chakra_title_large'>{t('pengaturan_anggota_modal_unggah_hasil_pengunggahan_selesai')}</Heading>
                            </Flex>
                            <Flex direction='column' gap='24px'>
                                <Heading size='chakra_title_medium'>
                                    {t('pengaturan_anggota_modal_unggah_hasil_nama_file')}: {file?.name}
                                </Heading>
                                <Heading size='chakra_title_medium'>
                                    {t('pengaturan_anggota_modal_unggah_hasil_total_lokasi')}: {upload?.total_location}
                                </Heading>
                                <Flex direction='column' gap='16px'>
                                    <Flex alignItems='center' gap='10px'>
                                        <JGoogleIcon name='task_alt' color='success' />
                                        <Heading size='chakra_title_medium'>
                                            {t('pengaturan_anggota_modal_unggah_hasil_berhasil')}: {upload?.success}
                                        </Heading>
                                    </Flex>
                                    <Flex alignItems='center' gap='10px'>
                                        <JGoogleIcon name='cancel' color='error' />
                                        <Heading size='chakra_title_medium'>
                                            {t('pengaturan_anggota_modal_unggah_hasil_gagal')}: {upload?.failed}
                                        </Heading>
                                    </Flex>
                                    <Flex alignItems='center' gap='10px'>
                                        <JGoogleIcon name='info' color='warning' />
                                        <Heading size='chakra_title_medium'>
                                            {t('pengaturan_anggota_modal_unggah_hasil_duplikat')}: {upload?.dulicate}
                                        </Heading>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex direction='column' gap='8px'>
                                <Heading size='chakra_title_medium'>
                                    {t('pengaturan_anggota_modal_unggah_hasil_keterangan')}
                                </Heading>
                                <Flex 
                                    direction='column'
                                    padding='24px 16px' 
                                    backgroundColor='surfacePlus1' 
                                    borderWidth='1px' 
                                    borderColor='outline'
                                    borderRadius='8px'
                                    height='25vh'
                                    overflowY='auto'
                                >
                                    {
                                        upload?.errors?.length === 0 &&
                                        <Heading size='chakra_body_medium'>{t('pengaturan_anggota_modal_unggah_hasil_no_error')}</Heading>
                                    }

                                    {
                                        upload?.errors?.length > 0 &&
                                        <OrderedList>
                                            {
                                                upload?.errors?.map((v: any, index: number) => {
                                                    return (
                                                        <ListItem 
                                                            key={index} 
                                                            fontFamily='Roboto'
                                                            fontWeight='400'
                                                            fontSize='14px'
                                                            lineHeight='20px'
                                                            letterSpacing='0.25px'
                                                            color='onBackground'
                                                            marginInlineStart={`${upload?.failed?.toString()?.length / 2}em`}
                                                        >
                                                            <Heading size='chakra_body_medium'>
                                                                {handleGenerateInformation(v?.index, v?.key, v?.error_type)}
                                                            </Heading>
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                        </OrderedList> 
                                    }
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex 
                            alignItems='center' 
                            gap='24px' 
                            justifyContent='flex-end'
                        >
                            <Button 
                                variant='j-outline'
                                onClick={() => {
                                    modalResultDisclosure?.onClose()
                                    modalUploadDiscloseure?.onOpen()
                                }}
                            >
                                {t('pengaturan_anggota_modal_unggah_button_unggah_ulang')}
                            </Button>
                            <Button 
                                variant='j-primary'
                                onClick={() => {
                                    modalResultDisclosure?.onClose()
                                }}
                            >
                                {t('pengaturan_anggota_modal_unggah_button_tutup')}
                            </Button>
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default memo(ModalResult)