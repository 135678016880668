import http from "./http";
import urlApi from "./url";

export interface GetMenuPrivilegeMemberList{
  member_id: number
}

export interface MenuPrivilegeApplyProps{
  member_id: number
  menu:any
  menu_action:any
  toast?: any
}

export const menuListApi = async (team: string) => {
  try {
    const bodyPayload = new FormData()
    bodyPayload.set('profile_id', team)
    let response: any = await http(true).post(urlApi.menu_list, bodyPayload)
    return response.data
  } catch (error) {
    console.error(error);
    return null
  }
}

export async function menuList() {
  try{
    const team = localStorage.getItem('current_team') || ''
      const payload = { 
        profile_id: team,
      }
      const response: any = await http(true).post(urlApi?.menu.list, payload)
      return response.data
  } catch (error: any) {
      throw 'Error Get Member List'
  }
} 
export async function menuPrivilegeMemberList(props: GetMenuPrivilegeMemberList) {
  try{
    const team = localStorage.getItem('current_team') || ''
      const payload = { 
        profile_id: team,
        ...props
      }
      const response: any = await http(true).post(urlApi?.menu.privilege.member_list, payload)
      return response.data
  } catch (error: any) {
      throw 'Error Get Member List'
  }
}

export async function MenuPrivilegeApply(props: MenuPrivilegeApplyProps) {
  try{
    const team = localStorage.getItem('current_team') || ''
      const payload = { 
        profile_id: team,
        ...props
      }
      const response: any = await http(true).post(urlApi?.menu.privilege.apply, payload)
      if(response.code == '200'){
        props.toast('200', 'master_anggota_akses_menu_toast_done')
      } else {
        props.toast(response.code, 'master_anggota_akses_menu_toast_nodone')
      }
      return response.data
  } catch (error: any) {
      throw 'Error Get Member List'
  }
} 

