import React from 'react'
import StandaloneModule from './StandaloneModule'
import ModuleWrapper from './ModuleWrapper'
import { useTranslation } from 'react-i18next'
import { urlRouter } from '../../../router'
import { useSelector } from 'react-redux'
import { Divider, Stack, useColorMode } from '@chakra-ui/react'

const Modules = () => {
    const { t } = useTranslation()
    const { data } = useSelector((state: any) => state.menuPrivilage)
    const { colorMode } = useColorMode()

    const teamDataString = localStorage.getItem('current_team_data') || ''
    const teamData = JSON.parse(teamDataString)

    let menus = [
        {
            access: 1009,
            accessor: [1009],
            _class: 'beranda',
            name: t('sidebar_beranda'),
            icon: 'dashboard',
            to: urlRouter.dashboard,
        },
        {
            access: 1008,
            accessor: [1008],
            _class: 'pelanggan',
            name: t('sidebar_pelanggan'),
            icon: 'support_agent',
            submenu: [
                {
                    name: t('sidebar_master_pelanggan'),
                    to: urlRouter.CUSTOMER_MASTER,
                    access: 1008,
                },
                {
                    name: t('customer_setting_sidebar'),
                    to: urlRouter.customerSetting,
                    access: 1008,
                },
                {
                    name: t('customer_analis_sidebar'),
                    to: urlRouter.customerAnalisis,
                    access: 1008,
                },
            ],
        },
        {
            access: 1013,
            accessor: [1013, 1003, 1020],
            _class: 'tugas',
            name: t('sidebar_tugas'),
            icon: 'task',
            submenu: [
                {
                    name: t('sidebar_master_tugas'),
                    to: urlRouter.taskMaster,
                    access: 1013,
                },
                {
                    name: t('sidebar_tipe_tugas'),
                    to: urlRouter.taskType,
                    access: 1003,
                },
                {
                    name: t('sidebar_pemantauan_tugas'),
                    to: urlRouter.taskMonitoring,
                    access: 1020,
                },
                {
                    name: t('sidebar_pengaturan_tugas'),
                    to: urlRouter.taskSetting,
                    access: 1013,
                },
                {
                    name: t('sidebar_task-analytics'),
                    to: urlRouter.taskAnalytics,
                    access: 1013,
                },
            ],
        },
        // {
        //     access: 1009,
        //     accessor: [1009],
        //     _class: 'TASK_CALENDAR_VIEW_SIDEBAR',
        //     name: t('TASK_CALENDAR_VIEW_SIDEBAR'),
        //     icon: 'task',
        //     to: urlRouter.TASK_CALENDAR_VIEW,
        // },
        {
            access: 1028,
            accessor: [1028],
            _class: 'MASTER_OUTLET_PAGE_SIDEBAR',
            name: t('MASTER_OUTLET_PAGE_SIDEBAR'),
            icon: 'handshake',
            to: urlRouter.MASTER_OUTLET,
        },
        {
            access: 1029,
            accessor: [1029],
            _class: 'MasterStoreSidebar',
            name: t('MasterStoreSidebar'),
            icon: 'store',
            to: urlRouter.MASTER_STORE,
        },
        {
            access: 1030,
            accessor: [1030],
            _class: 'MASTER_PRODUCT_PAGE_SIDEBAR',
            name: t('MASTER_PRODUCT_PAGE_SIDEBAR'),
            icon: 'category',
            to: urlRouter.MASTER_PRODUCT,
        },
        // {
        //     access: 1009,
        //     accessor: [1009],
        //     _class: 'MASTER_INGREDIENT_SIDEBAR_1727235747122',
        //     name: t('MASTER_INGREDIENT_SIDEBAR'),
        //     icon: 'kitchen',
        //     to: urlRouter.MASTER_INGREDIENT,
        // },
        // {
        //     access: 1009,
        //     accessor: [1009],
        //     _class: 'MASTER_RECIPE_SIDEBAR_1727321134386',
        //     name: t('MASTER_RECIPE_SIDEBAR'),
        //     icon: 'menu_book',
        //     to: urlRouter.MASTER_RECIPE,
        // },
        {
            access: 1013,
            accessor: [1013],
            _class: 'Inventory',
            name: 'Inventory',
            icon: 'task',
            submenu: [
                {
                    name: 'Dashboard Inventory',
                    to: urlRouter.INVENTORY_DASHBOARD,
                    access: 1013,
                },
                {
                    name: 'Master Item',
                    to: urlRouter.INVENTORY_MASTER_ITEM,
                    access: 1013,
                },
                {
                    name: 'Master Category',
                    to: urlRouter.INVENTORY_MASTER_CATEGORY,
                    access: 1013,
                },
                {
                    name: 'Inventory',
                    to: urlRouter.INVENTORY_INVENTORY,
                    access: 1013,
                },
                {
                    name: 'Inventory Usage',
                    to: urlRouter.INVENTORY_USAGE,
                    access: 1013,
                },
                {
                    name: 'Riwayat Stok',
                    to: urlRouter.INVENTORY_STOCK_HISTORY,
                    access: 1013,
                },
            ],
        },
        {
            access: 1031,
            accessor: [1031],
            _class: 'POS',
            name: 'POS',
            icon: 'task',
            submenu: [
                {
                    name: 'Dashboard',
                    to: urlRouter.POS_DASHBOARD,
                    access: 1031,
                },
                {
                    name: t('COMMISSION_SIDEBAR'),
                    to: urlRouter.POS_COMMISSION,
                    access: 1031,
                },
                {
                    name: t('TRANSACTION_SIDEBAR'),
                    to: urlRouter.POS_TRANSACTION,
                    access: 1031,
                },
                {
                    name: 'Settlement',
                    to: urlRouter.SETTLEMENT_SETTLEMENT,
                    access: 1031,
                },
            ],
        },
        {
            access: 1027,
            accessor: [1027],
            _class: 'beranda',
            name: t('ticket_management_sidebar'),
            icon: 'confirmation_number',
            submenu: [
                {
                    name: t('ticket_management_sidebar_master'),
                    to: urlRouter.MANAGEMENT_TICKET,
                    access: 1027,
                },
                {
                    name: t('ticket_management_sidebar_setting'),
                    to: urlRouter.MANAGEMENT_TICKET_SETTING,
                    access: 1027,
                },
            ],
        },
        {
            access: 1013,
            accessor: [1013],
            _class: 'invoice_sidebar',
            name: t('invoice_sidebar'),
            icon: 'receipt_long',
            new: true,
            submenu: [
                {
                    name: t('invoice_dashboard_sidebar'),
                    to: urlRouter.INVOICE_DASHBOARD,
                    access: 1013,
                },
                {
                    name: t('invoice_master_sidebar'),
                    to: urlRouter.INVOICE_MASTER,
                    access: 1013,
                },
                {
                    name: t('receive_payment_sidebar'),
                    to: urlRouter.PAYMENT_RECEIVE,
                    access: 1013,
                },
                {
                    name: t('invoice_setting_sidebar'),
                    to: urlRouter.INVOICE_SETTING,
                    access: 1013,
                },
            ],
        },
        {
            access: 1013,
            accessor: [1013],
            _class: 'barang',
            name: t('sidebar_barang'),
            icon: 'category',
            submenu: [
                {
                    name: t('sidebar_dasbor_barang'),
                    to: urlRouter.itemDashboard,
                    access: 1013,
                },
                {
                    name: t('sidebar_daftar_barang'),
                    to: urlRouter.itemList,
                    access: 1013,
                },
                {
                    name: t('sidebar_tambah_barang'),
                    to: urlRouter.itemCreate,
                    access: 1013,
                },
            ],
        },
        {
            access: 1014,
            accessor: [1014, 1015],
            _class: 'cash_advanced',
            name: t('sidebar_cash_advanced'),
            icon: 'payments',
            submenu: [
                {
                    name: t('sidebar_cash_advanced_master'),
                    to: urlRouter.cashAdvance,
                    access: 1014,
                },
                {
                    name: t('sidebar_cash_advanced_transaction'),
                    to: urlRouter.cashAdvanceTransaction,
                    access: 1015,
                },
            ],
        },
        {
            access: 1014,
            accessor: [1014, 1015],
            _class: 'leads',
            name: t('sidebar_leads'),
            icon: 'dataset',
            submenu: [
                {
                    name: t('sidebar_leads_master'),
                    to: urlRouter.LEADS_MASTER,
                    access: 1014,
                },
                {
                    name: t('sidebar_leads_dashboard'),
                    to: urlRouter.LEADS_DASHBOARD,
                    access: 1015,
                },
                {
                    name: t('sidebar_leads_activity'),
                    to: urlRouter.LEADS_ACTIVITY,
                    access: 1015,
                },
                {
                    name: t('sidebar_leads_setting'),
                    to: urlRouter.LEADS_SETTING,
                    access: 1015,
                },
            ],
        },
        {
            name: 'divider',
        },
        {
            access: 1009,
            accessor: [1009],
            _class: 'tim',
            name: t('team_menu'),
            icon: 'diversity_3',
            to: urlRouter.TEAM_CONFIGURATION,
            new: true,
        },
        {
            access: 1017,
            accessor: [1017, 1023, 1024],
            _class: 'anggota',
            name: t('sidebar_anggota'),
            icon: 'groups',
            submenu: [
                {
                    name: t('sidebar_anggota_master'),
                    to: urlRouter.memberMaster,
                    access: 1017,
                },
                {
                    name: t('sidebar_grup_anggota'),
                    to: urlRouter.memberGroup,
                    access: 1017,
                },
                {
                    name: t('sidebar_anggota_anggota'),
                    to: urlRouter.memberPrivilage,
                    access: 1017,
                },
                {
                    name: t('sidebar_pengaturan_anggota'),
                    to: urlRouter.memberSetting,
                    access: 1023,
                },
                {
                    name: t('feedback_anggota'),
                    to: urlRouter.memberFeedback,
                    access: 1024,
                },
            ],
        },
        {
            access: 1025,
            accessor: [1025, 1026],
            _class: 'cuti',
            name: t('leave_sidebar'),
            icon: 'event_available',
            submenu: [
                {
                    name: t('leave_approval_sidebar'),
                    to: urlRouter.LEAVE_APPROVAL,
                    access: 1025,
                },
                {
                    name: t('leave_setting_sidebar'),
                    to: urlRouter.LEAVE_SETTING,
                    access: 1026,
                },
            ],
            new: true,
        },
        {
            access: 1021,
            accessor: [1021, 1022],
            _class: 'absensi',
            name: t('sidebar_absensi'),
            icon: 'co_present',
            submenu: [
                {
                    name: t('sidebar_absensi_monitoring'),
                    to: urlRouter.attendaceMonitoring,
                    access: 1021,
                },
                {
                    name: t('sidebar_absensi_pengaturan'),
                    to: urlRouter.attendaceSetting,
                    access: 1022,
                },
            ],
        },
        {
            access: 4,
            accessor: [4],
            _class: 'lokasi',
            name: t('sidebar_lokasi'),
            icon: 'location_on',
            to: urlRouter.lastLocation,
        },
        {
            access: 3,
            accessor: [3],
            _class: 'perjalanan',
            name: t('sidebar_perjalanan'),
            icon: 'map',
            to: urlRouter.memberJourney,
        },
        {
            name: 'divider',
        },
        {
            access: 1012,
            accessor: [1012],
            _class: 'konfigurasi',
            name: t('sidebar_konfigurasi'),
            icon: 'tune',
            submenu: [
                {
                    name: t('sidebar_konfigurasi_lookup'),
                    to: urlRouter.lookup,
                    access: 1012,
                },
            ],
        },
        {
            access: 1001,
            accessor: [1001],
            _class: 'laporan',
            name: t('sidebar_bulletin'),
            icon: 'assignment_turned_in',
            to: urlRouter.BULLETIN,
        },
        {
            access: 1016,
            accessor: [1016],
            _class: 'builder',
            name: t('sidebar_builder'),
            icon: 'construction',
            submenu: [
                {
                    name: t('sidebar_dashboard_builder'),
                    to: urlRouter.builderDashboard,
                    access: 1016,
                },
                {
                    name: t('sidebar_report_builder'),
                    to: urlRouter.builderReport,
                    access: 1016,
                },
            ],
        },
        {
            access: 1001,
            accessor: [1001],
            _class: 'laporan',
            name: t('sidebar_report'),
            icon: 'report',
            to: urlRouter.report,
        },
    ]

    teamData?.id == 714 &&
        menus?.unshift({
            access: 1009,
            accessor: [1009],
            _class: 'beranda',
            name: t('personal_dashboard_judul'),
            icon: 'dashboard',
            to: urlRouter.PERSONAL_DASHBOARD,
        })

    teamData?.id == 715 &&
        menus?.unshift({
            access: 1009,
            accessor: [1009],
            _class: 'beranda',
            name: t('personal_dashboard_judul'),
            icon: 'dashboard',
            to: urlRouter.PERSONAL_DASHBOARD_PCO,
        })

    menus = menus.filter((menu) => {
        return (
            menu._class != 'barang' &&
            // menu?._class != 'MASTER_OUTLET_PAGE_SIDEBAR' &&
            // menu?._class != 'MASTER_PRODUCT_PAGE_SIDEBAR' &&
            // menu?._class != 'Settlement' &&
            menu?._class != 'invoice_sidebar' &&
            // menu?._class != 'POS' &&
            menu?._class != 'Inventory' &&
            menu?._class != 'leads'
        )
    })

    return (
        <Stack spacing="8px">
            {data &&
                menus?.map((menu: any, menuIndex: number) => {
                    if (menu?.name === 'divider')
                        return (
                            <Divider
                                key={menuIndex}
                                borderColor={
                                    colorMode === 'dark'
                                        ? 'onSurface'
                                        : 'surfaceVariant'
                                }
                            />
                        )

                    let haveAccessMenu: boolean = false
                    menu.accessor.map((accessor: any) => {
                        if (data.find((x: any) => x.id === accessor))
                            return (haveAccessMenu = true)
                        else return
                    })
                    if (!haveAccessMenu) return

                    return menu?.submenu ? (
                        <ModuleWrapper menu={menu} />
                    ) : (
                        <StandaloneModule menu={menu} />
                    )
                })}
        </Stack>
    )
}

export default React.memo(Modules)
