import React, { useEffect } from 'react'
import JGoogleIcon from '../JGoogleIcon/JGoogleIcon'
import TeamInformation from './components/TeamInformation'
import Version from './components/Version'
import Modules from './components/Modules'
import { IL_STARTS } from '../../assets'
import { sidebarSlice, teamSliceV2 } from '../../redux'
import { useDispatch, useSelector } from 'react-redux'
import { 
    Box,
    Image, 
    Stack, 
    useColorMode, 
} from '@chakra-ui/react'

const Sidebar = () => {
    const { colorMode } = useColorMode()

    const BACKGROUND_COLOR: any = {
        'Default': '#017ad2',
        'Grey-vis': '#f0f4fb',
        'Green-vis': '#96c45e',
        'Yellow-vis': '#f1b801',
        'Red-vis': '#e03d3d',
    }

    const dispatch = useDispatch<any>()
    const teamV2: any = useSelector((state: any) => state?.teamV2)
    const sidebar = useSelector((state: any) => state?.sidebar)

    const backgroundColor = colorMode === 'dark' 
        ?   'surfacePlus1' 
        :   teamV2?.detail?.theme_object
            ?   BACKGROUND_COLOR[teamV2?.detail?.theme_object?.code]
            :   'primary'

    useEffect(() => {
        dispatch(teamSliceV2?.getTeamDetailAction())
    }, [])

    return (
        <Stack
            width={sidebar?.isReveal ? '248px' : '88px'}
            transition='0.3s'
            backgroundColor={backgroundColor}
            overflowY='auto'
            paddingY='16px'
            position='relative'
            zIndex={12}
        >
            <Stack position='relative' gap='24px'>
                <Image 
                    src={IL_STARTS} 
                    position='absolute'
                    top='0px'
                    left='0px'
                    right='0px'
                    // bottom='0px'
                    objectFit='cover'
                    marginTop='auto'
                    zIndex='0'
                />
                <JGoogleIcon 
                    name='menu' 
                    marginX='var(--sidebarPadding) !important'
                    cursor='pointer'
                    color={colorMode === 'light'
                        ?   teamV2?.detail?.theme_object?.code === 'Grey-vis' ? 'black' : '#FDFCFF'
                        :   'unset'
                    }
                    onClick={() => {
                        dispatch(sidebarSlice?.sidebarReveal())
                    }}
                    position='relative'
                    zIndex={1}
                />
                <Box paddingX='16px'>
                    <TeamInformation />
                </Box>
                <Modules />
                {sidebar?.isReveal
                    ?   <Version />
                    :   null
                }
            </Stack>
        </Stack>
    )
}

export default React.memo(Sidebar)