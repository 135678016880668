import React, { useState } from 'react'
import useLogin, { ISOCIAL_MEDIA } from './Login.hooks'
import { headingTheme } from '../../theme/components'
import { LOGIN_ILLUSTRATION_SVG } from '../../assets/illustrations'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { JGoogleIcon } from '../../components'
import { useMutation } from 'react-query'
import { useCustomToast } from '../../hooks'
import { urlRouter } from '../../router'
import { GoogleLogin } from '@react-oauth/google'
import { updateFcmTokenApi } from '../../apis/notificationApi'
import { getTokenFCM } from '../../firebase'
import { IAuthEmailPasswordSigninApiProps } from '../../services/authService/authService.interface'
import { authEmailPasswordSigninApi, authGoogleSigninApi } from '../../services/authService/authService'
import { 
    IC_GOOGLE, 
    IC_LOGIN_RIGHT_HAND, 
    lOGO_JARIVIS, 
} from '../../assets'
import { 
    Button,
    Center, 
    Flex, 
    FormControl, 
    FormErrorMessage, 
    FormLabel, 
    Heading, 
    Icon, 
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    SlideFade, 
} from '@chakra-ui/react'

const Login: React.FC = () => {
    const navigate = useNavigate()

    const { SOCIAL_MEDIA } = useLogin()
    const { t } = useTranslation()
    const { showToast } = useCustomToast()

    const [formDataEmailPassword, setFormDataEmailPassword] = useState<IAuthEmailPasswordSigninApiProps>({
        email: '',
        password: '',
    })
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [isIllustrationLoaded, setIsIllustrationLoaded] = useState<boolean>(false)

    const updateFcmToken = useMutation(updateFcmTokenApi, {
        onSuccess: () => {
            navigate(urlRouter.team, { replace: true })
        }
    })
    const googleSigninMutation = useMutation(authGoogleSigninApi, {
        onSuccess: async (response) => {
            localStorage.setItem('access_token', response?.token)
            localStorage.setItem('user_data', JSON.stringify(response?.data))
            const token: any = await getTokenFCM()
            updateFcmToken.mutate({ firebase_token: token })
        },
        onError: (error) => {
            console.log({googleSigninMutationError: error})
            if (typeof(error) == 'string') {
                return showToast({
                    status: 'error',
                    description: error == 'UNKNOWN_ERROR' ? t('AUTH_GOOGLE_SIGNIN_ERROR_SIGNIN_UNKNOWN') : error,
                })
            } else if (typeof(error) == 'number') {
                return showToast({
                    status: 'error',
                    description: t('AUTH_GOOGLE_SIGNIN_ERROR_SIGNIN_WITH_STATUS_CODE', { statusCode: error }),
                })
            }
        },
    })
    const emailPasswordSigninMutation = useMutation(authEmailPasswordSigninApi, {
        onSuccess: async (resp) => {
            localStorage.setItem('access_token', resp?.token)
            localStorage.setItem('user_data', JSON.stringify(resp?.data))
            const token: any = await getTokenFCM()
            updateFcmToken.mutate({ firebase_token: token })
        },
        onError: (error) => {
            console.log({errorEmailPasswordSigninMutation: error})
        }
    })

    function handleChangeFormDataEmailPassword(name: string, value: string) {
        setFormDataEmailPassword((prev: IAuthEmailPasswordSigninApiProps) => {
            return {
                ...prev,
                [name]: value,
            }
        })
    }

    const isEmailError = emailPasswordSigninMutation?.isError && typeof(emailPasswordSigninMutation?.error) == 'string' && emailPasswordSigninMutation?.error?.includes('email')
    const isPasswordError = emailPasswordSigninMutation?.isError && typeof(emailPasswordSigninMutation?.error) == 'string' && emailPasswordSigninMutation?.error?.includes('password')

    return (
        <Flex
            minHeight='100vh'
            backgroundColor='primaryContainer'
            justifyContent='center'
            alignItems='center'
            padding={{
                base: '8px',
                md: 'unset'
            }}
        >
            <Flex
                position='relative'
                width='1256px'
            >
                <Image
                    src={LOGIN_ILLUSTRATION_SVG}
                    width='100%'
                    onLoad={() => setIsIllustrationLoaded(true)}
                    display={{
                        base: 'none',
                        md: 'block'
                    }}
                />

                <SlideFade in={isIllustrationLoaded} offsetY='20px'>
                    <Flex
                        position={{
                            base: 'static',
                            md: 'absolute'
                        }}
                        right={{
                            base: 'unset',
                            md: '39px'
                        }}
                        top={{
                            base: 'unset',
                            md: '69px'
                        }}
                        bottom='59px'
                    >
                        <Flex
                            direction='column'
                            padding='50px 50px'
                            borderRadius='16px'
                            boxShadow='-4px 4px 16px 0px #00000040'
                            backgroundColor='background'
                            position={{
                                base: 'static',
                                md: 'relative'
                            }}
                            width={{
                                base: '100%',
                                md: '480px',
                            }}
                            gap='12px'
                        >
                            <Image 
                                src={lOGO_JARIVIS} 
                                height='30px' 
                                marginX='auto' 
                                display={{
                                    base: 'none',
                                    md: 'block'
                                }}
                            />
                            <Image
                                src={IC_LOGIN_RIGHT_HAND}
                                height='43px'
                                left='-8px'
                                position='absolute'
                                top='120px'
                                display={{
                                    base: 'none',
                                    md: 'block'
                                }}
                            />

                            <Flex direction='column' marginY='auto'>
                                <Heading size='chakra_title_large' textAlign='center'>{t('LOGIN_TITLE')}</Heading>
                                <Heading size='chakra_body_large' textAlign='center'>{t('LOGIN_SUBTITLE')}</Heading>
                            </Flex>

                            <Flex direction='column' gap='12px'>
                                <FormControl isInvalid={isEmailError}>
                                    <FormLabel>
                                        <Heading
                                            size='chakra_label_large'
                                            color={isEmailError ? 'error' : 'unset'}
                                        >{t('LOGIN_FORM_FIELD_EMAIL_LABEL')}</Heading>
                                    </FormLabel>
                                    <Input
                                        type='email'
                                        {...headingTheme?.sizes?.chakra_label_large}
                                        name='email'
                                        value={formDataEmailPassword?.email}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeFormDataEmailPassword(e?.target?.name, e?.target?.value)}
                                    />
                                    {isEmailError
                                        ? <FormErrorMessage>
                                            <Flex alignItems='center' gap='4px'>
                                                <JGoogleIcon
                                                    name='info'
                                                    color='error'
                                                    fontSize='16px'
                                                />
                                                <Heading
                                                    color='error'
                                                    size='chakra_body_small'
                                                >{emailPasswordSigninMutation?.error?.toString()}</Heading>
                                            </Flex>
                                        </FormErrorMessage>
                                        : null
                                    }
                                </FormControl>
                                <FormControl isInvalid={isPasswordError}>
                                    <FormLabel>
                                        <Heading
                                            size='chakra_label_large'
                                            color={isPasswordError ? 'error' : 'unset'}
                                        >{t('LOGIN_FORM_FIELD_PASSSWORD_LABEL')}</Heading>
                                    </FormLabel>
                                    <InputGroup>
                                        <Input
                                            type={showPassword ? 'text' : 'password'}
                                            {...headingTheme?.sizes?.chakra_label_large}
                                            name='password'
                                            value={formDataEmailPassword?.password}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeFormDataEmailPassword(e?.target?.name, e?.target?.value)}
                                        />
                                        <InputRightElement>
                                            <JGoogleIcon
                                                name={showPassword ? 'visibility_off' : 'visibility'}
                                                cursor='pointer'
                                                onClick={() => setShowPassword(!showPassword)}
                                            />
                                        </InputRightElement>
                                    </InputGroup>
                                    {isPasswordError
                                        ? <FormErrorMessage>
                                            <Flex alignItems='center' gap='4px'>
                                                <JGoogleIcon
                                                    name='info'
                                                    color='error'
                                                    fontSize='16px'
                                                />
                                                <Heading
                                                    color='error'
                                                    size='chakra_body_small'
                                                >{emailPasswordSigninMutation?.error?.toString()}</Heading>
                                            </Flex>
                                        </FormErrorMessage>
                                        : null
                                    }
                                </FormControl>
                                <Button
                                    variant='j-primary'
                                    width='100%'
                                    size='normal'
                                    isLoading={emailPasswordSigninMutation?.isLoading}
                                    onClick={() => {
                                        emailPasswordSigninMutation?.mutate(formDataEmailPassword)
                                    }}
                                >
                                    {t('LOGIN_FORM_BUTTON_SIGN_IN_LABEL')}
                                </Button>
                            </Flex>

                            <Heading size='chakra_label_large' textAlign='center'>{t('LOGIN_METHOD_OR')}</Heading>

                            <Flex direction='column' gap='12px'>
                                <Flex position='relative'>
                                    <Button
                                        {...headingTheme?.sizes?.chakra_label_large}
                                        leftIcon={<Image src={IC_GOOGLE} width='20px' height='20px' />}
                                        boxShadow='1px 2px 6px 0px #00000040'
                                        padding='14px'
                                        borderRadius='10px'
                                        backgroundColor='background'
                                        width='100%'
                                        height='48px'
                                    >
                                        {t('LOGIN_METHOD_GOOGLE')}
                                    </Button>
                                    <Flex opacity='0' position='absolute' width='100%' height='48px'>
                                        <GoogleLogin
                                            onSuccess={credentialResponse => {
                                                googleSigninMutation?.mutate({
                                                    google_token: credentialResponse?.credential
                                                })
                                            }}
                                            onError={() => {
                                                showToast({
                                                    status: 'error',
                                                    description: t('LOGIN_METHOD_GOOGLE_ERROR'),
                                                })
                                            }}
                                            width='275px'
                                        />
                                    </Flex>
                                </Flex>
                            </Flex>


                            <Flex alignItems='center' gap='16px' margin='auto'>
                                {SOCIAL_MEDIA?.map((data: ISOCIAL_MEDIA, index: number) => {
                                    return (
                                        <Center
                                            key={index}
                                            as={Link}
                                            href={data?.href}
                                            target='_blank'
                                            height='48px'
                                            width='48px'
                                            borderRadius='50px'
                                            backgroundColor='primaryContainer'
                                        >
                                            <Icon as={data?.icon} color='primary' fontSize='24px' />
                                        </Center>
                                    )
                                })}
                            </Flex>

                            <Flex
                                alignItems='center'
                                justifyContent='center'
                                gap='6px'
                            >
                                <JGoogleIcon name='copyright' fontSize='20px' marginTop='-2px' color='primary' />
                                <Heading size='chakra_title_medium'>2021 Jarivis</Heading>
                            </Flex>
                        </Flex>
                    </Flex>
                </SlideFade>
            </Flex>
        </Flex>
    )
}

export default Login