import { 
    Button,
    Flex, 
    Heading, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalFooter, 
    ModalHeader, 
    ModalOverlay, 
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { JButton, JTypography } from '../../../components'
import { Configuration } from '../../../query/attendanceSettingQuery'

interface WorkingHoursProps {
    is_open: boolean
    on_close: any
    configuration: Configuration
    on_cancel: any
    on_toggle: any
    is_loading: boolean
}

const WorkingHours = (props: WorkingHoursProps) => {
    const { t } = useTranslation()
    const { 
        is_open, 
        on_close, 
        configuration, 
        on_cancel, 
        on_toggle,
        is_loading,
    } = props

    return (
        <Modal 
            isOpen={is_open} 
            onClose={on_close} 
            size='lg' 
            isCentered
        >
            <ModalOverlay />
            <ModalContent backgroundColor='surfacePlus1'>
                <ModalHeader></ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex 
                        direction='column' 
                        gap='16px' 
                        paddingBottom='20px'
                    >
                        <Heading size='chakra_title_large'>
                            {
                                configuration?.is_absence_strict_time === 1
                                    ?   t('pengaturan_absen_otomatis_matikan_jam_kerja_judul')
                                    :   t('pengaturan_absen_otomatis_nyalakan_jam_kerja_judul')
                            }
                        </Heading>
                        {
                            configuration?.is_absence_strict_time === 1
                                ?   <Heading size='chakra_body_medium'>{t('pengaturan_absen_otomatis_matikan_jam_kerja_subjudul1')} <Heading as='span' size='chakra_body_medium' color='warning'>{t('pengaturan_absen_otomatis_matikan_jam_kerja_subjudul2')}</Heading> {t('pengaturan_absen_otomatis_matikan_jam_kerja_subjudul3')} <Heading as='span' size='chakra_body_medium' color='warning'>{t('pengaturan_absen_otomatis_matikan_jam_kerja_subjudul4')}</Heading> {t('pengaturan_absen_otomatis_matikan_jam_kerja_subjudul5')}</Heading>
                                :   <Heading size='chakra_body_medium'>{t('pengaturan_absen_otomatis_nyalakan_jam_kerja_subjudul1')} <Heading as='span' size='chakra_body_medium' color='warning'>{t('pengaturan_absen_otomatis_nyalakan_jam_kerja_subjudul2')}</Heading> {t('pengaturan_absen_otomatis_nyalakan_jam_kerja_subjudul3')} <Heading as='span' size='chakra_body_medium' color='warning'>{t('pengaturan_absen_otomatis_nyalakan_jam_kerja_subjudul4')}</Heading> {t('pengaturan_absen_otomatis_nyalakan_jam_kerja_subjudul5')}</Heading>
                        }
                        <Flex gap='16px' justifyContent='flex-end'>
                            <Button variant='j-text' onClick={on_cancel}>
                                {t('pengaturan_absen_otomatis_absen_out_modal_cancel')}
                            </Button>
                            <Button 
                                variant='j-primary' 
                                onClick={on_toggle} 
                                isLoading={is_loading}
                            >
                                {
                                    configuration?.is_absence_strict_time === 1
                                        ?   t('pengaturan_absen_otomatis_absen_out_modal_turn_off')
                                        :   t('pengaturan_absen_otomatis_absen_out_modal_turn_on')
                                }
                            </Button>
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default WorkingHours