import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    isReveal: true,
    isDrawerOpen: false,
}

const slice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        sidebarReveal: (state) => {
            state.isReveal = !state.isReveal
            state.isDrawerOpen = state.isDrawerOpen
        },
        sidebarOpenDrawer: (state) => {
            state.isReveal = state.isReveal
            state.isDrawerOpen = !state.isDrawerOpen
        },
    }
})

const { sidebarOpenDrawer, sidebarReveal } = slice.actions
const sidebarReducer = slice.reducer

const sidebarSlice = {
    sidebarReveal,
    sidebarOpenDrawer,
    sidebarReducer,
}

export default sidebarSlice