import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useToast } from '@chakra-ui/react'
import React from 'react'
import { JButton, JToast, JTypography } from '../../../components'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { kickMember } from '../../../apis/memberApi'

function ModalSwitchActive(props: any) {
    const {
        disclosure,
        data,
        refetch,
    } = props

    const { t } = useTranslation()
    const toast = useToast()

    const kick = useMutation(kickMember, {
        onSuccess: () => {
            disclosure.onClose();
            toaster()
            refetch();
        }
    })

    function toaster() {
        toast({
            position: 'top-right',
            render: () => (
                <JToast
                    type='success'
                    message={"Remove Done"}
                />
            ),
            duration: 1500,
            isClosable: true,
        })
    }

    return (
        <Modal isOpen={disclosure.isOpen} onClose={() => disclosure.onClose()} size='lg'>
            <ModalOverlay />
            <ModalContent
                borderRadius={'16px'}
                backgroundColor={"background"}
                pt={"24px"}
            >
                <ModalHeader>
                    <JTypography size='title-large'>
                        {data?.mode === 1 ? t("master_anggota_status_modal_title_non") : t("master_anggota_status_modal_title")}
                    </JTypography>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <JTypography size='body-medium'>
                        {t("master_anggota_status_modal_desc")}
                    </JTypography>
                </ModalBody>
                <ModalFooter>
                    <Flex alignSelf={'center'} alignItems='center' mt={'20px'} gap={'6px'}>
                        <JButton
                            variant="j-text"
                            size="normal"
                            onClick={() => {
                                disclosure.onClose();
                            }}
                            width={'150px'}
                        >
                            {t('master_anggota_live_tracking_batal')}
                        </JButton>
                        <JButton
                            variant="j-primary"
                            size="normal"
                            backgroundColor="errorContainer"
                            color="onErrorContainer"
                            onClick={() => {
                                kick.mutate({ member_id: data?.id })
                                disclosure.onClose();
                            }}
                            _focus={{ opacity: '1.5' }}
                            _hover={{ opacity: '0.7' }}
                            width={'150px'}
                        >
                            {t('master_anggota_status_inactive')}
                        </JButton>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ModalSwitchActive