import { generateErrorMessage } from '../helpers';
import http from './http'
import urlApi from './url'
import { AxiosResponse } from 'axios'

interface GetLiveTrackingListMemberApiProps {
    profile_id: number;
}

interface GetLiveTrackingListAreaApiProps {
    profile_id: number;
    page?: number;
    search?: string;
}

interface CreateLiveTrackingAreaApiProps {
    profile_id: number
    label: string
    radius: number
    is_radius_tolerance_active: number // 1 or 0
    radius_tolerance: number
    latitude: number
    longitude: number
    address: string
    start_date: string
    end_date: string
    member_profile_notify_id: string
    member_profile_id: string
}

interface UpdateLiveTrackingAreaApiProps {
    profile_id: number
    label: string
    radius: number
    is_radius_tolerance_active: number
    radius_tolerance: number
    latitude: number
    longitude: number
    address: string
    start_date: string
    end_date: string
    member_profile_notify_id: string
    member_profile_id: string
    live_tracking_location_id: number
}

interface UpdateLiveTrackingAreaStatusApiProps {
    profile_id: number
    live_tracking_location_id: number
    is_active: number
}

interface UpdateLiveTrackingStatusApiProps {
    profile_id: number
    is_live_tracking_strict_active: number
}

interface DeleteLiveTrackingAreaApiProps {
    profile_id: number
    live_tracking_location_id: number
}

interface IUploadLiveTrackingApi {
    file_csv: any
    onUploadProgress: any
}

export async function GetLiveTrackingStatusApi() {
    const response: AxiosResponse = await http(true).post(urlApi.liveTracking.get.status)
    return response
}

export async function GetLiveTrackingListMemberApi(data: GetLiveTrackingListMemberApiProps) {
    const response: AxiosResponse = await http(true).post(urlApi.liveTracking.get.memberList, data)
    return response
}

export async function GetLiveTrackingListAreaApi(data: GetLiveTrackingListAreaApiProps) {
    const response: AxiosResponse = await http(true).post(urlApi.liveTracking.get.areaList, {
        ...data,
        order_by: 'updated_at',
        order_type: 'desc'
    })
    return response
}

export async function CreateLiveTrackingAreaApi(data: CreateLiveTrackingAreaApiProps) {
    const response: AxiosResponse = await http(true).post(urlApi.liveTracking.create.area, data)
    return response
}

export async function UpdateLiveTrackingAreaApi(data: UpdateLiveTrackingAreaApiProps) {
    const response: AxiosResponse = await http(true).post(urlApi.liveTracking.update.area, data)
    return response
}

export async function UpdateLiveTrackingAreaStatusApi(data: UpdateLiveTrackingAreaStatusApiProps) {
    const response: AxiosResponse = await http(true).post(urlApi.liveTracking.update.areaStatus, data)
    return response
}

export async function UpdateLiveTrackingStatusApi(data: UpdateLiveTrackingStatusApiProps) {
    const response: AxiosResponse = await http(true).post(urlApi.liveTracking.update.status, data)
    return response
}

export async function DeleteLiveTrackingAreaApi(data: DeleteLiveTrackingAreaApiProps) {
    const response: AxiosResponse = await http(true).post(urlApi.liveTracking.delete.area, data)
    return response
}

export async function uploadLiveTrackiingApi(props: IUploadLiveTrackingApi) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const formData = new FormData()
        formData.set('profile_id', team)
        formData.set('file_csv', props?.file_csv)
        const response: any = await http(true).post(urlApi?.liveTracking?.import, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: props?.onUploadProgress,
        })
        if (response.code !== '200') throw generateErrorMessage(response?.message, 'Error import attendance location')
        return response.data
    } catch (error) {
        throw 'Error import attendance location'
    }
}
