import React from 'react'
import { motion } from 'framer-motion'
import { 
    AspectRatio, 
    Flex, 
    Skeleton, 
    SkeletonText,
} from '@chakra-ui/react'

const TeamSkeleton: React.FC = () => {
    return (
        <Flex
            flexDirection='column'
            padding='16px'
            minW='300px'
            h='325px'
            rounded='lg'
            backgroundColor='surfacePlus3'
            gap='16px'
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <AspectRatio width='full' ratio={1}>
                <Skeleton />
            </AspectRatio>
            <SkeletonText noOfLines={1} height='16px' />
        </Flex>
    )
}

export default TeamSkeleton