import http from "./http";
import urlApi from "./url";

export interface PrivilegeTemplateProps {}

export const privilegeTemplate = async (props : PrivilegeTemplateProps) => {
  try {
    const team = localStorage.getItem('current_team') || ''
    const payload = {
        profile_id: team,
        ...props
    }
    let response: any = await http(true).post(urlApi.template.privilege, payload)
    return response.data
  } catch (error) {
    console.error(error);
    return null
  }
}