import React, { useState } from 'react'
import JGoogleIcon from '../../JGoogleIcon/JGoogleIcon'
import {
    Center,
    Flex,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
} from '@chakra-ui/react'
import { getNotificationApi, getUnreadCountNotificationApi, readNotificationApi } from '../../../apis/notificationApi'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import JTypography from '../../JTypography/JTypography'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { urlRouter } from '../../../router'
import { id, enAU } from 'date-fns/locale'
import { format, isSameDay, isYesterday, startOfToday } from 'date-fns'
interface INotificationItem {
    notification: any
}

const NotificationButton = () => {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const notification = useQuery('header-notification-get-notifications', handleGetNotification)
    const unreadCount = useQuery('get-unread-count', getUnreadCountNotificationApi)
    const readNotification = useMutation(handleReadNotification, {
        onSuccess: () => {
            queryClient.invalidateQueries('header-notification-get-notifications')
            queryClient.invalidateQueries('get-unread-count')
        }
    })

    async function handleGetNotification() {
        return await getNotificationApi({ page: 1 })
    }
    async function handleReadNotification(id: any) {
        return await readNotificationApi({ id })
    }

    const NotificationItem = (props: INotificationItem) => {
        const { notification } = props

        function generateTitle(data: any) {
            switch (data?.type) {
                case 'task_duration_exceeded':
                    return t('notifikasi_task_duration_exceeded_title', { name: data?.display_name })
                case 'Task Reassigned':
                    return t('notifikasi_task_reassigned_title')
                case 'Task Created Sender':
                    return t('notifikasi_task_created_sender_title')
                case 'Task Created Receiver':
                    return t('notifikasi_task_created_receiver_title')
                case 'Task Created':
                    return t('notifikasi_task_created_title')
                case 'Task Finished':
                    return t('notifikasi_task_finished_title')
                case 'Task Finished' && data?.display_name:
                    return t('notifikasi_task_finished_other_title')
                case 'Live Tracking Out':
                    return t('notifikasi_live_tracking_out_title')
                case 'Device Reset':
                    return t('notifikasi_device_reset_title', { name: data?.display_name })
                case 'Leave Request Created':
                    return t('notifikasi_leave_req_title')
                case 'Leave Request Edited':
                    return t('notifikasi_leave_edit_title')
                case 'Leave Approval Accepted':
                    return t('notifikasi_leave_approve_accept_title')
                case 'Leave Approval Declined':
                    return t('notifikasi_leave_approve_decline_title')
                case 'customer_request_created':
                    return t('customer_request_created')
                case 'Leave Approval Changed':
                    return t('notifikasi_leave_approve_change_title')
                case 'Lead Created Receiver':
                    return t('notifikasi_lead_created')
            }
        }

        function generateSubtitle(data: any) {
            switch (data?.type) {
                case 'task_duration_exceeded':
                    return t('notifikasi_task_duration_exceeded_subtitle')
                case 'Task Reassigned':
                    return t('notifikasi_task_reassigned_subtitle', {
                        interpolation: { escapeValue: false },
                        task: data?.payload?.task_assignment?.task?.task_type?.name,
                        name: data?.display_name,
                        date: format(new Date(data?.payload?.task_assignment?.assign_date), 'dd-MM-yyyy'),
                    })
                case 'Task Created Sender':
                    return t('notifikasi_task_created_sender_subtitle', {
                        interpolation: { escapeValue: false },
                        task: data?.payload?.task_assignment?.task?.task_type?.name,
                        name: data.payload.task_assignment.user.display_name,
                        date: format(new Date(data?.payload?.task_assignment?.assign_date), 'dd-MM-yyyy'),
                    })
                case 'Task Created Receiver':
                    return t('notifikasi_task_created_receiver_subtitle', {
                        interpolation: { escapeValue: false },
                        task: data?.payload?.task_assignment?.task?.task_type?.name,
                        customer: data?.payload?.task_assignment?.task?.customer?.name,
                        from: data.payload.task_assignment.user.display_name,
                        date: format(new Date(data?.payload?.task_assignment?.assign_date), 'dd-MM-yyyy'),
                    })
                case 'Task Created':
                    return t('notifikasi_task_created_subtitle', {
                        interpolation: { escapeValue: false },
                        task: data?.payload?.task_assignment?.task?.task_type?.name,
                        customer: data?.payload?.task_assignment?.task?.customer?.name,
                        from: data?.display_name,
                        date: format(new Date(data?.payload?.task_assignment?.assign_date), 'dd-MM-yyyy'),
                    })
                case 'Task Finished':
                    return t('notifikasi_task_finished_subtitle', {
                        interpolation: { escapeValue: false },
                        task: data?.payload?.task_assignment?.task?.task_type?.name,
                        customer: data?.payload?.task_assignment?.task?.customer?.name,
                    })
                case 'Task Finished' && data?.display_name:
                    return t('notifikasi_task_finished_other_subtitle', {
                        interpolation: { escapeValue: false },
                        task: data?.payload?.task_assignment?.task?.task_type?.name,
                        customer: data?.payload?.task_assignment?.task?.customer?.name,
                        name: data?.display_name,
                        date: format(new Date(data?.payload?.task_assignment?.assign_date), 'dd-MM-yyyy'),
                    })
                case 'Live Tracking Out':
                    return t('notifikasi_live_tracking_out_subtitle', {
                        name: data?.display_name,
                        interpolation: { escapeValue: false },
                    })
                case 'Device Reset':
                    return t('notifikasi_device_reset_subtitle', {
                        name: data?.display_name,
                        interpolation: { escapeValue: false },
                    })
                case 'Leave Request Created':
                    return t('notifikasi_leave_req_subtitle', {
                        name: data?.payload?.leave_request?.user_id?.display_name,
                        category: data?.payload?.leave_request?.leave_category?.name,
                        //start and end can be change if index is full string 
                        start: data?.payload?.leave_request?.start_date.length > 0 ? format(new Date(data?.payload?.leave_request?.start_date), 'dd MMM yyyy') : format(new Date(data?.payload?.leave_request?.start_date?.date), 'dd MMM yyyy'),
                        // end: data?.payload?.leave_request?.end_date.length 
                        end: data?.payload?.leave_request?.end_date.length > 0 ? format(new Date(data?.payload?.leave_request?.end_date), 'dd MMM yyyy') : format(new Date(data?.payload?.leave_request?.end_date?.date), 'dd MMM yyyy')
                    })
                case 'Leave Request Edited':
                    return t('notifikasi_leave_edit_subtitle', {
                        name: data?.payload?.leave_request?.user_id?.display_name,
                        category: data?.payload?.leave_request?.leave_category?.name,
                        //start and end can be change if index is full string 
                        start: data?.payload?.leave_request?.start_date.length > 0 ? format(new Date(data?.payload?.leave_request?.start_date), 'dd MMM yyyy') : format(new Date(data?.payload?.leave_request?.start_date?.date), 'dd MMM yyyy'),
                        end: data?.payload?.leave_request?.end_date.length > 0 ? format(new Date(data?.payload?.leave_request?.end_date), 'dd MMM yyyy') : format(new Date(data?.payload?.leave_request?.end_date?.date), 'dd MMM yyyy')
                    })
                case 'Leave Approval Accepted':
                    return t('notifikasi_leave_approve_accept_subtitle', {
                        approver: data?.payload?.leave_request?.handled_by?.display_name,
                    })
                case 'Leave Approval Declined':
                    return t('notifikasi_leave_approve_decline_subtitle', {
                        approver: data?.payload?.leave_request?.handled_by?.display_name,
                    })
                case 'Leave Approval Changed':
                    return t('notifikasi_leave_approve_change_subtitle', {
                        approver: data?.payload?.leave_request?.handled_by?.display_name,
                    })
                case 'customer_request_created':
                    return t('customer_request_created_subtitle', {
                        requestorName: data?.payload?.customer_request?.customer_object?.name,
                    })
                case 'Lead Created Receiver':
                    return t('notifikasi_lead_created_subtitle', {
                        name: data?.payload?.lead?.name,
                        creator: data?.payload?.created_by?.display_name
                    })
            }
        }

        function generateRedirect() {
            switch (notification?.type) {
                case 'task_duration_exceeded':
                    return urlRouter?.taskMonitoringDetailResult?.replace(':id', notification?.payload?.task_assignment?.id)?.replace(':slug-task', 'detail-task')
                case 'Task Reassigned':
                    return urlRouter?.taskMonitoringDetailResult?.replace(':id', notification?.payload?.task_assignment?.id)?.replace(':slug-task', 'detail-task')
                case 'Task Created Sender':
                    return urlRouter?.taskMonitoringDetailResult?.replace(':id', notification?.payload?.task_assignment?.id)?.replace(':slug-task', 'detail-task')
                case 'Task Created Receiver':
                    return urlRouter?.taskMonitoringDetailResult?.replace(':id', notification?.payload?.task_assignment?.id)?.replace(':slug-task', 'detail-task')
                case 'Task Created':
                    return urlRouter?.taskMonitoringDetailResult?.replace(':id', notification?.payload?.task_assignment?.id)?.replace(':slug-task', 'detail-task')
                case 'Task Finished':
                    return urlRouter?.taskMonitoringDetailResult?.replace(':id', notification?.payload?.task_assignment?.id)?.replace(':slug-task', 'detail-task')
                case 'Task Finished' && notification?.display_name:
                    return urlRouter?.taskMonitoringDetailResult?.replace(':id', notification?.payload?.task_assignment?.id)?.replace(':slug-task', 'detail-task')
                case 'Live Tracking Out':
                    return `${urlRouter?.memberJourneyDetail}?member_id=${notification?.payload?.tracking?.user?.id}&journey_date=${notification?.datetime.split(' ')[0]}&journey_id=${notification?.payload?.tracking?.id}`
                case 'Device Reset':
                    return urlRouter?.memberMaster
                case 'Leave Request Created':
                    return urlRouter?.LEAVE_APPROVAL
                case 'Leave Request Edited':
                    return urlRouter?.LEAVE_APPROVAL
                case 'Leave Approval Accepted':
                    return urlRouter?.LEAVE_APPROVAL
                case 'Leave Approval Declined':
                    return urlRouter?.LEAVE_APPROVAL
                case 'Leave Approval Changed':
                    return urlRouter?.LEAVE_APPROVAL
                case 'customer_request_created':
                    return `${urlRouter?.MANAGEMENT_TICKET_DETAIL?.replace(':ticketId', notification?.payload?.customer_request?.id)}?tab_index=0&tab_name=detail`
                case 'Lead Created Receiver':
                    return `${urlRouter?.LEADS_DETAIL?.replace(':id', notification?.payload?.lead?.id)}`
            }
        }

        function handleGetDateNotif(time: string) {
            if (isSameDay(new Date(time), startOfToday())) {
                return t('notifikasi_hari_ini')
            } else if (isYesterday(new Date(time))) {
                return t('diskusi_tugas_kemarin')
            } else {
                return format(new Date(time), 'dd MMMM yyyy', {
                    locale: document.cookie.replace(/(?:(?:^|.*;\s*)i18next\s*\=\s*([^;]*).*$)|^.*$/, "$1") === 'id'
                        ? id
                        : enAU
                })
            }
        }

        return (
            <MenuItem
                as={Link}
                flexDirection={'column'}
                padding={'12px'}
                borderBottomWidth={'2px'}
                borderBottomColor={'surface'}
                gap={'12px'}
                backgroundColor={notification?.is_read === 1 ? 'background' : 'surfacePlus3'}
                to={`${generateRedirect()}`}
                reloadDocument={true}
                onClick={() => readNotification.mutate(notification?.id)}
            >
                <Flex width={'100%'} justifyContent={'space-between'}>
                    <JTypography fontWeight={'light'} size={'body-small'}>{handleGetDateNotif(notification?.datetime)}</JTypography>
                    <JTypography fontWeight={'light'} size={'body-small'}>{format(new Date(notification?.datetime), 'HH:mm')}</JTypography>
                </Flex>
                <Flex width={'100%'} flexDirection={'column'} gap={'12px'}>
                    <JTypography size={'body-small'} lineHeight={'125%'} fontWeight={'semibold'}>{generateTitle(notification)}</JTypography>
                    <JTypography size={'body-small'} lineHeight={'125%'}>{generateSubtitle(notification)}</JTypography>
                </Flex>
            </MenuItem>
        )
    }

    return (
        <Menu>
            <MenuButton
                as={IconButton}
                backgroundColor={'surfacePlus1'}
                borderRadius={'12px'}
            >
                <JGoogleIcon name={'notifications'} fontSize={'20px'} />
                {unreadCount?.data !== 0 &&
                    <Center
                        height='16px'
                        minWidth='16px'
                        borderRadius='16px'
                        backgroundColor='errorContainer'
                        position='absolute'
                        top='-4px'
                        right='-8px'
                    >
                        <JTypography size='label-medium'>
                            {unreadCount?.data > 9 ? '9+' : unreadCount?.data}
                        </JTypography>
                    </Center>
                }
            </MenuButton>
            <MenuList
                backgroundColor={'background'}
                padding={'0px'}
                width={'350px'}
                height={'50vh'}
                overflowY={'scroll'}
                border='none'
            >
                <Flex margin={'12px'} justifyContent='space-between'>
                    <JTypography size={'body-large'}>
                        {t('pemberitahuan_judul')}
                    </JTypography>
                    <MenuItem
                        as={Link}
                        to={urlRouter.notification}
                        width='max-content'
                        backgroundColor='transparent'
                        padding='0px'
                    >
                        <JTypography size={'body-large'} cursor='pointer' color='primary'>
                            {t('notifikasi_lihat_semua')}
                        </JTypography>
                    </MenuItem>
                </Flex>
                {notification?.data?.data?.map((notif: any, index: number) => {
                    return <NotificationItem key={index} notification={notif} />
                })}
            </MenuList>
        </Menu>
    )
}

export default NotificationButton