import React from 'react'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { BiChevronDown } from 'react-icons/bi'
import { FiMoon, FiSun } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { logout } from '../../../redux'
import { urlRouter } from '../../../router'
import { setDarkColor, setLightColor } from '../../../theme'
import style from "../header.module.css"
import { 
    Link, 
    useLocation, 
    useNavigate 
} from 'react-router-dom'
import { 
    Avatar, 
    Box, 
    Button, 
    Divider, 
    Flex, 
    Heading, 
    IconButton, 
    Menu, 
    MenuButton, 
    MenuItem, 
    MenuList, 
    Text, useColorMode 
} from '@chakra-ui/react'

const UserButton = () => {
    const { t } = useTranslation()
    let stringUserData: string = localStorage.getItem('user_data') || ""
    const userData = JSON.parse(stringUserData)
    const { colorMode, toggleColorMode } = useColorMode()
    const location = useLocation()
    const isBlacklistPage = location.pathname === urlRouter.team
    const dispatch = useDispatch<any>()
    const navigate = useNavigate()

    function toggleTheme() {
        if (colorMode === 'light') {
            setDarkColor()
        } else {
            setLightColor()
        }
        toggleColorMode()
    }

    function handleLogout() {
        dispatch(logout())
        navigate(urlRouter.login, { replace: true })
        window.location.reload()
    }

    return (
        <Menu>
            <MenuButton as={Button} rightIcon={<BiChevronDown />} className={style.profile_dropdown}>
                <Flex alignItems="center" gap="10px">
                    <Avatar
                        name={userData.display_name}
                        src={userData.photo_url}
                        className={style.profile_picture}
                        fontSize="10px"
                        size='sm'
                    />
                    <Heading size='chakra_body_large'>{userData.display_name}</Heading>
                </Flex>
            </MenuButton>
            <MenuList className={style.profile_dropdown_content} shadow='md' padding='16px'>
                <MenuItem className={`${style.profile_dropdown_content_item} ${style.mobile_only}`}>
                    <IconButton as={Flex} ml="auto" aria-label="notification" icon={colorMode === 'light' ? <FiMoon /> : <FiSun />} className={`${style.header_button} ${style.theme}`} onClick={toggleTheme} />
                </MenuItem>
                <Divider className={style.mobile_only} my="8px" />
                {!isBlacklistPage &&
                    <Link to={urlRouter.team}>
                        <MenuItem 
                            className={style.profile_dropdown_content_item} 
                            backgroundColor='surfacePlus1'
                            _hover={{
                                backgroundColor: 'surfacePlus3',
                                borderRadius: '4px'
                            }}
                        >
                            <span className={`material-symbols-outlined`}>change_circle</span>
                            <Heading size='chakra_body_large'>{t("header_ganti_tim")}</Heading>
                        </MenuItem>
                    </Link>
                }
                <Menu placement="bottom">
                    <MenuButton 
                        className={`${style.profile_dropdown_content_item}`}
                        _hover={{
                            backgroundColor: 'surfacePlus3',
                            borderRadius: '4px'
                        }}
                    >
                        <Flex gap="16px" alignItems='center'>
                            <span className={`material-symbols-outlined`}>language</span>
                            <Heading size='chakra_body_large'>{t("header_ganti_bahasa")}</Heading>
                            <Box as="span" ml="auto" className={`material-symbols-outlined`}>navigate_next</Box>
                        </Flex>
                    </MenuButton>
                    <MenuList 
                        shadow="2xl" 
                        className={`${style.profile_dropdown_content}`}
                        position='absolute'
                        left={document.cookie.replace(/(?:(?:^|.*;\s*)i18next\s*\=\s*([^;]*).*$)|^.*$/, "$1") === 'id' ? '-346px' : '-366px'}
                        top='-64px'
                        padding='16px'
                    >
                        <MenuItem 
                            className={`${style.profile_dropdown_content_item}`} 
                            onClick={() => i18next.changeLanguage('id')} 
                            backgroundColor='surfacePlus1'
                            _hover={{
                                backgroundColor: 'surfacePlus3',
                                borderRadius: '4px'
                            }}
                        >
                            <Heading size='chakra_body_large'>{t("header_ganti_bahasa_indonesia")}</Heading>
                        </MenuItem>
                        <MenuItem 
                            className={`${style.profile_dropdown_content_item}`} 
                            onClick={() => i18next.changeLanguage('en')}
                            backgroundColor='surfacePlus1'
                            _hover={{
                                backgroundColor: 'surfacePlus3',
                                borderRadius: '4px'
                            }}
                        >
                            <Heading size='chakra_body_large'>{t("header_ganti_bahasa_inggris")}</Heading>
                        </MenuItem>
                    </MenuList>
                </Menu>
                <MenuItem 
                    className={`${style.profile_dropdown_content_item} ${style.logout}`} 
                    onClick={handleLogout} backgroundColor='surfacePlus3'
                    _hover={{
                        backgroundColor: 'surfacePlus3',
                        borderRadius: '4px'
                    }}
                >
                    <span className={`material-symbols-outlined`}>logout</span>
                    <Heading size='chakra_body_large' color='error'>{t("header_keluar")}</Heading>
                </MenuItem>
            </MenuList>
        </Menu>
    )
}

export default UserButton