import { Suspense, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { urlRouter } from '../../router'
import { Header, Sidebar } from '../../components'
import { getTeamDetail, menuPrivilageSlice } from '../../redux'
import { 
    Navigate, 
    Outlet, 
    useLocation, 
} from 'react-router-dom'
import { 
    Center, 
    Flex, 
    Spinner, 
} from '@chakra-ui/react'

const MainApp = () => {
    const location = useLocation()
    const dispatch = useDispatch<any>()
    const dontRenderSidebar = location.pathname === urlRouter.team || location.pathname === urlRouter.invitation
    const token = localStorage.getItem('access_token')
    const team = localStorage.getItem('current_team')
    let isHaveteam = null
    const isPageTeam = location.pathname === urlRouter.team || location.pathname === urlRouter.invitation

    if (isPageTeam) {
        isHaveteam = true
    } else {
        if (team) {
            isHaveteam = true
        } else {
            isHaveteam = false
        }
    }

    useEffect(() => {
        dispatch(menuPrivilageSlice.getMenuPrivilageAction())
        dispatch(getTeamDetail())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team])

    return (
        token
            ?   isHaveteam
                ?   <Flex direction='column' width='100%'>
                        <Header />
                        <Flex
                            height='calc(100vh - var(--headerHeight))'
                            marginTop='var(--headerHeight)'
                            width='100%'
                        >
                            {!dontRenderSidebar && <Sidebar />}
                            <Flex
                                padding={{ base: '16px 8px', md: '16px 24px' }}
                                height='calc(100vh - var(--headerHeight))'
                                overflowY='auto'
                                // width='100%'
                                flex='1'
                                backgroundColor='surface'
                            >
                                <Suspense
                                    fallback={
                                        <Center width='100%'>
                                            <Spinner />
                                        </Center>
                                    }
                                >
                                    <Outlet />
                                </Suspense>
                            </Flex>
                        </Flex>
                    </Flex>
                :   <Navigate to={urlRouter.team} />
            :   <Navigate to={urlRouter.login} />
    )
}

export default MainApp