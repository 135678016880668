import { Button, CloseButton, Flex, Menu, MenuButton, MenuItem, MenuList, Popover, PopoverBody, PopoverContent, PopoverTrigger, Radio, RadioGroup, Stack, Tag, TagLabel, useDisclosure } from '@chakra-ui/react';
import { JButton, JGoogleIcon, JTypography } from '../../../components';
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getVersion } from '../../../apis';
import { useQuery } from 'react-query';


interface IFilter {
  id: any,
  name: string,
  option? : any,
}

interface GetFilterMasterMember{
  handleFilter: any,
  memberProps: any,
}

function FilterMasterMember(props : GetFilterMasterMember) {
    const { handleFilter, memberProps } = props;
    const [apply, setApply] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { t } = useTranslation()
    const { isLoading, error, data } = useQuery('version', getVersion);

    const [version, setVersion] = useState<any>([])

    useEffect(()=>{
      if (Array.isArray(data?.app_version)) {
        setVersion(data?.app_version)}
    },[data])


    const filter : IFilter[] =[
        {
          id:"nip",
          name: "NIP",
          option: [
            "Available",
            "Not Available"
          ]
        }, 
        {
          id:"status",
          name: "Status", 
          option: [
            "Active",
            "Not Active"
            
          ]
        },
        {
          id:"active_device",
          name: t('master_anggota_device'), 
          option: [
            "Available",
            "Not Available"
          ]
        },
        {
          id:"app_version",
          name:t('anggota_versi_aplikasi'),
          option: [...version]
        },
        {
          id:"active_geofence",
          name: t("anggota_geofance_aktif"), 
          option: [
            "Available",
            "Not Available"
          ]
        },
        {
          id:"face_recognition",
          name: t("master_anggota_face_recognition"), 
          option: [
            "Active",
            "Not Active"
          ]
        },
        {
          id:"live_tracking",
          name: "Live Tracking", 
          option: [
            "Active",
            "Inactive"
          ]
        },
      ]
    
    const [real, setReal] = useState<any>({
        active_device: memberProps.active_device,
        active_geofence: memberProps.active_geofence,
        app_version: memberProps.app_version,
        face_recognition: memberProps.face_recognition,
        live_tracking: memberProps.live_tracking,
        status: memberProps.status,
        nip: memberProps.nip
      })
    
    const [fill, setFill] = useState<any>({
      active_device: real.active_device,
      active_geofence: real.active_geofence,
      app_version: real.app_version,
      face_recognition: real.face_recognition,
      live_tracking: real.live_tracking,
      status: memberProps.status,
      nip: real.nip
    })
    
  function handleRadioClone(e : any) {
    const data : any = {...fill}
    data[e.target.name] = e.target.value
    setFill(data)
  }
  function handleRadio(e : any) {
    const data : any = {...fill}
    data[e.target.name] = e.target.value
    setFill(data)
    setReal(data)
    handleFilter(data)
  }

  function handleClearClone(e : any) {
    const data : any = {...fill}
    data[e] = ""
    setFill(data)
  }
  function handleClear(e : any) {
    const data : any = {...fill}
    data[e] = ""
    setFill(data)
    setReal(data)
    handleFilter(data)
  }

  function handleCancel() {
    //just not upload from clone api
    setFill(real)
  }

  function handleApply(){
    setReal(fill)
    setApply(true)
    handleFilter(fill)
  }

  return (
    <Flex>
        <Flex >
          <Popover placement='bottom-start' onClose={() =>{onClose(); handleCancel()}} isOpen={isOpen} onOpen={onOpen}>
            <PopoverTrigger>
              <Button 
                margin={"16px 0"} 
                variant={'j-outline'} 
                leftIcon={<JGoogleIcon name={"tune"} />}
              >
                Filter
              </Button>
            </PopoverTrigger>
            <PopoverContent border={'none'} bg={'surface'} sx={{"box-shadow": "0px 5px 10px 0px rgba(0, 0, 0, 0.3)"}}>
              <PopoverBody>
              {filter?.map((filter : any, i : number) =>{
                return(
                    <Flex
                      cursor={'pointer'}
                    >
                      <Menu placement='right' closeOnSelect={false} >
                        <MenuButton w={"100%"} as={Button} variant={'ghost'} >
                          <Flex 
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          >
                            <Flex  alignItems={'center'} gap={'12px'}>
                              {fill[filter.id] !== "" ?
                                <JGoogleIcon name={"check_circle"} />
                                : null}
                              <JTypography size='label-large' >
                                {filter.name}
                              </JTypography>
                            </Flex>
                            <JGoogleIcon name={"navigate_next"} />
                          </Flex>
                        </MenuButton >
                        <MenuList p={0} m={0} bg={'surface'} border={'none'} maxH={'40vh'} overflowX={'hidden'} overflowY={'scroll'}>
                          <RadioGroup value={fill[filter.id]}>
                            <Stack direction='column' alignItems={"start"}>
                              {fill[filter.id] !== ""  ? 
                              <Flex position='sticky' p={'8px'} top='0px' justify={'center'} zIndex={1} bg={'surface'} w={'100%'}>
                                <Button
                                  variant={'ghost'}
                                  name={filter.id} 
                                  onClick={() => {handleClearClone(filter.id)}}>
                                    <JTypography size='label-large' color={'error'}>
                                      {t("master_anggota_cancel_filter")}
                                    </JTypography>
                                </Button>
                              </Flex>: null}
                              {filter?.option.map((option : any, i : any) =>{
                                return (
                                <MenuItem p={"0 8px"} bg={'surface'}>
                                  <Radio
                                    w={'100%'}
                                    p={"3.5"}
                                    onChange={handleRadioClone} 
                                    name={filter.id} 
                                    value={option}
                                    key={i}
                                  >
                                    <JTypography size='label-large' >
                                      {option === "Available" ? 
                                        t("master_anggota_option_filter_terdaftar") 
                                        : option === "Not Available" ? t("master_anggota_option_filter_tidak_terdaftar") 
                                        : `${option}`}
                                    </JTypography>
                                  </Radio>
                                </MenuItem>
                                )
                              })}
                              </Stack>
                          </RadioGroup>
                        </MenuList>
                      </Menu>
                    </Flex>
                )
              })}
              </PopoverBody>
              <Flex
                m={"6px 20px 20px"}
                justifyContent={'center'} 
                gap={4}
              >
                <Button variant='j-outline' size='normal' onClick={() =>{onClose(); handleCancel()}}>
                  <JTypography size='label-large'>
                    {t("master_anggota_cancel")}
                  </JTypography>
                </Button>
                <JButton variant='j-primary' size='normal' onClick={() => {onClose(); handleApply()}}>
                  <JTypography size='label-large'>
                    {t("master_anggota_terap")}
                  </JTypography>
                </JButton>
              </Flex>
            </PopoverContent>
          </Popover>
        </Flex>
        {apply ?
          <Flex gap={'2px 6px'} alignItems={'center'} maxW={'100%'} wrap={'wrap'}>
          {filter?.map((filter : any, i : number) =>{
            return(
              <>
                <Menu>
                  {real[filter.id] !== "" ?
                    <Flex as={Tag} 
                      size={'md'}
                      borderRadius='full'
                      variant='solid'
                      colorScheme='blue'
                      alignItems={'center'}
                    >
                      <MenuButton >
                        <TagLabel>
                          <JTypography size='label-medium'>
                            {filter.name} - {real[filter.id] === "Available" ? 
                                  t("master_anggota_option_filter_terdaftar") 
                                  : real[filter.id] === "Not Available" ? t("master_anggota_option_filter_tidak_terdaftar") 
                                  : `${real[filter.id]}`}
                          </JTypography>
                        </TagLabel>
                      </MenuButton >
                      <CloseButton 
                        size={'sm'} 
                        borderRadius='full'  
                        cursor={'pointer'} 
                        onClick={() => {handleClear(filter.id)}}
                      />
                    </Flex>
                  : null}
                  <MenuList p={"8px"} bg={'surface'}>
                    <RadioGroup value={real[filter.id]}>
                      <Stack direction='column' alignItems={"start"}>
                        {filter?.option.map((option : any, i : any) =>{
                          return (
                          <MenuItem p={"0"} bg={'surface'}>
                            <Radio
                              w={'100%'}
                              p={"3.5"}
                              onChange={handleRadio} 
                              name={filter.id} 
                              value={option}
                              key={i}
                            >
                              <JTypography size='label-large' >
                                {option === "Available" ? 
                                  t("master_anggota_option_filter_terdaftar") 
                                  : option === "Not Available" ? t("master_anggota_option_filter_tidak_terdaftar") 
                                  : `${option}`}
                              </JTypography>
                            </Radio>
                          </MenuItem>
                          )
                        })}
                      </Stack>
                    </RadioGroup>
                  </MenuList>
                </Menu>
              </>
              )
            })}
          </Flex>
        : null}
    </Flex>
  )
}

export default FilterMasterMember