import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { attendaceEditConfigApi, attendaceMonitorApi, urlApi } from '../../apis';

const initialState = {
  // attendance config
  loadingEditAttendanceConfig: false,
  successEditAttendanceConfig: false,
  errorEditAttendanceConfig: false,
  messageEditAttendanceConfig: '',

  // attendance monitor
  loadingGetAttendanceMonitor: false,
  successGetAttendanceMonitor: false,
  errorGetAttendanceMonitor: false,
  messageGetAttendanceMonitor: '',
  attendanceMonitor: null,
  attendanceMonitorMetadata: null,
}

export const editAttendanceConfig = createAsyncThunk(urlApi.attendance_edit_config, async (data: any, thunkAPI) => {
  try {
    const {
      is_absence_mandatory_for_check_in, 
      limit_clock_in, 
      limit_clock_out
    } = data
    const response: any = await attendaceEditConfigApi(is_absence_mandatory_for_check_in, limit_clock_in, limit_clock_out)
    return response
  } catch (error) {
    const message = "error get member privilages"
    return thunkAPI.rejectWithValue(message)
  }
})

export const getAttendanceMonitor = createAsyncThunk(urlApi.attendance_monitor, async (data: any, thunkAPI) => {
  try {
    const {
      search,
      start_date, 
      end_date, 
      page
    } = data
    const response: any = await attendaceMonitorApi(search, start_date, end_date, page)
    return response
  } catch (error) {
    const message = "error get attendance monitor"
    return thunkAPI.rejectWithValue(message)
  }
})

export const attendanceSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    resetEditAttendanceConfig: (state) => {
      state.loadingEditAttendanceConfig = false
      state.successEditAttendanceConfig = false
      state.errorEditAttendanceConfig = false
      state.messageEditAttendanceConfig = ''
    },
    resetGetAttendanceMonitor: (state) => {
      state.loadingGetAttendanceMonitor = false
      state.successGetAttendanceMonitor = false
      state.errorGetAttendanceMonitor = false
      state.messageGetAttendanceMonitor = ''
      state.attendanceMonitor = null
      state.attendanceMonitorMetadata = null
    },
  },
  extraReducers: (builder) => {
    builder
      // attendance edit config
      .addCase(editAttendanceConfig.pending, (state) => {
        state.loadingEditAttendanceConfig = true
        state.successEditAttendanceConfig = false
        state.errorEditAttendanceConfig = false
        state.messageEditAttendanceConfig = ''
      })
      .addCase(editAttendanceConfig.fulfilled, (state, action) => {
        state.loadingEditAttendanceConfig = false
        state.successEditAttendanceConfig = true
      })
      .addCase(editAttendanceConfig.rejected, (state, action: any) => {
        state.loadingEditAttendanceConfig = false
        state.errorEditAttendanceConfig = true
        state.messageEditAttendanceConfig = 'error edit attendance configuration'
      })

      // attendance monitor get
      .addCase(getAttendanceMonitor.pending, (state) => {
        state.loadingGetAttendanceMonitor = true
        state.successGetAttendanceMonitor = false
        state.errorGetAttendanceMonitor = false
        state.messageGetAttendanceMonitor = ''
      })
      .addCase(getAttendanceMonitor.fulfilled, (state, action) => {
        state.loadingGetAttendanceMonitor = false
        state.successGetAttendanceMonitor = true
        state.attendanceMonitor = action.payload.data
        state.attendanceMonitorMetadata = action.payload.metadata
      })
      .addCase(getAttendanceMonitor.rejected, (state, action: any) => {
        state.loadingGetAttendanceMonitor = false
        state.errorGetAttendanceMonitor = true
        state.messageGetAttendanceMonitor = 'error get attendance monitor'
      })
  },
})

export const { resetEditAttendanceConfig, resetGetAttendanceMonitor } = attendanceSlice.actions
export default attendanceSlice.reducer