import React, { memo, useState } from 'react'
import { JButton, JTypography, JInput, JGoogleIcon, JToast } from '../../../components'
import { 
    Flex, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalHeader, 
    ModalOverlay, 
    useToast,
    ModalFooter
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { apiSwitchTracking } from '../../../apis/memberSwitchLiveTrackingApi'
interface IModalSwitchTracking {
    disclosure: any
    data: any
    refetch: any
    setData: any
    mode: any
}

const ModalSwitchTracking: React.FC<IModalSwitchTracking> = (props: IModalSwitchTracking) => {
    const { 
        disclosure,
        data,
        refetch,
        mode
    } = props
    const { t } = useTranslation();
    const toast = useToast();
    const switchLiveTrackingMutation = useMutation(apiSwitchTracking, {
        onSuccess: () => {
            disclosure.onClose();
            refetch();
        }
    });
    function toaster (code:string, msg:string){
        toast({
          position: 'top-right',
          render: () => (
              <JToast
                  type={code === "200"? 'success' : 'error'}
                  message={code !== "200" ? msg : data?.mode === "AKTIF" ? t('master_anggota_live_tracking_hidupkan_berhasil', {username: data.name}) : t('master_anggota_live_tracking_matikan_berhasil', {username: data.name})}
              />
          ),
          duration: 1500,
          isClosable: true,
        })
    }
    return (
        <Modal
            isOpen={disclosure.isOpen}
            onClose={()=>{
                disclosure.onClose()
            }}
            size={"lg"}
        >
            <ModalOverlay />
            <ModalContent
                borderRadius={'16px'}
                backgroundColor={"background"}
                padding={"24px"}
            >
                <ModalHeader
                    padding={"0px"}
                    paddingRight={"24px"}
                    mt={'10px'}
                    mb={2}
                >
                    <JTypography paddingTop={"24px"} size="title-large">{mode !== "TIDAK AKTIF" ? t('master_anggota_live_tracking_konfirmasi_judul') : t('master_anggota_live_tracking_konfirmasi_judul_inaktif')}</JTypography>
                </ModalHeader>
                <ModalCloseButton top={"24px"} right={"24px"} />
                <ModalBody
                    as={Flex}
                    flexDirection={"column"}
                    gap={"16px"}
                    padding={"0px"}
                    marginTop={"24px"}
                >
                    <Flex>
                        <JTypography color={"onSurfaceVariant"} size="label-large">{mode !== "TIDAK AKTIF" ? t('master_anggota_live_tracking_konfirmasi_desc') : t('master_anggota_live_tracking_konfirmasi_desc_inaktif')}</JTypography>
                    </Flex>
                </ModalBody>
                <ModalFooter paddingBottom={0}>
                    <Flex alignSelf={'center'} alignItems='center' mt={'20px'}>
                        <JButton
                            variant="j-text"
                            size="normal"
                            onClick={()=>{
                                disclosure.onClose();
                            }}
                            width={'150px'}
                        >
                            {t('master_anggota_live_tracking_batal')}
                        </JButton>
                        <JButton
                            variant="j-primary"
                            size="normal"
                            backgroundColor={"errorContainer"}
                            color={"onErrorContainer"}
                            onClick={()=>{
                                switchLiveTrackingMutation.mutate({user_id: data?.id, is_geofance: data?.mode, toaster: toaster});
                                disclosure.onClose();
                            }}
                            _focus={{ opacity: '1.5' }}
                            _hover={{ opacity: '0.7' }}
                            width={'150px'}
                        >
                            {mode === "TIDAK AKTIF" ? t('master_anggota_live_tracking_matikan') : t('master_anggota_live_tracking_hidupkan')}
                        </JButton>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default memo(ModalSwitchTracking)