import { useEffect, useState } from 'react'
import {
    Divider,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Skeleton,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Switch,
    Text,
} from '@chakra-ui/react'
import {
    CircleF,
    GoogleMap,
    MarkerF,
    useLoadScript,
} from '@react-google-maps/api'
import { Button, PageTitle } from '../../components'
import style from './task-setting.module.css'
import { useDispatch, useSelector } from 'react-redux'
import {
    getTeamDetail,
    updateTaskSettingCheckIn,
    updateTaskSettingRadius,
} from '../../redux'
import { useTranslation } from 'react-i18next'

const TaskSetting = () => {
    const dispatch = useDispatch<any>()
    const { t } = useTranslation()
    const { isLoadingTeamDetail, teamDetail } = useSelector(
        (state: any) => state.team
    )
    const { loadingUpdateTaskSettingCheckIn, loadingUpdateTaskSettingRadius } =
        useSelector((state: any) => state.task)
    const [radius, setRadius] = useState<number>(50)

    // modal
    const [modalToggle, setModalToggle] = useState(false)
    const [modalRadius, setModalRadius] = useState(false)
    const [modalConfirmChangeRadius, setModalConfirmChangeRadius] =
        useState(false)

    // google maps
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyD14zNix-s-MJSfWwLwAWFaOF05vKhEI_4',
    })
    const centerDefault = {
        lat: -6.2213866,
        lng: 106.7796252,
    }
    const zoomDefault = 15

    function handleGetTeamDetail() {
        dispatch(getTeamDetail())
    }

    async function handleUpdateTaskSettingCheckin() {
        await dispatch(
            updateTaskSettingCheckIn(
                teamDetail.profile_mobileconfig.is_checkin_strict_location === 1
                    ? 0
                    : 1
            )
        )
    }

    async function handleUpdateTaskSettingRadius() {
        await dispatch(updateTaskSettingRadius(radius))
    }

    useEffect(() => {
        handleGetTeamDetail()
    }, [])
    console.log(teamDetail)
    return (
        <Flex className={`${style.task_setting}`}>
            <PageTitle text={t('pengaturan_tugas_judul')} />
            <Flex className={`${style.task_setting_content}`}>
                {/* is active info */}
                <Flex className={`${style.task_setting_content_left}`}>
                    <Text className={`${style.fs16_500}`}>
                        {t('pengaturan_tugas_radius_checkin_tugas')}
                    </Text>
                    <Flex gap={'16px'} alignItems={'center'}>
                        {isLoadingTeamDetail ? (
                            <Skeleton height={'21px'} width={'70px'} />
                        ) : (
                            <Text className={`${style.fs14_500}`}>
                                {teamDetail &&
                                teamDetail?.profile_mobileconfig
                                    .is_checkin_strict_location === 1
                                    ? t(
                                          'pengaturan_tugas_radius_checkin_tugas_status_aktif'
                                      )
                                    : t(
                                          'pengaturan_tugas_radius_checkin_tugas_status_tidak_aktif'
                                      )}
                            </Text>
                        )}
                        <span
                            className={`material-symbols-outlined ${style.icon}`}
                        >
                            navigate_next
                        </span>
                    </Flex>
                </Flex>

                {/* divider */}
                <Divider
                    borderColor={'var(--colorOnSurface)'}
                    orientation={'vertical'}
                    className={`${style.divider_vertical}`}
                />
                <Divider
                    borderColor={'var(--colorOnSurface)'}
                    orientation={'horizontal'}
                    className={`${style.divider_horizontal}`}
                />

                {/* maps */}
                <Flex className={`${style.task_setting_content_right}`}>
                    {isLoaded && (
                        <GoogleMap
                            mapContainerStyle={{
                                height: '160px',
                                width: '100%',
                                borderRadius: '16px',
                            }}
                            center={centerDefault}
                            zoom={zoomDefault}
                            clickableIcons={false}
                        >
                            <MarkerF position={centerDefault} />
                            <CircleF
                                center={centerDefault}
                                radius={
                                    teamDetail &&
                                    teamDetail?.profile_mobileconfig
                                        .strict_checkin_radius
                                }
                                options={{
                                    strokeOpacity: 0.5,
                                }}
                            />
                        </GoogleMap>
                    )}
                    <Flex justifyContent={`space-between`} gap={`16px`}>
                        <Flex direction={'column'} gap={'9px'}>
                            <Text className={`${style.fs16_500}`}>
                                {t('pengaturan_tugas_radius_checkin_tugas')}
                            </Text>
                            <Text className={`${style.fs14_400}`}>
                                {t('pengaturan_tugas_memberikan')}
                                <span
                                    className={`${style.italic} ${style.block}`}
                                >
                                    {t('pengaturan_tugas_catatan')}{' '}
                                    <span className={`${style.bold}`}>
                                        {t('pengaturan_tugas_catatan_text')}
                                    </span>
                                    .
                                </span>
                            </Text>
                        </Flex>
                        {isLoadingTeamDetail ? (
                            <Skeleton height={'21px'} width={'35px'} />
                        ) : (
                            <Switch
                                onClickCapture={() => setModalToggle(true)}
                                height={'max-content'}
                                isChecked={
                                    teamDetail &&
                                    teamDetail?.profile_mobileconfig
                                        .is_checkin_strict_location === 1
                                        ? true
                                        : false
                                }
                            />
                        )}
                    </Flex>
                    <Flex
                        direction={`row`}
                        padding={`24px 16px`}
                        borderRadius={`16px`}
                        borderColor={`var(--colorOnSurface)`}
                        borderWidth={`1px`}
                        width={`100%`}
                        justifyContent={`space-between`}
                        alignItems={`center`}
                        cursor={`${
                            teamDetail &&
                            teamDetail?.profile_mobileconfig
                                .is_checkin_strict_location === 1
                                ? 'pointer'
                                : 'not-allowed'
                        }`}
                        onClick={() => {
                            if (
                                teamDetail &&
                                teamDetail?.profile_mobileconfig
                                    .is_checkin_strict_location === 1
                            ) {
                                setRadius(
                                    teamDetail &&
                                        teamDetail?.profile_mobileconfig
                                            .strict_checkin_radius
                                )
                                setModalRadius(true)
                            } else {
                                return
                            }
                        }}
                    >
                        <Flex direction={`column`} gap={`16px`}>
                            <Text className={`${style.fs14_500}`}>
                                {t('pengaturan_tugas_besar_radius')}
                            </Text>
                            {isLoadingTeamDetail ? (
                                <Skeleton height={'21px'} width={'130px'} />
                            ) : (
                                <Text className={`${style.fs14_400}`}>
                                    {t('pengaturan_tugas_besar_radius_nilai', {
                                        radius:
                                            teamDetail &&
                                            teamDetail?.profile_mobileconfig
                                                .strict_checkin_radius,
                                    })}
                                </Text>
                            )}
                        </Flex>
                        <span
                            className={`material-symbols-outlined ${style.icon}`}
                        >
                            navigate_next
                        </span>
                    </Flex>
                </Flex>
            </Flex>

            {/* toggle modal */}
            <Modal
                isOpen={modalToggle}
                onClose={() => setModalToggle(false)}
                isCentered
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent
                    bgColor={'var(--colorSurface1)'}
                    padding={`24px`}
                    margin={`10px`}
                >
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody
                        as={Flex}
                        flexDirection={`column`}
                        gap={`16px`}
                        padding={`0`}
                    >
                        <Text className={`${style.fs16_500}`}>
                            {t('pengaturan_tugas_modal_toggle_radius_judul', {
                                to:
                                    teamDetail &&
                                    teamDetail?.profile_mobileconfig
                                        .is_checkin_strict_location === 1
                                        ? t('pengaturan_tugas_mematikan')
                                        : t('pengaturan_tugas_menyalakan'),
                            })}
                        </Text>
                        <Text className={`${style.fs14_400}`}>
                            {t('pengaturan_tugas_modal_toggle_radius_body')}
                        </Text>
                    </ModalBody>
                    <ModalFooter
                        as={Flex}
                        gap={`24px`}
                        justifyContent={`flex-end`}
                        padding={`0`}
                        mt={`24px`}
                    >
                        <Button
                            type={'text'}
                            text={t('pengaturan_tugas_modal_toggle_batal')}
                            onClick={() => setModalToggle(false)}
                        />
                        <Button
                            text={t('pengaturan_tugas_modal_toggle_submit', {
                                submit:
                                    teamDetail &&
                                    teamDetail?.profile_mobileconfig
                                        .is_checkin_strict_location === 1
                                        ? t('pengaturan_tugas_matikan')
                                        : t('pengaturan_tugas_nyalakan'),
                            })}
                            onClick={async () => {
                                await handleUpdateTaskSettingCheckin()
                                setModalToggle(false)
                                handleGetTeamDetail()
                            }}
                            loading={loadingUpdateTaskSettingCheckIn}
                        />
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* toggle radius */}
            <Modal
                isOpen={modalRadius}
                size="xl"
                onClose={() => setModalRadius(false)}
                isCentered
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent
                    bgColor={'var(--colorSurface1)'}
                    padding={`24px`}
                    margin={`10px`}
                >
                    <ModalHeader padding="0px" as={Flex} gap={`16px`}>
                        <span
                            className={`material-symbols-outlined ${style.icon} ${style.pointer}`}
                            onClick={() => setModalRadius(false)}
                        >
                            arrow_back
                        </span>
                        <Text className={`${style.fs16_500}`}>
                            {t('pengaturan_tugas_modal_atur_radius_judul')}
                        </Text>
                    </ModalHeader>
                    <ModalBody
                        as={Flex}
                        flexDirection={`column`}
                        gap={`24px`}
                        padding={`0`}
                        mt={`20px`}
                    >
                        <Flex gap={`24px`}>
                            <Slider
                                aria-label="slider-ex-1"
                                value={radius}
                                min={50}
                                max={1000}
                                onChange={(e: any) => setRadius(e)}
                            >
                                <SliderTrack>
                                    <SliderFilledTrack />
                                </SliderTrack>
                                <SliderThumb />
                            </Slider>
                            <Flex
                                height={`48px`}
                                width={`120px`}
                                borderRadius={`16px`}
                                fontSize={`14px`}
                                bgColor={`var(--colorSurface)`}
                                alignItems={`center`}
                                paddingRight={`15px`}
                            >
                                <Input
                                    height={`48px`}
                                    width={`70px`}
                                    borderRadius={`16px`}
                                    // fontSize={`14px`}
                                    // bgColor={`var(--colorSurface)`}
                                    border={`none`}
                                    value={radius}
                                    min={50}
                                    max={1000}
                                    onChange={(e: any) => {
                                        if (
                                            Number(e.target.value) >= 50 &&
                                            Number(e.target.value) <= 1000
                                        ) {
                                            setRadius(Number(e.target.value))
                                        }
                                    }}
                                    onKeyDown={(e: any) => {
                                        if (e.code === 'ArrowDown') {
                                            setRadius((prev: any) => {
                                                return prev - 1
                                            })
                                        } else if (e.code === 'ArrowUp') {
                                            setRadius((prev: any) => {
                                                return prev + 1
                                            })
                                        }
                                    }}
                                    _focusVisible={{
                                        border: 'none',
                                    }}
                                />
                                <Text>M</Text>
                            </Flex>
                        </Flex>
                        <Text className={`${style.fs14_500}`}>
                            {t(
                                'pengaturan_tugas_modal_atur_radius_contoh_penerapan'
                            )}
                        </Text>
                        {isLoaded && (
                            <GoogleMap
                                mapContainerStyle={{
                                    height: '362px',
                                    width: '100%',
                                    borderRadius: '16px',
                                    marginTop: '-16px',
                                }}
                                center={centerDefault}
                                zoom={zoomDefault}
                                clickableIcons={false}
                            >
                                <MarkerF position={centerDefault} />
                                <CircleF
                                    center={centerDefault}
                                    radius={radius}
                                    options={{
                                        strokeOpacity: 0.5,
                                    }}
                                />
                            </GoogleMap>
                        )}
                    </ModalBody>
                    <ModalFooter
                        as={Flex}
                        gap={`24px`}
                        justifyContent={`flex-end`}
                        padding={`0`}
                        mt={`24px`}
                    >
                        <Button
                            text={t(
                                'pengaturan_tugas_modal_atur_radius_simpan'
                            )}
                            onClick={() => setModalConfirmChangeRadius(true)}
                            loading={loadingUpdateTaskSettingCheckIn}
                        />
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* confirm change radius modal */}
            <Modal
                isOpen={modalConfirmChangeRadius}
                onClose={() => setModalConfirmChangeRadius(false)}
                isCentered
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent
                    bgColor={'var(--colorSurface1)'}
                    padding={`24px`}
                    margin={`10px`}
                >
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody
                        as={Flex}
                        flexDirection={`column`}
                        gap={`16px`}
                        padding={`0`}
                    >
                        <Text className={`${style.fs16_500}`}>
                            {t('pengaturan_tugas_modal_confirm_radius_judul')}
                        </Text>
                        <Text className={`${style.fs14_400}`}>
                            {t('pengaturan_tugas_modal_confirm_radius_body')}
                        </Text>
                    </ModalBody>
                    <ModalFooter
                        as={Flex}
                        gap={`24px`}
                        justifyContent={`flex-end`}
                        padding={`0`}
                        mt={`24px`}
                    >
                        <Button
                            type={'text'}
                            text={t('pengaturan_tugas_modal_confirm_batal')}
                            onClick={() => setModalConfirmChangeRadius(false)}
                        />
                        <Button
                            text={t('pengaturan_tugas_modal_confirm_submit')}
                            onClick={async () => {
                                await handleUpdateTaskSettingRadius()
                                setModalConfirmChangeRadius(false)
                                setModalRadius(false)
                                handleGetTeamDetail()
                            }}
                            loading={loadingUpdateTaskSettingRadius}
                        />
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    )
}

export default TaskSetting
