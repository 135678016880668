import React, { memo } from 'react'
import { JButton, JTypography } from '../../../components'
import { 
    Avatar, 
    Flex, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalHeader, 
    ModalOverlay, 
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

interface IModalFaceRecognitionDetail {
    disclosure: any
    modalDeleteDisclosure: any
    data: any
}

const ModalFaceRecognitionDetail: React.FC<IModalFaceRecognitionDetail> = (props: IModalFaceRecognitionDetail) => {
    const { 
        disclosure, 
        modalDeleteDisclosure, 
        data,
    } = props
    const { t } = useTranslation()

    return (
        <Modal
            isOpen={disclosure.isOpen}
            onClose={disclosure.onClose}
            size={"lg"}
        >
            <ModalOverlay />
            <ModalContent
                borderRadius={'16px'}
                backgroundColor={"background"}
                padding={"24px"}
            >
                <ModalHeader
                    padding={"0px"}
                    mt={'10px'}
                    mb={2}
                >
                    <JTypography size="title-medium">{`${t('master_anggota_face_recognition')} (${data?.user?.display_name})`}</JTypography>
                </ModalHeader>
                <ModalCloseButton top={"24px"} right={"24px"} />
                <ModalBody
                    as={Flex}
                    flexDirection={"column"}
                    gap={"16px"}
                    padding={"0px"}
                    marginTop={"24px"}
                >
                    <Flex justifyContent={'space-between'} alignItems='center'>
                        <Flex alignItems={'center'} gap='13px'>
                            <Avatar
                                height='55px'
                                width='55px'
                                name={data?.user?.display_name}
                                src={data?.user?.face_recognition_photo_url}
                            />
                            <JTypography size="body-large">{t('master_anggota_face_recognition_registered_face')}</JTypography>
                        </Flex>
                        <JButton
                            variant="j-outline"
                            size="small"
                            onClick={modalDeleteDisclosure.onOpen}
                        >
                            {t('master_anggota_face_recognition_remove_face_submit')}
                        </JButton>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default memo(ModalFaceRecognitionDetail)