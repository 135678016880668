import React from 'react'
import { useSelector } from 'react-redux'
import { Link as ReactRouterLink, useLocation } from 'react-router-dom'
import { 
    Flex,
    Heading, 
    Link, 
    useColorMode, 
} from '@chakra-ui/react'

interface SubmoduleProps {
    submodule: any
}

const Submodule = (props: SubmoduleProps) => {
    const { submodule } = props

    const location = useLocation()
    const teamV2: any = useSelector((state: any) => state?.teamV2)
    const { colorMode } = useColorMode()

    return (
        <Link
            as={ReactRouterLink}
            paddingRight={0}
            to={submodule?.to}
            _hover={{ textDecoration: 'none' }}
        >
            <Flex 
                borderLeftWidth={location?.pathname === submodule?.to ? '3px' : '0px'}
                borderLeftColor={colorMode === 'light'
                    ?   location?.pathname === submodule?.to 
                        ?   teamV2?.detail?.theme_object?.code === 'Grey-vis' ? 'black' : '#FDFCFF'
                        :   'unset'
                    :   location?.pathname === submodule?.to ? 'primary' : 'unset'
                }
                transition='.3s'
                padding='12px 16px'
                gap='12px'
                alignItems='center'
            >
                <Heading
                    size='chakra_title_small'
                    overflow='hidden'
                    whiteSpace='nowrap'
                    lineHeight='150%'
                    // color='#FDFCFF'
                    color={colorMode === 'light'
                        ?   teamV2?.detail?.theme_object?.code === 'Grey-vis' ? 'black' : '#FDFCFF'
                        :   'inherit'
                    }
                >{submodule.name}</Heading>
            </Flex>
        </Link>
    )
}

export default React.memo(Submodule)