const headingTheme = {
    sizes: {
        'display-large': { fontSize: '57px', fontWeight: '600', fontFamily: 'Poppins' },
        'display-medium': { fontSize: '45px', fontWeight: '500', fontFamily: 'Poppins' },
        'display-small': { fontSize: '36px', fontWeight: '500', fontFamily: 'Poppins' },

        'headline-large': { fontSize: '32px', fontWeight: '400', fontFamily: 'Poppins' },
        'headline-medium': { fontSize: '28px', fontWeight: '500', fontFamily: 'Poppins' },
        'headline-small': { fontSize: '24px', fontWeight: '400', fontFamily: 'Poppins' },

        'title-large': { fontSize: '22px', fontWeight: '400', fontFamily: 'Poppins' },
        'title-medium': { fontSize: '16px', fontWeight: '500',fontFamily: 'Roboto' },
        'title-small': { fontSize: '14px', fontWeight: '500', fontFamily: 'Roboto' },

        'label-large': { fontSize: '14px', fontWeight: '500', fontFamily: 'Roboto' },
        'label-medium': { fontSize: '12px', fontWeight: '500', fontFamily: 'Roboto' },
        'label-small': { fontSize: '11px', fontWeight: '500', fontFamily: 'Roboto' },

        'body-large': { fontSize: '16px', fontWeight: '400', fontFamily: 'Roboto' },
        'body-medium': { fontSize: '14px', fontWeight: '400', fontFamily: 'Roboto' },
        'body-small': { fontSize: '12px', fontWeight: '400', fontFamily: 'Roboto' },

        'chakra_display_large': {
            fontFamily: 'Poppins',
            fontWeight: '600',
            fontSize: '57px',
            lineHeight: '85.5px',
            letterSpacing: '-0.5px',
            color: 'onBackground',
        },
        'chakra_display_medium': {
            fontFamily: 'Poppins',
            fontWeight: '500',
            fontSize: '45px',
            lineHeight: '67.5px',
            color: 'onBackground',
        },
        'chakra_display_small': {
            fontFamily: 'Poppins',
            fontWeight: '500',
            fontSize: '36px',
            lineHeight: '54px',
            color: 'onBackground',
        },
        'chakra_headline_large': {
            fontFamily: 'Poppins',
            fontWeight: '400',
            fontSize: '32px',
            lineHeight: '48px',
            letterSpacing: '0.3px',
            color: 'onBackground',
        },
        'chakra_headline_medium': {
            fontFamily: 'Poppins',
            fontWeight: '500',
            fontSize: '28px',
            lineHeight: '42px',
            letterSpacing: '-1%',
            color: 'onBackground',
        },
        'chakra_headline_small': {
            fontFamily: 'Poppins',
            fontWeight: '400',
            fontSize: '24px',
            lineHeight: '36px',
            color: 'onBackground',
        },
        'chakra_title_large': {
            fontFamily: 'Poppins',
            fontWeight: '400',
            fontSize: '22px',
            lineHeight: '33px',
            letterSpacing: '0.3px',
            color: 'onBackground',
        },
        'chakra_title_medium': {
            fontFamily: 'Roboto',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '18.75px',
            letterSpacing: '0.15px',
            color: 'onBackground',
        },
        'chakra_title_small': {
            fontFamily: 'Roboto',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '16.41px',
            letterSpacing: '0.1px',
            color: 'onBackground',
        },
        'chakra_label_large': {
            fontFamily: 'Roboto',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.1px',
            color: 'onBackground',
        },
        'chakra_label_medium': {
            fontFamily: 'Roboto',
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '0.5px',
            color: 'onBackground',
        },
        'chakra_label_small': {
            fontFamily: 'Roboto',
            fontWeight: '500',
            fontSize: '11px',
            lineHeight: '16px',
            letterSpacing: '0.5px',
            color: 'onBackground',
        },
        'chakra_body_large': {
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.5px',
            color: 'onBackground',
        },
        'chakra_body_medium': {
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.25px',
            color: 'onBackground',
        },
        'chakra_body_small': {
            fontFamily: 'Roboto',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '0.4px',
            color: 'onBackground',
        },
    },
}

export default headingTheme
