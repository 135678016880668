import {
  Box,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Button, LoadingModal, PageTitle } from "../../components";
import { useEffect, useState } from "react";
import style from "./attendance-monitoring.module.css";
import { getAttendanceMonitor } from "../../redux";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import moment from "moment";
import AttendanceListTable from "./components/AttendanceListTable";
import { useMutation } from "react-query";
import ReactPaginate from "react-paginate";

const AttendanceMonitoring = () => {
  const [attendanceDate, setAttendanceDate] = useState({
    start_date: format(new Date(), "yyyy-MM-dd"),
    end_date: format(new Date(), "yyyy-MM-dd"),
  });
  const [search, setSearch] = useState("");
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const attendance = useMutation({
    mutationFn: (): any => getAttendanceMonitor,
  });
  const {
    attendanceMonitorMetadata,
    loadingGetAttendanceMonitor,
    attendanceMonitor,
  } = useSelector((state: any) => state.attendance);

  function handleGetAttendanceMonitor() {
    const data = {
      search: "",
      start_date: attendanceDate.start_date,
      end_date: attendanceDate.end_date,
      page: 1,
    };
    dispatch(getAttendanceMonitor(data));
  }


  function handlePagination(type: any) {
    const data = {
      search: "",
      start_date: attendanceDate.start_date,
      end_date: attendanceDate.end_date,
      page:
        type === "next"
          ? attendanceMonitorMetadata.current_page + 1
          : attendanceMonitorMetadata.current_page - 1,
    };
    dispatch(getAttendanceMonitor(data));
  }
  function handleSearch(keyword: any) {
    setSearch(keyword?.target?.value);
    const data = {
      search: keyword.target.value,
      start_date: attendanceDate.start_date,
      end_date: attendanceDate.end_date,
      page: 1,
    };
    if (keyword.type === "click" || keyword.key === "Enter") {
      dispatch(getAttendanceMonitor(data));
    }
  }
  function handleChangeAttendanceDate(e: any) {
    if (e.target.value === "") return;
    setAttendanceDate((previous: any) => {
      return {
        ...previous,
        [e.target.name]: e.target.value,
      };
    });
  }

  function handlePaginationNum(type: any) {
    const data = {
      search: "",
      start_date: attendanceDate.start_date,
      end_date: attendanceDate.end_date,
      page: type + 1,
    };
    dispatch(getAttendanceMonitor(data));
  }

  useEffect(() => {
    handleGetAttendanceMonitor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendanceDate]);

  return (
    <Flex className={`${style.attendance_monitoring}`}>
      <PageTitle text={t("monitoring_absensi")} />
      <Flex className={`${style.controls}`}>
        <InputGroup width="max-content" className={`${style.searchbar}`}>
          <InputRightElement
            children={
              <span
                className="material-symbols-outlined"
                style={{ cursor: "default" }}
                onClick={(e: any) => handleSearch(e)}
              >
                search
              </span>
            }
          />
          <Input
            type="search"
            width="max-content"
            placeholder={t("monitoring_absensi_pencarian_placeholder")}
            value={search}
            onChange={(e: any) => handleSearch(e)}
            className={`${style.fs14}`}
            onKeyDown={(keyword: any) => {
              const data = {
                search: keyword.target.value,
                start_date: attendanceDate.start_date,
                end_date: attendanceDate.end_date,
                page: attendanceMonitorMetadata.current_page,
              };
              if (keyword.type === "click" || keyword.key === "Enter") {
                dispatch(getAttendanceMonitor(data));
              }
            }}
          />
        </InputGroup>
        <Flex className={`${style.control_date}`}>
          <Text whiteSpace="nowrap" className={`${style.fs14}`}>
            {t("monitoring_absensi_berdasarkan_tanggal")}
          </Text>
          <Flex alignItems="center" gap="8px">
            <Text className={`${style.fs14} ${style.since}`}>
              {t("monitoring_absensi_tanggal_mulai")}
            </Text>
            <Input
              width="max-content"
              type="date"
              className={`${style.fs14}`}
              defaultValue={attendanceDate.start_date}
              name="start_date"
              onChange={handleChangeAttendanceDate}
            />
          </Flex>
          <Text className={`${style.control_date_separator}`}>-</Text>
          <Flex alignItems="center" gap="8px">
            <Text className={`${style.fs14} ${style.since}`}>
              {t("monitoring_absensi_tanggal_sampai")}
            </Text>
            <Input
              width="max-content"
              type="date"
              className={`${style.fs14}`}
              defaultValue={attendanceDate.end_date}
              name="end_date"
              onChange={handleChangeAttendanceDate}
            />
          </Flex>
        </Flex>
      </Flex>
      <AttendanceListTable attendance={attendance} />

      {attendanceMonitorMetadata !== null && (
        <Flex
          backgroundColor="surfacePlus4"
          padding="22px 16px"
          alignItems="center"
          justifyContent="space-between"
          borderRadius="0px 0px 16px 16px"
        >
          <Heading size="chakra_label_large" color="onSurfaceVariant">
            {t("monitoring_absensi_tabel_informasi", {
              from: attendanceMonitorMetadata.from,
              to: attendanceMonitorMetadata.to,
              total: attendanceMonitorMetadata.total,
            })}
          </Heading>
          <Flex gap="8px">
            <Box marginTop="-20px">
              <ReactPaginate
                pageCount={attendanceMonitorMetadata?.last_page}
                pageRangeDisplayed={3}
                breakLabel="..."
                onPageChange={(e) => {
                  handlePaginationNum(e.selected);
                }}
                previousLabel={`< ${t(
                  "customer_master_table_pagination_previous"
                )}`}
                nextLabel={`${t("customer_master_table_pagination_next")} >`}
                containerClassName="react-paginate-pagination"
                pageLinkClassName="react-paginate-page-item"
                breakLinkClassName="react-paginate-page-item"
                previousLinkClassName="react-paginate-page-item-previous"
                nextLinkClassName="react-paginate-page-item-next"
                activeClassName="react-paginate-page-item-active"
              />
            </Box>
          </Flex>
        </Flex>
      )}

      <LoadingModal show={loadingGetAttendanceMonitor} />
    </Flex>
  );
};

export default AttendanceMonitoring;
