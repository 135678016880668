import http from './http'
import urlApi from './url'
import { generateErrorMessage } from '../helpers'

interface IGetMembers {
    search?: any
}

interface IGetJournies {
    member_id: any
    tanggal: any
}

interface IGetStraightDistances {
    member_id: any
    start_date: any
    end_date: any
}

interface IGetElapsedTimes {
    member_id: any
    start_date: any
    end_date: any
}

interface IGetLiveTrackingAreas {
    member_id: any
}

interface IGetCustomers {
    member_id: any
    tanggal: any
}

export async function getMembersApi(props: IGetMembers) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            page: 1,
            show: 1000,
            is_active: 1,
            with_columns: '',
            ...props
        }
        const response: any = await http(true).post(urlApi.memberJourney.post.getMembers, payload)
        if (response.code !== '200') throw generateErrorMessage(response?.message, 'Error get members')
        return response.data
    } catch (error) {
        throw 'Error get members'
    }
}

export const getJourniesApi = async (props: IGetJournies) => {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }

        let response: any = await http(true).post(urlApi.memberJourney.post.getJournies, payload)
        if (response.code !== '200') throw generateErrorMessage(response?.message, 'Error get journies')
        return response.data
    } catch (error) {
        throw 'Error get journies'
    }
}

export const getStraightDistancesApi = async (props: IGetStraightDistances) => {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }

        let response: any = await http(true).post(urlApi.memberJourney.post.getStraightDistances, payload)
        if (response.code !== '200') throw generateErrorMessage(response?.message, 'Error get straight distance')
        return response.data
    } catch (error) {
        throw 'Error get straight distance'
    }
}

export const getElapsedTimesApi = async (props: IGetElapsedTimes) => {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }

        let response: any = await http(true).post(urlApi.memberJourney.post.getElapsedTimes, payload)
        if (response.code !== '200') throw generateErrorMessage(response?.message, 'Error get elapsed time')
        return response.data
    } catch (error) {
        throw 'Error get elapsed time'
    }
}

export async function getLiveTrackingAreasApi(props: IGetLiveTrackingAreas) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.memberJourney.post.getLiveTrackingAreas, payload)
        if (response.code !== '200') throw generateErrorMessage(response?.message, 'Error get live tracking areas')
        return response.data
    } catch (error) {
        throw 'Error get live tracking areas'
    }
}

export async function getCustomersApi(props: IGetCustomers) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.memberJourney.post.getCustomers, payload)
        if (response.code !== '200') throw generateErrorMessage(response?.message, 'Error get customer points')
        return response.data
    } catch (error) {
        throw 'Error get customer points'
    }
}