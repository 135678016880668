import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import restEnLang from './locales/en/rest.en.json'
import restIdLang from './locales/id/rest.id.json'

/**
 * Login page and its components localization
 */
import loginEnLang from './locales/en/login.en.json'
import loginIdLang from './locales/id/login.id.json'

/**
 * Team page and its components localization
 */
import teamEnLang from './locales/en/team.en.json'
import teamIdLang from './locales/id/team.id.json'

/**
 * Invitation page and its components localization
 */
import invitationEnLang from './locales/en/invitation.en.json'
import invitationIdLang from './locales/id/invitation.id.json'

/**
 * Multiple Reschedule Component and its components localization
 */
import multipleRescheduleEnLang from './locales/en/multipleReschedule.en.json'
import multipleRescheduleIdLang from './locales/id/multipleReschedule.id.json'

/**
 * Master Outlet Page and its components localization
 */
import masterOutletEnLang from './locales/en/masterOutlet.en.json'
import masterOutletIdLang from './locales/id/masterOutlet.id.json'

/**
 * Master Product page and its components localization
 */
import masterProductEnLang from './locales/en/masterProduct.en.json'
import masterProductIdLang from './locales/id/masterProduct.id.json'

/**
 * Settlement page and its components localization
 */
import settlementEnLang from './locales/en/settlement.en.json'
import settlementIdLang from './locales/id/settlement.id.json'

/**
 * Commission page and its components localization
 */
import commissionEnLang from './locales/en/commission.en.json'
import commissionIdLang from './locales/id/commission.id.json'

/**
 * Transactions page and its components localization
 */
import transactionEnLang from './locales/en/transaction.en.json'
import transactionIdLang from './locales/id/transaction.id.json'

/**
 * Bulletin page and its components localization
 */
import bulletinEnLang from './locales/en/bulletin.en.json'
import bulletinIdLang from './locales/id/bulletin.id.json'

/**
 * Bulletin page and its components localization
 */
import leadEnLang from './locales/en/lead.en.json'
import leadIdLang from './locales/id/lead.id.json'

/**
 * Task Calendar View page and its components localization
 */
import taskCalendarViewEnLang from './locales/en/taskCalendarView.en.json'
import taskCalendarViewIdLang from './locales/id/taskCalendarView.id.json'

/**
 * Master Ingredient page and its components localization
 */
import masterIngredientEnLang from './locales/en/masterIngredient.en.json'
import masterIngredientIdLang from './locales/id/masterIngredient.id.json'

/**
 * Master Ingredient page and its components localization
 */
import masterRecipeEnLang from './locales/en/masterRecipe.en.json'
import masterRecipeIdLang from './locales/id/masterRecipe.id.json'

/**
 * Master Outlet page and its components localization
 */
import masterStoreEnLang from './locales/en/masterStore.en.json'
import masterStoreIdLang from './locales/id/masterStore.id.json'

const resources = {
    en: {
        translation: {
            ...loginEnLang,
            ...teamEnLang,
            ...invitationEnLang,
            ...multipleRescheduleEnLang,
            ...masterOutletEnLang,
            ...settlementEnLang,
            ...commissionEnLang,
            ...transactionEnLang,
            ...masterProductEnLang,
            ...bulletinEnLang,
            ...leadEnLang,
            ...restEnLang,
            ...taskCalendarViewEnLang,
            ...masterIngredientEnLang,
            ...masterRecipeEnLang,
            ...masterStoreEnLang,
        },
    },
    id: {
        translation: {
            ...loginIdLang,
            ...teamIdLang,
            ...invitationIdLang,
            ...multipleRescheduleIdLang,
            ...masterOutletIdLang,
            ...settlementIdLang,
            ...commissionIdLang,
            ...transactionIdLang,
            ...masterProductIdLang,
            ...bulletinIdLang,
            ...leadIdLang,
            ...restIdLang,
            ...taskCalendarViewIdLang,
            ...masterIngredientIdLang,
            ...masterRecipeIdLang,
            ...masterStoreIdLang,
        },
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'id',
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
