import http from "./http";
import urlApi from "./url";

export const loginApi = async (google_token: string) => {
  try {
    const bodyPayload = new FormData()
    bodyPayload.set('google_token', google_token)
    let response: any = await http(false).post(urlApi.auth, bodyPayload)
    response.data.token = response.token
    response.data.signature = response.signature
    response.data.message = response.message
    return response.data
  } catch (error) {
    console.error(error);
    return null
  }
}