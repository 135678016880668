import http from './http'
import urlApi from './url'
import { generateErrorMessage } from '../helpers'

interface IRejectInvitation {
    token_invitation: string
}

interface IAcceptInvitation {
    token_invitation: string
}

export async function getInvitationApi() {
    try {
        const response: any = await http(true).post(urlApi?.invitation?.getInvitations)
        if (response.code !== '200') throw generateErrorMessage(response?.message, 'Error get invitations')
        return response.data
    } catch (error: any) {
        throw generateErrorMessage(error, 'Error get invitations')
    }
}

export async function rejectInvitationApi(props: IRejectInvitation) {
    try {
        const payload = { ...props }
        const response: any = await http(true).post(urlApi?.invitation?.rejectInvitation, payload)
        if (response.code !== '200') throw generateErrorMessage(response?.message, 'Error reject invitation')
        return response.data
    } catch (error: any) {
        throw generateErrorMessage(error, 'Error reject invitation')
    }
}

export async function acceptInvitationApi(props: IAcceptInvitation) {
    try {
        const payload = { ...props }
        const response: any = await http(true).post(urlApi?.invitation?.acceptInvitation, payload)
        if (response.code !== '200') throw generateErrorMessage(response?.message, 'Error accept invitation')
        return response.data
    } catch (error: any) {
        throw generateErrorMessage(error, 'Error accept invitation')
    }
}