import axios, { 
    AxiosError, 
    AxiosInstance, 
    AxiosResponse, 
    CreateAxiosDefaults, 
} from 'axios'

const http = (withAuth = false) => {
    const config: CreateAxiosDefaults = { baseURL: process.env.REACT_APP_BASE_URL }

    const axiosInstance: AxiosInstance = axios.create(config)

    axiosInstance.interceptors.request.use(
        function (config) {
            // Do something before request is sent
            const authToken = localStorage.getItem('access_token')
            if (withAuth) {
                config.headers.token = authToken
            }
            return config
        }, 
        function (error) {
            // Do something with request error
            return Promise.reject(error)
        }
    )

    axiosInstance.interceptors.response.use(
        function (response: AxiosResponse) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response.data
        }, 
        function (error: AxiosError) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error?.response)
        }
    )

    return axiosInstance
}

export default http


