import React from 'react'
import { JGoogleIcon } from '../../../components'
import { useTranslation } from 'react-i18next'
import { 
    Input, 
    InputGroup, 
    InputRightElement, 
} from '@chakra-ui/react'

interface ISearchbarMember {
    onChange: any
    value: string
    onSubmit: any
}

const SearchbarMember: React.FC<ISearchbarMember> = (props: ISearchbarMember) => {
    const { onChange, value, onSubmit } = props

    const { t } = useTranslation()

    return (
        <InputGroup>
            <InputRightElement
                children={<JGoogleIcon name='search' cursor='pointer' onClick={() => onSubmit()} />}
            />
            <Input
                type='search'
                onChange={onChange}
                onKeyDown={(e: any) => e.code === 'Enter' && onSubmit()}
                value={value}
                height='40px'
                placeholder={t('perjalanan_search_member')}
                fontFamily='Roboto'
            />
        </InputGroup>
    )
}

export default SearchbarMember