import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/authSlice'
import teamReducer from './slices/teamSlice'
import menuReducer from './slices/menuSlice'
import memberReducer from './slices/memberSlice'
import lastLocationReducer from './slices/lastLocationSlice'
import attendanceReducer from './slices/attendanceSlice'
import taskReducer from './slices/taskSlice'
import menuPrivilageSlice from './slices/menuPrivilageSlice'
import teamSliceV2 from './slices/teamSliceV2'
import sidebarSlice from './slices/sidebarSlice'

export const store = configureStore({
    reducer: {
        auth: authReducer,
        team: teamReducer,
        menu: menuReducer,
        member: memberReducer,
        lastLocation: lastLocationReducer,
        attendance: attendanceReducer,
        task: taskReducer,
        menuPrivilage: menuPrivilageSlice.reducer,
        teamV2: teamSliceV2.reducer,
        sidebar: sidebarSlice.sidebarReducer
    },
})
