import { 
    Box,
    Button,
    Divider,
    Flex, 
    FormErrorMessage, 
    Heading, 
    Input, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalFooter, 
    ModalHeader, 
    ModalOverlay, 
} from '@chakra-ui/react'
import { 
    Circle, 
    GoogleMap, 
    MarkerF 
} from '@react-google-maps/api'
import { useTranslation } from 'react-i18next'
import { 
    JButton, 
    JGoogleIcon, 
    JInput, 
    JTypography 
} from '../../../components'
import { headingTheme } from '../../../theme/components'
import CustomStepper from '../../../components/CustomStepper/CustomStepper'
import { IC_MS_LOCATION_ON_LIGHT } from '../../../assets'

interface LocationInformationProps {
    is_open: boolean
    on_close: any
    on_back: any
    initial_area: any
    render_selected_members: any
    on_submit: any
    is_loading: boolean
    label_value: string
    label_on_change: any
    errorLabel: boolean
}

const LocationInformation = (props: LocationInformationProps) => {
    const { t } = useTranslation()
    const {
        is_open,
        on_close,
        on_back,
        initial_area,
        render_selected_members,
        on_submit,
        is_loading,
        label_value,
        label_on_change,
        errorLabel
    } = props

    return (
        <Modal
            isOpen={is_open}
            onClose={on_close}
            scrollBehavior={'outside'}
            closeOnOverlayClick={false}
            size={'5xl'}
            blockScrollOnMount={true}
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                backgroundColor={'surfacePlus1'}
                // height='96vh'
                overflow='auto'
                borderRadius='24px'
                margin='0px'
                // width='800px'
            >
                <ModalHeader
                    as={Flex}
                    flexDirection={'column'}
                    alignItems={'center'}
                    padding={'24px 24px 0px'}
                    gap='24px'
                >
                    <Flex width='282px' marginX='auto' marginTop='28px'>
                        <CustomStepper stepLength={3} stepActive={2} />
                    </Flex>
                    <Heading size={'chakra_title_medium'}>{t('pengaturan_absen_modal_label_lokasi_judul')}</Heading>
                </ModalHeader>
                <ModalCloseButton
                    right='24px'
                    top='24px'
                />
                <ModalBody 
                    as={Flex}
                    gap={'24px'}
                    padding={'0px 24px'}
                    marginTop='24px'
                    height='50vh'
                >
                    <Flex
                        as={Flex}
                        gap={'48px'}
                        direction='row'
                        width='100%'
                    >
                        <Flex direction='column' gap='8px'>
                            <Heading size='chakra_title_small' marginBottom='8px'>{t('pengaturan_absen_modal_label_lokasi_detail_lokasi_label')}</Heading>
                            <GoogleMap
                                mapContainerStyle={{
                                    height: '254px',
                                    width: '428px',
                                    borderRadius: '16px',
                                }}
                                center={{
                                    lat: initial_area.latitude,
                                    lng: initial_area.longitude,
                                }}
                                zoom={15}
                                clickableIcons={false}
                            >
                                <MarkerF
                                    position={{
                                        lat: initial_area.latitude,
                                        lng: initial_area.longitude,
                                    }}
                                    icon={{
                                        url: IC_MS_LOCATION_ON_LIGHT,
                                        rotation: 1,
                                        scaledSize: { width: 35, height: 35, equals: () => true },
                                    }}
                                />
                                <Circle
                                    center={{
                                        lat: initial_area.latitude,
                                        lng: initial_area.longitude,
                                    }}
                                    radius={initial_area.radius}
                                    options={{
                                        strokeColor: 'transparent',
                                        fillColor: '#0079D2',
                                        fillOpacity: 0.19,
                                        zIndex: 2,
                                    }}
                                />
                            </GoogleMap>
                            <Flex direction='column' gap='4px'>
                                <Flex alignItems='center' gap='4px'>
                                    <JGoogleIcon name='location_on' color='primary' />
                                    <Heading size='chakra_body_medium'>{initial_area.address}</Heading>
                                </Flex>
                                <Flex alignItems='center' gap='4px'>
                                    <JGoogleIcon name='settings_accessibility' color='primary' />
                                    <Heading size='chakra_body_medium'>{t('pengaturan_anggota_radius_sebesar', { radius: initial_area.radius })}</Heading>
                                </Flex>
                            </Flex>
                            {render_selected_members()}
                        </Flex>

                        <Divider orientation='vertical' />

                        <Flex
                            direction={'column'}
                            gap={'12px'}
                            width='100%'
                        >
                            <Heading size='chakra_title_small'>{t('pengaturan_anggota_label_lokasi')} <Box as='span' color='error'>*</Box></Heading>
                            <Input
                                {...headingTheme?.sizes?.chakra_label_large}
                                isInvalid={errorLabel}
                                placeholder={t('pengaturan_anggota_label_lokasi')}
                                value={label_value}
                                onChange={label_on_change}
                            />
                            {errorLabel && 
                                <Heading size='chakra_body_medium' color='onErrorContainer'>{t('pengaturan_absensi_label_error')}</Heading>
                            }
                        </Flex>
                    </Flex>
                </ModalBody>
                <ModalFooter
                    padding='24px'
                    gap={'16px'}
                >
                    <Button
                        size={'normal'}
                        variant={'j-outline'}
                        onClick={on_back}
                        width='160px'
                    >
                        {t('pengaturan_anggota_live_tracking_kembali')}
                    </Button>

                    <Button
                        size={'normal'}
                        variant={'j-primary'}
                        onClick={on_submit}
                        isLoading={is_loading}
                        width='160px'
                    >
                        {t('pengaturan_absen_simpan')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default LocationInformation