import {
    Fragment, 
    useState,
    useRef,
    useEffect
} from 'react'
import { 
    BsStarFill, 
} from 'react-icons/bs'
import {
    Avatar,
    Center,
    Checkbox,
    Divider,
    Flex,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Progress,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Switch,
    useToast,
    Tabs,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Skeleton,
    useDisclosure,
    Popover,
    Button,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Grid,
    GridItem,
    Icon,
    Input,
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {
    JButton, JGoogleIcon, JInput, JTypography 
} from '../../components'
import {
    useGetMemberSettingStatus,
    useGetMemberSettingAreas,
    useGetMemberSettingMemberList,
    useGetMemberSettingMemberListForNotify,
    useCreateMemberSettingArea,
    useEditMemberSettingStatus,
    useEditMemberSettingStatusArea,
    useEditMemberSettingArea,
    useDeleteMemberSettingArea,
    useSearchMemberSettingArea
} from '../../query'
import Area from './components/Area'
import PlacesAutocomplete, {
    geocodeByAddress, getLatLng
} from 'react-places-autocomplete'
import {
    Circle, GoogleMap, MarkerF 
} from '@react-google-maps/api'
import JToast from '../../components/JToast/JToast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useMutation } from 'react-query'
import { GetLiveTrackingListAreaApi } from '../../apis'
import './InfiniteScroll.css'
import UploadTemplate from './components/UploadTemplate'
import ModalUploadTemplate from './components/ModalUploadTemplate'
import ModalResult from './components/ModalResult'
import { feedbackMasterApi, feedbackCreateApi, feedbackMasterDeleteApi } from '../../apis/feedbackApi'
import { headingTheme } from '../../theme/components'
import CustomStepper from '../../components/CustomStepper/CustomStepper'
import { IC_MS_LOCATION_ON_LIGHT } from '../../assets'
interface InitialAreaProps {
    centerPoint: string
    centerPointLatLng: {
        lat: number
        lng: number
    }
    centerPointAddress: string
    radius: number
    isRadiusTolranceOn: boolean
    radiusTolerance: number
    members: any[]
    label: string
    startTime: string
    endTime: string
}

const MemberSetting = () => {
    const {t} = useTranslation()
    const toast = useToast()
    const ref: any = useRef<any>();
    const modalUploadTemplateDisclosure = useDisclosure()
    const modalResultDisclosure = useDisclosure()
    const [chipLow, setChipLow] = useState('')
    const [addChipLow, setAddChipLow] = useState<any>([])
    const [errorChipLow, setErrorChipLow] = useState(false)
    const [chipHigh, setChipHigh] = useState('')
    const [addChipHigh, setAddChipHigh] = useState<any>([])
    const [errorChipHigh, setErrorChipHigh] = useState(false)
    const [scrollFlag, setScrollFlag] = useState(false)
    const [isUpdateFlow, setIsUpdateFlow] = useState<any>(false)
    const [modalDetailSize, setModalDetailSize] = useState('xl');
    const [area, setArea] = useState<any>(null)
    const [searchChooseMember, setSearchChooseMember] = useState('')
    const [searchSendAlert, setSearchSendAlert] = useState('')

    const [modalToggleStatus, setModalToggleStatus] = useState(false)
    const [modalToggleStatusArea, setModalToggleStatusArea] = useState(false)
    const [modalDeleteArea, setModalDeleteArea] = useState(false)
    const [areaDetailsModal, setAreaDetailsModal] = useState(false)

    const [file, setFile] = useState<any>(null)
    const [upload, setUpload] = useState<any>(null)

    const [initialAreaDefault] = useState<InitialAreaProps>({
        centerPoint: '',
        centerPointLatLng: {
            lat: -6.2213866,
            lng: 106.7796252,
        },
        centerPointAddress: '',
        radius: 50,
        isRadiusTolranceOn: false,
        radiusTolerance: 0,
        members: [],
        label: '',
        startTime: '00:00:00',
        endTime: '00:00:00',
    })
    const [initialArea, setInitialArea] = useState<InitialAreaProps>(initialAreaDefault)
    const [feedbackMasterData, setFeedbackMasterData] = useState<any[]>([])
    const [modalTentukanLokasi, setModalTentukanLokasi] = useState(false)
    const [modalTentukanAnggota, setModalTentukanAnggota] = useState(false)
    const [modalTentukanPeringatan, setModalTentukanPeringatan] = useState(false)
    const [modalDetailAreaLokasi, setModalDetailAreaLokasi] = useState(false)
    const [memberSettingAreas, setMemberSettingAreas] = useState<any>([])
    const [configGetLiveTrackingList, setConfigGetLiveTrackingList] = useState<any>({
        page: 1,
        search: '',
        profile_id: localStorage.getItem('current_team'),
    })

    const modalCancelLiveTrackingDisclosure = useDisclosure()

    const mutationLiveTrackingArea: any = useMutation(GetLiveTrackingListAreaApi, {
        onSuccess: (resp) => {
            setMemberSettingAreas((prev: any[]) => {
                return [
                    ...prev,
                    ...resp?.data
                ]
            })
        }
    })
    
    const {
        data: memberSettingStatus,
        refetch: refetchMemberSettingStatus,
        isFetching: fetchingMemberSettingStatus,
    } = useGetMemberSettingStatus()
    // const {
    //     isFetching: fetchingMemberSettingAreas,
    //     refetch: refetchMemberSettingAreas,
    //     data: memberSettingAreasFetch,
    // }: any = useGetMemberSettingAreas(configGetLiveTrackingList, setMemberSettingAreas)
    const {
        isFetching: fetchingMemberSettingMemberList,
        data: memberSettingMemberList,
        refetch: refetchGetMemberSettingMemberList,
    } = useGetMemberSettingMemberList()

    const {
        isFetching: fetchingCreateMemberSettingArea, 
        refetch: refetchCreateMemberSettingArea,
        isLoading: isLoadingCreateMemberSettingArea
    } = useCreateMemberSettingArea(initialArea)
    const {
        isFetching: fetchingUpdateMemberSettingStatus, 
        refetch: refetchUpdateMemberSettingStatus
    } = useEditMemberSettingStatus(memberSettingStatus || false)
    const {
        isFetching: fetchingUpdateMemberSettingStatusArea, 
        refetch: refetchUpdateMemberSettingStatusArea
    } = useEditMemberSettingStatusArea(area)
    const {
        isFetching: fetchingUpdateMemberSettingArea, 
        refetch: refetchUpdateMemberSettingArea
    } = useEditMemberSettingArea({
        ...initialArea,
        id: isUpdateFlow?.id,
    })
    const {
        isFetching: fetchingDeleteMemberSettingArea, 
        refetch: refetchDeleteMemberSettingArea
    } = useDeleteMemberSettingArea(area)
    function handleChangeChipLow(e:any){
        if(chipLow?.length < 40){
            setChipLow(e?.target?.value)
        } else{
            setChipLow(e?.target?.value?.substring(0,39))
        }
    }
    function handleChipLowEnter(e:any){
        if(e?.key === "Enter"){
            if(chipLow.length>0){
                var arrChip:any = [...addChipLow];
                arrChip.push(chipLow);
                setAddChipLow(arrChip);
                setErrorChipLow(false);
                setChipLow('');
                createFeedbackMaster?.mutate({rating: 'bad', description: `${"\\"}${chipLow}${'\\'}`})
            } else {
                setErrorChipLow(true)
            }
        }
    }
    function handlePushChipLow(){
        if(feedbackMaster?.data?.metadata?.manipulatable_column?.toogle?.is_master_feedback === "1"){
            if(chipLow.length>0){
                var arrChip:any = [...addChipLow];
                arrChip.push(chipLow);
                setAddChipLow(arrChip);
                setErrorChipLow(false);
                setChipLow('');
                createFeedbackMaster?.mutate({rating: 'bad', description: `${"\\"}${chipLow}${'\\'}`})
            } else {
                setErrorChipLow(true)
            }
        }
    }
    function handleChangeChipHigh(e:any){
        if(chipHigh?.length < 40){
            setChipHigh(e?.target?.value)
        } else{
            setChipHigh(e?.target?.value?.substring(0,39))
        }
    }
    function handleChipHighEnter(e:any){
        if(e?.key === "Enter"){
            if(chipHigh.length>0){
                var arrChip:any = [...addChipHigh];
                arrChip.push(chipHigh);
                setAddChipHigh(arrChip);
                setErrorChipHigh(false);
                setChipHigh('');
                createFeedbackMaster?.mutate({rating: 'good', description: `${"\\"}${chipHigh}${'\\'}`})
            } else {
                setErrorChipHigh(true)
            }
        }
    }
    function handlePushChipHigh(){
        if(feedbackMaster?.data?.metadata?.manipulatable_column?.toogle?.is_master_feedback === "1"){
            if(chipHigh.length>0){
                var arrChip:any = [...addChipHigh];
                arrChip.push(chipHigh)
                setAddChipHigh(arrChip)
                setErrorChipHigh(false);
                setChipHigh('');
                createFeedbackMaster?.mutate({rating: 'good', description: `${"\\"}${chipHigh}${'\\'}`})
            } else {
                setErrorChipHigh(true)
            }
        }
    }
    function handleDeleteChip(e:any){
        if(feedbackMaster?.data?.metadata?.manipulatable_column?.toogle?.is_master_feedback === "1"){
            deleteFeedbackMaster?.mutate({master_feedback_id: e?.id})
        }
    }
    function handleSelectMember(user: any, type: 'chooseMember' | 'sendAlert') {
        const userId = user.id
        const tempMembers: any[] = []

        const selectedMembers: any = []
        if(type === 'chooseMember'){
            initialArea['members'].forEach((group: any) => {
                group.members.forEach((member: any) => {
                    if (member.checked === true) {
                        if (selectedMembers.find((m: any) => m.id === member.id) === undefined) selectedMembers.push(member)
                    }
                })
            })
        } else{
            initialArea['members'].forEach((group: any) => {
                group.members.forEach((member: any) => {
                    if (member.checked_notify === true) {
                        if (selectedMembers.find((m: any) => m.id === member.id) === undefined) selectedMembers.push(member)
                    }
                })
            })
        }

        if (!user.checked_notify && type === 'sendAlert' && selectedMembers?.length >= 3)return;
        if(type === 'sendAlert'){
            initialArea['members'].forEach((group: any) => {
                group.members.forEach((member: any) => {
                    if (member.id === userId) {
                        member.checked_notify = !member.checked_notify
                    }
                })
                const isUncheckExist = group.members.find((member: any) => member.checked_notify === false) !== undefined
                group.checked_notify = group.members.length === 0 ? false : isUncheckExist ? false : true
                tempMembers.push(group)
            })
        } else {
            initialArea['members'].forEach((group: any) => {
                group.members.forEach((member: any) => {
                    if (member.id === userId) {
                        member.checked = !member.checked
                    }
                })
                const isUncheckExist = group.members.find((member: any) => member.checked === false) !== undefined
                group.checked = group.members.length === 0 ? false : isUncheckExist ? false : true
                tempMembers.push(group)
            })
        }

        setInitialArea((prev: any) => {
            return {
                ...prev,
                members: tempMembers 
            }
        })
    }
    function handleSelectGroupMember(group: any, type: 'chooseMember' | 'sendAlert') {
        const groupCheck = group.checked
        const groupMember = group.members.map((member: any) => member.id)
        const tempMembers: any = []
        if(type === 'chooseMember'){
            initialArea['members'].forEach((group: any) => {
                group.members.forEach((member: any) => {
                    if (groupMember.includes(member.id)) {
                        member.checked = !groupCheck
                    }
                })
                const isUncheckExist = group.members.find((member: any) => member.checked === false) !== undefined
                group.checked = group.members.length === 0 ? false : isUncheckExist ? false : true
                tempMembers.push(group)
            })
        } else {
            initialArea['members'].forEach((group: any) => {
                group.members.forEach((member: any) => {
                    if (groupMember.includes(member.id)) {
                        member.checked_notify = !groupCheck
                    }
                })
                const isUncheckExist = group.members.find((member: any) => member.checked_notify === false) !== undefined
                group.checked_notify = group.members.length === 0 ? false : isUncheckExist ? false : true
                tempMembers.push(group)
            })
        }

        setInitialArea((prev: any) => {
            return {
                ...prev,
                members: tempMembers
            }
        })
    }
    function countGroupMembers() {
        const selectedMembers: any = []
        const selectedGroups: any = []
        initialArea['members'].forEach((group: any) => {
            group.members.forEach((member: any) => {
                if (member.checked === true) {
                    if (selectedMembers.find((m: any) => m.id === member.id) === undefined) selectedMembers.push(member)
                }
            })
        });
        initialArea['members'].forEach((group: any) => {
            group.members.find((member: any) => member.checked === true) !== undefined && selectedGroups.push(group)
        });
        return selectedGroups.length;
    }
    function handleDeleteMember(member: any, type: 'chooseMember' | 'sendAlert') {
        const memberId = member?.id
        const tempMembers: any = []
        if(type === 'chooseMember'){
            initialArea['members'].forEach((group: any) => {
                group.members.forEach((member: any) => {
                    if (member.id === memberId) {
                        member.checked = !member.checked
                    }
                })
                const isUncheckExist = group.members.find((member: any) => member.checked === false) !== undefined
                group.checked = group.members.length === 0 ? false : isUncheckExist ? false : true
                tempMembers.push(group)
            })
        } else{
            initialArea['members'].forEach((group: any) => {
                group.members.forEach((member: any) => {
                    if (member.id === memberId) {
                        member.checked_notify = !member.checked_notify
                    }
                })
                const isUncheckExist = group.members.find((member: any) => member.checked_notify === false) !== undefined
                group.checked_notify = group.members.length === 0 ? false : isUncheckExist ? false : true
                tempMembers.push(group)
            })
        }

        setInitialArea((prev: any) => {
            return {
                ...prev,
                members: tempMembers 
            }
        })
    }
    const handleChangeSearch = (event: any) => {
        if(event.key === 'Enter'){
            setMemberSettingAreas([])
            setConfigGetLiveTrackingList( (prev:any)=> {
                return{
                    ...prev,
                    search: event.target.value,
                    page: 1
                }
            })
            setTimeout(() => {
                mutationLiveTrackingArea?.mutate({
                    profile_id: configGetLiveTrackingList.profile_id,
                    page: 1,
                    search: event.target.value,
                })
            }, 1);
        }
    }
    function handlePrepareaUpdate(area: any) {
        const tempMembers = memberSettingMemberList
        tempMembers.map((group: any) => {
            group.members.map((member: any) => {
                if (area.members.find((m: any) => m.id === member.id)) {
                    member.checked = true
                } else {
                    member.checked = false
                }
            })
            if (group.members.find((m: any) => m.checked === false) === undefined) {
                group.checked_notify = true
            } else {
                group.checked_notify = false
            }
        })
        tempMembers.map((group: any) => {
            group.members.map((member: any) => {
                // if (member.id === area.member_notify.id) {
                //     member.checked = true
                // } else {
                //     member.checked = false
                // }
                if (area.member_notify.find((m: any) => m.id === member.id)) {
                    member.checked_notify = true
                } else {
                    member.checked_notify = false
                }
            })
            if (group.members.find((m: any) => m.checked_notify === false) === undefined) {
                group.checked_notify = true
            } else {
                group.checked_notify = false
            }
        })

        setSearchChooseMember('')
        setSearchSendAlert('')
        setInitialArea({
            label: area.label,
            radius: area.radius,
            isRadiusTolranceOn: area.is_radius_tolerance_active === 1 ? true : false,
            radiusTolerance: area.radius_tolerance,
            centerPointLatLng: {
                lat: area.latitude,
                lng: area.longitude,
            },
            centerPointAddress: area.address,
            startTime: area.start_time,
            endTime: area.end_time,
            centerPoint: area.address,
            members: tempMembers
        })
        setIsUpdateFlow(area)
        setModalTentukanLokasi(true)
    }

    useEffect(() => {
        // refetchMemberSettingAreas();
        // window.scrollTo({ top: 0, behavior: "smooth" });
        mutationLiveTrackingArea?.mutate(configGetLiveTrackingList)
    }, [])
    function testScroll(){
        if(ref?.current?.lastScrollTop > 100 && !scrollFlag){
            window.scrollTo({top: 200, behavior: 'smooth'})
            setScrollFlag(true)
        } else if (ref?.current?.lastScrollTop < 100 && scrollFlag){
            window.scrollTo({top: 0, behavior: 'smooth'})
            setScrollFlag(false)
        }
    } 
    const feedbackMaster = useMutation(feedbackMasterApi, {
        onSuccess: (resp: any) => {
            console.log({ resp })
            setFeedbackMasterData((prev: any) => {
                return resp?.data
            })
            setAddChipHigh(resp?.data?.filter((p:any)=> p?.rating === 'good' && p?.is_active == "1"))
            setAddChipLow(resp?.data?.filter((p:any)=> p?.rating === 'bad' && p?.is_active == "1"))
        },
        onError: (error: any) => console.log({ error })
    })
    const createFeedbackMaster = useMutation(feedbackCreateApi, {
        onSuccess: (resp: any) => {
            feedbackMaster?.mutate({is_master_feedback: feedbackMaster?.data?.metadata?.manipulatable_column?.toogle?.is_master_feedback === "1" ? "AKTIF" : "TIDAK AKTIF"})
            setFeedbackMasterData((prev: any) => {
                return resp?.data
            })
            toast({
                position: 'top-right',
                render: () => (
                    <JToast
                        type={'success'}
                        message={t('feedback_master_success_chip')}
                    />
                ),
                duration: 3000,
                isClosable: true,
            })
        },
        onError: (error: any) => console.log({ error })
    })
    const deleteFeedbackMaster = useMutation(feedbackMasterDeleteApi, {
        onSuccess: (resp: any) => {
            feedbackMaster?.mutate({is_master_feedback: feedbackMaster?.data?.metadata?.manipulatable_column?.toogle?.is_master_feedback === "1" ? "AKTIF" : "TIDAK AKTIF"})
            setFeedbackMasterData((prev: any) => {
                return resp?.data
            })
            toast({
                position: 'top-right',
                render: () => (
                    <JToast
                        type={'success'}
                        message={t('feedback_master_success_chip_delete')}
                    />
                ),
                duration: 3000,
                isClosable: true,
            })
        },
        onError: (error: any) => console.log({ error })
    })
    function handleChangeStatusFeedback(e:any){
        if(e){
            feedbackMaster?.mutate({is_master_feedback: "AKTIF"})
        } else {
            feedbackMaster?.mutate({is_master_feedback: "TIDAK AKTIF"})
        }
        toast({
            position: 'top-right',
            render: () => (
                <JToast
                    type={'success'}
                    message={ e ? t('feedback_master_success_toggle_on') : t('feedback_master_success_toggle_off')}
                />
            ),
            duration: 3000,
            isClosable: true,
        })
    }
    useEffect(() => {
        feedbackMaster?.mutate({is_master_feedback: feedbackMaster?.data?.metadata?.manipulatable_column?.toogle?.is_master_feedback === "0" ? 'TIDAK AKTIF' : "AKTIF"})
    }, [])
    function RenderSelectedMembers(type: 'chooseMember' | 'sendAlert') {
        const selectedMembers: any = []
        if(type==='chooseMember'){
            initialArea['members'].forEach((group: any) => {
                group.members.forEach((member: any) => {
                    if (member.checked === true) {
                        if (selectedMembers.find((m: any) => m.id === member.id) === undefined) selectedMembers.push(member)
                    }
                })
            })
        } else{
            initialArea['members'].forEach((group: any) => {
                group.members.forEach((member: any) => {
                    if (member.checked_notify === true) {
                        if (selectedMembers.find((m: any) => m.id === member.id) === undefined) selectedMembers.push(member)
                    }
                })
            })
        }
        return (
            <Flex
                direction={'column'}
                gap={'16px'}
                height='100%'
            >
                {type === 'sendAlert' ? (
                    <Flex direction='column' gap='8px'>
                        <Heading size={'chakra_title_small'}>
                            {t('pengaturan_anggota_anggota_terpilih', {total: selectedMembers.length})}
                        </Heading>
                        <Heading size={'chakra_body_medium'}>{t('pengaturan_anggota_anggota_terdaftar_sublabel')}</Heading>
                    </Flex>
                ) : (
                    <Flex direction='column' gap='8px'>
                        <Heading size={'chakra_title_small'}>{t('pengaturan_anggota_anggota_terdaftar')}</Heading>
                        <Heading size={'chakra_body_medium'}>{t('pengaturan_anggota_anggota_terdaftar_sublabel')}</Heading>
                    </Flex>
                )}
                <Flex 
                    direction='column' 
                    gap='16px'
                    // height='calc(58vh - 12px)'
                    overflowY='auto'
                >
                    {selectedMembers.map((member: any) => (
                        <Flex
                            key={member.id}
                            gap={'12px'}
                            alignItems={'center'}
                        >
                            <Avatar name={member.display_name} src={member?.photo_url} />
                            <Flex
                                direction={'column'}
                                gap={'4px'}
                            >
                                <Heading
                                    size='chakra_label_large'
                                    wordBreak={'break-word'}
                                >
                                    {member.display_name}
                                </Heading>
                                <Heading
                                    size='chakra_body_medium'
                                    wordBreak={'break-word'}
                                >
                                    {member.username}
                                </Heading>
                            </Flex>
                            <JGoogleIcon
                                marginLeft='auto'
                                name='delete'
                                cursor={'pointer'}
                                color='primary'
                                onClick={() => handleDeleteMember(member, type)}
                            />
                        </Flex>
                    ))}
                </Flex>
            </Flex>
        )
    }

    function RenderSelectedChooseMember() {
        const selectedMembers: any = []
        initialArea.members.map((group: any) => {
            group.members.map((member: any) => {
                if (member.checked && selectedMembers.find((sm: any) => sm.id === member.id) === undefined) selectedMembers.push(member)
            })
        })
        return (
            <Flex alignItems={'center'}>
                {selectedMembers.map((member: any, index: number) => {
                    if (index <= 3)
                        return (
                            <Avatar
                                width={'24px'}
                                height={'24px'}
                                key={index}
                                name={member.display_name}
                                src={member.photo_url}
                                marginLeft={index > 0 ? '-6px' : '0px'}
                                size='sm'
                            />
                        )
                })}
                <Heading
                    size={'chakra_body_medium'}
                    marginLeft={'12px'}
                >
                    {t('pengaturan_anggota_area_jumlah_anggota', {total: selectedMembers.length})}
                </Heading>
            </Flex>
        )
    }

    function handleDetailView(area: any, type: 'update' | 'detail') {
        // if(countGroupMembers()>1){
        //     setModalDetailSize('4xl');
        // } else {
        //     setModalDetailSize('xl');
        // };
        refetchGetMemberSettingMemberList();
        const tempMembers = memberSettingMemberList
        tempMembers.map((group: any) => {
            group.members.map((member: any) => {
                if (area.members.find((m: any) => m.id === member.id)) {
                    member.checked = true
                } else {
                    member.checked = false
                }
            })
            if (group.members.find((m: any) => m.checked === false) === undefined) {
                group.checked = true
            } else {
                group.checked = false
            }
        })
        tempMembers.map((group: any) => {
            group.members.map((member: any) => {
                if(Array.isArray(area.member_notify)){
                    if (area.member_notify.find((m: any) => m.id === member.id)) {
                        member.checked_notify = true
                    } else {
                        member.checked_notify = false
                    }
                } else{
                    if ([area.member_notify].find((m: any) => m.id === member.id)) {
                        member.checked_notify = true
                    } else {
                        member.checked_notify = false
                    }
                }

            })
            if (group.members.find((m: any) => m.checked === false) === undefined) {
                group.checked_notify = true
            } else {
                group.checked_notify = false
            }
        })

        setSearchChooseMember('')
        setSearchSendAlert('')
        setInitialArea({
            label: area.label,
            radius: area.radius,
            isRadiusTolranceOn: area.is_radius_tolerance_active === 1 ? true : false,
            radiusTolerance: area.radius_tolerance,
            centerPointLatLng: {
                lat: area.latitude,
                lng: area.longitude,
            },
            centerPointAddress: area.address,
            startTime: area.start_time,
            endTime: area.end_time,
            centerPoint: area.address,
            members: tempMembers
        })
        setIsUpdateFlow(area)
        if(type === "update"){
            setModalTentukanLokasi(true)
        } else {
            setAreaDetailsModal(true)
        }
       
    }
    function RenderDetailMembers(type: 'chooseMember' | 'sendAlert' | 'chooseMemberDetail' | 'sendAlertDetail') {
        const selectedMembers: any = []
        const selectedGroups: any = []
        if(type === 'chooseMember' || type == 'chooseMemberDetail'){
            initialArea['members'].forEach((group: any) => {
                group.members.forEach((member: any) => {
                    if (member.checked === true) {
                        if (selectedMembers.find((m: any) => m.id === member.id) === undefined) selectedMembers.push(member)
                    }
                })
            })
        } else {
            initialArea['members'].forEach((group: any) => {
                group.members.forEach((member: any) => {
                    if (member.checked_notify === true) {
                        if (selectedMembers.find((m: any) => m.id === member.id) === undefined) selectedMembers.push(member)
                    }
                })
            })
        }

        type === "chooseMember" || type == 'chooseMemberDetail' &&
            initialArea['members'].forEach((group: any) => {
                group.members.find((member: any) => member.checked === true) !== undefined && selectedGroups.push(group)
            });
        type === "chooseMember" || type == 'chooseMemberDetail' &&
            selectedGroups.forEach((group: any) => {
                group.members = group.members.filter((member: any) => member.checked === true);
            });

        if (type == 'sendAlertDetail') return selectedMembers
        if (type == 'chooseMemberDetail') return selectedGroups
        return (
            <Flex
                direction={'column'}
                gap={'16px'}
                height={type === 'chooseMember' ? '750px' : '200px'}
            >
                {type === 'sendAlert' 
                    ?   (
                            <>
                                {selectedMembers.length <= 0 ?
                                    <JTypography mt={2} mb={2} color={'onSurfaceVariant'} size='body-medium'>
                                        {t('pengaturan_anggota_detail_live_tracking_notifikasi_kosong')}
                                    </JTypography> :
                                    <Flex direction={'column'} >
                                        <JTypography mb={3} mt={2} color={'onSurfaceVariant'} size='body-medium'>
                                            {t('pengaturan_anggota_detail_live_tracking_notifikasi')}
                                        </JTypography>
                                        {selectedMembers.map((member: any) => (
                                            <Flex
                                                key={member.id}
                                                gap={'24px'}
                                                alignItems={'center'}
                                                mb={3}
                                            >
                                                <Avatar name={member.display_name} src={member?.photo_url} />
                                                <Flex
                                                    direction={'column'}
                                                    gap={'4px'}
                                                >
                                                    <JTypography
                                                        size='title-medium'
                                                        wordBreak={'break-word'}
                                                    >
                                                        {member.display_name}
                                                    </JTypography>
                                                    <JTypography
                                                        size='body-medium'
                                                        wordBreak={'break-word'}
                                                        color={'onSurfaceVariant'}
                                                    >
                                                        {member.username}
                                                    </JTypography>
                                                </Flex>
                                            </Flex>
                                        ))}
                                    </Flex>
                                }
                            </>
                        )           
                    :   type == 'chooseMember'
                        ?   (
                                <Flex mt={8} h={"100%"} w={'100%'} direction={'column'} position={'relative'}>
                                    <JTypography size={'title-medium'}>{`${t('pengaturan_anggota_di_tracking')} (${selectedMembers.length})`}</JTypography>
                                    <Flex  overflowY={'scroll'} w={'100%'} flexWrap={'wrap'} direction={'row'}  alignItems={'flex-start'} justifyContent={'flex-start'} borderRadius={"18px"} height={"100%"} mt={4} backgroundColor={'surfacePlus3'}>
                                        {selectedGroups.length > 1 &&
                                            <Divider height={'90%'} orientation='vertical' backgroundColor={'outlineVariant'} position={'absolute'} my={5} left={'50%'} />
                                        }
                                        {selectedGroups.map((groups: any) => (
                                            <Flex direction={'column'} w={selectedGroups.length > 1 ? '50%' : '100%'}> 
                                                <JTypography size={'body-medium'} paddingTop={4} paddingX={5}> {groups.display_name} ({groups.members.length}) </JTypography>  
                                                <Flex direction={'column'}alignItems={'flex-start'} justifyContent={'flex-start'} flexWrap={'wrap'}>
                                                    
                                                    <Flex direction={'column'}
                                                        paddingY={2}
                                                        paddingX={3}
                                                    >
                                                        {groups.members.map((members: any) => (
                                                            <Flex
                                                                gap={'24px'}
                                                                mt={3}
                                                                alignItems={'center'}
                                                            >
                                                                <Avatar name={members.display_name} src={members?.photo_url} />
                                                                <Flex
                                                                    direction={'column'}
                                                                    gap={'4px'}
                                                                >
                                                                    <JTypography
                                                                        size='title-medium'
                                                                        wordBreak={'break-word'}
                                                                    >
                                                                        {members.display_name}
                                                                    </JTypography>
                                                                    <JTypography
                                                                        size='body-medium'
                                                                        wordBreak={'break-word'}
                                                                        color={'onSurfaceVariant'}
                                                                    >
                                                                        {members.username}
                                                                    </JTypography>
                                                                </Flex>

                                                            </Flex>

                                                        ))}
                                                    </Flex>

                                                </Flex>
                                            </Flex>
                                        )
                                        )}

                                    </Flex>
                                </Flex>
                            )
                        :   null
                    }

            </Flex>
        )
    }

    useEffect(()=>{
        if(countGroupMembers()>1){
            setModalDetailSize('4xl');
        } else {
            setModalDetailSize('xl');
        };
    }, [initialArea])
    
    return (
        <Flex
            gap={'24px'}
            width={'100%'}
            direction={'column'}
        >
            <JTypography size='title-large'>{t('pengaturan_anggota_judul')}</JTypography>
            <Flex
                padding={'24px 16px'}
                borderRadius={'16px'}
                backgroundColor={'surfacePlus3'}
                gap={'24px'}
                width={'100%'}
            >
                <Tabs width={'100%'}>
                    <Flex gap={'24px'}>
                        <TabList
                            flexDirection={'column'}
                            width={'42%'}
                            border={'none'}
                        >
                            <Tab
                                as={Flex}
                                padding={'14px 16px'}
                                width={'100%'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                _selected={{
                                    borderLeftColor: 'primary',
                                    borderLeftWidth: '2px',
                                }}
                                cursor={'pointer'}
                            >
                                <Flex>
                                    <JTypography size='label-large'>{t('pengaturan_anggota_live_tracking')}</JTypography>
                                </Flex>
                                <Flex
                                    gap={'16px'}
                                    alignItems={'center'}
                                >
                                    {fetchingMemberSettingStatus ? (
                                        <Skeleton
                                            width={'48px'}
                                            height={'20px'}
                                        />
                                    ) : (
                                        <JTypography size='title-small'>
                                            {memberSettingStatus
                                                ? t('pengaturan_anggota_live_tracking_aktif')
                                                : t('pengaturan_anggota_live_tracking_tidak_aktif')}
                                        </JTypography>
                                    )}
                                    <JGoogleIcon name='navigate_next' />
                                </Flex>
                            </Tab>
                            <Tab
                                as={Flex}
                                padding={'14px 16px'}
                                width={'100%'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                _selected={{
                                    borderLeftColor: 'primary',
                                    borderLeftWidth: '2px',
                                }}
                                cursor={'pointer'}
                            >
                                <Flex>
                                    <JTypography size='label-large'>{t('pengaturan_anggota_feedback')}</JTypography>
                                </Flex>
                                <Flex
                                    gap={'16px'}
                                    alignItems={'center'}
                                >
                                    {feedbackMaster?.isLoading ? (
                                        <Skeleton
                                            width={'48px'}
                                            height={'20px'}
                                        />
                                    ) : (
                                        <JTypography size='title-small'>
                                            {feedbackMaster?.data?.metadata?.manipulatable_column?.toogle?.is_master_feedback === "1"
                                                ? t('pengaturan_anggota_live_tracking_aktif')
                                                : t('pengaturan_anggota_live_tracking_tidak_aktif')}
                                        </JTypography>
                                    )}
                                    <JGoogleIcon name='navigate_next' />
                                </Flex>
                            </Tab>
                        </TabList>

                        <TabPanels
                            borderLeftColor={'outline'}
                            borderLeftWidth={'1px'}
                            paddingLeft={'24px'}
                        >
                            {/* tab general */}
                            <TabPanel
                            minHeight={'calc(100vh - var(--headerHeight) - 32px - 24px - 33px)'}
                            gap={'24px'}>
                                <Flex justifyContent={'space-between'} gap={'24px'}>
                                    <Flex
                                        direction={'column'}
                                        gap={'8px'}
                                    >
                                        <JTypography size={'title-medium'}>{t('pengaturan_anggota_live_tracking')}</JTypography>
                                        <JTypography size={'body-medium'}>{t('pengaturan_anggota_tujuan')}</JTypography>
                                    </Flex>
                                    {fetchingMemberSettingStatus ? (
                                        <Skeleton
                                            width={'48px'}
                                            height={'20px'}
                                        />
                                    ) : (
                                        <Switch
                                            isChecked={memberSettingStatus}
                                            onClickCapture={() => {
                                                setModalToggleStatus(true)
                                            }}
                                            height={'min-content'}
                                        />
                                    )}
                                </Flex>
                                <UploadTemplate modalUploadTemplateDisclosure={modalUploadTemplateDisclosure} />
                                <Divider
                                    borderColor={'neutral.30'}
                                    orientation={'horizontal'}
                                    margin={'24px 0px'}
                                />
                                <Flex
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                    gap={'24px'}
                                    margin={'24px 0px'}
                                >
                                    <JTypography size={'label-large'}>{t('pengaturan_anggota_daftar_live_tracking')}</JTypography>
                                    <JButton
                                        variant={'j-primary'}
                                        leftIcon={<JGoogleIcon name='add' />}
                                        size={'normal'}
                                        disabled={
                                            memberSettingStatus === false ||
                                            memberSettingStatus === undefined ||
                                            fetchingMemberSettingMemberList 
                                        }
                                        onClick={() => {
                                            const tempMember = memberSettingMemberList
                                            tempMember.map((group: any) => {
                                                group.checked = false
                                                group.members.map((member: any) => {
                                                    member.checked = false
                                                })
                                            })
                                            tempMember.map((group: any) => {
                                                group.checked_notify = false
                                                group.members.map((member: any) => {
                                                    member.checked_notify = false
                                                })
                                            })
                                            setIsUpdateFlow(null)
                                            setSearchChooseMember('')
                                            setSearchSendAlert('')
                                            setInitialArea({
                                                ...initialAreaDefault,
                                                members: tempMember
                                            })
                                            setModalTentukanLokasi(true)
                                        }}
                                    >
                                        {t('pengaturan_anggota_tambah_area_live_tracking')}
                                    </JButton>
                                </Flex>
                                <JInput
                                    isDisabled={
                                        memberSettingStatus === false ||
                                        memberSettingStatus === undefined ||
                                        fetchingMemberSettingMemberList
                                    }
                                    type={'search'}
                                    placeholder={t('pengaturan_anggota_cari_area_live_tracking')}
                                    value={configGetLiveTrackingList?.search}
                                    onChange={(e: any) => setConfigGetLiveTrackingList( (prev:any)=> {
                                        return{
                                            ...prev,
                                            search: e.target.value,
                                        }
                                    })}
                                    onKeyPress={(e: any) => handleChangeSearch(e)}
                                    leftIcon={<JGoogleIcon name={'search'} />}
                                    marginBottom={'24px'}
                                />
                                {
                                    mutationLiveTrackingArea?.data &&
                                    !mutationLiveTrackingArea?.isFetching &&
                                    <Heading
                                        marginY='-8px' 
                                        size='chakra_body_medium'
                                        color='onSurface'
                                        marginBottom={'24px'}
                                    >Total Area: {mutationLiveTrackingArea?.data?.metadata?.total}</Heading>
                                }
                                {
                                    memberSettingAreas?.length === 0 &&
                                    mutationLiveTrackingArea?.isLoading &&
                                    <Skeleton
                                        height={'252px'}
                                        width={'100%'}
                                        borderRadius={'16px'}
                                    />
                                }
                                <Flex onScroll = {testScroll} id="scrollableDiv" maxHeight={'470px'} width={'100%'} overflowY={'scroll'} >
                                    {memberSettingAreas &&
                                        <InfiniteScroll
                                            onScroll={testScroll}
                                            style={{ width: '100%' }}
                                            ref={ref}
                                            scrollableTarget="scrollableDiv"
                                            dataLength={Number(memberSettingAreas?.length)}
                                            next={() => {
                                                setConfigGetLiveTrackingList((prev: any) => {
                                                    return {
                                                        ...prev,
                                                        page: prev?.page + 1
                                                    }
                                                })
                                                setTimeout(() => {
                                                    // refetchMemberSettingAreas();
                                                    mutationLiveTrackingArea?.mutate({
                                                        profile_id: configGetLiveTrackingList.profile_id,
                                                        page: mutationLiveTrackingArea?.variables.page + 1,
                                                        search: configGetLiveTrackingList.search,
                                                    })
                                                }, 1)
                                            }}
                                            hasMore={Number(mutationLiveTrackingArea?.data?.metadata?.current_page) < Number(mutationLiveTrackingArea?.data?.metadata?.last_page) && mutationLiveTrackingArea?.data}
                                            loader={null}
                                            >
                                            {memberSettingAreas?.map((area:any, index: number)=>{
                                                return (
                                                    <Area
                                                        key={index}
                                                        status = {memberSettingStatus}
                                                        onClickSwitch={() => {
                                                            setArea(area)
                                                            setModalToggleStatusArea(true)
                                                        }}
                                                        onClickDelete={() => {
                                                            setArea(area)
                                                            setModalDeleteArea(true)
                                                        }}
                                                        onClickUpdate={() => handlePrepareaUpdate(area)}
                                                        onClickDetail={() => handleDetailView(area, "detail")}
                                                        {...area}
                                                    />
                                                )
                                            })}

                                            {
                                                mutationLiveTrackingArea?.isLoading &&
                                                <>
                                                    <Flex
                                                        direction={'column'}
                                                        gap={'16px'}
                                                    >
                                                        <Skeleton
                                                            height={'252px'}
                                                            width={'100%'}
                                                            borderRadius={'16px'}
                                                        />
                                                        <Skeleton
                                                            height={'252px'}
                                                            width={'100%'}
                                                            borderRadius={'16px'}
                                                        />
                                                        <Skeleton
                                                            height={'252px'}
                                                            width={'100%'}
                                                            borderRadius={'16px'}
                                                        />
                                                    </Flex>
                                                </>
                                            }
                                        </InfiniteScroll>
                                    }   
                                </Flex>
                            {
                                memberSettingAreas?.length === 0 &&
                                !mutationLiveTrackingArea?.isLoading &&
                                mutationLiveTrackingArea?.data &&
                                <Center height='100%' marginTop='-32px'>
                                    <Heading size='chakra_body_medium'>
                                        {t('pengaturan_anggota_pencarian_live_tracking_tidak_ditemukan', { 
                                            keyword: configGetLiveTrackingList?.search 
                                        })}
                                    </Heading>
                                </Center>
                            }
                               
                            </TabPanel>
                            <TabPanel
                            minHeight={'calc(100vh - var(--headerHeight) - 32px - 24px - 33px)'}
                            gap={'24px'}>
                                <Flex justifyContent={'space-between'} gap={'24px'}>
                                    <Flex
                                        direction={'column'}
                                        gap={'8px'}
                                    >
                                        <JTypography size={'title-medium'} mb={2} >{t('pengaturan_anggota_feedback')}</JTypography>
                                        <JTypography size={'body-medium'}>{t('pengaturan_anggota_feedback_desc')}</JTypography>
                                    </Flex>
                                    {feedbackMaster?.isLoading ? (
                                        <Skeleton
                                            width={'48px'}
                                            height={'20px'}
                                        />
                                    ) : (
                                        <Switch
                                            isChecked={feedbackMaster?.data?.metadata?.manipulatable_column?.toogle?.is_master_feedback === "0" ? false : true}
                                            onChange={(e:any) => {
                                                handleChangeStatusFeedback(e?.target?.checked)
                                            }}
                                            height={'min-content'}
                                        />
                                    )}
                                </Flex>
                                <Divider
                                    borderColor={'neutral.30'}
                                    orientation={'horizontal'}
                                    margin={'32px 0px'}
                                />
                                <Flex direction={'column'} flexWrap={'wrap'} opacity={feedbackMaster?.data?.metadata?.manipulatable_column?.toogle?.is_master_feedback === "0" ? 0.5 : 1} cursor={'default'}>
                                    <JTypography size={'body-medium'}>{t('pengaturan_anggota_feedback_judul')}</JTypography>
                                    <Flex mt={'32px'} >
                                        <JTypography marginTop={'-1px'} size={'body-medium'}>
                                            {t('pengaturan_anggota_feedback_rate')}   
                                        </JTypography>
                                        <JTypography size={'body-medium'}>
                                            <Icon 
                                                as={BsStarFill} 
                                                h='16px' 
                                                w='16px' 
                                                color='#eac23f' 
                                                marginTop={'-6px'}
                                                marginRight={'4px'}
                                                marginLeft={'4px'}
                                            />
                                        </JTypography>
                                        <JTypography marginTop={'-1px'} size={'body-medium'}>
                                            1-3
                                        </JTypography>
                                        <Popover trigger='hover' placement='right'>
                                            <PopoverTrigger>
                                                <Button
                                                    backgroundColor='transparent'
                                                    padding='0'
                                                    _hover={{}}
                                                    _focus={{}}
                                                    _active={{}}
                                                    alignItems={'flex-start'}
                                                    cursor={'default'}
                                                >
                                                    <JGoogleIcon marginTop={'-5px'} name='info'/>
                                                </Button>
                                            </PopoverTrigger>
                                            <PopoverContent
                                                backgroundColor='surfacePlus1'
                                                border='none'
                                                width='500px'
                                                padding='16px'
                                                shadow={'dark-lg'}
                                                display={feedbackMaster?.data?.metadata?.manipulatable_column?.toogle?.is_master_feedback === "0" ? 'none' : 'flex'}
                                            >
                                                <PopoverBody padding='0px' textTransform='none'>
                                                    <JTypography size='body-medium' whiteSpace='break-spaces'>
                                                    {t('pengaturan_anggota_feedback_popover_low')}
                                                    </JTypography>
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover> 
                                    </Flex>     
                                    <Flex>
                                    <Flex maxWidth={'750px'} direction={'column'}>
                                        <Grid gridTemplateColumns='repeat(3, 1fr)' gap={'8px'}>
                                            {addChipLow?.map((item:any, index: number)=>
                                                <GridItem>
                                                    <JInput rightIcon={<JGoogleIcon cursor={'pointer'} onClick={()=>handleDeleteChip(item)} color={'outlineVariant'} marginTop={'-2px'} name='cancel'/>} _disabled={{ cursor: 'normal', opacity: 1 }} _hover={{  }} isDisabled borderRadius={'12px'} value={item?.description?.replace(/[^\w\s]/gi, '')} onChange={(e:any)=> handleChangeChipLow(e)} borderColor={'outlineVariant'} width='auto' placeholder={t('pengaturan_anggota_feedback_input')}></JInput>
                                                </GridItem>
                                            )}
                                            {addChipLow?.length < 5 &&  
                                                <GridItem>
                                                    <JInput isDisabled={feedbackMaster?.data?.metadata?.manipulatable_column?.toogle?.is_master_feedback === "0" ? true : false} isInvalid={errorChipLow} borderRadius={'12px'} value={chipLow} onChange={(e:any)=> handleChangeChipLow(e)} onKeyDown={(e:any)=> handleChipLowEnter(e)} borderColor={'outlineVariant'} width='auto' placeholder={t('pengaturan_anggota_feedback_input')}></JInput>
                                                    {errorChipLow &&
                                                        <JTypography mt={2} size='body-medium' color={'error'} whiteSpace='break-spaces'>
                                                            {t('pengaturan_anggota_feedback_error')}
                                                        </JTypography>
                                                    }
                                                </GridItem>
                                            }
                                            {addChipLow?.length < 5 && 
                                                <GridItem>
                                                    <Button  
                                                        backgroundColor='transparent'
                                                        alignItems={'center'}
                                                        border={'1px'}
                                                        borderRadius={'12px'}
                                                        borderColor={'outlineVariant'}
                                                        // marginLeft={'-12px'}
                                                        cursor={feedbackMaster?.data?.metadata?.manipulatable_column?.toogle?.is_master_feedback === "0" ? "not-allowed" : "pointer"}
                                                        onClick={handlePushChipLow}
                                                    > 
                                                        <JGoogleIcon color={'outlineVariant'} name='add'/>
                                                    </Button> 
                                                </GridItem>
                                            }
                                        </Grid>
                                        </Flex>
                                    </Flex>
                                    <Flex mt={'54px'}>
                                        <JTypography marginTop={'-1px'} size={'body-medium'}>
                                            {t('pengaturan_anggota_feedback_rate')}   
                                        </JTypography>
                                        <JTypography size={'body-medium'}>
                                            <Icon 
                                                as={BsStarFill} 
                                                h='16px' 
                                                w='16px' 
                                                color='#eac23f' 
                                                marginTop={'-6px'}
                                                marginRight={'4px'}
                                                marginLeft={'4px'}
                                            />
                                        </JTypography>
                                        <JTypography marginTop={'-1px'} size={'body-medium'}>
                                            4-5
                                        </JTypography>
                                        <Popover trigger='hover' placement='right'>
                                            <PopoverTrigger>
                                                <Button
                                                    backgroundColor='transparent'
                                                    padding='0'
                                                    _hover={{}}
                                                    _focus={{}}
                                                    _active={{}}
                                                    alignItems={'flex-start'}
                                                    cursor={'default'}
                                                >
                                                    <JGoogleIcon marginTop={'-5px'} name='info'/>
                                                </Button>
                                            </PopoverTrigger>
                                            <PopoverContent
                                                backgroundColor='surfacePlus1'
                                                border='none'
                                                width='500px'
                                                padding='16px'
                                                shadow={'dark-lg'}
                                                display={feedbackMaster?.data?.metadata?.manipulatable_column?.toogle?.is_master_feedback === "0" ? 'none' : 'flex'}
                                            >
                                                <PopoverBody padding='0px' textTransform='none'>
                                                    <JTypography size='body-medium' whiteSpace='break-spaces'>
                                                    {t('pengaturan_anggota_feedback_popover_high')}
                                                    </JTypography>
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover> 
                                    </Flex>     
                                    <Flex>
                                        <Flex maxWidth={'750px'} direction={'column'}>
                                        <Grid gridTemplateColumns='repeat(3, 1fr)' gap={'8px'}>
                                            {addChipHigh?.map((item:any, index: number)=>
                                                <GridItem>
                                                    <JInput w={'auto'} rightIcon={<JGoogleIcon cursor={'pointer'} onClick={()=>handleDeleteChip(item)} color={'outlineVariant'} marginTop={'-2px'} name='cancel'/>} _disabled={{ cursor: 'normal', opacity: 1 }} _hover={{  }} isDisabled borderRadius={'12px'} value={item?.description?.replace(/[^\w\s]/gi, '')} onChange={(e:any)=> handleChangeChipLow(e)} borderColor={'outlineVariant'} placeholder={t('pengaturan_anggota_feedback_input')}></JInput>
                                                </GridItem>
                                            )}
                                            
                                            {addChipHigh?.length < 5 && 
                                                <GridItem>
                                                    <JInput isDisabled={feedbackMaster?.data?.metadata?.manipulatable_column?.toogle?.is_master_feedback === "0" ? true : false} w={'auto'} isInvalid={errorChipHigh} borderRadius={'12px'} value={chipHigh} onChange={(e:any)=> handleChangeChipHigh(e)} onKeyDown={(e:any)=> handleChipHighEnter(e)} borderColor={'outlineVariant'} placeholder={t('pengaturan_anggota_feedback_input')}></JInput>
                                                    {errorChipHigh &&
                                                        <JTypography mt={2} size='body-medium' color={'error'} whiteSpace='break-spaces'>
                                                            {t('pengaturan_anggota_feedback_error')}
                                                        </JTypography>
                                                    }
                                                </GridItem>
                                            }
                                            
                                            {addChipHigh?.length < 5 && 
                                                <GridItem>
                                                    <Button  
                                                        backgroundColor='transparent'
                                                        alignItems={'center'}
                                                        border={'1px'}
                                                        borderRadius={'12px'}
                                                        borderColor={'outlineVariant'}
                                                        cursor={feedbackMaster?.data?.metadata?.manipulatable_column?.toogle?.is_master_feedback === "0" ? "not-allowed" : "pointer"}
                                                        onClick={handlePushChipHigh}
                                                    > 
                                                        <JGoogleIcon color={'outlineVariant'} name='add'/>
                                                    </Button> 
                                                </GridItem>
                                            }
                                        </Grid>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </TabPanel> 
                            {/* ini tab panel buat set target yang dihold */}
                            {/* <TabPanel
                                minHeight={'calc(84vh - var(--headerHeight) - 32px - 24px - 33px)'}
                                gap={'24px'}
                            >
                                <Flex justifyContent={'space-between'} gap={'24px'}>
                                    <Flex
                                        direction={'column'}
                                        gap={'8px'}
                                    >
                                        <JTypography size={'title-medium'} mb={'24px'}>{t('pengaturan_anggota_target')}</JTypography>
                                        <JTypography size={'body-medium'} color={'onSurfaceVariant'}>{t('pengaturan_anggota_target_desc')}</JTypography>
                                    </Flex>
                                    {fetchingMemberSettingStatus ? (
                                        <Skeleton
                                            width={'48px'}
                                            height={'20px'}
                                        />
                                    ) : (
                                        <Switch
                                            isChecked={memberSettingStatus}
                                            onClickCapture={() => {
                                                setModalToggleStatus(true)
                                            }}
                                            height={'min-content'}
                                        />
                                    )}
                                </Flex>
                                <Divider
                                    borderColor={'neutral.30'}
                                    orientation={'horizontal'}
                                    margin={'24px 0px'}
                                />
                                <Flex
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                    gap={'24px'}
                                    mb={'32px'}
                                    margin={'24px 0px'}
                                >
                                    <Flex
                                        direction={'column'}
                                        gap={'8px'}
                                    >
                                        <JTypography size={'title-medium'} mb={'24px'}>{t('pengaturan_anggota_target_quantity')}*</JTypography>
                                        <JTypography size={'body-medium'} color={'onSurfaceVariant'}>{t('pengaturan_anggota_target_quantity_desc')}</JTypography>
                                    </Flex>
                                    <Flex
                                        direction={'row'}
                                        justifyContent={'flex-end'}
                                        gap={'8px'}
                                        alignItems={'center'}
                                    >
                                        <JInput 
                                            type='number' 
                                            w={'35%'} 
                                            height={'48px'}
                                            justifyContent={'flex-end'}
                                        />
                                        <JTypography
                                            size={'body-medium'}
                                            color={'onSurfaceVariant'}
                                            w={'30%'}
                                        >
                                            {t('pengaturan_anggota_target_quantity_unit')}
                                        </JTypography>
                                    </Flex>
                                </Flex>
                                <Flex
                                    direction={'column'}
                                    gap={'8px'}
                                    mt={'40px'}
                                >
                                    <JTypography
                                        size={'title-medium'}
                                        mb={'16px'}
                                    >
                                        {t('pengaturan_anggota_target_quantity_unit_apply')}*
                                    </JTypography>
                                    <Flex gap={'12px'}>
                                        <JButton height={'32px'} size='small' variant='j-outline' color={'onSurfaceVariant'} borderColor='outline' backgroundColor={'transparent'}>All</JButton>
                                        <JButton height={'32px'} size='small' variant='j-outline' color={'onSurfaceVariant'} borderColor='outline' backgroundColor={'transparent'}>All</JButton>
                                        <JButton height={'32px'} size='small' variant='j-outline' color={'onSurfaceVariant'} borderColor='outline' backgroundColor={'transparent'}>All</JButton>
                                    </Flex>
                                    <JTypography
                                        size={'title-medium'}
                                        mb={'16px'}
                                        mt={'32px'}
                                    >
                                        Member Group
                                    </JTypography>
                                </Flex>
                                <Flex
                                    justifyContent={'center'}
                                    mt={'72px'}
                                >
                                    <JButton variant='j-primary' size='normal' w={'150px'} h={'48px'}> Save </JButton>
                                </Flex>
                            </TabPanel> */}
                        </TabPanels>
                    </Flex>
                </Tabs>
            </Flex>

            {/* modal toggle status member setting */}
            <Modal
                isOpen={modalToggleStatus}
                onClose={() => setModalToggleStatus(false)}
                isCentered
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent backgroundColor={'surfacePlus1'}>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton
                        isDisabled={fetchingUpdateMemberSettingStatus}
                        onClick={() => setModalToggleStatus(false)}
                    />
                    <ModalBody
                        as={Flex}
                        flexDirection={'column'}
                        gap={'16px'}
                    >
                        <JTypography size={'title-medium'}>
                            {memberSettingStatus
                                ? t('pengaturan_anggota_live_tracking_status_modal_matikan_judul')
                                : t('pengaturan_anggota_live_tracking_status_modal_nyalakan_judul')}
                        </JTypography>
                        <JTypography size={'body-medium'}>{t('pengaturan_anggota_live_tracking_status_modal_subjudul')}</JTypography>
                    </ModalBody>
                    <ModalFooter gap={'33px'}>
                        <JButton
                            size={'normal'}
                            variant={'j-text'}
                            onClick={() => setModalToggleStatus(false)}
                            isDisabled={fetchingUpdateMemberSettingStatus}
                        >
                            {t('pengaturan_anggota_live_tracking_batal')}
                        </JButton>
                        <JButton
                            size={'normal'}
                            variant={'j-primary'}
                            onClick={async () => {
                                await refetchUpdateMemberSettingStatus()
                                await mutationLiveTrackingArea?.mutate({
                                    profile_id: configGetLiveTrackingList.profile_id,
                                    page: 1,
                                    search: '',
                                })
                                await refetchMemberSettingStatus()
                                setModalToggleStatus(false)
                                toast({
                                    position: 'top-right',
                                    render: () => (
                                        <JToast
                                            type={'success'}
                                            message={t(
                                                memberSettingStatus
                                                    ? 'pengaturan_anggota_sukses_matikan_alt'
                                                    : 'pengaturan_anggota_sukses_nyalakan_alt',
                                            )}
                                        />
                                    ),
                                    duration: 3000,
                                    isClosable: true,
                                })
                            }}
                            isLoading={fetchingUpdateMemberSettingStatus}
                        >
                            {memberSettingStatus
                                ? t('pengaturan_anggota_live_tracking_matikan')
                                : t('pengaturan_anggota_live_tracking_nyalakan')}
                        </JButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* modal toggle status area */}
            <Modal
                isOpen={modalToggleStatusArea}
                onClose={() => setModalToggleStatusArea(false)}
                isCentered
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent backgroundColor={'surfacePlus1'}>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton
                        isDisabled={fetchingUpdateMemberSettingStatusArea}
                        onClick={() => setModalToggleStatusArea(false)}
                    />
                    <ModalBody
                        as={Flex}
                        flexDirection={'column'}
                        gap={'16px'}
                    >
                        <JTypography size={'title-medium'}>
                            {area !== null && area.is_active === 1
                                ? t('pengaturan_anggota_live_tracking_area_status_modal_matikan_judul', {label: area?.label})
                                : t('pengaturan_anggota_live_tracking_area_status_modal_nyalakan_judul', {label: area?.label})}
                        </JTypography>
                        <JTypography size={'body-medium'}>
                            {area !== null && area.is_active === 1
                                ? t('pengaturan_anggota_live_tracking_area_status_modal_matikan_subjudul')
                                : t('pengaturan_anggota_live_tracking_area_status_modal_nyalakan_subjudul')}
                        </JTypography>
                    </ModalBody>
                    <ModalFooter gap={'33px'}>
                        <JButton
                            size={'normal'}
                            variant={'j-text'}
                            onClick={() => setModalToggleStatusArea(false)}
                            isDisabled={fetchingUpdateMemberSettingStatusArea}
                        >
                            {t('pengaturan_anggota_live_tracking_batal')}
                        </JButton>
                        <JButton
                            size={'normal'}
                            variant={'j-primary'}
                            onClick={async () => {
                                await refetchUpdateMemberSettingStatusArea()
                                setModalToggleStatusArea(false)
                                toast({
                                    position: 'top-right',
                                    render: () => (
                                        <JToast
                                            type={'success'}
                                            message={`${t(
                                                area !== null && area.is_active === 1
                                                    ? 'pengaturan_anggota_sukses_nonaktifkan_alt'
                                                    : 'pengaturan_anggota_sukses_aktifkan_alt',
                                                {label: area.label},
                                            )}`}
                                        />
                                    ),
                                    duration: 3000,
                                    isClosable: true,
                                })
                                setMemberSettingAreas([])
                                setConfigGetLiveTrackingList( (prev:any)=> {
                                    return{
                                        ...prev,
                                        search: '',
                                    }
                                })
                                setTimeout(() => {
                                    mutationLiveTrackingArea?.mutate({
                                        profile_id: configGetLiveTrackingList.profile_id,
                                        page: 1,
                                        search: '',
                                    })
                                }, 1)
                            }}
                            isLoading={fetchingUpdateMemberSettingStatusArea}
                        >
                            {area !== null && area.is_active === 1
                                ? t('pengaturan_anggota_live_tracking_matikan')
                                : t('pengaturan_anggota_live_tracking_nyalakan')}
                        </JButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* modal delete area */}
            <Modal
                isOpen={modalDeleteArea}
                onClose={() => setModalDeleteArea(false)}
                isCentered
                closeOnOverlayClick={false}
            >
                <ModalOverlay />
                <ModalContent backgroundColor={'surfacePlus1'}>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton
                        isDisabled={fetchingDeleteMemberSettingArea}
                        onClick={() => setModalDeleteArea(false)}
                    />
                    <ModalBody
                        as={Flex}
                        flexDirection={'column'}
                        gap={'16px'}
                    >
                        <JTypography size={'title-medium'}>
                            {t('pengaturan_anggota_live_tracking_hapus_area_judul', {label: area?.label})}
                        </JTypography>
                        <JTypography size={'body-medium'}>{t('pengaturan_anggota_live_tracking_hapus_area_subjudul')}</JTypography>
                    </ModalBody>
                    <ModalFooter gap={'33px'}>
                        <JButton
                            size={'normal'}
                            variant={'j-text'}
                            onClick={() => setModalDeleteArea(false)}
                            isDisabled={fetchingDeleteMemberSettingArea}
                        >
                            {t('pengaturan_anggota_live_tracking_batal')}
                        </JButton>
                        <JButton
                            size={'normal'}
                            variant={'j-primary'}
                            onClick={async () => {
                                await refetchDeleteMemberSettingArea()
                                setModalDeleteArea(false)
                                toast({
                                    position: 'top-right',
                                    render: () => (
                                        <JToast
                                            type={'success'}
                                            message={t('pengaturan_anggota_sukses_hapus_alt')}
                                        />
                                    ),
                                    duration: 3000,
                                    isClosable: true,
                                })
                                setMemberSettingAreas([])
                                setConfigGetLiveTrackingList( (prev:any)=> {
                                    return{
                                        ...prev,
                                        search: '',
                                    }
                                })
                                setTimeout(() => {
                                    mutationLiveTrackingArea?.mutate({
                                        profile_id: configGetLiveTrackingList.profile_id,
                                        page: 1,
                                        search: '',
                                    })
                                }, 1)
                            }}
                            isLoading={fetchingDeleteMemberSettingArea}
                        >
                            {t('pengaturan_anggota_hapus')}
                        </JButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* modal 1/4 tentukan lokasi */}
            <Modal
                isOpen={modalTentukanLokasi}
                onClose={() => null}
                scrollBehavior={'outside'}
                closeOnOverlayClick={false}
                size={'5xl'}
                blockScrollOnMount={true}
                isCentered
            >
                <ModalOverlay />
                <ModalContent 
                    backgroundColor={'surfacePlus1'}
                    // height='96vh'
                    overflow='auto'
                    borderRadius='24px'
                    margin='0px'
                    // width='1000px'
                >
                    <ModalHeader
                        as={Flex}
                        flexDirection={'column'}
                        alignItems={'center'}
                        padding={'24px 24px 0px'}
                        gap='24px'
                    >
                        <Flex width='398px' marginX='auto' marginTop='28px'>
                            <CustomStepper stepLength={4} stepActive={0} />
                        </Flex>
                        <Heading size={'chakra_title_medium'}>{t('pengaturan_anggota_tetapkan_letak_area')}</Heading>
                    </ModalHeader>
                    <ModalCloseButton
                        isDisabled={fetchingDeleteMemberSettingArea}
                        onClick={() => modalCancelLiveTrackingDisclosure?.onOpen()}
                        right='24px'
                        top='24px'
                    />
                    <ModalBody
                        as={Flex}
                        flexDirection={'column'}
                        gap={'24px'}
                        // height='50vh'
                        overflow='auto'
                        padding={'0px 24px'}
                        marginTop='24px'
                    >
                        <Flex gap='48px'>
                            <Flex direction='column' gap='8px' width='100%'>
                                <PlacesAutocomplete
                                    value={initialArea.centerPoint}
                                    onChange={(e: any) => {
                                        setInitialArea((prev: any) => {
                                            return {
                                                ...prev,
                                                centerPoint: e,
                                            }
                                        })
                                    }}
                                    onSelect={async (e: any) => {
                                        const results = await geocodeByAddress(e)
                                        const maxAddressComponents = results.reduce((maxObj: any, currentObj: any) => {
                                            if (
                                                Array.isArray(currentObj?.address_components) && 
                                                currentObj?.address_components?.length > (maxObj?.address_components ? maxObj?.address_components?.length : 0)
                                            ) {
                                                return currentObj;
                                            }
                                            return maxObj;
                                        }, {})
                                        const latLng = await getLatLng(maxAddressComponents)
                                        setInitialArea((prev: any) => {
                                            return {
                                                ...prev,
                                                centerPoint: e,
                                                centerPointLatLng: {
                                                    lat: latLng.lat,
                                                    lng: latLng.lng,
                                                },
                                                centerPointAddress: e,
                                            }
                                        })
                                    }}
                                >
                                    {({
                                        getInputProps, suggestions, getSuggestionItemProps, loading 
                                    }) => (
                                        <div>
                                            <Heading
                                                size={'chakra_title_small'}
                                                marginBottom={'8px'}
                                            >
                                                {t('pengaturan_anggota_titik_pusat')}
                                            </Heading>
                                            <Input
                                                {...headingTheme?.sizes?.chakra_label_large}
                                                {...getInputProps({
                                                    placeholder: t('pengaturan_anggota_cari_lokasi'),
                                                    className: 'location-search-input',
                                                })}
                                            />
                                            <div className='autocomplete-dropdown-container'>
                                                {loading && (
                                                    <Progress
                                                        marginTop={'10px'}
                                                        size='xs'
                                                        isIndeterminate
                                                    />
                                                )}
                                                {suggestions.map((suggestion, index) => {
                                                    return (
                                                        <Flex
                                                            {...getSuggestionItemProps(suggestion)}
                                                            key={index}
                                                            padding={'5px'}
                                                            direction={'column'}
                                                            borderBottomColor={'neutral.30'}
                                                            borderBottomWidth={'1px'}
                                                            cursor={'pointer'}
                                                        >
                                                            <JTypography size={'label-large'}>{suggestion.description}</JTypography>
                                                        </Flex>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                                <Flex height='272px' marginTop='8px'>
                                    <GoogleMap
                                        mapContainerStyle={{
                                            height: '272px',
                                            width: '100%',
                                            borderRadius: '16px',
                                        }}
                                        center={initialArea.centerPointLatLng}
                                        zoom={15}
                                        clickableIcons={false}
                                    >
                                        <MarkerF 
                                            position={initialArea.centerPointLatLng} 
                                            icon={{
                                                url: IC_MS_LOCATION_ON_LIGHT,
                                                rotation: 1,
                                                scaledSize: { width: 35, height: 35, equals: () => true },
                                            }}
                                        />
                                        <Circle
                                            center={initialArea.centerPointLatLng}
                                            radius={initialArea.radius}
                                            options={{
                                                strokeColor: 'transparent',
                                                fillColor: '#0079D2',
                                                fillOpacity: 0.19,
                                                zIndex: 2,
                                            }}
                                        />
                                        {initialArea.radiusTolerance && (
                                            <Circle
                                                center={initialArea.centerPointLatLng}
                                                radius={initialArea.radius + initialArea.radiusTolerance}
                                                options={{
                                                    strokeColor: 'transparent',
                                                    fillColor: '#D27E00',
                                                    fillOpacity: 0.19,
                                                    zIndex: 1,
                                                }}
                                            />
                                        )}
                                    </GoogleMap>
                                </Flex>
                                {initialArea.centerPointAddress !== '' && (
                                    <Flex alignItems='center' gap='4px'>
                                        <JGoogleIcon name='location_on' fontSize='24px' color='primary' />
                                        <Heading size={'chakra_body_medium'}>{initialArea.centerPointAddress}</Heading>
                                    </Flex>
                                )}
                            </Flex>

                            <Divider borderColor={'neutral.30'} orientation='vertical' />
                            
                            <Flex direction='column' gap='8px' width='100%'>
                                <Flex
                                    direction={'column'}
                                    gap={'16px'}
                                >
                                    <Flex gap='1rem'>
                                        <Flex
                                            gap={'8px'}
                                            direction={'column'}
                                        >
                                            <Heading size={'chakra_title_small'}>{t('pengaturan_anggota_radius_lacak')}*</Heading>
                                            <Heading size={'chakra_body_medium'}>{t('pengaturan_anggota_radius_lacak_sublabel')}</Heading>
                                        </Flex>
                                        <JInput
                                            rightIcon={'m'}
                                            wrapperWidth={'110px'}
                                            width={'110px'}
                                            type={'number'}
                                            min={50}
                                            max={50000}
                                            value={initialArea.radius}
                                            onChange={(e: any) => {
                                                setInitialArea((prev: any) => {
                                                    return {
                                                        ...prev,
                                                        radius: parseInt(e.target.value),
                                                    }
                                                })
                                            }}
                                        />
                                    </Flex>
                                    <Slider
                                        aria-label='slider-ex-1'
                                        min={50}
                                        max={50000}
                                        value={initialArea.radius}
                                        onChange={(e: any) => {
                                            setInitialArea((prev: any) => {
                                                return {
                                                    ...prev,
                                                    radius: e,
                                                }
                                            })
                                        }}
                                    >
                                        <SliderTrack>
                                            <SliderFilledTrack />
                                        </SliderTrack>
                                        <SliderThumb />
                                    </Slider>
                                </Flex>
                                <Divider borderColor={'neutral.30'} />
                                <Flex
                                    direction={'column'}
                                    gap={'16px'}
                                >
                                    <Flex>
                                        <Flex
                                            gap={'8px'}
                                            direction={'column'}
                                        >
                                            <Heading size={'chakra_title_small'}>{t('pengaturan_anggota_radius_toleransi')}</Heading>
                                            <Heading size={'chakra_body_medium'}>{t('pengaturan_anggota_radius_toleransi_sublabel')}</Heading>
                                        </Flex>
                                        <Switch
                                            height={'min-content'}
                                            isChecked={initialArea.isRadiusTolranceOn}
                                            onChange={(e: any) => {
                                                setInitialArea((prev: any) => {
                                                    return {
                                                        ...prev,
                                                        isRadiusTolranceOn: e.target.checked,
                                                    }
                                                })
                                            }}
                                        />
                                    </Flex>
                                    <Flex
                                        direction={'column'}
                                        gap={'8px'}
                                        alignItems={'flex-end'}
                                    >
                                        <JInput
                                            rightIcon={'m'}
                                            wrapperWidth={'110px'}
                                            width={'110px'}
                                            type={'number'}
                                            min={0}
                                            max={100}
                                            value={initialArea.radiusTolerance}
                                            isDisabled={!initialArea.isRadiusTolranceOn}
                                            onChange={(e: any) => {
                                                setInitialArea((prev: any) => {
                                                    return {
                                                        ...prev,
                                                        radiusTolerance: parseInt(e.target.value),
                                                    }
                                                })
                                            }}
                                        />
                                        {!initialArea.isRadiusTolranceOn ? (
                                            <Slider
                                                aria-label='slider-ex-1'
                                                min={0}
                                                max={100}
                                                value={initialArea.radiusTolerance}
                                                cursor={'not-allowed'}
                                            >
                                                <SliderTrack>
                                                    <SliderFilledTrack />
                                                </SliderTrack>
                                                <SliderThumb />
                                            </Slider>
                                        ) : (
                                            <Slider
                                                aria-label='slider-ex-1'
                                                min={0}
                                                max={100}
                                                value={initialArea.radiusTolerance}
                                                cursor={'pointer'}
                                                onChange={(e: any) => {
                                                    setInitialArea((prev: any) => {
                                                        return {
                                                            ...prev,
                                                            radiusTolerance: e,
                                                        }
                                                    })
                                                }}
                                            >
                                                <SliderTrack>
                                                    <SliderFilledTrack />
                                                </SliderTrack>
                                                <SliderThumb />
                                            </Slider>
                                        )}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter gap={'16px'} padding='24px'>
                        <Button
                            size={'normal'}
                            variant={'j-primary'}
                            onClick={() => {
                                setModalTentukanLokasi(false)
                                setModalTentukanAnggota(true)
                            }}
                            isDisabled={initialArea.centerPointAddress === ''}
                            width='160px'
                        >
                            {t('pengaturan_anggota_live_tracking_lanjut')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* modal 2/4 tentukan anggota */}
            <Modal
                isOpen={modalTentukanAnggota}
                onClose={() => null}
                scrollBehavior={'outside'}
                closeOnOverlayClick={false}
                size={'4xl'}
                blockScrollOnMount={true}
                isCentered
            >
                <ModalOverlay />
                <ModalContent 
                    backgroundColor={'surfacePlus1'}
                    height='96vh'
                    overflow='auto'
                    borderRadius='24px'
                    margin='0px'
                    width='800px'
                >
                    <ModalHeader
                        as={Flex}
                        flexDirection={'column'}
                        alignItems={'center'}
                        padding={'24px 24px 0px'}
                        gap='24px'
                    >
                        <Flex width='398px' marginX='auto' marginTop='28px'>
                            <CustomStepper stepLength={4} stepActive={1} />
                        </Flex>
                        <Heading size={'chakra_title_medium'}>{t('pengaturan_anggota_tentukan_anggota')}</Heading>
                    </ModalHeader>
                    <ModalCloseButton 
                        onClick={() => modalCancelLiveTrackingDisclosure?.onOpen()} 
                        right='24px'
                        top='24px'
                    />
                    <ModalBody
                        as={Flex}
                        gap={'24px'}
                        padding={'0px 24px'}
                        marginTop='24px'
                        height='50%'
                        marginBottom='12px'
                    >
                        <Flex
                            direction={'column'}
                            gap={'8px'}
                            width={'50%'}
                            position='relative'
                        >
                            <Flex 
                                direction='column' 
                                gap='8px'
                                backgroundColor='inherit'
                            >
                                <Heading size={'chakra_title_small'}>{t('pengaturan_anggota_tambah_anggota')}</Heading>
                                <Heading size={'chakra_body_medium'}>{t('pengaturan_anggota_tambah_anggota_sublabel')}</Heading>
                            </Flex>

                            <Flex marginY='4px'>
                                <Input  
                                    {...headingTheme?.sizes?.chakra_label_large}
                                    placeholder={t('pengaturan_anggota_cari_anggota')}
                                    type={'search'}
                                    value={searchChooseMember}
                                    onChange={(e: any) => setSearchChooseMember(e.target.value)}
                                />
                            </Flex>

                            <Flex
                                direction={'column'}
                                gap={'12px'}
                                // height='calc(53vh - 12px)'
                                overflow='auto'
                            >
                                {initialArea.members.map((member: any, index: number) => {
                                    return (
                                        <Fragment key={index}>
                                            <Checkbox
                                                isChecked={member.checked}
                                                onChange={() => handleSelectGroupMember(member, 'chooseMember')}
                                            >
                                                <Heading size='chakra_label_large' marginLeft='14px'>{member.display_name}</Heading>
                                            </Checkbox>
                                            <Flex
                                                direction={'column'}
                                                gap={'12px'}
                                            >
                                                {member.members.map((user: any, index: number) => {
                                                    const MemberChooseMember = () => (
                                                        <Checkbox
                                                            key={index}
                                                            isChecked={user.checked}
                                                            gap={'16px'}
                                                            onChange={() => {
                                                                handleSelectMember(user, 'chooseMember')
                                                            }}
                                                        >
                                                            <Flex
                                                                gap={'12px'}
                                                                alignItems={'center'}
                                                                marginLeft='-12px'
                                                            >
                                                                <Avatar name={user.display_name} src={user?.photo_url} />
                                                                <Flex
                                                                    direction={'column'}
                                                                    gap={'4px'}
                                                                >
                                                                    <Heading
                                                                        size='chakra_label_large'
                                                                        wordBreak={'break-word'}
                                                                    >
                                                                        {user.display_name}
                                                                    </Heading>
                                                                    <Heading
                                                                        size='chakra_body_medium'
                                                                        wordBreak={'break-word'}
                                                                    >
                                                                        {user.username}
                                                                    </Heading>
                                                                </Flex>
                                                            </Flex>
                                                        </Checkbox>
                                                    )
                                                    if (searchChooseMember === '') return <MemberChooseMember />
                                                    else if (
                                                        searchChooseMember !== '' &&
                                                        user.display_name.toLowerCase().includes(searchChooseMember)
                                                    )
                                                        return <MemberChooseMember />
                                                })}
                                            </Flex>
                                        </Fragment>
                                    )
                                })}
                            </Flex>
                        </Flex>
                        <Flex
                            width={'50%'}
                            direction={'column'}
                        >
                            {RenderSelectedMembers('chooseMember')}
                        </Flex>
                    </ModalBody>
                    <ModalFooter flexDirection='column' gap={'24px'} padding='0px 24px 24px'>
                        <Divider />
                        <Flex gap='16px' justifyContent='flex-end' marginLeft='auto'>
                            <Button
                                size='normal'
                                variant={'j-outline'}
                                onClick={() => {
                                    setModalTentukanAnggota(false)
                                    setModalTentukanLokasi(true)
                                }}
                                width='160px'
                            >
                                {t('pengaturan_anggota_live_tracking_kembali')}
                            </Button>
                            <Button
                                size='normal'
                                variant={'j-primary'}
                                onClick={() => {
                                    setModalTentukanAnggota(false)
                                    setModalTentukanPeringatan(true)
                                }}
                                isDisabled={
                                    initialArea.members.find((group: any) =>
                                        group.members.some((member: any) => member.checked === true),
                                    ) === undefined
                                }
                                width='160px'
                            >
                                {t('pengaturan_anggota_live_tracking_lanjut')}
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* modal 3/4 kirim peringatan */}
            <Modal
                isOpen={modalTentukanPeringatan}
                onClose={() => null}
                scrollBehavior={'outside'}
                closeOnOverlayClick={false}
                size={'4xl'}
                blockScrollOnMount={true}
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor={'surfacePlus1'}
                    height='96vh'
                    overflow='auto'
                    borderRadius='24px'
                    margin='0px'
                    width='800px'
                >
                    <ModalHeader
                        as={Flex}
                        flexDirection={'column'}
                        alignItems={'center'}
                        padding={'24px 24px 0px'}
                        gap='24px'
                    >
                        <Flex width='398px' marginX='auto' marginTop='28px'>
                            <CustomStepper stepLength={4} stepActive={2} />
                        </Flex>
                        <Heading size={'chakra_title_medium'}>{t('pengaturan_anggota_kirim_peringatan')}</Heading>
                    </ModalHeader>
                    <ModalCloseButton 
                        onClick={() => modalCancelLiveTrackingDisclosure?.onOpen()} 
                        right='24px'
                        top='24px'
                    />
                    <ModalBody
                        as={Flex}
                        gap={'24px'}
                        padding={'0px 24px'}
                        marginTop='24px'
                        height='50vh'
                        marginBottom='12px'
                    >
                        <Flex
                            direction={'column'}
                            gap={'8px'}
                            width={'50%'}
                            position='relative'
                        >
                            <Flex 
                                direction='column' 
                                gap='8px'
                                backgroundColor='inherit'
                            >
                                <Heading size={'chakra_title_small'}>{t('pengaturan_anggota_kirim_peringatan')}</Heading>
                                <Heading size={'chakra_body_medium'}>{t('pengaturan_anggota_kirim_peringatan_sublabel')}</Heading>
                            </Flex>

                            <Flex marginY='4px'>
                                <Input  
                                    {...headingTheme?.sizes?.chakra_label_large}
                                    type={'search'}
                                    value={searchSendAlert}
                                    onChange={(e: any) => setSearchSendAlert(e.target.value)}
                                />
                            </Flex>

                            <Flex
                                direction={'column'}
                                gap={'12px'}
                                // height='calc(53vh - 12px)'
                                overflow='auto'
                            >
                                {initialArea.members.map((member: any, index: number) => {
                                    return (
                                        <Fragment key={index}>
                                            <Checkbox
                                                isChecked={member.checked_notify}
                                                cursor={'not-allowed'}
                                            >
                                                <Heading size='chakra_label_large' marginLeft='14px'>{member.display_name}</Heading>
                                            </Checkbox>
                                            <Flex
                                                direction={'column'}
                                                gap={'12px'}
                                            >
                                                {member.members.map((user: any, index: number) => {
                                                    const MemberSendAlert = () => (
                                                        <Checkbox
                                                            key={index}
                                                            isChecked={user.checked_notify}
                                                            gap={'16px'}
                                                            onChange={() => handleSelectMember(user, 'sendAlert')}
                                                            // cursor={
                                                            //     user.checked
                                                            //         ? 'pointer'
                                                            //         : initialArea.notify.find((group: any) =>
                                                            //             group.members.some((member: any) => member.checked === true),
                                                            //         )
                                                            //             ? 'not-allowed'
                                                            //             : 'pointer'
                                                            // }
                                                        >
                                                            <Flex
                                                                gap={'12px'}
                                                                alignItems={'center'}
                                                                marginLeft='-12px'
                                                            >
                                                                <Avatar name={user.display_name} src={user.photo_url} />
                                                                <Flex
                                                                    direction={'column'}
                                                                    gap={'4px'}
                                                                >
                                                                    <Heading
                                                                        size='chakra_label_large'
                                                                        wordBreak={'break-word'}
                                                                    >
                                                                        {user.display_name}
                                                                    </Heading>
                                                                    <Heading
                                                                        size='chakra_body_medium'
                                                                        wordBreak={'break-word'}
                                                                    >
                                                                        {user.username}
                                                                    </Heading>
                                                                </Flex>
                                                            </Flex>
                                                        </Checkbox>
                                                    )
                                                    if (searchSendAlert === '') return <MemberSendAlert />
                                                    else if (
                                                        searchSendAlert !== '' &&
                                                        user.display_name.toLowerCase().includes(searchSendAlert)
                                                    )
                                                        return <MemberSendAlert />
                                                })}
                                            </Flex>
                                        </Fragment>
                                    )
                                })}
                            </Flex>
                        </Flex>
                        <Flex
                            width={'50%'}
                            direction={'column'}
                        >
                            {RenderSelectedMembers('sendAlert')}
                        </Flex>
                    </ModalBody>
                    <ModalFooter flexDirection='column' gap={'24px'} padding='0px 24px 24px'>
                        <Divider />
                        <Flex gap='16px' justifyContent='flex-end' marginLeft='auto'>
                            <Button
                                size='normal'
                                variant={'j-outline'}
                                onClick={() => {
                                    setModalTentukanPeringatan(false)
                                    setModalTentukanAnggota(true)
                                }}
                                width='160px'
                            >
                                {t('pengaturan_anggota_live_tracking_kembali')}
                            </Button>

                            <Button
                                size='normal'
                                variant={'j-primary'}
                                onClick={() => {
                                    setModalTentukanPeringatan(false)
                                    setModalDetailAreaLokasi(true)
                                }}
                                isDisabled={
                                    initialArea.members.find((group: any) =>
                                        group.members.some((member: any) => member.checked_notify === true),
                                    ) === undefined
                                }
                                width='160px'
                            >
                                {t('pengaturan_anggota_live_tracking_lanjut')}
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* modal 4/4 detail area lokasi */}
            <Modal
                isOpen={modalDetailAreaLokasi}
                onClose={() => null}
                scrollBehavior={'outside'}
                closeOnOverlayClick={false}
                size={'5xl'}
                blockScrollOnMount={true}
                isCentered
            >
                <ModalOverlay />
                <ModalContent 
                    backgroundColor={'surfacePlus1'}
                    // height='96vh'
                    overflow='auto'
                    borderRadius='24px'
                    margin='0px'
                    // width='800px'
                >
                    <JGoogleIcon
                        position='absolute'
                        name='arrow_back'
                        left='24px'
                        top='24px'
                        cursor='pointer'
                        onClick={() => {
                            setModalDetailAreaLokasi(false)
                            setModalTentukanPeringatan(true)
                        }}
                    />
                    <ModalHeader
                        as={Flex}
                        flexDirection={'column'}
                        alignItems={'center'}
                        padding={'24px 24px 0px'}
                        gap='24px'
                    >
                        <Flex width='398px' marginX='auto' marginTop='28px'>
                            <CustomStepper stepLength={4} stepActive={4} />
                        </Flex>
                        <Heading size={'chakra_title_medium'}>{t('pengaturan_anggota_berikan_detail_live_tracking')}</Heading>
                    </ModalHeader>
                    <ModalCloseButton
                        isDisabled={fetchingCreateMemberSettingArea}
                        onClick={() => modalCancelLiveTrackingDisclosure?.onOpen()}
                        right='24px'
                        top='24px'
                    />
                    <ModalBody 
                        as={Flex}
                        gap={'24px'}
                        padding={'0px 24px'}
                        marginTop='24px'
                        // height='50vh'
                        marginBottom='12px'
                    >
                        <Flex
                            as={Flex}
                            gap={'48px'}
                            width='100%'
                            // direction='column'
                        >
                            <Flex direction='column' gap='16px' width='100%'>
                                <Heading size='chakra_title_small'>{t('pengaturan_anggota_detail_lokasi')}</Heading>
                                <GoogleMap
                                    mapContainerStyle={{
                                        height: '254px',
                                        width: '428px',
                                        borderRadius: '16px',
                                    }}
                                    center={initialArea.centerPointLatLng}
                                    zoom={15}
                                    clickableIcons={false}
                                >
                                    <MarkerF
                                        position={initialArea.centerPointLatLng}
                                        icon={{
                                            url: IC_MS_LOCATION_ON_LIGHT,
                                            rotation: 1,
                                            scaledSize: { width: 35, height: 35, equals: () => true },
                                        }}
                                    />
                                    <Circle
                                        center={initialArea.centerPointLatLng}
                                        radius={initialArea.radius}
                                        options={{
                                            strokeColor: 'transparent',
                                            fillColor: '#0079D2',
                                            fillOpacity: 0.19,
                                            zIndex: 2,
                                        }}
                                    />
                                    {initialArea.radiusTolerance && (
                                        <Circle
                                            center={initialArea.centerPointLatLng}
                                            radius={initialArea.radius + initialArea.radiusTolerance}
                                            options={{
                                                strokeColor: 'transparent',
                                                fillColor: '#D27E00',
                                                fillOpacity: 0.19,
                                                zIndex: 1,
                                            }}
                                        />
                                    )}
                                </GoogleMap>

                                <Flex direction='column' gap='8px' width='100%'>
                                    <Flex alignItems='center' gap='4px' width='100%'>
                                        <JGoogleIcon name='location_on' color='primary' />
                                        <Heading size='chakra_body_medium'>{initialArea.centerPointAddress}</Heading>
                                    </Flex>
                                    <Flex alignItems='center' gap='4px' width='100%'>
                                        <JGoogleIcon name='settings_accessibility' color='primary' />
                                        <Heading size='chakra_body_medium'>{t('pengaturan_anggota_radius_sebesar', { radius: initialArea.radius })}</Heading>
                                    </Flex>
                                </Flex>

                                {RenderSelectedChooseMember()}
                            </Flex>

                            <Divider orientation='vertical' />
                            
                            <Flex direction='column' gap='16px' width='100%'>
                                <Flex
                                    direction={'column'}
                                    gap={'12px'}
                                    width='100%'
                                >
                                    <Heading size='chakra_title_small'>{t('pengaturan_anggota_label_lokasi')} <Box as='span' color='error'>*</Box></Heading>
                                    <Input
                                        {...headingTheme?.sizes?.chakra_label_large}
                                        placeholder={t('pengaturan_anggota_label_lokasi')}
                                        value={initialArea.label}
                                        onChange={(e: any) =>
                                            setInitialArea((prev: any) => {
                                                return {
                                                    ...prev,
                                                    label: e.target.value,
                                                }
                                            })
                                        }
                                    />
                                </Flex>
                                <Flex
                                    direction={'column'}
                                    gap={'12px'}
                                >
                                    <Heading size='chakra_title_small'>{t('pengaturan_anggota_jam_tracking')} <Box as='span' color='error'>*</Box></Heading>
                                    <Flex gap={'12px'}>
                                        <Flex gap='8px' alignItems='center'>
                                            <Heading size='chakra_label_large' color='primary'>{t('pengaturan_anggota_jam_mulai')}</Heading>
                                            <Input
                                                {...headingTheme?.sizes?.chakra_label_large}
                                                type={'time'}
                                                width='100%'
                                                value={initialArea.startTime}
                                                onChange={(e: any) =>
                                                    setInitialArea((prev: any) => {
                                                        return {
                                                            ...prev,
                                                            startTime: e.target.value,
                                                        }
                                                    })
                                                }
                                            />
                                        </Flex>

                                        <Flex gap='8px' alignItems='center'>
                                            <Heading size='chakra_label_large' color='primary'>{t('pengaturan_anggota_jam_akhir')}</Heading>
                                            <Input
                                                {...headingTheme?.sizes?.chakra_label_large}
                                                type={'time'}
                                                width='100%'
                                                value={initialArea.endTime}
                                                onChange={(e: any) =>
                                                    setInitialArea((prev: any) => {
                                                        return {
                                                            ...prev,
                                                            endTime: e.target.value,
                                                        }
                                                    })
                                                }
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter
                        gap={'16px'}
                        padding='24px'
                    >
                        <Button
                            size='normal'
                            variant={'j-outline'}
                            onClick={() => {
                                setModalDetailAreaLokasi(false)
                                setModalTentukanPeringatan(true)
                            }}
                            width='160px'
                        >
                            {t('pengaturan_anggota_live_tracking_kembali')}
                        </Button>

                        <Button
                            size='normal'
                            variant={'j-primary'}
                            onClick={async () => {
                                if (!isUpdateFlow) {
                                    await refetchCreateMemberSettingArea()
                                } else {
                                    await refetchUpdateMemberSettingArea()
                                }
                                setMemberSettingAreas([])
                                setConfigGetLiveTrackingList( (prev:any)=> {
                                    return{
                                        ...prev,
                                        search: '',
                                    }
                                })
                                setTimeout(() => {
                                    mutationLiveTrackingArea?.mutate({
                                        profile_id: configGetLiveTrackingList.profile_id,
                                        page: 1,
                                        search: '',
                                    })
                                }, 1)

                                // await refetchMemberSettingStatus()
                                await refetchGetMemberSettingMemberList()
                                setModalDetailAreaLokasi(false)
                                if (!isUpdateFlow) {
                                    toast({
                                        position: 'top-right',
                                        render: () => (
                                            <JToast
                                                type={'success'}
                                                message={t('pengaturan_anggota_sukses_tambahkan_alt')}
                                            />
                                        ),
                                        duration: 3000,
                                        isClosable: true,
                                    })
                                }
                            }}
                            isDisabled={
                                initialArea.label === '' || initialArea.startTime === '00:00:00' || initialArea.endTime === '00:00:00'
                            }
                            isLoading={
                                fetchingCreateMemberSettingArea || 
                                fetchingUpdateMemberSettingArea ||
                                isLoadingCreateMemberSettingArea ||
                                mutationLiveTrackingArea?.isLoading ||
                                fetchingMemberSettingStatus ||
                                fetchingMemberSettingMemberList 
                            }
                            width='160px'
                        >
                            {t('pengaturan_anggota_simpan')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal
                isOpen={modalCancelLiveTrackingDisclosure.isOpen}
                onClose={modalCancelLiveTrackingDisclosure?.onClose}
                closeOnOverlayClick={false}
                blockScrollOnMount={true}
                size='md'
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor='surfacePlus1'
                    borderRadius='8px'
                    padding='12px 18px'
                    margin='0px'
                >
                    <ModalHeader padding='0px'></ModalHeader>
                    <ModalCloseButton onClick={modalCancelLiveTrackingDisclosure?.onClose}/>
                    <ModalBody padding='0px' marginTop='28px'>
                        <Heading size='chakra_title_large'>{t('pengaturan_anggota_modal_cancel_live_tracking_title')}</Heading>
                        <Heading size='chakra_body_medium' marginTop='8px'>{t('pengaturan_anggota_modal_cancel_live_tracking_subtitle')}</Heading>
                    </ModalBody>
                    <ModalFooter padding='0px' gap='16px' marginTop='8px'>
                        <Button
                            size='normal'
                            variant={'j-outline'}
                            onClick={modalCancelLiveTrackingDisclosure?.onClose}
                        >
                            {t('pengaturan_anggota_modal_cancel_live_tracking_button_cancel')}
                        </Button>
                        <Button
                            size='normal'
                            variant={'j-primary'}
                            onClick={() => {
                                setModalTentukanLokasi(false)
                                setModalTentukanAnggota(false)
                                setModalTentukanPeringatan(false)
                                setModalDetailAreaLokasi(false)
                                modalCancelLiveTrackingDisclosure?.onClose()
                            }}
                        >
                            {t('pengaturan_anggota_modal_cancel_live_tracking_button_ok')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* modal detail area */}
            {
                areaDetailsModal &&
                <Modal
                    isOpen={areaDetailsModal}
                    onClose={() => setAreaDetailsModal(false)}
                    scrollBehavior={'outside'}
                    closeOnOverlayClick={false}
                    // size={modalDetailSize}
                    size='6xl'
                    blockScrollOnMount={true}
                    isCentered
                >
                    <ModalOverlay/>
                    <ModalContent backgroundColor={'background'} margin='12px' borderRadius='24px'>
                        <ModalHeader
                            as={Flex}
                            flexDirection={'column'}
                            alignItems={'center'}
                            gap={'12px'}
                            padding={"20px 12px"}
                        >
                            <JTypography size={'title-large'}>{t('pengaturan_anggota_detail_live_tracking')}</JTypography>
                        </ModalHeader>
                        <ModalCloseButton
                            isDisabled={fetchingCreateMemberSettingArea}
                            padding={"12px 12px"}
                            onClick={() => {
                                setAreaDetailsModal(false)
                            }}
                        />
                        <ModalBody paddingX={8} paddingY={6}>
                            <Flex gap={'24px'}>
                                <Flex
                                    direction={'column'}
                                    gap={'16px'}
                                    width='100%'
                                >
                                    <JTypography size='title-medium'>{initialArea.label}</JTypography>
                                    <GoogleMap
                                        mapContainerStyle={{
                                            height: '286px',
                                            width: '100%',
                                            borderRadius: '16px',
                                        }}
                                        center={initialArea.centerPointLatLng}
                                        zoom={15}
                                        clickableIcons={false}
                                    >
                                        <MarkerF position={initialArea.centerPointLatLng} />
                                        <Circle
                                            center={initialArea.centerPointLatLng}
                                            radius={initialArea.radius}
                                            options={{
                                                strokeColor: 'transparent',
                                                fillColor: '#0079D2',
                                                fillOpacity: 0.19,
                                                zIndex: 2,
                                            }}
                                        />
                                        {initialArea.radiusTolerance && (
                                            <Circle
                                                center={initialArea.centerPointLatLng}
                                                radius={initialArea.radius + initialArea.radiusTolerance}
                                                options={{
                                                    strokeColor: 'transparent',
                                                    fillColor: '#D27E00',
                                                    fillOpacity: 0.19,
                                                    zIndex: 1,
                                                }}
                                            />
                                        )}
                                    </GoogleMap>
                                    <Flex alignItems='center' gap='8px'>
                                        <JGoogleIcon name='location_on' />
                                        <JTypography width={'85%'} color={'onSurfaceVariant'} size='body-medium'>{initialArea?.centerPointAddress}</JTypography>
                                    </Flex>
                                    <Flex alignItems='center' justifyContent='space-between'>
                                        <Heading size='chakra_body_medium'>{t('detail_pengaturan_anggota_radius_sebesar', {radius: initialArea?.radius})}</Heading>
                                        <Heading size='chakra_body_medium'>{t('detail_pengaturan_anggota_radius_toleransi_sebesar', {radius: initialArea?.radiusTolerance})}</Heading>
                                    </Flex>
                                    <Flex alignItems='center' justifyContent='space-between'>
                                        <Heading size='chakra_body_medium'>{t('pengaturan_anggota_jam_tracking')}</Heading>
                                        <Heading size='chakra_body_medium'>{initialArea?.startTime} - {initialArea?.endTime}</Heading>
                                    </Flex>
                                </Flex>
                                <Flex
                                    direction={'column'}
                                    gap={'16px'}
                                    width={'100%'}
                                >
                                        <Tabs>
                                            <TabList>
                                                <Heading 
                                                    as={Tab} 
                                                    size='chakra_label_large'
                                                    padding='14px 16px'
                                                    color='onSurfaceVariant'
                                                    width='100%'
                                                    _selected={{
                                                        color: 'primary',
                                                        borderBottomWidth: '1px',
                                                        borderBottomColor: 'primary',
                                                    }}
                                                >Anggota Yang Ditentukan</Heading>
                                                <Heading 
                                                    as={Tab} 
                                                    size='chakra_label_large'
                                                    padding='14px 16px'
                                                    color='onSurfaceVariant'
                                                    width='100%'
                                                    _selected={{
                                                        color: 'primary',
                                                        borderBottomWidth: '1px',
                                                        borderBottomColor: 'primary',
                                                    }}
                                                >Notifikasi Peringatan</Heading>
                                            </TabList>

                                            <TabPanels>
                                                <TabPanel padding='0px'>
                                                    <Heading size='chakra_title_small' marginTop='16px'>
                                                        Anggota Yang Di Tentukan ({RenderDetailMembers('chooseMemberDetail').reduce((accumulator: any, currentObj: any) => accumulator + currentObj.members.length, 0)})
                                                    </Heading>

                                                    <Flex 
                                                        marginTop='16px'
                                                        padding='12px' 
                                                        borderWidth='1px' 
                                                        borderColor='primary' 
                                                        borderRadius='12px'
                                                        backgroundColor='surfacePlus3'
                                                        overflow='auto'
                                                        height='331px'
                                                    >
                                                        <Accordion allowMultiple allowToggle width='100%'>
                                                            {RenderDetailMembers('chooseMemberDetail').map((member: any, index: number) => {
                                                                return (
                                                                    <AccordionItem
                                                                        key={index}
                                                                        marginTop={index > 0 ? '12px' : '0px'}
                                                                        borderTop={index > 0 ? 'unset' : 'none'}
                                                                        borderColor='primary'
                                                                    >
                                                                        <h2>
                                                                            <AccordionButton padding='10px 12px'>
                                                                                <Box as='span' flex='1' textAlign='left'>
                                                                                    <Heading size='chakra_label_large'>{member.display_name} ({member.members.filter((check: any) => check.checked === true).length})</Heading>
                                                                                </Box>
                                                                                <AccordionIcon />
                                                                            </AccordionButton>
                                                                        </h2>
                                                                        <Divider borderColor='primary' opacity='1' />
                                                                        <AccordionPanel pb={4}>
                                                                            <Flex direction='column' gap='4px'>
                                                                                {member.members.map((members: any) => (
                                                                                    <>
                                                                                        {members.checked === true &&
                                                                                            <Flex
                                                                                                gap={'24px'}
                                                                                                mt={3}
                                                                                                alignItems={'center'}
                                                                                            >
                                                                                                <Avatar name={members.display_name} src={members?.photo_url} />
                                                                                                <Flex
                                                                                                    direction={'column'}
                                                                                                    gap={'4px'}
                                                                                                >
                                                                                                    <JTypography
                                                                                                        size='title-medium'
                                                                                                        wordBreak={'break-word'}
                                                                                                    >
                                                                                                        {members.display_name}
                                                                                                    </JTypography>
                                                                                                    <JTypography
                                                                                                        size='body-medium'
                                                                                                        wordBreak={'break-word'}
                                                                                                        color={'onSurfaceVariant'}
                                                                                                    >
                                                                                                        {members.username}
                                                                                                    </JTypography>
                                                                                                </Flex>

                                                                                            </Flex>
                                                                                        }
                                                                                    </>
                                                                                ))}
                                                                            </Flex>
                                                                        </AccordionPanel>
                                                                    </AccordionItem>
                                                                )
                                                            })}
                                                        </Accordion>
                                                    </Flex>
                                                </TabPanel>
                                                <TabPanel padding='0px'>
                                                    <Heading size='chakra_title_small' marginTop='16px'>Anggota Yang Di Tentukan ({RenderDetailMembers('sendAlertDetail')?.length})</Heading>
                                                    <Flex 
                                                        marginTop='16px'
                                                        padding='12px' 
                                                        borderWidth='1px' 
                                                        borderColor='primary' 
                                                        borderRadius='12px'
                                                        backgroundColor='surfacePlus3'
                                                        overflow='auto'
                                                        height='331px'
                                                    >
                                                        <Flex marginTop='-34px'>{RenderDetailMembers('sendAlert')}</Flex>
                                                    </Flex>
                                                </TabPanel>
                                            </TabPanels>
                                        </Tabs>
                                </Flex>
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            }

            <ModalUploadTemplate 
                modalUploadTemplateDisclosure={modalUploadTemplateDisclosure} 
                modalResultDisclosure={modalResultDisclosure} 
                setUpload={setUpload}
                setPropsFile={setFile}
                refetchLiveTrackingData={mutationLiveTrackingArea}
                setMemberSettingAreas={setMemberSettingAreas}
            />
            <ModalResult 
                modalResultDisclosure={modalResultDisclosure} 
                modalUploadDiscloseure={modalUploadTemplateDisclosure}
                upload={upload} 
                file={file} 
            />
        </Flex>
    )
}

export default MemberSetting
