import URL_API from '../../apis/urlApi'
import { getMenuPrivilageApi } from '../../services/menuPrivilageService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    loadingMessage: '',
    successMessage: '',
    errorMessage: '',
    data: null,
}

const getMenuPrivilageAction = createAsyncThunk(URL_API.MENU_PRIVILAGE_LIST, async () => {
    return await getMenuPrivilageApi()
})

const slice = createSlice({
    name: 'menu-privilage',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.loadingMessage = ''
            state.successMessage = ''
            state.errorMessage = ''
            state.data = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMenuPrivilageAction.pending, (state, action) => {
                state.isLoading = true
                state.isSuccess = false
                state.isError = false
                state.loadingMessage = ''
                state.successMessage = ''
                state.errorMessage = ''
                state.data = null
            })
            .addCase(getMenuPrivilageAction.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.loadingMessage = ''
                state.successMessage = ''
                state.errorMessage = ''
                state.data = action.payload
            })
            .addCase(getMenuPrivilageAction.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.loadingMessage = ''
                state.successMessage = ''
                state.errorMessage = ''
                state.data = null
            })
    },
})

const { reset } = slice.actions
const reducer = slice.reducer

const menuPrivilageSlice = {
    reducer,
    getMenuPrivilageAction,
    reset,
}

export default menuPrivilageSlice