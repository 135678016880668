import http from './http'
import urlApi from './url'
import fileDownload from 'js-file-download'
import moment from 'moment'
import axios from 'axios'

export interface ApiGetMembersProps {
    page?: number
    show?: number
    search: string
}

export interface ApiDownloadMembersProps {}

export interface ApiGetTaskTypesProps {
    page: number
    show: number
    search: string
}
export interface apiMembersGetProps {
    search: any
}

export interface ApiGetTargetVisitProps {
    task_type_id: string
    member_id: string
}

export interface ApiUpdateTargetVisitProps {
    member_ids: string
    targets: string
}

export interface apiMemberPrivilegeListMemberProps {
    member_id: string
    show?: number
}

export interface apiMemberPrivilegeMemberApplyProps {
    member_id: string
    member: any
    toast: any
}

export async function apiGetMembers(props: ApiGetMembersProps) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            is_active: 1,
            with_column: JSON.stringify(["absence_status", "cash_balance", "target_visit"]),
            ...props
        }
        const response: any = await http(true).post(urlApi.memberList.get.members, payload)
        return {
            data: response.data,
            metadata: response.metadata
        }
    } catch (error) {
        throw 'error get members'
    }
}

export async function apiDownloadMembers(props: ApiDownloadMembersProps) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const endpoint = `${process.env.REACT_APP_BASE_URL}/${urlApi.memberList.export.members}`
        const response: any = await axios({
            url: endpoint,
            method: 'POST',
            data: payload,
            headers: {
                'Content-Type': 'multipart/form-data', 
                'Accept' : 'application/json', 
                'Token' : `${localStorage.getItem('access_token')}`
            },
            responseType: 'blob',
        })
        if (response.headers['content-type'] === "application/json"){
            alert('error download member')
        } else {
            if (response.headers['content-type'] === 'application/zip'){
                fileDownload(response.data, `${moment().format("YYYY-MM-DD")}.zip`)
            } else {
                fileDownload(response.data, `${moment().format("YYYY-MM-DD")}.xlsx`)
            }
        }
        return response.data
    } catch (error) {
        throw 'error export member'
    }
}

export async function apiMembersGet(props: apiMembersGetProps) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.masterTask.get.member, payload)
        return {
            data: response.data,
            metadata: response.metadata,
        }
    } catch (error) {
        throw 'error get member'
    }
}

export async function apiGetTaskTypes(props: ApiGetTaskTypesProps) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            order_by: "name",
            order_type: "ASC",
            is_active: 1,
            ...props
        }
        const response: any = await http(true).post(urlApi.memberList.get.taskTypes, payload)
        return {
            data: response.data,
            metadata: response.metadata
        }
    } catch (error) {
        throw 'error get task types'
    }
}

export async function apiGetTargetVisit(props: ApiGetTargetVisitProps) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.memberList.get.targetVisit, payload)
        return response.data
    } catch (error) {
        throw 'error get target visit'
    }
}

export async function apiUpdateTargetVisit(props: ApiUpdateTargetVisitProps) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.memberList.update.targetVisit, payload)
        return response.data
    } catch (error) {
        throw 'error update target visit'
    }
}

export async function apiMemberPrivilegeListMember(props: apiMemberPrivilegeListMemberProps) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.memberList.privilage.list, payload)
        return response.data
    } catch (error) {
        throw 'error get member privilege'
    }
}

export async function apiMemberPrivilegeMemberApply(props: apiMemberPrivilegeMemberApplyProps) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.memberList.privilage.apply, payload)
        if(response.code == '200'){
            props.toast('200', 'master_anggota_akses_anggota_toast_done')
          } else {
            props.toast(response.code, 'master_anggota_akses_anggota_toast_nodone')
          }
        return response.data
    } catch (error) {
        throw 'error apply member privilege'
    }
}

