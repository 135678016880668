import React from 'react'
import Lottie from 'react-lottie'
import SIDEBAR_NEW_FEATURE_DARK_ID from '../../../assets/animations/JV_SIDEBAR_NEW_FEATURE_DARK_ID.json'
import SIDEBAR_NEW_FEATURE_LIGHT_ID from '../../../assets/animations/JV_SIDEBAR_NEW_FEATURE_LIGHT_ID.json'
import SIDEBAR_NEW_FEATURE_DARK_ENG from '../../../assets/animations/JV_SIDEBAR_NEW_FEATURE_DARK_ENG.json'
import SIDEBAR_NEW_FEATURE_LIGHT_ENG from '../../../assets/animations/JV_SIDEBAR_NEW_FEATURE_LIGHT_ENG.json'
import { Box, useColorMode } from '@chakra-ui/react'

const NewFeatureTag = () => {
    const { colorMode } = useColorMode()

    const locale = document.cookie.replace(/(?:(?:^|.*;\s*)i18next\s*\=\s*([^;]*).*$)|^.*$/, "$1")

    return (
        <Box marginLeft='-16px' marginTop='-4px'>
            <Lottie
                options={{
                    animationData: colorMode === 'dark'
                        ? locale === 'id'
                            ? SIDEBAR_NEW_FEATURE_DARK_ID
                            : SIDEBAR_NEW_FEATURE_DARK_ENG
                        : locale === 'id'
                            ? SIDEBAR_NEW_FEATURE_LIGHT_ID
                            : SIDEBAR_NEW_FEATURE_LIGHT_ENG,
                    autoplay: true,
                    loop: true
                }}
            />
        </Box>
    )
}

export default React.memo(NewFeatureTag)