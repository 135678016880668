import { FaInstagram, FaLinkedinIn } from 'react-icons/fa'

export interface ISOCIAL_MEDIA {
    href: string
    icon: any
    label: string
}

const useLogin = () => {
    const SOCIAL_MEDIA: ISOCIAL_MEDIA[] = [
        {
            href: 'https://www.linkedin.com/company/jarivis',
            label: 'linkedin',
            icon: FaLinkedinIn,
        },
        {
            href: 'https://www.instagram.com/jari.visibility/',
            label: 'instagram',
            icon: FaInstagram,
        },
    ]

    return { SOCIAL_MEDIA }
}

export default useLogin