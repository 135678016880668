import http from "./http";
import urlApi from "./url";
interface IGetMembers {
  search?: string
}

export const lastLocationListApi = async (team: any) => {
  try {
    const bodyPayload = new FormData()
    bodyPayload.set('profile_id', team)

    let response: any = await http(true).post(urlApi.last_location_list, bodyPayload)
    return response.data
  } catch (error) {
    console.error(error);
    return null
  }
}
export async function getMembersApi(props: IGetMembers) {
  try {
      const team = localStorage.getItem('current_team') || ''
      const payload = {
          profile_id: team,
          page: 1,
          show: 1000,
          is_active: 1,
          with_columns: '',
          ...props
      }
      const response: any = await http(true).post(urlApi.memberJourney.post.getMembers, payload)
      console.log({ RESPONSE_GET_MEMBERS: response })
      if (response.code !== '200') throw 'Error get members'
      return response.data
  } catch (error) {
      throw 'Error get members'
  }
}
