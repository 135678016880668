import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

const firebaseConfig = {
    apiKey: 'AIzaSyBUoBC03nYgT4blLrquN_SmrVv1rHp84m8',
    authDomain: 'jarvis-dev-a0eea.firebaseapp.com',
    databaseURL: 'https://jarvis-dev-a0eea.firebaseio.com',
    projectId: 'jarvis-dev-a0eea',
    storageBucket: 'jarvis-dev-a0eea.appspot.com',
    messagingSenderId: '468875982189',
    appId: '1:468875982189:web:dc48b5e848be5cee319664',
    measurementId: 'G-J08H5GMVYY'
}

export const firebaseApp = initializeApp(firebaseConfig)
export const firebaseMessaging = getMessaging(firebaseApp)

export const getTokenFCM = async () => {
    try {
        const currentToken = await getToken(firebaseMessaging, { vapidKey: 'BL-0hauDPYEJ_ikDKxCC3QPtlwiQ079AB-GasBVOE55zDaAmdlzS6VEj3zhwmrcHMl4HnUUXpo-WLoPIvCeY1CI' })
        if (currentToken) {
            return currentToken
        } else {
            console.error('No registration token available. Request permission to generate one.')
        }
    } catch (err) {
        console.error('An error occurred while retrieving token. ', err)
    }
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(firebaseMessaging, (payload) => {
            console.log({payload})
            resolve(payload)
        })
    })