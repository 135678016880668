import {
    Avatar,
    Flex,
    Heading,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Skeleton,
    Text,
    useColorMode,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, JGoogleIcon, PageTitle } from '../../components'
import { getLastLocation, getMemberPrivilages } from '../../redux'
import style from './last-location.module.css'
import { MemberItem } from './LastLocationComponents'
import {
    GoogleMap,
    MarkerClusterer,
    useLoadScript,
    MarkerF,
    InfoWindow,
} from '@react-google-maps/api'
import {
    IC_ATTENDANCE_IN,
    IC_MS_DEVICE_DARK,
    IC_MS_DEVICE_LIGHT,
    IC_MS_LOCATION_ON_DARK,
    IC_MS_LOCATION_ON_LIGHT,
    IC_MS_SHIFTS_ACTIVITY_DARK,
    IC_MS_SHIFTS_ACTIVITY_LIGHT,
    IC_MS_SIGNAL_DARK,
    IC_MS_SIGNAL_LIGHT,
    IC_USER_NOT_FOUND,
} from '../../assets'
import { useTranslation } from 'react-i18next'
import { getMembersApi } from '../../apis/lastLocationApi'
import { useMutation } from 'react-query'
import { format } from 'date-fns'
import { enAU, id } from 'date-fns/locale'
const LastLocation = () => {
    const [search, setSearch] = useState<any>('')
    const dispatch = useDispatch<any>()
    const { colorMode } = useColorMode()
    const { t } = useTranslation()
    // const { memberPrivilages, loadingGetMemberPrivilages } = useSelector((state: any) => state.member)
    const { lastLocation, successGetLastLocation, loadingGetLastLocation } =
        useSelector((state: any) => state.lastLocation)
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyD14zNix-s-MJSfWwLwAWFaOF05vKhEI_4',
    })
    const centerDefault = {
        lat: -6.2213866,
        lng: 106.7796252,
    }
    const zoomDefault = 10
    const [center, setCenter] = useState(centerDefault)
    const [zoom, setZoom] = useState(zoomDefault)
    const [target, setTarget] = useState<any>(null)

    // function handleGetMemberPrivilages(search: string) {
    //     const data = {
    //         page: '',
    //         show: '',
    //         is_active: 1,
    //         search: search,
    //         with_columns: '',
    //     }
    //     dispatch(getMemberPrivilages(data))
    // }

    function handleGetLastLocation() {
        dispatch(getLastLocation())
    }

    function handleCheckDisplayPicture(photo: any) {
        const http = new XMLHttpRequest()
        http.open('HEAD', photo, false)
        http.send()
        if (http.status === 200) {
            return true
        } else {
            return false
        }
    }

    function handleClickMarker(location: any) {
        const tempCenter = {
            lat: location.latitude,
            lng: location.longitude,
        }
        const tempTarget = lastLocation.find(
            (loc: any) => loc.user.id === location.user.id
        )
        setTarget(tempTarget)
        setCenter(tempCenter)
        setZoom(15)
    }

    function handleClickMember(member: any) {
        const tempLocation = lastLocation.find(
            (loc: any) => loc.user.id === member.user.id
        )
        handleClickMarker(tempLocation)
    }
    async function handleGetMembers(props: { search?: string }) {
        const { search } = props
        return await getMembersApi({ search })
    }
    const members = useMutation(handleGetMembers)
    function handleChangeSearch(e: any) {
        setSearch(e.target.value)
        members.mutate({ search: e.target.value })
    }

    function handleGenerateIconBattery() {
        if (target.battery_level === 0) {
            return 'battery_0_bar'
        } else if (target.battery_level <= 20) {
            return 'battery_1_bar'
        } else if (target.battery_level <= 40) {
            return 'battery_2_bar'
        } else if (target.battery_level <= 60) {
            return 'battery_3_bar'
        } else if (target.battery_level <= 80) {
            return 'battery_4_bar'
        } else if (target.battery_level <= 90) {
            return 'battery_5_bar'
        } else if (target.battery_level <= 90) {
            return 'battery_6_bar'
        } else {
            return 'battery_full'
        }
    }

    function handleGenerateCustomIcon(lightIcon: any, darkIcon: any) {
        if (colorMode === 'dark') return darkIcon
        else return lightIcon
    }

    useEffect(() => {
        handleGetLastLocation()
        members.mutate({ search: '' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Flex className={style.last_location}>
            <Flex
                w={'100%'}
                alignItems={'center'}
                justifyContent={'space-between'}
            >
                <PageTitle text={t('lokasi_judul')} />
                <Button
                    type="outline"
                    text={t('lokasi_perbarui_lokasi')}
                    leftIcon="sync"
                    loading={loadingGetLastLocation}
                    loadingText={t('lokasi_mendapatkan_lokasi')}
                    onClick={handleGetLastLocation}
                />
            </Flex>

            <Flex className={style.content_wrapper}>
                <Flex className={style.member_wrapper}>
                    {target === null && (
                        <InputGroup>
                            <InputRightElement
                                children={
                                    <span className="material-symbols-outlined">
                                        search
                                    </span>
                                }
                            />
                            <Input
                                type="search"
                                onChange={(e: any) => {
                                    handleChangeSearch(e)
                                }}
                                value={search}
                                placeholder={t('master_tugas_cari_anggota')}
                            />
                        </InputGroup>
                    )}

                    {target === null && (
                        <Flex className={style.member_list}>
                            {members.isLoading && (
                                <>
                                    <Skeleton
                                        borderRadius="5px"
                                        height="48px"
                                        width="100%"
                                    ></Skeleton>
                                    <Skeleton
                                        borderRadius="5px"
                                        height="48px"
                                        width="100%"
                                    ></Skeleton>
                                    <Skeleton
                                        borderRadius="5px"
                                        height="48px"
                                        width="100%"
                                    ></Skeleton>
                                </>
                            )}
                            {!members?.isLoading &&
                                members?.data !== null &&
                                members?.data?.length !== 0 &&
                                members?.data?.map(
                                    (member: any, index: number) => {
                                        return (
                                            <MemberItem
                                                key={index}
                                                profile_picture={
                                                    member.user.photo_url
                                                }
                                                fullname={
                                                    member.user.display_name
                                                }
                                                email={member.user.username}
                                                onClick={() =>
                                                    handleClickMember(member)
                                                }
                                            />
                                        )
                                    }
                                )}
                        </Flex>
                    )}
                    {target !== null && (
                        <Flex
                            direction="column"
                            gap="18px"
                            overflow="scroll"
                            height="100%"
                        >
                            <span
                                onClick={() => setTarget(null)}
                                className={`material-symbols-outlined ${style.icon} ${style.pointer}`}
                            >
                                arrow_back
                            </span>
                            <Flex gap="10px" alignItems="center">
                                <Avatar
                                    src={target.user.photo_url}
                                    name={target.user.display_name}
                                    width="48px"
                                    height="48px"
                                />
                                <Heading size="chakra_title_large">
                                    {target.user.display_name}
                                </Heading>
                            </Flex>

                            <Flex gap="5px">
                                <Image
                                    width="24px"
                                    height="24px"
                                    src={handleGenerateCustomIcon(
                                        IC_MS_LOCATION_ON_LIGHT,
                                        IC_MS_LOCATION_ON_DARK
                                    )}
                                />
                                <Flex
                                    direction="column"
                                    gap="10px"
                                    paddingTop="4px"
                                >
                                    <Heading
                                        color="onBackground"
                                        size="chakra_title_medium"
                                    >
                                        {t('lokasi_posisi_sekarang')}
                                    </Heading>
                                    <Heading
                                        size="chakra_body_medium"
                                        color="onSurface"
                                    >
                                        {target.address ?? '-'}
                                    </Heading>
                                </Flex>
                            </Flex>

                            <Flex gap="5px">
                                <Image
                                    width="24px"
                                    height="24px"
                                    src={handleGenerateCustomIcon(
                                        IC_MS_SHIFTS_ACTIVITY_LIGHT,
                                        IC_MS_SHIFTS_ACTIVITY_DARK
                                    )}
                                />
                                <Flex
                                    direction="column"
                                    gap="10px"
                                    paddingTop="4px"
                                >
                                    <Heading
                                        color="onBackground"
                                        size="chakra_title_medium"
                                    >
                                        {t('lokasi_aktivitas_terakhir')}
                                    </Heading>
                                    <Heading
                                        size="chakra_body_medium"
                                        color="onSurface"
                                    >
                                        {target.mode ?? '-'}
                                    </Heading>
                                </Flex>
                            </Flex>

                            <Flex gap="5px">
                                <JGoogleIcon color="primary" name="refresh" />
                                <Flex
                                    direction="column"
                                    gap="10px"
                                    paddingTop="4px"
                                >
                                    <Heading
                                        color="onBackground"
                                        size="chakra_title_medium"
                                    >
                                        {t('lokasi_last_update')}
                                    </Heading>
                                    <Heading
                                        size="chakra_body_medium"
                                        color="onSurface"
                                    >
                                        {target.datetime
                                            ? `${format(
                                                  new Date(target?.datetime),
                                                  'dd MMM yyyy HH:mm:ss',
                                                  {
                                                      locale:
                                                          document.cookie.replace(
                                                              /(?:(?:^|.*;\s*)i18next\s*\=\s*([^;]*).*$)|^.*$/,
                                                              '$1'
                                                          ) === 'id'
                                                              ? id
                                                              : enAU,
                                                  }
                                              )} ${' '} (GMT${
                                                  target.datetime_offset
                                              })
                                                `
                                            : '-'}
                                    </Heading>
                                </Flex>
                            </Flex>

                            <Flex gap="5px">
                                <Image
                                    width="24px"
                                    height="24px"
                                    src={handleGenerateCustomIcon(
                                        IC_MS_DEVICE_LIGHT,
                                        IC_MS_DEVICE_DARK
                                    )}
                                />
                                <Flex
                                    direction="column"
                                    gap="10px"
                                    paddingTop="4px"
                                >
                                    <Heading
                                        color="onBackground"
                                        size="chakra_title_medium"
                                    >
                                        {t('lokasi_perangkat')}
                                    </Heading>
                                    <Heading
                                        size="chakra_body_medium"
                                        color="onSurface"
                                    >
                                        {target.device_name ?? '-'}
                                    </Heading>
                                </Flex>
                            </Flex>

                            <Flex>
                                <Flex gap="5px" width="50%">
                                    <JGoogleIcon
                                        color="primary"
                                        name={handleGenerateIconBattery()}
                                    />
                                    <Flex
                                        direction="column"
                                        gap="10px"
                                        paddingTop="4px"
                                    >
                                        <Heading
                                            color="onBackground"
                                            size="chakra_title_medium"
                                        >
                                            {t('lokasi_baterai')}
                                        </Heading>
                                        <Heading
                                            size="chakra_body_medium"
                                            color="onSurface"
                                        >
                                            {target.battery_level
                                                ? `${target.battery_level}%`
                                                : '-'}
                                        </Heading>
                                    </Flex>
                                </Flex>
                                <Flex gap="5px" width="50%">
                                    <Image
                                        width="24px"
                                        height="24px"
                                        src={handleGenerateCustomIcon(
                                            IC_MS_SIGNAL_LIGHT,
                                            IC_MS_SIGNAL_DARK
                                        )}
                                    />
                                    <Flex
                                        direction="column"
                                        gap="10px"
                                        paddingTop="4px"
                                    >
                                        <Heading
                                            color="onBackground"
                                            size="chakra_title_medium"
                                        >
                                            {t('lokasi_sinyal')}
                                        </Heading>
                                        <Heading
                                            size="chakra_body_medium"
                                            color="onSurface"
                                        >
                                            {target.signal_strength ?? '-'}
                                        </Heading>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    )}
                </Flex>
                <Flex className={style.map_wrapper}>
                    {isLoaded ? (
                        <GoogleMap
                            mapContainerStyle={{
                                height: '100%',
                                width: '100%',
                                borderRadius: '16px',
                            }}
                            center={center}
                            zoom={zoom}
                            clickableIcons={false}
                        >
                            {successGetLastLocation && (
                                <MarkerClusterer maxZoom={10}>
                                    {(clusterer) =>
                                        lastLocation?.map(
                                            (location: any, index: number) => {
                                                return (
                                                    <MarkerF
                                                        key={index}
                                                        position={{
                                                            lat: Number(
                                                                location.latitude
                                                            ),
                                                            lng: Number(
                                                                location.longitude
                                                            ),
                                                        }}
                                                        clusterer={clusterer}
                                                        icon={{
                                                            // url: handleCheckDisplayPicture(location.user.photo_url) === true ? location.user.photo_url : IC_USER_NOT_FOUND,
                                                            url: IC_USER_NOT_FOUND,
                                                            rotation: 1,
                                                            scaledSize: {
                                                                width: 35,
                                                                height: 35,
                                                                equals: () =>
                                                                    true,
                                                            },
                                                        }}
                                                        onClick={() =>
                                                            handleClickMarker(
                                                                location
                                                            )
                                                        }
                                                    >
                                                        {target?.user.id ===
                                                            location.user
                                                                .id && (
                                                            <InfoWindow
                                                                position={{
                                                                    lat: Number(
                                                                        location.latitude
                                                                    ),
                                                                    lng: Number(
                                                                        location.longitude
                                                                    ),
                                                                }}
                                                                onCloseClick={() => {
                                                                    if (
                                                                        target
                                                                            .user
                                                                            .id ===
                                                                        location
                                                                            .user
                                                                            .id
                                                                    ) {
                                                                        setTarget(
                                                                            null
                                                                        )
                                                                    }
                                                                }}
                                                            >
                                                                <Flex
                                                                    color="black"
                                                                    p="16px"
                                                                >
                                                                    {
                                                                        location
                                                                            .user
                                                                            .display_name
                                                                    }
                                                                </Flex>
                                                            </InfoWindow>
                                                        )}
                                                    </MarkerF>
                                                )
                                            }
                                        )
                                    }
                                </MarkerClusterer>
                            )}
                        </GoogleMap>
                    ) : (
                        <Skeleton
                            height="100%"
                            width="100%"
                            borderRadius="16px"
                        ></Skeleton>
                    )}
                </Flex>
            </Flex>
        </Flex>
    )
}

export default LastLocation
