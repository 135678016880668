import {Box} from "@chakra-ui/react"


const JCard = (props: any) => {
    return(
        <Box 
            role={props.role || ''}
            borderRadius='16px'
            backgroundColor= {props.bg}
            marginRight= {props.mr}
            padding = '24px 30px'
            marginBottom={props.mb}
            onClick={props.onClick}
            borderColor={props.borderColor}
            borderWidth={props.borderColor === 'none' ? '0px' : '1px'}
            width='224px'
            height='140px'
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
        >
            {props.children}
        </Box>
    )
}

export default JCard