import { Avatar, Checkbox, Divider, Flex, FormControl, FormLabel, Grid, GridItem, InputGroup, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Switch, Tag, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { JButton, JGoogleIcon, JInput, JToast, JTypography } from '../../../components'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { MenuPrivilegeApply, menuList, menuPrivilegeMemberList} from '../../../apis/menuApi'
import { useDebounce } from 'use-debounce'

function ModalAksesMenu(props: any) {
    const {
        disclosure,
        member
    } = props
    const { t } = useTranslation()
    const toast = useToast()
    const [searchbar, setSearchbar] = useState('')
    const [checked, setChecked] = useState([false, false])
    const [search] = useDebounce(searchbar, 500);

    const allChecked = checked.every(Boolean)
    const isIndeterminate = checked.some(Boolean) && !allChecked

    const [configAksesMenu, setConfigAksesMenu] = useState<any>({
        member_id: '',
        menu: [],
        menu_action: []
    })

    const aksesMenu = useQuery("akses-menu", getMenuList)
    const menuMember = useQuery("menu-member", getMenuMember, {
        onSuccess(data) {
            const menu_action = data
                .filter((obj: any) => obj.menu_action.length !== 0)
                .flatMap((obj: any) => obj.menu_action.map((action: any) => ({ id: action.id })));
            setConfigAksesMenu((prev: any) => {
                return {
                    ...prev,
                    menu: data.map((obj: any) => ({ id: obj.id })),
                    menu_action: menu_action
                }
            })
        },
    })
    const saveMenu = useMutation(MenuPrivilegeApply)

    async function getMenuList() {
        return await menuList()
    }
    async function getMenuMember() {
        return await menuPrivilegeMemberList({ member_id: member?.id })
    }

    function handleSwitch(e: any) {
        const newConfigAksesMenu = { ...configAksesMenu }
        if (configAksesMenu[e.target.name].some((list: any) => list.id === parseInt(e.target.id))) {
            newConfigAksesMenu[e.target.name] = newConfigAksesMenu[e.target.name].filter((list: any) => list.id !== parseInt(e.target.id))

        } else {
            newConfigAksesMenu[e.target.name] = [...newConfigAksesMenu[e.target.name], { id: parseInt(e.target.id) }]
        }
        setConfigAksesMenu(newConfigAksesMenu)
    }

    function handleCheckedMenu(to: string, active: boolean) {
        if (to === 'all') {
            if (active) {
                setConfigAksesMenu((prev: any) => {
                    const menu = aksesMenu.data.map((obj: any) => ({ id: obj.id }))
                    const menu_action = aksesMenu.data.filter((obj: any) => obj.menu_action.length !== 0).flatMap((obj: any) => obj.menu_action.map((action: any) => ({ id: action.id })));
                    return {
                        ...prev,
                        menu: menu,
                        menu_action: menu_action
                    }
                })
                setChecked([active, active])
            } else {
                setChecked([false, false])
                setConfigAksesMenu({
                    memeber_id: configAksesMenu.member_id,
                    menu: [],
                    menu_action: []
                }
                )
            }
        }
        if (to === 'mobile') {
            if (active) {
                const menu = aksesMenu.data?.filter((data: any) => data.id < 1000).map((obj: any) => ({ id: obj.id }))
                const menu_action = aksesMenu.data.filter((obj: any) => obj.id < 1000 && obj.menu_action.length !== 0).flatMap((obj: any) => obj.menu_action.map((action: any) => ({ id: action.id })));
                setConfigAksesMenu((prev: any) => {
                    return {
                        ...prev,
                        menu: [...menu, ...prev.menu.filter((obj: any) => obj.id > 1000)],
                        menu_action: [...menu_action, ...prev.menu_action.filter((obj: any) => obj.id > 1000)]
                    }
                })
                setChecked([active, checked[1]])
            } else {
                setChecked([active, checked[1]])
                setConfigAksesMenu((prev: any) => {
                    return {
                        ...prev,
                        menu: [...prev.menu.filter((obj: any) => obj.id > 1000)],
                        menu_action: [...prev.menu_action.filter((obj: any) => obj.id > 1000)]
                    }
                })
            }
        }
        if (to === 'cms') {
            if (active) {
                const menu = aksesMenu.data?.filter((data: any) => data.id > 1000).map((obj: any) => ({ id: obj.id }))
                const menu_action = aksesMenu.data.filter((obj: any) => obj.id > 1000 && obj.menu_action.length !== 0).flatMap((obj: any) => obj.menu_action.map((action: any) => ({ id: action.id })));
                setConfigAksesMenu((prev: any) => {
                    return {
                        ...prev,
                        menu: [...menu, ...prev.menu.filter((obj: any) => obj.id < 1000)],
                        menu_action: [...menu_action, ...prev.menu_action.filter((obj: any) => obj.id < 1000)]
                    }
                })
                setChecked([checked[0], active])
            } else {
                setChecked([checked[0], active])
                setConfigAksesMenu((prev: any) => {
                    return {
                        ...prev,
                        menu: [...prev.menu.filter((obj: any) => obj.id < 1000)],
                        menu_action: [...prev.menu_action.filter((obj: any) => obj.id < 1000)]
                    }
                })
            }
        }
    }

    function handleSave() {
        saveMenu.mutate({
            member_id: member?.id,
            menu: JSON.stringify({ ...configAksesMenu.menu }),
            menu_action: JSON.stringify({ ...configAksesMenu.menu_action }),
            toast: toaster
        })
        setChecked([false, false])
        disclosure.onClose()
        window.location.reload()
    }

    function handleClose() {
        setConfigAksesMenu({
            member_id: "",
            menu: [],
            menu_action: []
        })
        setChecked([false, false])
        setSearchbar('')
    }

    function toaster(code: string, msg: string) {
        toast({
            position: 'top-right',
            render: () => (
                <JToast
                    type={code === "200" ? 'success' : 'error'}
                    message={t(msg)}
                />
            ),
            duration: 1500,
            isClosable: true,
        })
    }

    useEffect(() => {
        menuMember.refetch()
    }, [member])

    return (
        <Modal isOpen={menuMember.isFetched && disclosure.isOpen} onClose={() => { disclosure.onClose() }} size='3xl' onCloseComplete={handleClose}>
            <ModalOverlay />
            <ModalContent
                borderRadius={'16px'}
                backgroundColor={"background"}
                padding={"24px"}
            >
                <ModalHeader
                    mt={'10px'}
                    mb={2}
                >
                    <JTypography size="title-large">{`${t('master_anggota_akses_edit')} ${t('master_anggota_akses_menu')}`}</JTypography>
                </ModalHeader>
                <ModalCloseButton top={"16px"} right={"24px"} />
                <ModalBody>
                    <Flex align='center' gap='12px'>
                        <JTypography size='body-small'>{t("master_anggota_akses_menu_for")}</JTypography>
                        <Flex align='center' gap='6px'>
                            <Avatar
                                height={'34px'}
                                width={'34px'}
                                name={member?.name}
                                src={member?.photo}
                            />
                            <JTypography size='label-large'>{member?.name}</JTypography>
                        </Flex>
                    </Flex>
                    <Flex w='100%' align='center' justify='space-between' mt={'24px'}>
                        <InputGroup width="max-content">
                            <JInput
                                type="search"
                                leftIcon={<JGoogleIcon name='search' />}
                                width="max-content"
                                placeholder={t("master_anggota_akses_menu_search")}
                                onChange={(e: any) => setSearchbar(e.target.value)}
                            />
                        </InputGroup>
                        <Flex gap='16px' align='center'>
                            <JTypography size='body-large'>{t('master_anggota_akses_menu_pilih_semua')}</JTypography>
                            <Checkbox isChecked={allChecked} isIndeterminate={isIndeterminate} onChange={(e: any) => handleCheckedMenu('all', e.target.checked)}></Checkbox>
                        </Flex>
                    </Flex>
                    <Grid gridTemplateColumns={'45% 10% 45%'} mt='32px' w='100%' h='360px' overflow='hidden' overflowY='scroll'>
                        <GridItem >
                            <Flex gap='6px' >
                                <Tag borderRadius='full' bg='primaryContainer' color='onPrimaryContainer'>
                                    <JTypography size='label-large'>{t('master_anggota_akses_menu_mobile')}</JTypography>
                                </Tag>
                                <Checkbox isChecked={checked[0]} onChange={(e: any) => handleCheckedMenu('mobile', e.target.checked)}></Checkbox>
                            </Flex>
                            <Divider w='100%' size='2px' my='12px' />
                            {aksesMenu.data?.filter((data: any) => data.id < 1000 && data.caption.toLowerCase().includes(search.toLowerCase())).map((akses: any, i: number) => {
                                return (
                                    <FormControl>
                                        <Flex justify='space-between' mb='32px' align='center'>
                                            <JTypography size='label-large'>{akses.caption}</JTypography>
                                            <Switch name='menu' id={akses.id} isChecked={configAksesMenu?.menu.find((data: any) => data.id == akses.id) } onClickCapture={(e: any) => handleSwitch(e)} />
                                        </Flex>
                                        {akses.menu_action?.map((sub: any, i: any) => {
                                            return (
                                                <Flex m='32px 0 32px 16px' justify='space-between' align='center'>
                                                    <JTypography size='label-large'>{sub.caption}</JTypography>
                                                    <Switch name='menu_action' id={sub.id}
                                                        isDisabled={!configAksesMenu?.menu.find((data: any) => data.id == akses.id)}
                                                        isChecked={configAksesMenu?.menu.find((data: any) => data.id == akses.id) && configAksesMenu?.menu_action.find((data: any) => data.id === sub.id)}
                                                        onClickCapture={(e: any) => handleSwitch(e)} />
                                                </Flex>
                                            )
                                        })}
                                    </FormControl>
                                )
                            })}
                        </GridItem>
                        <GridItem></GridItem>
                        <GridItem>
                            <Flex gap='6px'>
                                <Tag borderRadius='full' bg='primaryContainer' color='onPrimaryContainer'>
                                    <JTypography size='label-large'>{t('master_anggota_akses_menu_cms')}</JTypography>
                                </Tag>
                                <Checkbox isChecked={checked[1]} onChange={(e: any) => handleCheckedMenu('cms', e.target.checked)}></Checkbox>
                            </Flex>
                            <Divider w='100%' size='2px' my='12px' />
                            {aksesMenu.data?.filter((data: any) => data.id > 1000 && data.caption.toLowerCase().includes(search.toLowerCase())).map((akses: any, i: number) => {
                                return (
                                    <FormControl>
                                        <Flex justify='space-between' mb='32px' align='center'>
                                            <JTypography size='label-large'>{akses.caption}</JTypography>
                                            <Switch name='menu' id={akses.id} isChecked={configAksesMenu?.menu.find((data: any) => data.id == akses.id)} onClickCapture={(e: any) => handleSwitch(e)} />
                                        </Flex>
                                        {akses.menu_action?.map((sub: any, i: any) => {
                                            return (
                                                <Flex m='32px 0 32px 16px' justify='space-between' align='center'>
                                                    <JTypography size='label-large'>{sub.caption}</JTypography>
                                                    <Switch name='menu_action' id={sub.id}
                                                        isDisabled={!configAksesMenu?.menu.find((data: any) => data.id == akses.id)}
                                                        isChecked={configAksesMenu?.menu.find((data: any) => data.id == akses.id) && configAksesMenu?.menu_action.find((data: any) => data.id === sub.id)}
                                                        onClickCapture={(e: any) => handleSwitch(e)} />
                                                </Flex>
                                            )
                                        })}
                                    </FormControl>
                                )
                            })}
                        </GridItem>
                    </Grid>
                </ModalBody>
                <ModalFooter
                    gap={"12px"}
                    padding={"0px"}
                    justifyContent={"center"}
                >
                    <Flex direction={'row'}>
                        <JButton
                            size="normal"
                            variant="j-primary"
                            type='submit'
                            my={2} mx={4} px={84}
                            onClick={handleSave}
                        >
                            <JTypography size='label-large'>{t("master_anggota_akses_simpan")}</JTypography>
                        </JButton>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ModalAksesMenu