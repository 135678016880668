import React, { useEffect } from 'react'
import { getTeams } from '../../redux'
import { useNavigate } from 'react-router-dom'
import { urlRouter } from '../../router'
import { useTranslation } from 'react-i18next'
import { JButton, JGoogleIcon, JToast, PageTitle } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { TeamCard, TeamSkeleton } from './TeamComponent'
import {
    Box,
    Button,
    Center,
    Container,
    Flex,
    Grid,
    Heading,
    Img,
    useColorMode,
    useDisclosure,
    useMediaQuery,
    useToast,
} from '@chakra-ui/react'
import { useMutation } from 'react-query'
import { getInvitationApi } from '../../apis/invitationApi'
import ModalInviteList from './Modals/ModalInviteList'
import empty from '../../assets/images/empty-2.png'

const Team: React.FC = () => {
    const dispatch = useDispatch<any>()
    const navigate = useNavigate()
    const toast: any = useToast()
    const { colorMode } = useColorMode()
    const { t } = useTranslation()
    const modalInviteList = useDisclosure()
    const { isLoading, list } = useSelector((state: any) => state.team)

    const [isLargerThan1440] = useMediaQuery('(min-width: 1440px)')
    const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)')
    const [isLargerThan800] = useMediaQuery('(min-width: 800px)')
    const [isLargerThan560] = useMediaQuery('(min-width: 560px)')
    const numSkeletons = isLargerThan1440 ? 10 : isLargerThan1280 ? 8 : isLargerThan800 ? 6 : isLargerThan560 ? 4 : 2;

    const handleSelectTeam = (team: any) => {
        const teamData = {
            id: team.id,
            logo: team.profile_globalconfig,
            name: team.profile_globalconfig.display_name,
        }
        localStorage.setItem('current_team', JSON.stringify(team.id))
        localStorage.setItem('current_team_data', JSON.stringify(teamData))
        localStorage.setItem('team_config', JSON.stringify(team))
        navigate(urlRouter.home, { replace: true })
    }

    const { data: invitations, mutate: inviteMutate } = useMutation(getInvitationApi, {
        onError: (error: any) => {
            toast({
                position: 'top-right',
                render: () => (
                    <JToast type='error' message={error} />
                ),
            })
        },
        onSuccess: (data: any) => {
            if (data.length === 0) return modalInviteList.onClose()
        }
    })

    useEffect(() => {
        dispatch(getTeams())
        inviteMutate()
    }, [])

    useEffect(() => {
        if (!modalInviteList.isOpen) {
            dispatch(getTeams())
        }
    }, [modalInviteList.isOpen, dispatch])

    return (
        <Flex flexDir='column' w='100%'>
            <Flex justify='space-between' h='40px' m='8px 0 24px' align='center'>
                <PageTitle text={t('tim_pilih_tim')} />
                {invitations?.length > 0 ?
                    <Button position='relative' p='12px 8px' leftIcon={<JGoogleIcon name='notifications' color={colorMode === 'dark' ? 'primary' : 'onPrimary'} />} bg={colorMode === 'dark' ? 'surface' : 'primary'} border='1px solid var(--colorPrimary)' onClick={modalInviteList.onOpen} _hover={{}} _active={{}}>
                        <Heading size='chakra_label_large' color={colorMode === 'dark' ? 'primary' : 'onPrimary'}>
                            {t('tim_pilih_invite', { invite: invitations?.length })}
                        </Heading>
                        <Center
                            position="absolute"
                            bottom="5px"
                            left="5px"
                            w="6px"
                            h="6px"
                            bg="error"
                            rounded='full'
                            _before={{
                                content: '""',
                                position: 'absolute',
                                width: '6px',
                                height: '6px',
                                backgroundColor: 'var(--colorError)',
                                borderRadius: '50%',
                                animation: `1s 1s infinite`,
                            }}
                        />
                    </Button> : null}
            </Flex>
            <Flex wrap='wrap' w='100%' rowGap='32px' columnGap='32px' justify='flex-start'>
                {isLoading ?
                    Array.from({ length: numSkeletons }).map((_, index) => (
                        <TeamSkeleton key={index} />
                    ))
                    :
                    list?.length > 0 ?
                        list?.map((team: any, index: number) => (
                            <TeamCard
                                key={index}
                                _key={index}
                                onSelect={() => handleSelectTeam(team)}
                                text={team.profile_globalconfig.display_name}
                                img={team.profile_globalconfig.logo}
                            />
                        ))
                        : <Center w='100%' my='20vh' flexDir='column' gap='12px'>
                            <Img src={empty}/>
                            <Heading size='chakra_title_medium'>{t('tim_pilih_tim_empty')}</Heading>
                        </Center>
                }
            </Flex>
            <ModalInviteList lists={invitations} inviteMutate={inviteMutate} disclosure={modalInviteList} />
        </Flex>
    )
}

export default Team
