import React from 'react'
import { useTranslation } from 'react-i18next'
import { 
    Flex, 
    Image, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalOverlay, 
    Text, 
} from '@chakra-ui/react'
import { 
    IC_ATTENDANCE_IN, 
    IC_ATTENDANCE_OUT, 
    IC_AUTO_UPDATE, 
    IC_CHECK_IN, 
    IC_CHECK_OUT, 
    IC_DRAFT, 
    IC_OUT_ZONE, 
    IC_RESULT, 
    IL_ATTENDANCE_ZONE, 
    IL_CHECK_IN, 
    IL_LIVE_TRACKING, 
} from '../../../assets'
import { JTypography } from '../../../components'

interface IJourneyLegend {
    isOpen: boolean
    onClose: any
}

const JourneyLegend = (props: IJourneyLegend) => {
    const { t } = useTranslation()
    const { isOpen, onClose } = props

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
            <ModalOverlay />
            <ModalContent bgColor="var(--colorSurface)" mx="10px">
                <ModalCloseButton />
                <ModalBody p="16px" width='584px'>
                    <Text fontSize='16px' mb="24px">{t("perjalanan_legenda")}</Text>
                    <Flex gap="24px">
                        <Flex direction="column" gap="16px">
                            <Flex direction="column" gap="8px">
                                <Text fontSize='14px' fontWeight='500'>{t("modal_legenda_zona_live_tracking")}</Text>
                                <Image height='64px' src={IL_LIVE_TRACKING} />
                            </Flex>
                            <Flex direction="column" gap="8px">
                                <Text fontSize='14px' fontWeight='500'>{t("modal_legenda_zona_check_in")}</Text>
                                <Image height='64px' src={IL_CHECK_IN} />
                            </Flex>
                            <Flex direction="column" gap="8px">
                                <Text fontSize='14px' fontWeight='500'>{t("modal_legenda_zona_attendance")}</Text>
                                <Image height='64px' src={IL_ATTENDANCE_ZONE} />
                            </Flex>
                        </Flex>
                        <Flex direction="column" gap="16px">
                            <Flex gap="16px" alignItems="center">
                                <Image width='24px' height='24px' src={IC_ATTENDANCE_IN} />
                                <JTypography size='label-small'>
                                    {t("modal_legenda_absen_masuk")}
                                </JTypography>
                            </Flex>
                            <Flex gap="16px" alignItems="center">
                                <Image width='24px' height='24px' src={IC_ATTENDANCE_OUT} />
                                <JTypography size='label-small'>
                                    {t("modal_legenda_absen_keluar")}
                                </JTypography>
                            </Flex>
                            <Flex gap="16px" alignItems="center">
                                <Image width='24px' height='24px' src={IC_AUTO_UPDATE} />
                                <JTypography size='label-small'>
                                    {t("modal_legenda_auto_update")}
                                </JTypography>
                            </Flex>
                            <Flex gap="16px" alignItems="center">
                                <Image width='24px' height='24px' src={IC_CHECK_IN} />
                                <JTypography size='label-small'>
                                    {t("modal_legenda_check_in")}
                                </JTypography>
                            </Flex>
                            <Flex gap="16px" alignItems="center">
                                <Image width='24px' height='24px' src={IC_DRAFT} />
                                <JTypography size='label-small'>
                                    {t("modal_legenda_draf")}
                                </JTypography>
                            </Flex>
                            <Flex gap="16px" alignItems="center">
                                <Image width='24px' height='24px' src={IC_CHECK_OUT} />
                                <JTypography size='label-small'>
                                    {t("modal_legenda_check_out")}
                                </JTypography>
                            </Flex>
                            <Flex gap="16px" alignItems="center">
                                <Image width='24px' height='24px' src={IC_RESULT} />
                                <JTypography size='label-small'>
                                    {t("modal_legenda_hasil")}
                                </JTypography>
                            </Flex>
                            <Flex gap="16px" alignItems="center">
                                <Image width='24px' height='24px' src={IC_OUT_ZONE} />
                                <JTypography size='label-small'>
                                    {t("modal_legenda_keluar_dari_zona_tracking")}
                                </JTypography>
                            </Flex>
                        </Flex>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default JourneyLegend