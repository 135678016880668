import http from './http'
import urlApi from './url'

export interface UpdateNipProps {
    member_id: string
    nip: string
    toaster: any
}

export async function apiUpdateNip(props: UpdateNipProps) {
    try {
        const team = localStorage.getItem('current_team') || ''
        const payload = {
            profile_id: team,
            ...props
        }
        const response: any = await http(true).post(urlApi.nip.update, payload)
        if(response.code === '200'){
            props.toaster('200', response.message)
        } else{
            props.toaster('0', response.message)
        }
        return response;
    } catch (error) {
        throw 'error edit nip'
    }
}
