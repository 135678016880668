import React, { memo, useState } from 'react'
import { JButton, JTypography, JInput, JGoogleIcon, JToast } from '../../../components'
import { 
    Flex, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalHeader, 
    ModalOverlay, 
    FormControl,
    FormErrorMessage,
    InputGroup,
    useToast
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { apiUpdateNip } from '../../../apis/memberNipApi'
interface IModalEditNip {
    disclosure: any
    data: any
    refetch: any
    setData: any
}

const ModalEditNip: React.FC<IModalEditNip> = (props: IModalEditNip) => {
    const { 
        disclosure,
        data,
        refetch,
        setData
    } = props
    const { t } = useTranslation();
    const toast = useToast();
    const [errorEdit, setErrorEdit] = useState(false)
    const updateMemberNipMutation = useMutation(apiUpdateNip, {
        onSuccess: () => {
            disclosure.onClose();
            refetch();
        }
    });
    function handleChangeNIP(e:any){
        setData((prev:any)=>({...prev, nip: e.target.value}));
    }
    function toaster (code:string, msg:string){
        toast({
          position: 'top-right',
          render: () => (
              <JToast
                  type={code === "200"? 'success' : 'error'}
                  message={code !== "200" ? msg : t('master_anggota_NIP_edit_simpan_sukses')}
              />
          ),
          duration: 1500,
          isClosable: true,
        })
    }
    return (
        <Modal
            isOpen={disclosure.isOpen}
            onClose={()=>{
                disclosure.onClose()
            }}
            size={"lg"}
        >
            <ModalOverlay />
            <ModalContent
                borderRadius={'16px'}
                backgroundColor={"background"}
                padding={"24px"}
            >
                <ModalHeader
                    padding={"0px"}
                    mt={'10px'}
                    mb={2}
                >
                    <JTypography size="title-medium">{`${t('master_anggota_NIP_edit')} (${data?.display_name})`}</JTypography>
                </ModalHeader>
                <ModalCloseButton top={"24px"} right={"24px"} />
                <ModalBody
                    as={Flex}
                    flexDirection={"column"}
                    gap={"16px"}
                    padding={"0px"}
                    marginTop={"24px"}
                >
                    <FormControl isInvalid={errorEdit}>
                        <InputGroup width="100%">
                            <JInput
                            leftIcon={<JGoogleIcon name='edit' />}
                            type="text"
                            width="100%"
                            value={data?.nip}
                            placeholder={t("master_anggota_NIP_edit_placeholder")}
                            onChange={(e:any)=> handleChangeNIP(e)}
                            />
                        </InputGroup>
                        {errorEdit && 
                            <FormErrorMessage>{t('master_anggota_input_NIP_error')}</FormErrorMessage>
                        }
                    </FormControl>
                    <Flex alignSelf={'center'} alignItems='center' mt={'20px'}>
                        <JButton
                            variant="j-primary"
                            size="normal"
                            onClick={()=>{
                                if(data?.nip?.length > 30 || (!/[^a-zA-Z0-9]/.test(data?.nip) === false && data?.nip?.length > 0)){
                                    setErrorEdit(true)
                                } else {
                                    updateMemberNipMutation.mutate({member_id: data?.id, nip: data?.nip === null ? "" : data?.nip, toaster: toaster});
                                    disclosure.onClose();
                                };
                            }}
                            width={'150px'}
                        >
                            {t('master_anggota_NIP_edit_simpan')}
                        </JButton>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default memo(ModalEditNip)