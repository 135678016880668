import React, { useEffect, useState } from 'react'
import Rated from './components/Rated'
import TextareaAutosize from 'react-textarea-autosize'
import { lOGO_JARIVIS } from '../../assets'
import { useSearchParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { feedbackFormDataApi, feedbackSubmitFormApi, feedbackMasterApi } from '../../apis/feedbackApi'
import { BsStar, BsStarFill } from 'react-icons/bs'
import { 
    Button, 
    Flex, 
    Heading, 
    Icon, 
    Image, 
    Textarea, 
    Tag,
    Divider
} from '@chakra-ui/react'
import moment from 'moment'
import { JTypography } from '../../components'
import { add, format } from 'date-fns'
const FeedbackForm: React.FC = () => {
    const [searchParams] = useSearchParams()
    const [addChipHigh, setAddChipHigh] = useState<any>([])
    const [addChipLow, setAddChipLow] = useState<any>([])
    const [isRated, setIsRated] = useState<boolean>(false)
    const [chips, setChips] = useState<any>([])
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
    const [rate, setRate] = useState<any>(null)
    const [feedback, setFeedback] = useState('')
    console.log(rate)
    
    const formData = useMutation(feedbackFormDataApi, { 
        onSuccess: (resp: any) => {
            setAddChipHigh(resp.chip_options.filter((p:any)=> p?.rating === 'good' && p?.is_active === "1"))
            setAddChipLow(resp.chip_options.filter((p:any)=> p?.rating === 'bad' && p?.is_active === "1"))
        },
        onError: (error: any) => console.log({error})
    })

    const submitForm = useMutation(feedbackSubmitFormApi, {
        onSuccess: () => {
            setIsSubmitted(true)
        },
        onError: (error: any) => console.log({error})
    })

    function handleRate(rates: number) {
        setIsRated(true)
        if(rates > 3 && rate <= 3){
            setChips([]);
        } else if(rates <= 3 && rate > 3){
            setChips([]);
        }
        setRate(rates)
    }

    function handleSubmit() {
        submitForm?.mutate({
            slug: searchParams?.get('id') || '',
            rating: Number(rate),
            description: feedback,
            chip: chips
        })
    }
    function handleChangeChip(e:any){
        if(chips?.find((p:any)=>p === e?.description) !== undefined){
            setChips(chips?.filter((p:any)=> p !== e?.description))
        } else {
            setChips((prev:any)=>[...prev, e?.description])
        }
    }
    
    useEffect(() => {
        formData?.mutate({ 
            slug: searchParams?.get('id') || '' 
        });
    }, [])
    
    return (
        <Flex 
            paddingY='24px' 
            paddingX='16px'
            direction='column' 
            backgroundColor='background'
            alignContent={'center'}
            justifyContent={'center'}
            alignItems={'center'}
            w={'100%'}
        >   
            <Image alignSelf={'flex-start'} marginLeft={'32px'} src={lOGO_JARIVIS} width='130px' marginBottom='24px' />
            <Flex w={'345px'}>
                { formData?.data?.status === "INACTIVE" && <Rated data={formData} /> }
                { isSubmitted && <Rated data={formData} /> }

                {
                    isSubmitted === false && formData?.data?.status === "ACTIVE" &&
                    <Flex 
                        direction='column'  
                        alignContent={'space-between'}
                        gap='12px'
                    >
                        <Heading alignSelf={'center'} size='chakra_title_large'>Form Feedback</Heading>

                        <Flex 
                            direction='row' 
                            justifyContent={'space-between'}
                            alignContent={'space-between'}
                            alignItems='center'
                            gap='8px'
                            marginTop='11px'
                            textAlign={'end'}
                        >
                            <Heading size='chakra_label_large' opacity={0.7}>Nama Perusahaan</Heading>
                            <Heading textAlign={'end'} fontSize={formData?.data?.company?.display_name?.length > 26 ? '12px' : '14px'}>
                                {formData?.data?.company?.display_name}
                            </Heading>
                        </Flex>

                        <Flex 
                            direction='row' 
                            justifyContent={'space-between'}
                            alignContent={'space-between'}
                            alignItems='center'
                            gap='8px'
                        >
                            <Heading size='chakra_label_large' opacity={0.7}>Nama Teknisi</Heading>
                            <Heading textAlign={'end'} fontSize={formData?.data?.user?.display_name?.length > 26 ? '12px' : '14px'}>
                                {formData?.data?.user?.display_name}
                            </Heading>
                        </Flex>

                        <Flex 
                            direction='row' 
                            justifyContent={'space-between'}
                            alignContent={'space-between'}
                            alignItems='center'
                            gap='8px'
                        >
                            <Heading size='chakra_label_large' opacity={0.7}>Pekerjaan</Heading>
                            <Heading textAlign={'end'} fontSize={formData?.data?.tasktype?.name?.length > 26 ? '12px' : '14px'}>
                                {formData?.data?.tasktype?.name}
                            </Heading>
                        </Flex>
                        <Flex 
                            direction='row' 
                            justifyContent={'space-between'}
                            alignContent={'space-between'}
                            alignItems='center'
                            gap='8px'
                        >
                            <Heading size='chakra_label_large' opacity={0.7}>Nama Pelanggan</Heading>
                            <Heading textAlign={'end'} fontSize={formData?.data?.customer?.name?.length > 26 ? '12px' : '14px'}>
                                {formData?.data?.customer?.name}
                            </Heading>
                        </Flex>
                        <Flex 
                            direction='row' 
                            justifyContent={'space-between'}
                            alignContent={'space-between'}
                            alignItems='center'
                            gap='8px'
                        >
                            <Heading size='chakra_label_large' opacity={0.7}>Waktu Pengerjaan</Heading>
                            <Heading textAlign={'end'} fontSize={'14px'}>
                                {format(add(new Date(formData?.data?.updated_at), { hours: 7 }), 'dd MMM yyyy | hh.mm')}
                            </Heading>
                        </Flex>
                        <Divider
                            orientation='horizontal'
                            borderBottomWidth={'2px'}
                            opacity={1}
                        />
                        <Flex 
                            direction='column' 

                            alignItems='center'
                            gap='10px'
                            marginTop='2px'
                        >
                            <Heading size='chakra_label_large'>Nilai Kami</Heading>
                            <Flex
                                gap='12px'
                                alignItems='center'
                            >
                                {
                                    !isRated && [...Array(5)]?.map((_: any, i: number) => {
                                        return (
                                            <Icon 
                                                key={i}
                                                as={BsStar} 
                                                h='22px' 
                                                w='22px' 
                                                color='#eac23f' 
                                                cursor='pointer'
                                                onClick={() => {
                                                    handleRate(i+1)
                                                }}
                                            />
                                        )
                                    })
                                }

                                {
                                    rate && isRated && [...Array(rate)]?.map((_: any, i: number) => {
                                        return (
                                            <Icon 
                                                key={i}
                                                as={BsStarFill} 
                                                h='22px' 
                                                w='22px' 
                                                color='#eac23f' 
                                                cursor='pointer'
                                                onClick={() => {
                                                    handleRate(i+1)
                                                }}
                                            />
                                        )
                                    })
                                }

                                {
                                    rate && isRated && [...Array(5 - rate)]?.map((_: any, i: number) => {
                                        return (
                                            <Icon 
                                                key={i}
                                                as={BsStar} 
                                                h='22px' 
                                                w='22px' 
                                                color='#eac23f' 
                                                cursor='pointer'
                                                onClick={() => {
                                                    handleRate(i+1+rate)
                                                }}
                                            />
                                        )
                                    })
                                }
                            </Flex>
                            <Flex alignItems='center' justifyContent='space-between'>
                                <Heading size='chakra_label_medium' position='relative' left='-35px'>Sangat Buruk</Heading>
                                <Heading size='chakra_label_medium' position='relative' right='-30px'>Sangat Baik</Heading>
                            </Flex>
                            <Flex maxWidth={'500px'} justifyContent={'center'} gap={'8px'} flexWrap={'wrap'}>
                                {rate !== 0 && rate !== null && 
                                    <>
                                    {rate > 3 ? 
                                        addChipHigh?.map((item:any, index: number)=>
                                            <Tag borderWidth={'1px'} cursor={'pointer'} onClick={()=>handleChangeChip(item)} borderColor={chips?.find((p:any)=> item?.description === p) !== undefined ? 'onPrimary' : 'outlineVariant'} borderRadius={'12px'} height={'32px'} background={chips?.find((p:any)=> item?.description === p) !== undefined ? 'primary' : 'transparent'} variant='solid' size={'md'} colorScheme='teal'>
                                                <JTypography p={'8px'} fontWeight={'semibold'} color={chips?.find((p:any)=> item?.description === p) !== undefined ? 'onPrimary' : 'outlineVariant'} size='body-medium'> {item?.description?.replace(/[^\w\s]/gi, '')} </JTypography>
                                            </Tag>
                                        ) : 
                                        addChipLow?.map((item:any, index: number)=>
                                            <Tag borderWidth={'1px'} cursor={'pointer'} onClick={()=>handleChangeChip(item)} borderColor={chips?.find((p:any)=> item?.description === p) !== undefined ? 'onPrimary' : 'outlineVariant'} borderRadius={'12px'} height={'32px'} background={chips?.find((p:any)=> item?.description === p) !== undefined ? 'primary' : 'transparent'} variant='solid' size={'md'} colorScheme='teal'>
                                                <JTypography p={'8px'} fontWeight={'semibold'} color={chips?.find((p:any)=> item?.description === p) !== undefined ? 'onPrimary' : 'outlineVariant'} size='body-medium'> {item?.description?.replace(/[^\w\s]/gi, '')} </JTypography>
                                            </Tag>
                                        )
                                    }
                                    </>
                                }
                                
                            </Flex>
                        </Flex>

                        {
                            isRated &&
                            rate &&
                            <Flex direction='column'>
                                <Textarea  
                                    as={TextareaAutosize}
                                    placeholder='Berikan masukan Anda disini..' 
                                    width='345px'
                                    value={feedback}
                                    onChange={(e: any) => {
                                        setFeedback(e?.target?.value)
                                    }}
                                    maxLength={150}
                                    fontSize='12px'
                                />
                                <Heading marginLeft='auto' size='chakra_body_small' fontSize='10px'>{feedback?.length}/150</Heading>
                            </Flex>
                        }
                        <Flex direction={'column'} position={'sticky'} bottom={0} backgroundColor={'background'} p={4}>
                            <Heading alignSelf={'center'} size='chakra_body_small'>Pemberian Feedback ini hanya dapat dilakukan 1 kali.</Heading>
                            <Button 
                                variant='j-primary' 
                                disabled={!isRated}
                                onClick={handleSubmit}
                                isLoading={submitForm?.isLoading}
                                width={'full'}
                                fontSize='14px'
                                alignSelf={'center'}
                            >Kirim</Button>
                        </Flex>
                    </Flex>
                }
            </Flex>
        </Flex>
    )
}

export default FeedbackForm