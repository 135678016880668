import {
    Navigate,
    Outlet,
} from "react-router-dom";
import urlRouter from "./url";

const PublicRoute = () => {
    const token = localStorage.getItem('access_token')
    return !token || token === 'null' ? <Outlet /> : <Navigate to={urlRouter.home} />;
};

export default PublicRoute;