import URL_API from '../apis/urlApi'
import { http } from '../apis'
import { handleErrorMessage, handleGetTeamId } from './helpers'


export async function getMenuPrivilageApi() {
    try {
        const payload = { profile_id: handleGetTeamId() }
        const response: any = await http(true).post(URL_API.MENU_PRIVILAGE_LIST, payload)
        if (response.code !== '200') throw handleErrorMessage(response?.message, 'error get manu privilage')
        return response.data
    } catch (error) {
        throw error
    }
}