import { 
    Box,
    Button,
    Divider, 
    Flex, 
    Heading, 
    Input, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalFooter, 
    ModalHeader, 
    ModalOverlay, 
    Progress, 
    Slider, 
    SliderFilledTrack, 
    SliderThumb, 
    SliderTrack,
} from '@chakra-ui/react'
import { 
    Circle, 
    GoogleMap, 
    MarkerF, 
} from '@react-google-maps/api'
import { useTranslation } from 'react-i18next'
import PlacesAutocomplete from 'react-places-autocomplete'
import { 
    JGoogleIcon,
    JInput, 
    JTypography,
} from '../../../components'
import { headingTheme } from '../../../theme/components'
import CustomStepper from '../../../components/CustomStepper/CustomStepper'
import { IC_MS_LOCATION_ON_LIGHT } from '../../../assets'

interface SpecifyLocationProps {
    is_open: boolean
    on_close: any
    on_next: any
    initial_area: any
    set_initial_area: any
    places_value: any
    places_on_change: any
    places_on_select: any
}

const SpecifyLocation = (props: SpecifyLocationProps) => {
    const { t } = useTranslation()
    const {
        is_open,
        on_close,
        on_next,
        initial_area,
        set_initial_area,
        places_value,
        places_on_change,
        places_on_select,
    } = props

    const theme = localStorage.getItem('chakra-ui-color-mode')
   
    return (
        <Modal
            isOpen={is_open}
            onClose={on_close}
            scrollBehavior={'outside'}
            closeOnOverlayClick={false}
            size={'5xl'}
            blockScrollOnMount={true}
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                backgroundColor={'surfacePlus1'}
                // height='96vh'
                overflow='auto'
                borderRadius='24px'
                margin='0px'
                // width='800px'
            >
                <ModalHeader
                    as={Flex}
                    flexDirection={'column'}
                    alignItems={'center'}
                    padding={'24px 24px 0px'}
                    gap='24px'
                >
                    <Flex width='282px' marginX='auto' marginTop='28px'>
                        <CustomStepper stepLength={3} stepActive={0} />
                    </Flex>
                    <Heading size={'chakra_title_medium'}>{t('pengaturan_anggota_tetapkan_letak_area')}</Heading>
                </ModalHeader>
                <ModalCloseButton 
                    onClick={on_close} 
                    right='24px'
                    top='24px'
                />
                <ModalBody
                    as={Flex}
                    flexDirection={'row'}
                    gap={'48px'}
                    // height='50vh'
                    overflow='auto'
                    padding={'0px 24px'}
                    marginTop='24px'
                >
                    <Flex direction='column' gap='8px'>
                        <PlacesAutocomplete
                            value={places_value}
                            onChange={places_on_change}
                            onSelect={places_on_select}
                        >
                            {({
                                getInputProps, suggestions, getSuggestionItemProps, loading
                            }) => (
                                <div>
                                    <Heading
                                        size={'chakra_title_small'}
                                        marginBottom={'8px'}
                                    >
                                        {t('pengaturan_absen_modal_titik_lokasi_cari_lokasi_label')}
                                    </Heading>
                                    <Input
                                        {...headingTheme?.sizes?.chakra_label_large}
                                        {...getInputProps({
                                            placeholder: t('pengaturan_absen_modal_titik_lokasi_pencarian_lokasi'),
                                            className: 'location-search-input',
                                        })}
                                    />
                                    <div className='autocomplete-dropdown-container'>
                                        {loading && (
                                            <Progress
                                                marginTop={'10px'}
                                                size='xs'
                                                isIndeterminate
                                            />
                                        )}
                                        {suggestions.map((suggestion, index) => {
                                            return (
                                                <Flex
                                                    {...getSuggestionItemProps(suggestion)}
                                                    key={index}
                                                    padding={'5px'}
                                                    direction={'column'}
                                                    borderBottomColor={'neutral.30'}
                                                    borderBottomWidth={'1px'}
                                                    cursor={'pointer'}
                                                >
                                                    <JTypography size={'label-large'}>{suggestion.description}</JTypography>
                                                </Flex>
                                            )
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                        
                        <Flex height='254px' marginTop='8px'>
                            <GoogleMap
                                mapContainerStyle={{
                                    height: '254px',
                                    width: '428px',
                                    borderRadius: '16px',
                                }}
                                center={{
                                    lat: initial_area.latitude,
                                    lng: initial_area.longitude
                                }}
                                zoom={15}
                                clickableIcons={false}
                            >
                                <MarkerF 
                                    position={{
                                        lat: initial_area.latitude,
                                        lng: initial_area.longitude
                                    }} 
                                    icon={{
                                        url: IC_MS_LOCATION_ON_LIGHT,
                                        rotation: 1,
                                        scaledSize: { width: 35, height: 35, equals: () => true },
                                    }}
                                />
                                <Circle
                                    center={{
                                        lat: initial_area.latitude,
                                        lng: initial_area.longitude
                                    }}
                                    radius={initial_area.radius}
                                    options={{
                                        strokeColor: 'transparent',
                                        fillColor: '#0079D2',
                                        fillOpacity: 0.19,
                                        zIndex: 2,
                                    }}
                                />
                            </GoogleMap>
                        </Flex>

                        {initial_area.address !== '' && (
                            <Flex alignItems='center' gap='4px' color='primary'>
                                <JGoogleIcon name='location_on' />
                                <Heading size={'chakra_body_medium'}>{initial_area.address}</Heading>
                            </Flex>
                        )}
                    </Flex>

                    <Divider borderColor={'neutral.30'} orientation='vertical' height='335px' />

                    <Flex
                        direction={'column'}
                        gap={'16px'}
                    >
                        <Flex
                            gap={'8px'}
                            direction={'column'}
                        >
                            <Heading size={'chakra_title_small'}>{t('pengaturan_absen_modal_titik_lokasi_radius_absen_label')} <Box as='span' color='error'>*</Box></Heading>
                        </Flex>
                        <Flex
                            alignItems={'center'}
                            width={'100%'}
                            justifyContent={'space-between'}
                        >
                            <Heading size={'chakra_body_medium'}>{t('pengaturan_absen_modal_titik_lokasi_radius_absen_sublabel')}</Heading>
                            <JInput
                                rightIcon={'m'}
                                wrapperWidth={'90px'}
                                width={'90px'}
                                type={'number'}
                                min={50}
                                max={1000}
                                value={initial_area.radius}
                                onChange={(e: any) => {
                                    set_initial_area((prev: any) => {
                                        return {
                                            ...prev,
                                            radius: parseInt(e.target.value),
                                        }
                                    })
                                }}
                            />
                        </Flex>
                        <Slider
                            aria-label='slider-ex-1'
                            min={50}
                            max={1000}
                            value={initial_area.radius}
                            onChange={(e: any) => {
                                set_initial_area((prev: any) => {
                                    return {
                                        ...prev,
                                        radius: e,
                                    }
                                })
                            }}
                        >
                            <SliderTrack>
                                <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb />
                        </Slider>
                    </Flex>
                </ModalBody>
                <ModalFooter gap={'16px'} padding='24px'>
                    <Button
                        size={'normal'}
                        variant={'j-primary'}
                        onClick={on_next}
                        isDisabled={initial_area?.address?.length <= 0}
                        width='160px'
                    >
                        {t('pengaturan_anggota_live_tracking_lanjut')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default SpecifyLocation