import React, { memo, useMemo } from 'react'
import { JGoogleIcon } from '../../../components'
import { useTranslation } from 'react-i18next'
import { CSVLink } from 'react-csv'
import { Button, Flex } from '@chakra-ui/react'

interface IUploadTemplate {
    modalUploadTemplateDisclosure: any
}

const UploadTemplate: React.FC<IUploadTemplate> = (props: IUploadTemplate) => {
    const { modalUploadTemplateDisclosure } = props

    const { t } = useTranslation()

    const template = useMemo(() => [
        ['label*', 'member ID*', 'Address*', 'Latitude*', 'Longitude*', 'Radius (meter)*'],
        ['Belleza Office', 'aan,dimas,indra', 'Permata Hijau', "contoh: -6.2212324", "contoh: 106.7791311", '150'],
    ], [])

    return (
        <Flex 
            marginTop='8px' 
            gap='24px' 
            alignItems='center'
        >
            <CSVLink 
                data={template} 
                filename='template_lokasi_absen.csv' 
                separator=';'
            >
                <Button variant='j-outline' leftIcon={<JGoogleIcon name='download' />}>
                    {t('pengaturan_absen_unduh_template')}
                </Button>
            </CSVLink>
            <Button 
                variant='j-primary' 
                leftIcon={<JGoogleIcon name='file_upload' />}
                onClick={modalUploadTemplateDisclosure.onOpen}
            >
                {t('pengaturan_absen_unggah_template')}
            </Button>
        </Flex>
    )
}

export default memo(UploadTemplate)