import { CircularProgress, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Text } from "@chakra-ui/react"
import style from './loading-modal.module.css'

interface LoadingModalProps {
  show: boolean
}

const LoadingModal = (props: LoadingModalProps) => {
  return (
    <Modal onClose={(): void => undefined} size="xs" isOpen={props.show} isCentered>
      <ModalOverlay border="none" />
      <ModalContent border="none">
        <ModalBody border="none">
          <Flex className={style.loading_modal_body}>
            <CircularProgress size="32px" isIndeterminate color='var(--colorPrimary)' />
            <Text>Mohon tunggu</Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default LoadingModal