import React from 'react'
import { useSelector } from 'react-redux'
import { 
    Heading, 
    Stack, 
    useColorMode, 
} from '@chakra-ui/react'

const Version = () => {
    const teamV2: any = useSelector((state: any) => state?.teamV2)
    const { colorMode } = useColorMode()

    return (
        <Stack spacing='8px' marginX='var(--sidebarPadding) !important'>
            <Heading  
                color={colorMode === 'dark'
                    ?   'unset'
                    :   teamV2?.detail?.theme_object?.code === 'Grey-vis' ? 'black' : '#FDFCFF'
                }
                size='chakra_body_small' 
                marginTop='16px'
            >v2.5.2</Heading>
            <Heading 
                color={colorMode === 'dark'
                    ?   'unset'
                    :   teamV2?.detail?.theme_object?.code === 'Grey-vis' ? 'black' : '#FDFCFF'
                }
                size='chakra_body_small'
            >Copyright © Jarivis</Heading>
            <Heading 
                color={colorMode === 'dark'
                    ?   'unset'
                    :   teamV2?.detail?.theme_object?.code === 'Grey-vis' ? 'black' : '#FDFCFF'
                }
                size='chakra_body_small'
            >All Right Reserved.</Heading>
        </Stack>
    )
}

export default React.memo(Version)