function setDarkColor() {
  document.documentElement.style.setProperty('--colorPrimary', '#A2C9FF');
  document.documentElement.style.setProperty('--colorOnPrimary', '#00315B');
  document.documentElement.style.setProperty('--colorPrimaryContainer', '#004881');
  document.documentElement.style.setProperty('--colorOnPrimaryContainer', '#D3E4FF');

  document.documentElement.style.setProperty('--colorSecondary', '#B8C8E0');
  document.documentElement.style.setProperty('--colorOnSecondary', '#223145');
  document.documentElement.style.setProperty('--colorSecondaryContainer', '#39485C');
  document.documentElement.style.setProperty('--colorOnSecondaryContainer', '#D4E3FD');
  
  document.documentElement.style.setProperty('--colorTertiary', '#DBBBEA');
  document.documentElement.style.setProperty('--colorOnTertiary', '#3E274C');
  document.documentElement.style.setProperty('--colorTertiaryContainer', '#563D64');
  document.documentElement.style.setProperty('--colorOnTertiaryContainer', '#F5D9FF');
  
  document.documentElement.style.setProperty('--colorError', '#FFB4AB');
  document.documentElement.style.setProperty('--colorOnError', '#690005');
  document.documentElement.style.setProperty('--colorErrorContainer', '#93000A');
  document.documentElement.style.setProperty('--colorOnErrorContainer', '#FFB4AB');
  
  document.documentElement.style.setProperty('--colorBackground', '#1A1C1E');
  document.documentElement.style.setProperty('--colorOnBackground', '#E3E2E6');
  
  document.documentElement.style.setProperty('--colorSurface', '#1A1C1E');
  document.documentElement.style.setProperty('--colorOnSurface', '#E3E2E6');
  
  document.documentElement.style.setProperty('--colorSurfaceVariant', '#43474E');
  document.documentElement.style.setProperty('--colorOnSurfaceVariant', '#C3C6CF');
  document.documentElement.style.setProperty('--outline', '#8D9199');

  document.documentElement.style.setProperty('--colorSurface1', '#212529');
  document.documentElement.style.setProperty('--colorSurface2', '#252a30');
  document.documentElement.style.setProperty('--colorSurface3', '#292f37');
  document.documentElement.style.setProperty('--colorSurface4', '#2a3139');
  document.documentElement.style.setProperty('--colorSurface5', '#2d343e');
};

export default setDarkColor;