import { Box, Button, Flex, Heading, InputGroup, Skeleton, useDisclosure, useToast } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PageTitle } from '../../components'
import { getMember } from '../../redux'
import style from './member-master.module.css'
import { useTranslation } from 'react-i18next'
import MasterMemberTable from './MemberMasterTable'
import { JInput, JGoogleIcon, JToast } from '../../components'
import Device from './MasterMemberModels/Device'
import { deleteMemberDeviceApiProps, deleteMemberDeviceApi } from '../../apis/memberDeviceApi'
import { useMutation } from 'react-query'
import { useDebouncedCallback } from 'use-debounce'
import ReactPaginate from 'react-paginate'
import { Get_Member_Props } from '../../apis/memberApi'
import FilterMasterMember from './components/FilterMasterMember'
import ModalMemberInvite from './components/ModalInvite/ModalMemberInvite'
import { useNavigate } from 'react-router-dom'
import { urlRouter } from '../../router'

const MemberMaster = () => {
    const dispatch = useDispatch<any>()
    const { t } = useTranslation()
    const toast = useToast()
    const navigate = useNavigate()
    const modalMemberInvite = useDisclosure()
    const {
        loadingGetMember,
        member,
        memberMetadata,
    } = useSelector((state: any) => state.member)
    const [openModalDevice, setOpenModalDevice] = useState(false)
    const [openModalConfirm, setOpenModalConfirm] = useState(false)
    const [device, setDevice] = useState('')
    const [userName, setUserName] = useState('')
    const resetDevice = useMutation(handleResetDevice)
    const [configResetDevice, setConfigResetDevice] = useState<deleteMemberDeviceApiProps>({
        user_id: '',
        toaster: toaster
    })
    const [page, setPage] = useState(1)
    const [searchbar, setSearchbar] = useState('')

    const [configGetMember, setConfigGetMember] = useState<Get_Member_Props>({
        page: page,
        order_by: "",
        order_type: "desc",
        show: 10,
        search: "",

        nip: "",
        status: "",
        active_device: "",
        active_geofence: "",
        app_version: "",
        face_recognition: "",
        live_tracking: "",
    })

    const debouncedSearchbar = useDebouncedCallback(
        // function
        (value) => {
            setSearchbar(value)
            dispatch(getMember({
                ...configGetMember,
                page: 1,
                search: value,
            }))
        },
        // delay in ms
        1000
    )

    async function handleResetDevice() {
        if (configResetDevice.user_id !== '') {
            return await deleteMemberDeviceApi(configResetDevice);
        }
    }

    function toaster(code: string, msg: string) {
        toast({
            position: 'top-right',
            render: () => (
                <JToast
                    type={code === "200" ? 'success' : 'error'}
                    message={code !== "200" ? msg : t('master_anggota_reset_sukses', { userName: userName, })}
                />
            ),
            duration: 1500,
            isClosable: true,
        })
    }

    function handleReset() {
        resetDevice.mutate();
        setOpenModalDevice(false);
        setOpenModalConfirm(false);
        const data = {
            page: memberMetadata.current_page,
            show: 10,
        }
        dispatch(getMember(data))
    }

    function handleGetMember() {
        dispatch(getMember(configGetMember))
    }

    function handlePagination(type: any) {
        setConfigGetMember(
            {
                page: type === 'next' ? memberMetadata.current_page + 1 : memberMetadata.current_page - 1,
                show: 10,
            }
        )
        dispatch(getMember(configGetMember))
    }

    function handleSortName(by: any) {
        const data: any = {
            order_by: by === "" ? "user.display_name" : by === "user.display_name" ? "user.created_at" : "",
            order_type: by === "" || "user.created_at" ? "asc" : "desc",
        }
        setConfigGetMember((prev: any) => { return { ...prev, ...data } })
        return dispatch(getMember(data))
    }

    function handleClickDevice(id: any) {
        setTimeout(() => {
            setOpenModalDevice(true);
        }, 1)
        setUserName(id.user.display_name);
        setConfigResetDevice({
            user_id: id.user.id,
            toaster: toaster
        })
        setDevice(id.user.device)
    }

    function handleRefetchMember(type: any) {
        setConfigGetMember({
            order_by: configGetMember.order_by,
            page: memberMetadata.current_page,
            show: 10,
        })
        dispatch(getMember(configGetMember))
    }

    useEffect(() => {
        dispatch(getMember(configGetMember))
    }, [configGetMember])

    return (
        <Flex className={`${style.member_master}`}>
            <PageTitle text={t("master_anggota_judul")} />
            <Flex w='100%' justify='space-between'>
                <Flex gap='16px'>
                    <InputGroup width="max-content" margin={"16px 0"}>
                        <JInput
                            type="search"
                            leftIcon={<JGoogleIcon name='search' />}
                            width="max-content"
                            placeholder={t("master_anggota_pencarian_placeholder")}
                            className={`${style.fs14}`}
                            onChange={(e: any) => {
                                debouncedSearchbar(e?.target?.value)
                            }}
                        />
                    </InputGroup>
                    <FilterMasterMember handleFilter={setConfigGetMember} memberProps={configGetMember} />
                </Flex>
                <Flex align='center' gap='16px'>
                    {/* <Button onClick={() => navigate(urlRouter.memberInviteList)} variant='outline' position='relative' p='14px 24px' border='1px solid var(--colorPrimary)' rounded='12px'>
                        <Heading size='chakra_label_large' color='primary'>Invitation List</Heading>
                        <Box position='absolute' top='-5px' right='-5px' bg='errorContainer' w='15px' h='15px' rounded='full'>
                            <Heading size='chakra_label_small'>6</Heading>
                        </Box>
                    </Button> */}
                    <ModalMemberInvite />
                </Flex>
            </Flex>

            <MasterMemberTable
                data={member}
                metadata={memberMetadata}
                handlePrevious={() => handlePagination("prev")}
                handleNext={() => handlePagination("next")}
                isLoading={loadingGetMember}
                handleClickDevice={handleClickDevice}
                handleSortName={handleSortName}
                configGetMember={configGetMember}
                refetch={handleRefetchMember}
                resetDevice={handleReset}
            />

            <Flex
                backgroundColor='surfacePlus4'
                padding='22px 16px'
                alignItems='center'
                justifyContent='space-between'
                borderRadius='0px 0px 16px 16px'
            >
                {loadingGetMember
                    ? <Skeleton height='20px' width='300px' />
                    : <Heading size='chakra_label_large' color='onSurfaceVariant'>
                        {t('daftar_anggota_tabel_keterangan', { from: memberMetadata?.from, to: memberMetadata?.to, total: memberMetadata?.total })}
                    </Heading>
                }
                {loadingGetMember
                    ? <Skeleton height='20px' width='300px' />
                    : <Box mt={'-20px'} display='flex' alignItems='center'>
                        <ReactPaginate
                            pageCount={memberMetadata?.last_page}
                            pageRangeDisplayed={5}
                            breakLabel="..."
                            previousLabel={`< ${t('customer_master_table_pagination_previous')}`}
                            nextLabel={`${t('customer_master_table_pagination_next')} >`}
                            onPageChange={(e) => {
                                if (memberMetadata?.current_page === Number(e.selected + 1)) return
                                // return setPage(Number(e.selected) + 1)
                                const data: any = {
                                    page: Number(e.selected + 1),
                                    show: 10,
                                    search: searchbar,
                                    order_by: configGetMember.order_by,
                                    nip: configGetMember.nip,
                                    status: configGetMember.status,
                                    active_device: configGetMember.active_device,
                                    active_geofence: configGetMember.active_geofence,
                                    app_version: configGetMember.app_version,
                                    face_recognition: configGetMember.face_recognition,
                                    live_tracking: configGetMember.live_tracking,
                                }
                                return dispatch(getMember(data))
                            }}
                            // renderOnZeroPageCount={null}
                            containerClassName="react-paginate-pagination"
                            pageLinkClassName="react-paginate-page-item"
                            breakLinkClassName="react-paginate-page-item"
                            previousLinkClassName="react-paginate-page-item-previous"
                            nextLinkClassName="react-paginate-page-item-next"
                            activeClassName="react-paginate-page-item-active"
                            initialPage={memberMetadata?.current_page ? Number(memberMetadata?.current_page - 1) : 0}
                        />
                    </Box>
                }
            </Flex>
            {
                <Device
                    show={openModalDevice}
                    name={device}
                    userName={userName}
                    resetDevice={handleReset}
                    openModalConfirm={openModalConfirm}
                    setOpenModalConfirm={setOpenModalConfirm}
                    onClose={() => setOpenModalDevice(false)}
                />
            }
        </Flex>
    )
}

export default MemberMaster