import React from 'react'
import style from './team-card.module.css'
import { motion } from 'framer-motion'
import { 
    AspectRatio, 
    Avatar, 
    Flex, 
    Heading, 
    Img, 
    Text, 
} from '@chakra-ui/react'

interface ITeamCard {
    text: string
    img: any
    onSelect: any
    _key: number
}

const TeamCard: React.FC<ITeamCard> = (props: ITeamCard) => {
    return (
        <Flex
            w='100%'
            minW='300px'
            // m='auto'
            h='325px'
            className={style.team_card}
            onClick={props.onSelect}
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <AspectRatio width='full' ratio={1}>
                <Avatar
                    className={style.team_img}
                    width='full'
                    src={props?.img}
                    name={props.text}
                    borderRadius='0px !important'
                />
            </AspectRatio>
            <Heading size='chakra_title_medium'>{props.text}</Heading>
        </Flex>
    )
}

export default TeamCard