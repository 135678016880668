import http from "./http";
import urlApi from "./url";

export interface teamEditProps {
  logo: any;
  code: string;
  display_name: string;
  description: string;
  autoupdate_frequency: any;
  device_locked: any;
  is_absence_mandatory_for_check_in: any;
  use_system_auth: any;
  mail_server: string;
  port: string;
  username: string;
  password: string;
  encryption_type: string;
  sender_name: string;
}

export const teamListApi = async () => {
  try {
    let response: any = await http(true).post(urlApi.team_list);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const teamDetailApi = async () => {
  try {
    const team = localStorage.getItem("current_team") || "";
    const bodyPayload = new FormData();
    bodyPayload.set("profile_id", team);
    let response = await http(true).post(urlApi.team_get, bodyPayload);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const teamEditApi = async (props : teamEditProps) => {
  try {
    const team = localStorage.getItem("current_team") || "";
    const formData = new FormData()
        formData.set('profile_id', team)
        formData.set('logo', props.logo)
        formData.set('code', props.code)
        formData.set('display_name', props.display_name)
        formData.set('description', props.description)
        formData.set('autoupdate_frequency', props.autoupdate_frequency)
        formData.set('device_locked', props.device_locked)
        formData.set('is_absence_mandatory_for_check_in', props.is_absence_mandatory_for_check_in)
        formData.set('use_system_auth', props.use_system_auth)
        formData.set('mail_server', props.mail_server)
        formData.set('port', props.port)
        formData.set('username', props.username)
        formData.set('password', props.password)
        formData.set('encryption_type', props.encryption_type)
        formData.set('sender_name', props.sender_name)
    let response = await http(true).post(urlApi.team_edit, formData);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
