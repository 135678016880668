import React from 'react'
import JGoogleIcon from '../../JGoogleIcon/JGoogleIcon'
import NewFeatureTag from './NewFeatureTag'
import { motion } from 'framer-motion'
import { Link as ReactRouterLink, useLocation } from 'react-router-dom'
import { 
    Flex, 
    Heading, 
    Link, 
    useColorMode, 
} from '@chakra-ui/react'
import { useSelector } from 'react-redux'

interface StandaloneModuleProps {
    menu: any
}

const StandaloneModule = (props: StandaloneModuleProps) => {
    const { menu } = props

    const location = useLocation()
    const { colorMode } = useColorMode()
    const sidebar = useSelector((state: any) => state?.sidebar)
    const teamV2: any = useSelector((state: any) => state?.teamV2)

    return (
        <Link
            as={ReactRouterLink}
            to={menu.to}
            _hover={{ textDecoration: 'none' }}
            transition='.3s'
            position='relative'
            width='100%'
            display='flex'
            padding='12px 0px'
            gap='16px'
            alignItems='center'
            cursor='pointer'
            paddingX='16px'
        >
            <Flex
                gap='16px'
                as={motion.div}
                initial={{ x: '-100%' }}
                animate={{ x: 0 }}
                color={colorMode === 'light'
                    ?   location?.pathname === menu?.to 
                        ?   '#FDFCFF' 
                        :   colorMode === 'light'
                            ?   teamV2?.detail?.theme_object?.code === 'Grey-vis' ? 'black' : '#FDFCFF'
                            :   '#FDFCFF'
                    :   location?.pathname === menu?.to ? 'primary' : 'unset'
                }
            >
                <JGoogleIcon 
                    name={menu?.icon} 
                    color={colorMode === 'light'
                        ?   teamV2?.detail?.theme_object?.code === 'Grey-vis' ? 'black' : '#FDFCFF'
                        :   '#FDFCFF'
                    } 
                />
                <Heading 
                    size='chakra_title_medium' 
                    lineHeight='150%' 
                    fontWeight='500'
                    overflow='hidden'
                    whiteSpace='nowrap'
                    color={colorMode === 'light'
                        ?   teamV2?.detail?.theme_object?.code === 'Grey-vis' ? 'black' : '#FDFCFF'
                        :   'inherit'
                    }
                    display={sidebar?.isReveal ? 'block' : 'none'}
                >{menu.name}</Heading>
                {menu?.new && sidebar?.isReveal
                    ?   <NewFeatureTag />
                    :   null
                }
            </Flex>
        </Link>
    )
}

export default React.memo(StandaloneModule)