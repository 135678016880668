
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  JButton,
  JGoogleIcon,
  JTypography,
} from "../../../components";
import ConfirmRemove from './ConfirmRemove';
const Device = (props: any) => {
    const {
        show,
        onClose,
        userName,
        name,
        resetDevice,
        setOpenModalConfirm,
        openModalConfirm
    } = props;
    const {t} = useTranslation();
    return (
      <Modal isOpen={show} onClose={onClose} size={"lg"}>
        <ModalOverlay/>
        <ModalContent borderRadius={'16px'} backgroundColor={"background"} padding={"24px"}>
          <ModalHeader padding={"0px"} mt={'10px'} mb ={2} >
              <JTypography size="body-medium"> {`${t('anggota_perangkat_aktif')} (${userName})`} </JTypography>
          </ModalHeader>
          <ModalCloseButton top={"24px"} right={"24px"} />
          <ModalBody
            as={Flex}
            flexDirection={"column"}
            gap={"16px"}
            padding={"0px"}
            marginTop={"24px"}
          >
              <Flex justifyContent={'space-between'} >
                  <Flex alignItems={'center'} >
                      <JGoogleIcon name="phone_android"/>
                      <JTypography size="body-medium" ml={3} > {name} </JTypography>
                  </Flex>
                  <JButton onClick={()=>setOpenModalConfirm(true)} variant="j-outline" size="small" >
                      {t('anggota_perangkat_keluar')}
                  </JButton>
              </Flex>
          </ModalBody>
          <ModalFooter
            gap={"12px"}
            padding={"0px"}
            marginTop={"24px"}
            justifyContent={"end"}
          >
          
          </ModalFooter>
        </ModalContent>
        <ConfirmRemove
          show={openModalConfirm}
          resetDevice={resetDevice}
          onClose={()=>setOpenModalConfirm(false)}
        />
      </Modal>
    );
};

export default Device;
