import { 
    Avatar, 
    Button, 
    Checkbox, 
    Divider, 
    Flex, 
    Heading, 
    Input, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalFooter, 
    ModalHeader, 
    ModalOverlay, 
} from '@chakra-ui/react'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { 
    JButton, 
    JGoogleIcon, 
    JInput, 
    JTypography, 
} from '../../../components'
import { headingTheme } from '../../../theme/components'
import CustomStepper from '../../../components/CustomStepper/CustomStepper'

interface DefineMemberProps {
    is_open: boolean
    on_close: any
    on_back: any
    search_value: string
    search_on_change: any
    initial_area: any
    group_on_check: any
    member_on_check: any
    on_next: any
    selected_members: any
}

const DefineMember = (props: DefineMemberProps) => {
    const { t } = useTranslation()
    const {
        is_open,
        on_close,
        on_back,
        search_value,
        search_on_change,
        initial_area,
        group_on_check,
        member_on_check,
        on_next,
        selected_members,
    } = props
    
    return (
        <Modal
            isOpen={is_open}
            onClose={on_close}
            scrollBehavior={'outside'}
            closeOnOverlayClick={false}
            size={'4xl'}
            blockScrollOnMount={true}
            isCentered
        >
            <ModalOverlay />
            <ModalContent 
                backgroundColor={'surfacePlus1'}
                height='96vh'
                overflow='auto'
                borderRadius='24px'
                margin='0px'
                width='800px'
            >
                <ModalHeader
                    as={Flex}
                    flexDirection={'column'}
                    alignItems={'center'}
                    padding={'24px 24px 0px'}
                    gap='24px'
                >
                    <Flex width='282px' marginX='auto' marginTop='28px'>
                        <CustomStepper stepLength={3} stepActive={1} />
                    </Flex>
                    <Heading size={'chakra_title_medium'}>{t('pengaturan_absen_modal_tentukan_anggota_judul')}</Heading>
                </ModalHeader>
                <ModalCloseButton 
                    onClick={on_close} 
                    right='24px'
                    top='24px'
                />
                <ModalBody
                    as={Flex}
                    gap={'24px'}
                    padding={'0px 24px'}
                    marginTop='24px'
                    height='50vh'
                    marginBottom='12px'
                >
                    <Flex
                        direction={'column'}
                        gap={'8px'}
                        width={'50%'}
                        position='relative'
                    >
                        <Flex 
                            direction='column' 
                            gap='8px'
                            backgroundColor='inherit'
                        >
                            <Heading size={'chakra_title_small'}>{t('pengaturan_absen_modal_tentukan_anggota_label')}</Heading>
                            <Heading size={'chakra_body_medium'}>{t('pengaturan_absen_modal_tentukan_anggota_sublabel')}</Heading>
                        </Flex>

                        <Flex marginY='4px'>
                            <Input  
                                {...headingTheme?.sizes?.chakra_label_large}
                                type={'search'}
                                value={search_value}
                                onChange={(e: any) => search_on_change(e.target.value)}
                                placeholder={t('pengaturan_absen_modal_tentukan_anggota_cari_anggota_pencarian')}
                            />
                        </Flex>

                        <Flex
                            direction={'column'}
                            gap={'12px'}
                            // height='calc(40vh - 12px)'
                            overflow='auto'
                        >
                            {initial_area.member_profile_id.map((member: any, index: number) => {
                                return (
                                    <Fragment key={index}>
                                        <Checkbox
                                            isChecked={member.checked}
                                            onChange={() => group_on_check(member)}
                                        >
                                            <Heading size='chakra_label_large' marginLeft='14px'>{member.display_name}</Heading>
                                        </Checkbox>
                                        <Flex
                                            direction={'column'}
                                            gap={'12px'}
                                        >
                                            {member.members.map((user: any, index: number) => {
                                                const MemberChooseMember = () => (
                                                    <Checkbox
                                                        key={index}
                                                        isChecked={user.checked}
                                                        gap={'16px'}
                                                        onChange={() => member_on_check(user)}
                                                    >
                                                        <Flex
                                                            gap={'12px'}
                                                            alignItems={'center'}
                                                            marginLeft='-12px'
                                                        >
                                                            <Avatar name={user.display_name} src={user?.photo_url} />
                                                            <Flex
                                                                direction={'column'}
                                                                gap={'4px'}
                                                            >
                                                                <Heading
                                                                    size='chakra_label_large'
                                                                    wordBreak={'break-word'}
                                                                >
                                                                    {user.display_name}
                                                                </Heading>
                                                                <Heading
                                                                    size='chakra_body_medium'
                                                                    wordBreak={'break-word'}
                                                                >
                                                                    {user.username}
                                                                </Heading>
                                                            </Flex>
                                                        </Flex>
                                                    </Checkbox>
                                                )
                                                if (search_value === '') {
                                                    return <MemberChooseMember />
                                                } else if (search_value !== '' && user.display_name.toLowerCase().includes(search_value)) {
                                                    return <MemberChooseMember />
                                                }
                                            })}
                                        </Flex>
                                    </Fragment>
                                )
                            })}
                        </Flex>
                    </Flex>
                    <Flex
                        width={'50%'}
                        direction={'column'}
                    >
                        {selected_members()}
                    </Flex>
                </ModalBody>
                <ModalFooter flexDirection='column' gap={'24px'} padding='0px 24px 24px'>
                    <Divider />
                    <Flex gap='16px' justifyContent='flex-end' marginLeft='auto'>
                        <Button
                            size={'normal'}
                            variant={'j-outline'}
                            onClick={on_back}
                            width='160px'
                        >
                            {t('pengaturan_anggota_live_tracking_kembali')}
                        </Button>

                        <Button
                            isDisabled={initial_area?.member_profile_id?.find((group: any) =>
                                group.members.some((member: any) => member.checked === true),
                            ) === undefined}
                            size={'normal'}
                            variant={'j-primary'}
                            onClick={on_next}
                            width='160px'
                        >
                            {t('pengaturan_absen_lanjut')}
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default DefineMember