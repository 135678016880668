import React from 'react'
import JGoogleIcon from '../../JGoogleIcon/JGoogleIcon'
import Submodule from './Submodule'
import NewFeatureTag from './NewFeatureTag'
import { useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import { 
    Accordion, 
    AccordionButton, 
    AccordionIcon, 
    AccordionItem, 
    AccordionPanel, 
    Heading,
    useColorMode, 
} from '@chakra-ui/react'

interface ModuleWrapperProps {
    menu: any
}

const ModuleWrapper = (props: ModuleWrapperProps) => {
    const { menu } = props

    const location = useLocation()
    const sidebar = useSelector((state: any) => state?.sidebar)
    const { colorMode } = useColorMode()
    const { data } = useSelector((state: any) => state.menuPrivilage)
    const teamV2: any = useSelector((state: any) => state?.teamV2)

    let redirects: any[] = []
    menu.submenu && menu.submenu.map((sub: any) => {
        return redirects.push(sub.to)
    })

    return (
        <Accordion defaultIndex={redirects.includes(location.pathname) ? [0] : []} allowMultiple>
            <AccordionItem 
                border='none'
                as={motion.div}
                initial={{ x: '-100%' }}
                animate={{ x: 0 }}
            >
                <AccordionButton
                    width='100%'
                    display='flex'
                    padding='12px 16px'
                    gap='16px'
                    alignItems='flex-start'
                    cursor='pointer'
                    color={colorMode === 'light'
                        ?   redirects.includes(location.pathname) ? '#FDFCFF' : '#FDFCFF'
                        :   redirects.includes(location.pathname) ? 'primary' : 'unset'
                    }
                >
                    <JGoogleIcon 
                        name={menu?.icon} 
                        color={colorMode === 'light'
                            ?   teamV2?.detail?.theme_object?.code === 'Grey-vis' ? 'black' : '#FDFCFF'
                            :   'inherit'
                        }
                    />
                    <Heading
                        size='chakra_title_medium'
                        lineHeight='150%'
                        fontWeight='500'
                        textAlign='left'
                        overflow='hidden'
                        whiteSpace='nowrap'
                        color={colorMode === 'light'
                            ?   teamV2?.detail?.theme_object?.code === 'Grey-vis' ? 'black' : '#FDFCFF'
                            :   'inherit'
                        }
                        display={sidebar?.isReveal ? 'block' : 'none'}
                    >{menu.name}</Heading>
                    {menu?.new && sidebar?.isReveal
                        ?   <NewFeatureTag />
                        :   null
                    }
                    <AccordionIcon 
                        marginLeft='auto' 
                        color={colorMode === 'light'
                            ?   teamV2?.detail?.theme_object?.code === 'Grey-vis' ? 'black' : '#FDFCFF'
                            :   '#FDFCFF'
                        } 
                        display={sidebar?.isReveal ? 'block' : 'none'}
                    />
                </AccordionButton>
                {sidebar?.isReveal
                    ?   <AccordionPanel 
                            marginTop='8px'
                            padding='0px 16px' 
                            backgroundColor={colorMode === 'light'
                                ?   redirects.includes(location.pathname) ? 'unset' : 'unset'
                                :   redirects.includes(location.pathname) ? '#212327' : 'unset'
                            }
                        >
                            {menu?.submenu?.map((submenu: any, submenuIndex: number) => {
                                const haveAccessSubmenu = data.find((x: any) => x.id === submenu?.access)
                                if (!submenu.hasOwnProperty('access') || haveAccessSubmenu === undefined) return
                                return <Submodule key={submenuIndex} submodule={submenu} />
                            })}
                        </AccordionPanel>
                    :   null
                }
            </AccordionItem>
        </Accordion>
    )
}

export default React.memo(ModuleWrapper)