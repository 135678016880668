import React from 'react'
import { Flex, Skeleton } from '@chakra-ui/react'

const SkeletonMemberItem: React.FC = () => {
    return (
        <Flex 
            gap='12px' 
            alignItems='center'
            width='full'
        >
            <Flex width='48px' height='48px'>
                <Skeleton 
                    width='48px' 
                    height='48px' 
                    rounded='full' 
                />
            </Flex>
            <Flex 
                flexDirection='column' 
                gap='8px' 
                width='full'
            >
                <Skeleton height='14px' width='75%' />
                <Skeleton height='12px' width='50%' />
            </Flex>
        </Flex>
    )
}

export default SkeletonMemberItem