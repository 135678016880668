import React, { 
    memo, 
    useEffect, 
    useState, 
} from 'react'
import style from './modalUploadTemplate.module.css'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import { JGoogleIcon, JToast } from '../../../components'
import { useMutation } from 'react-query'
import { uploadAttendanceLocationApi } from '../../../apis/attendanceSettingApi'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Heading,
    Flex,
    Button,
    Progress,
    Box,
    useToast,
} from '@chakra-ui/react'

interface IModalUploadTemplate {
    modalUploadTemplateDisclosure: any
    modalResultDisclosure: any
    setUpload: any
    setPropsFile: any
    refetchLiveTrackingData: any
    setMemberSettingAreas: any
}

const ModalUploadTemplate: React.FC<IModalUploadTemplate> = (props: IModalUploadTemplate) => {
    const { 
        modalUploadTemplateDisclosure, 
        modalResultDisclosure, 
        setUpload, 
        setPropsFile,
        refetchLiveTrackingData,
        setMemberSettingAreas,
    } = props

    const toast = useToast()
    const { t } = useTranslation()

    const [file, setFile] = useState<any>(null)
    const [progress, setProgress] = useState<any>(0)

    const { getRootProps,getInputProps } = useDropzone({
        accept: {
            '': ['.csv']
        }, 
        maxFiles: 1,
        multiple: false,
        onDropAccepted: (e: any) => {
            setFile(e[0])
        },
        onDropRejected: (e: any) => {
            alert('invalid file, only accept .csv file')
        },
    })

    const upload = useMutation(uploadAttendanceLocationApi, {
        onSuccess: (resp: any) => {
            setPropsFile(file)
            setUpload(resp)
            modalUploadTemplateDisclosure?.onClose()
            modalResultDisclosure?.onOpen()
            setMemberSettingAreas([])
            refetchLiveTrackingData?.mutate({
                profile_id: localStorage.getItem('current_team'),
                page: 1,
                search: '',
            })
            if (
                resp?.dulicate === 0 &&
                resp?.failed === 0 &&
                resp?.errors?.length === 0 &&
                resp?.success !== 0
            ) {
                toast({
                    position: 'top-right',
                    render: () => (
                        <JToast
                            type='success'
                            message={t('pengaturan_absen_toast_berhasil')}
                        />
                    ),
                    duration: 3000,
                    isClosable: true,
                })
            }
        }
    })

    useEffect(() => {
        if (modalUploadTemplateDisclosure?.isOpen === false) return
        setFile(null)
        setProgress(0)
    }, [modalUploadTemplateDisclosure?.isOpen])

    return (
        <Modal 
            isCentered
            isOpen={modalUploadTemplateDisclosure?.isOpen} 
            onClose={modalUploadTemplateDisclosure?.onClose}
            size='xl'
        >
            <ModalOverlay />
            <ModalContent backgroundColor='background' height='421px'>
                <ModalHeader>
                    <Heading size='chakra_title_large'>
                        {
                            upload?.isLoading
                                ?   t('pengaturan_absen_modal_unggah_lokasi_absen')
                                :   t('pengaturan_absen_modal_unggah_template_judul')
                        }
                    </Heading>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody padding='16px 24px'>
                    {
                        upload?.isLoading
                            ?   <Flex
                                    direction='column' 
                                    gap='16px' 
                                    height='100%'
                                >
                                    <Box
                                        marginTop='auto' 
                                        marginX='auto'
                                        width='75%'
                                    >
                                        <Heading 
                                            position='relative'
                                            size='chakra_label_medium' 
                                            color='background' 
                                            textAlign='center'
                                            marginBottom='-15.5px'
                                            zIndex={999}
                                        >{progress === 100 ? 99 : progress}%</Heading>
                                        <Progress 
                                            height='16px'
                                            rounded='full' 
                                            value={progress === 100 ? 99 : progress} 
                                        />
                                    </Box>
                                    <Heading 
                                        className={style.loading}
                                        size='chakra_label_large' 
                                        textAlign='center' 
                                        marginBottom='auto'
                                    >{t('pengaturan_absen_modal_unggah_button_mengunggah')}</Heading>
                                </Flex>
                            :   <Flex 
                                    direction='column' 
                                    gap='16px' 
                                    height='100%'
                                >
                                    <Flex
                                        {...getRootProps()}
                                        width='80%'
                                        height='170px'
                                        borderRadius='5px'
                                        borderWidth='1px'
                                        justifyContent='space-around'
                                        alignItems='center'
                                        cursor='pointer'
                                        borderColor='primary'
                                        marginX='auto'
                                    >
                                        <input {...getInputProps()} />
                                        <JGoogleIcon name='folder_open' />
                                        
                                    </Flex>
                                    <Heading size='chakra_body_medium' textAlign='center'>{t('pengaturan_absen_modal_unggah_template_deskripsi')}</Heading>
                                    {
                                        file &&
                                        <Heading size='chakra_body_medium'>{file?.name}</Heading>
                                    }
                                    <Button 
                                        variant='j-primary' 
                                        marginLeft='auto'
                                        marginTop='auto'
                                        onClick={() => {
                                            setProgress(0)
                                            upload?.mutate({
                                                file_csv: file,
                                                onUploadProgress: (event: any) => {
                                                    setProgress(Math.round((100 * event?.loaded) / event?.total));
                                                }
                                            })
                                        }}
                                    >
                                        {t('pengaturan_absen_modal_unggah_button_unggah')}
                                    </Button>
                                </Flex>
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default memo(ModalUploadTemplate)