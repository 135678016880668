import { Avatar, Checkbox, Flex, InputGroup, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Switch, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { JButton, JGoogleIcon, JInput, JToast, JTypography } from '../../../components'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'
import { useMutation, useQuery } from 'react-query'
import { apiMemberPrivilegeListMember, apiMemberPrivilegeMemberApply, apiMemberPrivilegeMemberApplyProps, apiMembersGet, apiMembersGetProps } from '../../../apis/memberListApi'

function ModalAksesMember(props: any) {
	const {
		disclosure,
		data,
		refetch,
		member
	} = props
	const { t } = useTranslation()
	const toast = useToast()
	const [searchbar, setSearchbar] = useState('')
	const [isCheck, setIsCheck] = useState(false)
	const [search] = useDebounce(searchbar, 500);

	const [configAksesMember, setConfigAksesMember] = useState<any>({
		member_id: "",
		member: []
	})
	const [configSearch, setConfigSearch] = useState<apiMembersGetProps>({
		search: ""
	})

	const memberList = useQuery("get-member", getMemberList)
	const memberPrivilageList = useQuery("get-member-privilage", getMemberPrivilageList, {
		onSuccess(data) {
			setConfigAksesMember((prev: any) => {
				return {
					...prev,
					member_id: member?.id,
					member: data.map((obj: any) => ({ id: obj.id })),
				}
			})
		},
	})
	const memberPrivilageApply = useMutation(apiMemberPrivilegeMemberApply)


	async function getMemberList() {
		return await apiMembersGet({ ...configSearch })
	}

	async function getMemberPrivilageList() {
		return await apiMemberPrivilegeListMember({ member_id: member?.id })
	}

	function handleCheckedMember(active: boolean) {
        setIsCheck(!isCheck)
		if (active) {
			setConfigAksesMember((prev: any) => {
				const member = memberList.data?.data.map((obj: any) => ({ id: obj.user.id }))
				return {
					...prev,
					member: member,
				}
			})
		} else {
			setConfigAksesMember({
				memeber_id: configAksesMember.member_id,
				member: [],
			}
			)
		}
	}

	function handleSwitch(e: any) {
		const newConfigAksesMember = { ...configAksesMember }
		if (configAksesMember[e.target.name].some((list: any) => list.id === parseInt(e.target.id))) {
			newConfigAksesMember[e.target.name] = newConfigAksesMember[e.target.name].filter((list: any) => list.id !== parseInt(e.target.id))

		} else {
			newConfigAksesMember[e.target.name] = [...newConfigAksesMember[e.target.name], { id: parseInt(e.target.id) }]
		}
		setConfigAksesMember(newConfigAksesMember)
	}

	function handleClose() {
        setIsCheck(false)
		setSearchbar('')
        setConfigAksesMember({
            member_id: "",
            member: [],
        })
    }

	function handleSave() {
        memberPrivilageApply.mutate({
            member_id: member?.id,
            member: JSON.stringify({ ...configAksesMember.member }),
            toast: toaster
        })
        setIsCheck(false)
        disclosure.onClose()
    }

	function toaster(code: string, msg: string) {
        toast({
            position: 'top-right',
            render: () => (
                <JToast
                    type={code === "200" ? 'success' : 'error'}
                    message={t(msg)}
                />
            ),
            duration: 1500,
            isClosable: true,
        })
    }

	useEffect(() => {
		setConfigSearch({
			search: search
		})
	}, [search])

	useEffect(() => {
		memberList.refetch()
	}, [configSearch])

	useEffect(() => {
		memberPrivilageList.refetch()
	}, [member])

	return (
		<Modal isOpen={disclosure.isOpen} onClose={() => { disclosure.onClose() }} size='md' onCloseComplete={handleClose}>
			<ModalOverlay />
			<ModalContent
				borderRadius={'16px'}
				backgroundColor={"background"}
				padding={"32px 24px"}
			>
				<ModalHeader p={'0'}>
					<JTypography size="title-large">{`${t('master_anggota_akses_edit')} ${t('master_anggota_akses_anggota')}`}</JTypography>
				</ModalHeader>
				<ModalCloseButton top={"16px"} right={"24px"} />
				<ModalBody p={'0'}>
					<Flex align='center' gap='12px' mt={'32px'} mb={'24px'}>
						<JTypography size='body-small'>{t("master_anggota_akses_menu_for")}</JTypography>
						<Flex align='center' gap='6px'>
							<Avatar
								height={'34px'}
								width={'34px'}
								name={member?.name}
								src={member?.photo}
							/>
							<JTypography size='label-large'>{member?.name}</JTypography>
						</Flex>
					</Flex>
					<Flex w='100%' align='center' justify='space-between' >
						<InputGroup width="max-content">
							<JInput
								type="search"
								leftIcon={<JGoogleIcon name='search' />}
								width="max-content"
								placeholder={t("master_anggota_akses_anggota_search")}
								onChange={(e: any) => setSearchbar(e.target.value)}
							/>
						</InputGroup>
					</Flex>
					<Flex gap='16px' my={'24px'}>
						<JTypography size='title-medium'>{t('master_anggota_akses_anggota_pilih_semua')}</JTypography>
						<Checkbox isChecked={isCheck} onChange={(e: any) => handleCheckedMember(e.target.checked)}></Checkbox>
					</Flex>
					<Flex flexDir='column' gap='16px' h='240px' overflow='hidden' overflowY='auto'>
						{memberList && memberList.data?.data.map((list: any, i: number) => {
							return (
								<Flex key={i} justify='space-between' align='center'>
									<Flex gap='24px'>
										<Avatar
											height={'48px'}
											width={'48px'}
											name={list?.user.display_name}
											src={list?.user.photo_url}
										/>
										<Flex flexDir='column' justify='center' gap='6px'>
											<JTypography size='title-medium'>{list?.user.display_name}</JTypography>
											<JTypography size='body-medium'>{list?.user.username}</JTypography>
										</Flex>
									</Flex>
									<Switch id={list.user.id} name='member'
										isChecked={configAksesMember.member?.find((data: any) => data.id == list?.user.id)}
										onClickCapture={(e: any) => handleSwitch(e)}
									/>
								</Flex>
							)
						})}
					</Flex>
				</ModalBody>
				<ModalFooter
					mt='24px'
					gap={"12px"}
					padding={"0px"}
					justifyContent={"center"}
				>
					<Flex direction={'row'}>
						<JButton
							size="normal"
							variant="j-primary"
							type='submit'
							mx={4} px={84}
							onClick={handleSave}
						>
							<JTypography size='label-large'>{t("master_anggota_akses_simpan")}</JTypography>
						</JButton>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalAksesMember