import {
    Divider,
    Flex,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Switch,
    useToast,
    Avatar,
    Skeleton,
    Center,
    Heading,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button
} from '@chakra-ui/react'
import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete'
import {
    AttendanceSetting_Create_AttendanceLocation_ApiProps,
} from '../../apis/attendanceSettingApi'
import {
    JButton,
    JGoogleIcon,
    JInput,
    JTypography,
} from '../../components'
import JToast from '../../components/JToast/JToast'
import { useMutation } from 'react-query'
import {
    useAttendaceSetting_get_attendanceLocationList,
    useAttendaceSetting_get_memberList,
    useAttendaceSetting_get_configuration,
    useAttendaceSetting_update_configuration,
    useAttendaceSetting_update_status,
    useAttendaceSetting_update_attendanceLocationStatus,
    useAttendaceSetting_create_location,
    useAttendaceSetting_delete_location,
    useAttendaceSetting_update_location,
} from '../../query'
import { 
    Configuration, 
    useAttendaceSetting_update_autoAbsentOut 
} from '../../query/attendanceSettingQuery'
import Area from './components/Area'
import { 
    AttendanceMandatory as AttendanceMandatoryModal,
    WorkingHours as WorkingHoursModal,
    AttendanceLocation as AttendanceLocationModal,
    LocationStatus as LocationStatusModal,
    DeleteLocation as DeleteLocationModal,
    SpecifyLocation as SpecifyLocationModal,
    DefineMember as DefineMemberModal,
    LocationInformation as LocationInformationModal,
} from './modals'
import { format } from 'date-fns'
import Details from './modals/Details'
import InfiniteScroll from 'react-infinite-scroll-component'
import './InfiniteScroll.css'
import {attendanceSetting_get_attendanceLocationListApi, teamDetailApi} from '../../apis'
import AutoAbsentOut from './components/AutoAbsentOut'
import UploadTemplate from './components/UploadTemplate'
import ModalUploadTemplate from './components/ModalUploadTemplate'
import ModalResult from './components/ModalResult'
const AttendanceSetting = () => {
    const { t } = useTranslation()
    const toast = useToast()
    const ref: any = useRef();
    const [scrollFlag, setScrollFlag] = useState(false)
    const [searchPlace, setSearchPlace] = useState('')
    const [searchChooseMember, setSearchChooseMember] = useState('')
    const [searchArea, setSearchArea] = useState('')
    const [errorLabel, setErrorLabel] = useState(false)
    const [area, setArea] = useState<any>(null)
    const [isUpdateFlow, setIsUpdateFlow] = useState<any>(null)
    const [modalToggleWajibAbsen, setModalToggleWajibAbsen] = useState(false)
    const [modalToggleJamKerja, setModalToggleJamKerja] = useState(false)
    const [modalToggleLokasiAbsen, setModalToggleLokasiAbsen] = useState(false)
    const [modalToggleLokasiAbsenStatus, setModalToggleLokasiAbsenStatus] = useState(false)
    const [modalToggleDeleteLocation, setModalToggleDeleteLocation] = useState(false)
    const [modalDetail, setModalDetail] = useState(false)
    const [modalCariLokasi, setModalCariLokasi] = useState(false)
    const [modalTentukanAnggota, setModalTentukanAnggota] = useState(false)
    const [modalLabelLokasi, setModalLabelLokasi] = useState(false)
    const modalUploadTemplateDisclosure = useDisclosure();
    const modalResultDisclosure = useDisclosure();
    const modalCancelAttendanceAreaDisclosure = useDisclosure()
    const [file, setFile] = useState<any>(null);
    const [upload, setUpload] = useState<any>(null);
    const [configuration, setConfiguration] = useState<Configuration>({
        is_absence_strict_location: 0,
        is_absence_mandatory_for_check_in: 0,
        is_absence_strict_time: 0,
        limit_clock_in: '',
        limit_clock_out: '',
        // is_auto_absence_out_active: null,
        // auto_absence_out_time: null,
        // auto_absence_out_reminder_time: null,
    })
    const [initialArea, setInitialArea] = useState<AttendanceSetting_Create_AttendanceLocation_ApiProps>({
        label: '',
        radius: 50,
        latitude: -6.2213866,
        longitude: 106.7796252,
        address: '',
        member_profile_id: [],
    })
    const [memberSettingAreas, setMemberSettingAreas] = useState<any>([])
    const [configGetLiveTrackingList, setConfigGetLiveTrackingList] = useState<any>({
        page: 1,
        search: '',
        profile_id: localStorage.getItem('current_team'),
    })
    const {
        data: attendanceSettingMemberList,
        refetch: refetchGetMemberSettingMemberList,
        isFetching: fetchingGetMemberSettingMemberList
    } = useAttendaceSetting_get_memberList()

    // const {
    //     data: attendanceSettingAttendanceLocationList,
    //     refetch: getAttendanceSettingAttendanceLocationList,
    //     isFetching: fetchingAttendanceSettingAttendanceLocationList
    // }: any = useAttendaceSetting_get_attendanceLocationList(configGetLiveTrackingList, setMemberSettingAreas)

    const {
        data: attendanceSettingConfiguration,
        isFetching: fetchingAttendanceSettingConfiguration,
        refetch: getAttendanceSettingConfiguration,
    } = useAttendaceSetting_get_configuration()

    const {
        refetch: updateAttendanceSettingStatus,
        isFetching: fetchingUpdateAttendanceSettingStatus,
    } = useAttendaceSetting_update_status({
        is_absence_strict_location: configuration.is_absence_strict_location === 1 ? 0 : 1
    })

    const {
        refetch: updateAttendanceSettingMandatoryCheckIn,
        isFetching: fetchingUpdateAttendanceSettingMandatoryCheckIn,
    } = useAttendaceSetting_update_configuration({
        ...configuration,
        is_absence_mandatory_for_check_in: configuration.is_absence_mandatory_for_check_in === 1 ? 0 : 1,
    })

    const {
        refetch: updateAttendanceSettingStrictTime,
        isFetching: fetchingUpdateAttendanceSettingStrictTime,
    } = useAttendaceSetting_update_configuration({
        ...configuration,
        is_absence_strict_time: configuration.is_absence_strict_time === 1 ? 0 : 1,
    })

    const {
        refetch: updateAttendanceSettingLimitClock,
        isFetching: fetchingUpdateAttendanceSettingLimitClock,
    } = useAttendaceSetting_update_configuration({
        ...configuration,
    })

    // const {
    //     refetch: updateAttendanceSettingAutoAbsentOut,
    //     isFetching: fetchingUpdateAttendanceSettingAutoAbsentOut,
    // } = useAttendaceSetting_update_autoAbsentOut({
    //     ...configuration,
    // })

    const {
        refetch: updateAttendanceSettingLocationStatus,
        isFetching: fetchingUpdateAttendanceSettingLocationStatus,
    } = useAttendaceSetting_update_attendanceLocationStatus({
        absence_location_id: area?.id,
        is_active: area?.is_active === 0 ? 1 : 0
    })

    const {
        refetch: createAttendanceSettingLocation,
        isFetching: fetchingCreateAttendanceSettingLocation,
    } = useAttendaceSetting_create_location(initialArea)

    const {
        refetch: deleteAttendanceSettingLocation,
        isFetching: fetchingDeleteAttendanceSettingLocation,
    } = useAttendaceSetting_delete_location(area)

    const {
        refetch: updateAttendanceSettingLocation,
        isFetching: fetchingUpdateAttendanceSettingLocation
    } = useAttendaceSetting_update_location({
        ...initialArea,
        absence_location_id: isUpdateFlow?.id
    })

    const teamDetail = useMutation(teamDetailApi)

    function handleSelectMember(user: any) {
        const userId = user.id
        const tempMembers: any[] = []
        initialArea.member_profile_id.forEach((group: any) => {
            group.members.forEach((member: any) => {
                if (member.id === userId) {
                    member.checked = !member.checked
                }
            })
            const isUncheckExist = group.members.find((member: any) => member.checked === false) !== undefined
            group.checked = group.members.length === 0 ? false : isUncheckExist ? false : true
            tempMembers.push(group)
        })
        setInitialArea((prev: any) => {
            return {
                ...prev,
                member_profile_id: tempMembers
            }
        })
    }
    const handleChangeSearch = (event: any) => {
        if(event.key === 'Enter'){
            setMemberSettingAreas([])
            setConfigGetLiveTrackingList( (prev:any)=> {
                return{
                    ...prev,
                    search: event.target.value,
                    page: 1
                }
            })
            setTimeout(() => {
                mutationLiveTrackingArea?.mutate({
                    profile_id: configGetLiveTrackingList.profile_id,
                    page: 1,
                    search: event.target.value,
                })
            }, 1);
        }
    }
    function testScroll(){
        if(ref?.current?.lastScrollTop > 100 && !scrollFlag){
            window.scrollTo({top: 200, behavior: 'smooth'})
            setScrollFlag(true)
        } else if (ref?.current?.lastScrollTop < 100 && scrollFlag){
            window.scrollTo({top: 0, behavior: 'smooth'})
            setScrollFlag(false)
        }
    } 
    function handleSelectGroupMember(group: any) {
        const groupCheck = group.checked
        const groupMember = group.members.map((member: any) => member.id)
        const tempMembers: any = []
        initialArea.member_profile_id.forEach((group: any) => {
            group.members.forEach((member: any) => {
                if (groupMember.includes(member.id)) {
                    member.checked = !groupCheck
                }
            })
            const isUncheckExist = group.members.find((member: any) => member.checked === false) !== undefined
            group.checked = group.members.length === 0 ? false : isUncheckExist ? false : true
            tempMembers.push(group)
        })
        setInitialArea((prev: any) => {
            return {
                ...prev,
                member_profile_id: tempMembers
            }
        })
    }
    function countGroupMembers() {
        const selectedGroups: any = []
        attendanceSettingMemberList?.forEach((group: any) => {
            group.members.find((member: any) => member.checked === true) !== undefined && selectedGroups.push(group)
        });
        return selectedGroups.length;
    }
    function handleDeleteMember(member: any) {
        const memberId = member?.id
        const tempMembers: any = []
        initialArea.member_profile_id.forEach((group: any) => {
            group.members.forEach((member: any) => {
                if (member.id === memberId) {
                    member.checked = !member.checked
                }
            })
            const isUncheckExist = group.members.find((member: any) => member.checked === false) !== undefined
            group.checked = group.members.length === 0 ? false : isUncheckExist ? false : true
            tempMembers.push(group)
        })
        setInitialArea((prev: any) => {
            return {
                ...prev,
                member_profile_id: tempMembers
            }
        })
    }

    function RenderSelectedMembers() {
        const selectedMembers: any = []
        initialArea.member_profile_id.forEach((group: any) => {
            group.members.forEach((member: any) => {
                if (member.checked === true) {
                    if (selectedMembers.find((m: any) => m.id === member.id) === undefined) selectedMembers.push(member)
                }
            })
        })

        return (
            <Flex
                direction={'column'}
                gap={'16px'}
                height='100%'
            >
                <Flex direction='column' gap='8px'>
                    <Heading size={'chakra_title_small'}>{t('pengaturan_anggota_anggota_terdaftar')}</Heading>
                    <Heading size={'chakra_body_medium'}>{t('pengaturan_anggota_anggota_terdaftar_sublabel')}</Heading>
                </Flex>
                <Flex
                    direction={'column'}
                    gap={'16px'}
                    overflowY='auto'
                >
                    {selectedMembers.map((member: any) => (
                        <Flex
                            key={member.id}
                            gap={'16px'}
                            alignItems={'center'}
                        >
                            <Avatar size='sm' name={member.display_name} src={member.photo_url} />
                            <Flex
                                direction={'column'}
                                gap={'4px'}
                            >
                                <Heading
                                    size='chakra_label_large'
                                    wordBreak={'break-word'}
                                >
                                    {member.display_name}
                                </Heading>
                                <Heading
                                    size='chakra_body_medium'
                                    wordBreak={'break-word'}
                                >
                                    {member.username}
                                </Heading>
                            </Flex>
                            <JGoogleIcon
                                name='delete'
                                cursor={'pointer'}
                                onClick={() => handleDeleteMember(member)}
                                marginLeft='auto'
                            />
                        </Flex>
                    ))}
                </Flex>
            </Flex>
        )
    }

    function RenderSelectedChooseMember() {
        const selectedMembers: any = []
        initialArea.member_profile_id.map((group: any) => {
            group.members.map((member: any) => {
                if (member.checked && selectedMembers.find((sm: any) => sm.id === member.id) === undefined) selectedMembers.push(member)
            })
        })
        return (
            <Flex alignItems={'center'}>
                {selectedMembers.map((member: any, index: number) => {
                    if (index < 3)
                        return (
                            <Avatar
                                size='sm'
                                width={'24px'}
                                height={'24px'}
                                key={index}
                                name={member.display_name}
                                src={member.photo_url}
                                marginLeft={index > 0 ? '-6px' : '0px'}
                            />
                        )
                })}
                <Heading
                    size={'chakra_body_medium'}
                    marginLeft={'12px'}
                >
                    {t('pengaturan_absen_area_anggota_ditetepankan', {total: selectedMembers.length})}
                </Heading>
            </Flex>
        )
    }

    async function handleCreateLocation() {
        // await getAttendanceSettingAttendanceLocationList()
        if(initialArea?.label?.length > 0){
            await createAttendanceSettingLocation()
            setModalLabelLokasi(false)
            setMemberSettingAreas([])
            setConfigGetLiveTrackingList( (prev:any)=> {
                return{
                    ...prev,
                    search: '',
                }
            })
            setTimeout(() => {
                mutationLiveTrackingArea?.mutate({
                    profile_id: configGetLiveTrackingList.profile_id,
                    page: 1,
                    search: '',
                })
            }, 1)
            toast({
                position: 'top-right',
                render: () => (
                    <JToast
                        type={'success'}
                        message={t('pengaturan_absen_sukses_tambahkan_lokasi_absen_area')}
                    />
                ),
                duration: 3000,
                isClosable: true,
            })
        } else {
            setErrorLabel(true)
        }
    }

    function handlePrepareaUpdate(area: any) {
        const tempMembers = attendanceSettingMemberList
        tempMembers.map((group: any) => {
            group.members.map((member: any) => {
                if (area.members.find((m: any) => m.id === member.id)) {
                    member.checked = true
                } else {
                    member.checked = false
                }
            })
            if (group.members.find((m: any) => m.checked === false) === undefined) {
                group.checked = true
            } else {
                group.checked = false
            }
        })

        setInitialArea({
            address: area.address,
            label: area.label,
            latitude: area.latitude,
            longitude: area.longitude,
            member_profile_id: tempMembers,
            radius: area.radius,
        })
        setIsUpdateFlow(area)
        setSearchPlace(area.address)
        setSearchChooseMember('')
        setModalCariLokasi(true)
    }
    function handlePrepareDetail(area: any) {
        // refetchGetMemberSettingMemberList()
        const tempMembers = attendanceSettingMemberList
        //countGroup
        tempMembers.map((group: any) => {
            group.members.map((member: any) => {
                if (area.members.find((m: any) => m.id === member.id)) {
                    member.checked = true
                } else {
                    member.checked = false
                }
            })
            if (group.members.find((m: any) => m.checked === true) === undefined) {
                group.checked = false
            } else {
                group.checked = true
            }
        })
        setInitialArea({
            address: area.address,
            label: area.label,
            latitude: area.latitude,
            longitude: area.longitude,
            member_profile_id: tempMembers,
            radius: area.radius,
        })
        setIsUpdateFlow(area)
        setSearchPlace(area.address)
        setSearchChooseMember('')
        setModalDetail(true)
    }

    async function handleUpdateLocation() {
        if(initialArea?.label.length > 0){
            await updateAttendanceSettingLocation()
            // await getAttendanceSettingAttendanceLocationList()
            setModalLabelLokasi(false)
            setMemberSettingAreas([])
            setConfigGetLiveTrackingList( (prev:any)=> {
                return{
                    ...prev,
                    search: '',
                }
            })
            setTimeout(() => {
                mutationLiveTrackingArea?.mutate({
                    profile_id: configGetLiveTrackingList.profile_id,
                    page: 1,
                    search: '',
                })
            }, 1)
            toast({
                position: 'top-right',
                render: () => (
                    <JToast
                        type={'success'}
                        message={t('pengaturan_absen_sukses_update_lokasi_absen_area')}
                    />
                ),
                duration: 3000,
                isClosable: true,
            })
        } else {
            setErrorLabel(true)
        }
    }

    async function submitAttendanceMandatoryModal() {
        await updateAttendanceSettingMandatoryCheckIn()
        await getAttendanceSettingConfiguration()
        setModalToggleWajibAbsen(false)
    }

    async function submitWorkingHoursModal() {
        await updateAttendanceSettingStrictTime()
        await getAttendanceSettingConfiguration()
        setModalToggleJamKerja(false)
        toast({
            position: 'top-right',
            render: () => (
                <JToast
                    type={'success'}
                    message={t(
                        configuration.is_absence_strict_time === 1
                            ? 'pengaturan_absen_otomatis_working_hours_success_off'
                            : 'pengaturan_absen_otomatis_working_hours_success_on'
                    )}
                />
            ),
            duration: 3000,
            isClosable: true,
        })
    }

    async function submitAttendanceLocationModal() {
        await updateAttendanceSettingStatus()
        await getAttendanceSettingConfiguration()
        setModalToggleLokasiAbsen(false)
        toast({
            position: 'top-right',
            render: () => (
                <JToast
                    type={'success'}
                    message={t(
                        configuration.is_absence_strict_location
                            ? 'pengaturan_absen_sukses_matikan_lokasi_absen'
                            : 'pengaturan_absen_sukses_nyalakan_lokasi_absen'
                    )}
                />
            ),
            duration: 3000,
            isClosable: true,
        })
    }

    async function submitLocationStatusModal() {
        // await updateAttendanceSettingStatus()
        // await getAttendanceSettingConfiguration()

        await updateAttendanceSettingLocationStatus()
        // await getAttendanceSettingAttendanceLocationList()
        setModalToggleLokasiAbsenStatus(false)
        setMemberSettingAreas([])
        await mutationLiveTrackingArea?.mutate({
            profile_id: configGetLiveTrackingList.profile_id,
            page: 1,
            search: '',
        })
        setConfigGetLiveTrackingList((prev: any) => {
            return {
                ...prev,
                search: ''
            }
        })
        toast({
            position: 'top-right',
            render: () => (
                <JToast
                    type={'success'}
                    message={t(
                        configuration.is_absence_strict_location
                            ? 'pengaturan_absen_sukses_matikan_lokasi_absen'
                            : 'pengaturan_absen_sukses_nyalakan_lokasi_absen'
                    )}
                />
            ),
            duration: 3000,
            isClosable: true,
        })
    }
  
    async function submitDeleteLocationModal() {
        await deleteAttendanceSettingLocation()
        // await getAttendanceSettingAttendanceLocationList()
        setModalToggleDeleteLocation(false)
        setMemberSettingAreas([])
        setConfigGetLiveTrackingList( (prev:any)=> {
            return{
                ...prev,
                search: '',
            }
        })
        setTimeout(() => {
            mutationLiveTrackingArea?.mutate({
                profile_id: configGetLiveTrackingList.profile_id,
                page: 1,
                search: '',
            })
        }, 1)
        toast({
            position: 'top-right',
            render: () => (
                <JToast
                    type={'success'}
                    message={t('pengaturan_absen_sukses_hapus_lokasi_absen_area')}
                />
            ),
            duration: 3000,
            isClosable: true,
        })
    }

    function onNextSpecifyLocationModal() {
        setModalCariLokasi(false)
        setModalTentukanAnggota(true)
    }

    async function placesOnSelectSpecifyLocationModal(e: any) {
        const results = await geocodeByAddress(e)
        const maxAddressComponents = results.reduce((maxObj: any, currentObj: any) => {
            if (
                Array.isArray(currentObj?.address_components) && 
                currentObj?.address_components?.length > (maxObj?.address_components ? maxObj?.address_components?.length : 0)
            ) {
                return currentObj;
            }
            return maxObj;
        }, {})
        const latLng = await getLatLng(maxAddressComponents)
        setInitialArea((prev: any) => {
            return {
                ...prev,
                latitude: latLng.lat,
                longitude: latLng.lng,
                address: e,
            }
        })
        setSearchPlace(e)
    }

    function onBackDefineMember() {
        setModalTentukanAnggota(false)
        setModalCariLokasi(true)
    }

    function onNextDefineMember() {
        setModalTentukanAnggota(false)
        setModalLabelLokasi(true)
    }

    function onChangeLabelLocation(e: any) {
        setErrorLabel(false)
        setInitialArea((prev: any) => {
            return {
                ...prev,
                label: e.target.value,
            }
        })
    }

    function onBackLocationInformation() {
        setModalLabelLokasi(false)
        setModalTentukanAnggota(true)
    }

    const mutationLiveTrackingArea: any = useMutation(attendanceSetting_get_attendanceLocationListApi, {
        onSuccess: (resp) => {
            setMemberSettingAreas((prev: any[]) => {
                return [
                    ...prev,
                    ...resp?.data
                ]
            })
        }
    })

    useEffect(() => {
        if (attendanceSettingConfiguration) setConfiguration(attendanceSettingConfiguration)
        if (attendanceSettingMemberList) {
            setInitialArea((prev: any) => {
                return {
                    ...prev,
                    member_profile_id: attendanceSettingMemberList
                }
            })
        }
    }, [attendanceSettingConfiguration, attendanceSettingMemberList])

    useEffect(() => {
        teamDetail?.mutate()
        mutationLiveTrackingArea?.mutate({
            profile_id: configGetLiveTrackingList.profile_id,
            page: configGetLiveTrackingList.page,
            search: configGetLiveTrackingList.search,
        })
    }, [])

    return (
        <Flex
            direction={'column'}
            gap={'24px'}
            width={'100%'}
        >
            <JTypography size='title-large'>
                {t('pengaturan_absensi_judul')}
            </JTypography>
            <Flex
                padding={'24px 16px'}
                borderRadius={'16px'}
                backgroundColor={'surfacePlus3'}
                gap={'24px'}
                width={'100%'}
            >
                <Tabs width={'100%'}>
                    <Flex gap={'24px'}>
                        <TabList
                            flexDirection={'column'}
                            width={'55%'}
                            border={'none'}
                        >
                            <Tab
                                as={Flex}
                                padding={'14px 16px'}
                                width={'100%'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                _selected={{
                                    borderLeftColor: 'primary',
                                    borderLeftWidth: '2px',
                                }}
                                cursor={'pointer'}
                            >
                                <JTypography size={'label-large'}>{t('pengaturan_absensi_umum')}</JTypography>
                                <JGoogleIcon name={'navigate_next'} />
                            </Tab>
                            <Tab
                                as={Flex}
                                padding={'14px 16px'}
                                width={'100%'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                _selected={{
                                    borderLeftColor: 'primary',
                                    borderLeftWidth: '2px',
                                }}
                                cursor={'pointer'}
                            >
                                <JTypography size={'label-large'}>{t('pengaturan_absensi_lokasi_absen')}</JTypography>
                                <Flex
                                    gap={'16px'}
                                    alignItems={'center'}
                                >
                                    {fetchingAttendanceSettingConfiguration ? 
                                        <Skeleton
                                            height={'14px'}
                                            width={'35px'}
                                        /> : 
                                        <JTypography size={'title-small'}>{configuration?.is_absence_strict_location === 1 ? t('pengaturan_absensi_lokasi_absen_aktif') :  t('pengaturan_absensi_lokasi_absen_tidak_aktif')}</JTypography>
                                    }
                                    <JGoogleIcon name={'navigate_next'} />
                                </Flex>
                            </Tab>
                        </TabList>

                        <TabPanels
                            borderLeftColor={'outline'}
                            borderLeftWidth={'1px'}
                            paddingLeft={'24px'}
                        >
                            {/* tab general */}
                            <TabPanel padding={'0px'}>
                                <JTypography size={'title-medium'}>{t('pengaturan_absensi_umum')}</JTypography>
                                <Flex
                                    marginTop={'38.5px'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                >
                                    <JTypography size={'title-medium'}>{t('pengaturan_absensi_wajib_absen')}</JTypography>
                                    <Switch
                                        isChecked={configuration?.is_absence_mandatory_for_check_in === 1
                                            ? true
                                            : false
                                        }
                                        onClickCapture={() => setModalToggleWajibAbsen(true)}
                                    />
                                </Flex>
                                <JTypography size={'body-medium'} marginTop={'22.5px'}>{t('pengaturan_absensi_lokasi_absen_subjudul')}</JTypography>
                                <Divider marginTop={'24px'} borderColor={'outline'} />
                                <Flex
                                    marginTop={'30.5px'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                >
                                    <JTypography size={'title-medium'}>{t('pengaturan_absensi_jam_kerja')}</JTypography>
                                    <Switch
                                        isChecked={configuration?.is_absence_strict_time === 1
                                            ? true
                                            : false
                                        }
                                        onClickCapture={() => setModalToggleJamKerja(true)}
                                    />
                                </Flex>
                                <JTypography size={'body-medium'} marginTop={'22.5px'}>{t('pengaturan_absensi_jam_kerja_sublabel')}</JTypography>
                                <Flex
                                    marginTop={'16px'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                >
                                    <JTypography size={'title-small'}>{t('pengaturan_absensi_batas_absen_masuk')}</JTypography>
                                    <JInput
                                        type={'time'}
                                        wrapperWidth={'min-content'}
                                        defaultValue={'00:00'}
                                        value={configuration?.limit_clock_in ? format(new Date(`${format(new Date(), 'MMMM d, yyyy')}, ${configuration?.limit_clock_in}`), 'HH:mm') : '00:00'}
                                        step={60}
                                        onChange={(e: any) => {
                                            setConfiguration((prev: any) => {
                                                return {
                                                    ...prev,
                                                    limit_clock_in: e.target.value
                                                }
                                            })
                                        }}
                                    />
                                </Flex>
                                <Divider marginY='16px' borderColor={'outline'} />
                                <Flex
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                >
                                    <JTypography size={'title-small'}>{t('pengaturan_absensi_batas_absen_keluar')}</JTypography>
                                    <JInput
                                        type={'time'}
                                        wrapperWidth={'min-content'}
                                        value={configuration?.limit_clock_out ? format(new Date(`${format(new Date(), 'MMMM d, yyyy')}, ${configuration?.limit_clock_out}`), 'HH:mm') : '00:00'}
                                        defaultValue={'00:00'}
                                        step={60}
                                        onChange={(e: any) => {
                                            setConfiguration((prev: any) => {
                                                return {
                                                    ...prev,
                                                    limit_clock_out: e.target.value
                                                }
                                            })
                                        }}
                                    />
                                </Flex>
                                {/* <AutoAbsentOut 
                                    data={configuration} 
                                    setData={setConfiguration} 
                                    update={updateAttendanceSettingLimitClock}
                                    isUpdating={fetchingUpdateAttendanceSettingLimitClock}
                                    updateAutoAbsentOut={updateAttendanceSettingAutoAbsentOut}
                                    isUpdatingAutoAbsentOut={fetchingUpdateAttendanceSettingAutoAbsentOut}
                                    refetch={getAttendanceSettingConfiguration}
                                /> */}

                                {
                                    attendanceSettingConfiguration?.limit_clock_in !== configuration.limit_clock_in ||
                                    attendanceSettingConfiguration?.limit_clock_out !== configuration.limit_clock_out
                                    // attendanceSettingConfiguration?.auto_absence_out_time !== configuration.auto_absence_out_time ||
                                    // attendanceSettingConfiguration?.auto_absence_out_reminder_time !== configuration.auto_absence_out_reminder_time
                                        ? (
                                            <Flex width={'100%'}>
                                                <JButton
                                                    marginTop={'32px'}
                                                    size={'normal'}
                                                    variant={'j-primary'}
                                                    marginX={'auto'}
                                                    onClick={async () => {
                                                        await updateAttendanceSettingLimitClock()
                                                        await getAttendanceSettingConfiguration()
                                                    }}
                                                    isDisabled={fetchingGetMemberSettingMemberList || fetchingUpdateAttendanceSettingStatus || fetchingUpdateAttendanceSettingStatus}
                                                    isLoading={fetchingUpdateAttendanceSettingLimitClock}
                                                >
                                                    {t('pengaturan_absen_simpan')}
                                                </JButton>
                                            </Flex>
                                        )
                                        : null
                                }
                            </TabPanel>

                            {/* tab attendance location */}
                            <TabPanel
                                as={Flex}
                                flexDirection={'column'}
                                padding={'0px'}
                                gap={'24px'}
                                minHeight={'calc(100vh - var(--headerHeight) - 32px - 24px - 33px - 33px)'}
                            >
                                <Flex justifyContent={'space-between'} alignItems={'center'}>
                                    <JTypography size={'title-medium'}>{t('pengaturan_absensi_lokasi_absen')}</JTypography>
                                    <Switch
                                        isChecked={configuration?.is_absence_strict_location === 1 ? true : false}
                                        onClickCapture={() => setModalToggleLokasiAbsen(true)}
                                    />
                                </Flex>
                                <JTypography size={'body-medium'} marginTop={'-15px'}>{t('pengaturan_absensi_lokasi_absen_subjudul')}</JTypography>
                                <UploadTemplate modalUploadTemplateDisclosure={modalUploadTemplateDisclosure} />
                                <Divider borderColor={'outline'} />
                                <Flex justifyContent={'space-between'} alignItems={'center'}>
                                    <JTypography size={'label-large'}>{t('pengaturan_absensi_daftar_lokasi_absen')}</JTypography>
                                    <JButton
                                        variant={'j-primary'}
                                        size={'normal'}
                                        leftIcon={<JGoogleIcon name={'add'} />}
                                        isDisabled={fetchingAttendanceSettingConfiguration || configuration?.is_absence_strict_location === 0 || fetchingGetMemberSettingMemberList || fetchingUpdateAttendanceSettingStatus || fetchingUpdateAttendanceSettingStatus}
                                        onClick={() => {
                                            const tempMembers = attendanceSettingMemberList
                                            tempMembers.map((group: any) => {
                                                group.checked = false
                                                group.members.map((member: any) => {
                                                    member.checked = false
                                                })
                                            })
                                            setSearchPlace('')
                                            setSearchChooseMember('')
                                            setIsUpdateFlow(null)
                                            setInitialArea((prev: any) => {
                                                return {
                                                    ...prev,
                                                    label: '',
                                                    radius: 50,
                                                    latitude: -6.2213866,
                                                    longitude: 106.7796252,
                                                    address: '',
                                                    member_profile_id: tempMembers,
                                                }
                                            })
                                            setModalCariLokasi(true)
                                        }}
                                    >
                                        <JTypography size={'label-large'}>{t('pengaturan_absensi_tambah_lokasi_absens')}</JTypography>
                                    </JButton>
                                </Flex>
                                <JInput
                                    type={'search'}
                                    cursor={configuration?.is_absence_strict_location === 1 ? 'cursor' : 'not-allowed'} 
                                    isDisabled={configuration?.is_absence_strict_location !== 1}
                                    opacity={configuration?.is_absence_strict_location === 1 ? 1 : 0.5}
                                    placeholder={t('pengaturan_absensi_pencarian_lokasi_absen')}
                                    leftIcon={<JGoogleIcon name={'search'} />}
                                    value={configGetLiveTrackingList.search}
                                    onKeyPress={(e: any) => handleChangeSearch(e)}
                                    onChange={(e: any) => setConfigGetLiveTrackingList((prev: any) => {
                                        return {
                                            ...prev,
                                            search: e?.target?.value
                                        }
                                    })}
                                />
                                {
                                    mutationLiveTrackingArea?.data &&
                                    !mutationLiveTrackingArea?.isFetching &&
                                    <Heading
                                        marginY='-8px'
                                        size='chakra_body_medium'
                                        color='onSurface'
                                    >Total Area: {mutationLiveTrackingArea?.data?.metadata?.total}</Heading>
                                }
                                <Flex onScroll={testScroll} id="scrollableDiv" cursor={configuration?.is_absence_strict_location === 1 ? 'pointer' : 'not-allowed'} opacity={configuration?.is_absence_strict_location === 1 ? 1 : 0.5} maxHeight={'470px'} width={'100%'} overflowY={'scroll'} direction={'column'}>
                                    {memberSettingAreas&&
                                        <InfiniteScroll
                                            onScroll={testScroll}
                                            style={{ width: '100%' }}
                                            ref={ref}
                                            scrollableTarget="scrollableDiv"
                                            dataLength={Number(memberSettingAreas?.length)}
                                            next={() => {
                                                setConfigGetLiveTrackingList((prev: any) => {
                                                    return {
                                                        ...prev,
                                                        page: prev?.page + 1
                                                    }
                                                })
                                                setTimeout(() => {
                                                    // refetchMemberSettingAreas();
                                                    mutationLiveTrackingArea?.mutate({
                                                        profile_id: configGetLiveTrackingList.profile_id,
                                                        page: mutationLiveTrackingArea?.variables.page + 1,
                                                        search: configGetLiveTrackingList.search,
                                                    })
                                                }, 1)
                                            }}
                                            hasMore={Number(mutationLiveTrackingArea?.data?.metadata?.current_page) < Number(mutationLiveTrackingArea?.data?.metadata?.last_page)}
                                            loader={null}
                                        >
                                            {memberSettingAreas?.map((location:any, index: number)=>{
                                                return (
                                                    <Area
                                                        key={index}
                                                        toggleActive = {configuration?.is_absence_strict_location}
                                                        {...location}
                                                        onClickSwitch={() => {
                                                            setArea(location)
                                                            setModalToggleLokasiAbsenStatus(true)
                                                        }}
                                                        onClickDelete={() => {
                                                            setArea(location)
                                                            setModalToggleDeleteLocation(true)
                                                        }}
                                                        onClickUpdate={() => handlePrepareaUpdate(location)}
                                                        onClickDetail={() => handlePrepareDetail(location)}
                                                    />
                                                )
                                            })}
            
                                            {
                                                mutationLiveTrackingArea?.isLoading &&
                                                <>
                                                    <Flex
                                                        direction={'column'}
                                                        gap={'16px'}
                                                    >
                                                        <Skeleton
                                                            height={'252px'}
                                                            width={'100%'}
                                                            borderRadius={'16px'}
                                                        />
                                                        <Skeleton
                                                            height={'252px'}
                                                            width={'100%'}
                                                            borderRadius={'16px'}
                                                        />
                                                        <Skeleton
                                                            height={'252px'}
                                                            width={'100%'}
                                                            borderRadius={'16px'}
                                                        />
                                                    </Flex>
                                                </>
                                            }
                                        </InfiniteScroll>
                                    }
                                </Flex>
                                {
                                    memberSettingAreas?.length === 0 &&
                                    !mutationLiveTrackingArea?.isLoading &&
                                    mutationLiveTrackingArea?.data &&
                                    <Center height='100%' marginTop='-32px' flex='1'>
                                        <Heading size='chakra_body_medium'>
                                            {t('pengaturan_absen_pencarian_lokasi_absen_tidak_ditemukan', { 
                                                keyword: configGetLiveTrackingList?.search 
                                            })}
                                        </Heading>
                                    </Center>
                                }
                            </TabPanel>
                        </TabPanels>
                    </Flex>
                </Tabs>
            </Flex>

            <AttendanceMandatoryModal
                configuration={configuration}
                is_loading={fetchingUpdateAttendanceSettingMandatoryCheckIn}
                is_open={modalToggleWajibAbsen}
                on_cancel={() => setModalToggleWajibAbsen(false)}
                on_close={() => setModalToggleWajibAbsen(false)}
                on_toggle={submitAttendanceMandatoryModal}
            />

            <WorkingHoursModal
                configuration={configuration}
                is_loading={fetchingUpdateAttendanceSettingStrictTime}
                is_open={modalToggleJamKerja}
                on_cancel={() => setModalToggleJamKerja(false)}
                on_close={() => setModalToggleJamKerja(false)}
                on_toggle={submitWorkingHoursModal}
            />

            <AttendanceLocationModal
                configuration={configuration}
                is_loading={fetchingUpdateAttendanceSettingStatus}
                is_open={modalToggleLokasiAbsen}
                on_cancel={() => setModalToggleLokasiAbsen(false)}
                on_close={() => setModalToggleLokasiAbsen(false)}
                on_toggle={submitAttendanceLocationModal}
            />

            <LocationStatusModal
                area={area}
                is_loading={fetchingUpdateAttendanceSettingLocationStatus || mutationLiveTrackingArea?.isLoading}
                is_open={modalToggleLokasiAbsenStatus}
                on_cancel={() => setModalToggleLokasiAbsenStatus(false)}
                on_close={() => setModalToggleLokasiAbsenStatus(false)}
                on_toggle={submitLocationStatusModal}
            />

            <DeleteLocationModal
                area={area}
                is_loading={fetchingDeleteAttendanceSettingLocation || mutationLiveTrackingArea?.isLoading}
                is_open={modalToggleDeleteLocation}
                on_cancel={() => setModalToggleDeleteLocation(false)}
                on_close={() => setModalToggleDeleteLocation(false)}
                on_toggle={submitDeleteLocationModal}
            />

            <SpecifyLocationModal
                initial_area={initialArea}
                set_initial_area={setInitialArea}
                is_open={modalCariLokasi}
                on_close={() => modalCancelAttendanceAreaDisclosure?.onOpen()}
                on_next={onNextSpecifyLocationModal}
                places_value={searchPlace}
                places_on_change={(e: any) => setSearchPlace(e)}
                places_on_select={placesOnSelectSpecifyLocationModal}
            />

            <Details
                initial_area={initialArea}
                set_initial_area={setInitialArea}
                is_open={modalDetail}
                size={countGroupMembers()}
                on_close={() => setModalDetail(false)}
            />

            <DefineMemberModal
                group_on_check={(member: any) => handleSelectGroupMember(member)}
                initial_area={initialArea}
                is_open={modalTentukanAnggota}
                member_on_check={(user: any) => handleSelectMember(user)}
                on_back={onBackDefineMember}
                on_close={() => modalCancelAttendanceAreaDisclosure?.onOpen()}
                on_next={onNextDefineMember}
                search_on_change={setSearchChooseMember}
                search_value={searchChooseMember}
                selected_members={RenderSelectedMembers}
            />
            <ModalUploadTemplate 
                modalUploadTemplateDisclosure={modalUploadTemplateDisclosure} 
                modalResultDisclosure={modalResultDisclosure} 
                setUpload={setUpload}
                setPropsFile={setFile}
                refetchLiveTrackingData={mutationLiveTrackingArea}
                setMemberSettingAreas={setMemberSettingAreas}
            />
            <ModalResult 
                modalResultDisclosure={modalResultDisclosure} 
                modalUploadDiscloseure={modalUploadTemplateDisclosure}
                upload={upload} 
                file={file} 
            />
            <LocationInformationModal
                initial_area={initialArea}
                is_loading={isUpdateFlow ? fetchingUpdateAttendanceSettingLocation || mutationLiveTrackingArea?.isLoading : fetchingCreateAttendanceSettingLocation || mutationLiveTrackingArea?.isLoading}
                is_open={modalLabelLokasi}
                label_on_change={onChangeLabelLocation}
                label_value={initialArea?.label}
                on_back={onBackLocationInformation}
                on_close={() => {
                    modalCancelAttendanceAreaDisclosure?.onOpen()
                }}
                on_submit={() => isUpdateFlow ? handleUpdateLocation() : handleCreateLocation()}
                errorLabel = {errorLabel}
                render_selected_members={RenderSelectedChooseMember}
            />

            <Modal
                isOpen={modalCancelAttendanceAreaDisclosure.isOpen}
                onClose={modalCancelAttendanceAreaDisclosure?.onClose}
                closeOnOverlayClick={false}
                blockScrollOnMount={true}
                size='md'
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    backgroundColor='surfacePlus1'
                    borderRadius='8px'
                    padding='12px 18px'
                    margin='0px'
                >
                    <ModalHeader padding='0px'></ModalHeader>
                    <ModalCloseButton onClick={modalCancelAttendanceAreaDisclosure?.onClose} />
                    <ModalBody padding='0px' marginTop='28px'>
                        <Heading size='chakra_title_large'>{t('pengaturan_anggota_modal_cancel_live_tracking_title')}</Heading>
                        <Heading size='chakra_body_medium' marginTop='8px'>{t('pengaturan_anggota_modal_cancel_live_tracking_subtitle')}</Heading>
                    </ModalBody>
                    <ModalFooter padding='0px' gap='16px' marginTop='8px'>
                        <Button
                            size='normal'
                            variant={'j-outline'}
                            onClick={modalCancelAttendanceAreaDisclosure?.onClose}
                        >
                            {t('pengaturan_anggota_modal_cancel_live_tracking_button_cancel')}
                        </Button>
                        <Button
                            size='normal'
                            variant={'j-primary'}
                            onClick={() => {
                                setModalCariLokasi(false)
                                setModalTentukanAnggota(false)
                                setModalLabelLokasi(false)
                                setErrorLabel(false)
                                modalCancelAttendanceAreaDisclosure?.onClose()
                            }}
                        >
                            {t('pengaturan_anggota_modal_cancel_live_tracking_button_ok')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex >
    )
}

export default AttendanceSetting
