import React from 'react'
import { Flex } from '@chakra-ui/react'
import JTypography from '../../JTypography/JTypography'
import JGoogleIcon from '../../JGoogleIcon/JGoogleIcon'
import { useTranslation } from 'react-i18next'

interface IOutcomingMessage {
    sender: string
    message: string
    time: string
    isReadAll: boolean
    onSelectMessage: any
    targetDelete: any
    id: any,
    isDeleted: any
}

const OutcomingMessage = (props: IOutcomingMessage) => {
    const { t } = useTranslation()
    const { 
        message, 
        time, 
        isReadAll, 
        onSelectMessage, 
        targetDelete, 
        id,
        isDeleted,
    } = props

    return (
        <Flex 
            flexDirection='column' 
            gap='6px' 
            width={targetDelete === id ? '372px' : '100%'}
            backgroundColor={targetDelete === id ? 'surfacePlus3' : 'unset'} 
            marginX={targetDelete === id ? '-16px' : '0px'}
            paddingX={targetDelete === id ? '21px' : '0px'}
        >
            <Flex 
                backgroundColor={'surfacePlus2'} 
                padding={'16px'} 
                borderRadius={'16px'} 
                borderTopRightRadius={'0px'}
                width={'max-content'}
                marginLeft={'auto'}
                flexDirection={'column'}
                maxWidth={'290px'}
                onClick={onSelectMessage}
            >
                <Flex alignItems={'center'} gap={'6px'}>
                    {isDeleted !== null && <JGoogleIcon name={'hide_source'} fontSize={'body-medium'} />}
                    <JTypography size={'body-medium'} fontWeight='400' color='onSurface' marginLeft={'auto'} wordBreak='break-all'>
                        {isDeleted !== null ? `[${t('diskusi_tugas_pesan_telah_dihapus')}]` : message}
                    </JTypography>
                </Flex>
                <Flex marginTop={'8px'} alignItems={'center'} gap={'8px'}>
                    <JGoogleIcon 
                        name={'done_all'} 
                        fontSize={'20px'} 
                        marginLeft={'auto'} 
                        color={isReadAll ? 'primary' : 'unset'}
                    />
                    <JTypography size={'body-small'} fontWeight={'400'} color='neutral.50'>
                        {time.substr(11, 5)}
                    </JTypography>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default OutcomingMessage