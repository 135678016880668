import React from 'react'
import {
    Box,
    Flex,
    Step,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    Stepper,
    useSteps,
} from '@chakra-ui/react'
import { palette } from '../../theme'
import { AnimatePresence, motion } from 'framer-motion'

interface ICustomStepperProps {
    stepLength: number
    stepActive: number
}

const CustomStepper: React.FC<ICustomStepperProps> = (props: ICustomStepperProps) => {
    const { stepActive, stepLength } = props

    const theme = localStorage.getItem('chakra-ui-color-mode')

    const { activeStep } = useSteps({
        index: stepActive,
        count: stepLength,
    })


    return (
        <Box position='relative' width='100%'>
            <Stepper size='sm' index={activeStep} gap='0'>
                {[...Array(stepLength)].map((_, index) => {
                    const animatedSeparator = index == stepActive - 1
                    const inactiveSeparatorColor = theme == 'light'
                        ?   palette?.primary['50']
                        :   palette?.primary['80']
                    const activeSeparatorColor = theme == 'light'
                        ?   palette?.primary['90']
                        :   palette?.primary['30']
                    const separatorColor = index <= stepActive - 1
                        ?   theme == 'light'
                            ?   palette?.primary['50']
                            :   palette?.primary['80']
                        :   theme == 'light'
                            ?   palette?.primary['90']
                            :   palette?.primary['30']

                    return (
                        <Step key={index}>
                            <StepIndicator 
                                width='50px'
                                height='50px'
                                sx={{
                                    '[data-status=complete] &': {
                                        background: 'primary',
                                        borderColor: 'primary',
                                        color: 'onPrimary',
                                    },
                                    '[data-status=active] &': {
                                        background: 'primary',
                                        borderColor: 'primary',
                                        color: 'onPrimary',
                                    },
                                    '[data-status=incomplete] &': {
                                        background: 'primaryContainer',
                                        borderColor: 'primaryContainer',
                                        color: 'primary',
    
                                    },
                                }}
                            >
                                <StepStatus
                                    complete={<StepNumber />}
                                    incomplete={<StepNumber />}
                                    active={<StepNumber />}
                                />
                            </StepIndicator>
                            {animatedSeparator
                                ?   <AnimatePresence exitBeforeEnter>
                                        <motion.div
                                            key={activeSeparatorColor}
                                            initial={{ width: 0 }}
                                            animate={{ width: '100%' }}
                                            exit={{ width: 0 }}
                                            transition={{ duration: 1 }}
                                            style={{ 
                                                backgroundColor: inactiveSeparatorColor, height: '4px',
                                                marginLeft: '-8px',
                                            }}
                                        />
                                    </AnimatePresence>
                                :   <motion.div
                                        style={{
                                            width: '100%',
                                            height: '4px',
                                            background: separatorColor,
                                            transformOrigin: 'left',
                                            marginLeft: '-8px'
                                        }}
                                    />
                            }
                        </Step>
                    )
                })}
            </Stepper>
        </Box>
    )
}

export default React.memo(CustomStepper)