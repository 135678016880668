import React from 'react'
import { Flex, Heading, Image } from '@chakra-ui/react'
import { IL_RATED } from '../../../assets'

interface IRated {
    data: any
}

const Rated: React.FC<IRated> = (props: IRated) => {
    const { data } = props

    return (
        <Flex 
            direction='column' 
            justifyContent='center' 
            alignItems='center'
            alignSelf={'center'}
            marginTop={'15%'}
            gap='36px'
            flex={1}
        >
            <Image src={IL_RATED} width='343px' />
            <Heading size='chakra_title_large' textAlign='center'>Terima kasih {data?.data?.customer?.name} sudah memberikan nilai untuk kami!</Heading>
        </Flex>
    )
}

export default Rated