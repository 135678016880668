import URL_API from '../../apis/urlApi'
import { getTeamDetailApi } from '../../services/teamService'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
    isLoadingDetail: false,
    isSuccessDetail: false,
    isErrorDetail: false,
    loadingMessageDetail: '',
    successMessageDetail: '',
    errorMessageDetail: '',
    detail: null,
}

const getTeamDetailAction = createAsyncThunk(URL_API.TEAM_DETAIL, async () => {
    return await getTeamDetailApi()
})

const slice = createSlice({
    name: 'menu-privilage',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoadingDetail = false
            state.isSuccessDetail = false
            state.isErrorDetail = false
            state.loadingMessageDetail = ''
            state.successMessageDetail = ''
            state.errorMessageDetail = ''
            state.detail = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTeamDetailAction.pending, (state, action) => {
                state.isLoadingDetail = true
                state.isSuccessDetail = false
                state.isErrorDetail = false
                state.loadingMessageDetail = ''
                state.successMessageDetail = ''
                state.errorMessageDetail = ''
                state.detail = state.detail
            })
            .addCase(getTeamDetailAction.fulfilled, (state, action) => {
                state.isLoadingDetail = false
                state.isSuccessDetail = true
                state.isErrorDetail = false
                state.loadingMessageDetail = ''
                state.successMessageDetail = ''
                state.errorMessageDetail = ''
                state.detail = action.payload
            })
            .addCase(getTeamDetailAction.rejected, (state, action) => {
                state.isLoadingDetail = false
                state.isSuccessDetail = true
                state.isErrorDetail = false
                state.loadingMessageDetail = ''
                state.successMessageDetail = ''
                state.errorMessageDetail = ''
                state.detail = null
            })
    },
})

const { reset } = slice.actions
const reducer = slice.reducer

const teamSliceV2 = {
    reducer,
    getTeamDetailAction,
    reset,
}

export default teamSliceV2