import React, { useEffect } from 'react'
import { setDarkColor, setLightColor } from "./theme"
import { Router } from "./router"
import { getTokenFCM } from './firebase'
import { useMutation } from 'react-query'
import { updateFcmTokenApi } from './apis/notificationApi'
import HotjarProvider from './configs/hotjar'
import { useColorMode } from '@chakra-ui/react'

const App = () => {
    const { colorMode } = useColorMode()
    
    const updateFcmToken = useMutation(handleUpdateFcmToken)

    async function handleUpdateFcmToken(token: any) {
        return await updateFcmTokenApi({ firebase_token: token })
    }

    async function handleGetFcmToken() {
        const token = await getTokenFCM()
        updateFcmToken.mutate(token)
    }

    useEffect(() => {
        if (colorMode === 'light') {
            setLightColor()
        } else {
            setDarkColor()
        }
    }, [colorMode])

    useEffect(() => {
        handleGetFcmToken()
        // onMessageListener()
    }, [])

    return (
        <React.Fragment>
            <HotjarProvider />
            <Router />
        </React.Fragment>
    )
}

export default App