import React from 'react'
import { Center, IconButton } from '@chakra-ui/react'
import JGoogleIcon from '../../JGoogleIcon/JGoogleIcon'
import JTypography from '../../JTypography/JTypography'

interface IChatButton {
    btnRefChatList: any
    onOpenChatList: any
    chats: any
}

const ChatButton = (props: IChatButton) => {
    const {
        btnRefChatList,
        onOpenChatList,
        chats,
    } = props

    return (
        <IconButton 
            ref={btnRefChatList}
            onClick={onOpenChatList}
            aria-label="chat" 
            backgroundColor={'surfacePlus1'}
            borderRadius={'12px'}
            icon={
                <>
                    <JGoogleIcon name={'chat'} fontSize={'20px'} />
                    {
                        chats.data && chats.data.length !== 0 && chats?.data?.reduce((accumulator: number, chat: any) => accumulator + chat.unread_count, 0) !== 0 
                            ?   <Center
                                    height='16px'
                                    minWidth='16px'
                                    borderRadius='16px'
                                    backgroundColor='errorContainer'
                                    position='absolute'
                                    top='-4px'
                                    right='-8px'
                                >
                                    <JTypography size='label-medium'>
                                        {
                                            chats.data.reduce((accumulator: number, chat: any) => {
                                                return accumulator + chat.unread_count
                                            }, 0)
                                        }
                                    </JTypography>
                                </Center>
                            :   null
                    }
                </>
            } 
        />
    )
}

export default ChatButton