import http from "./http";
import urlApi from "./url";

export interface listLicenseProps {}

export const listLicense = async (props : listLicenseProps) => {
  try {
    const team = localStorage.getItem('current_team') || ''
    const payload = {
        profile_id: team,
        ...props
    }
    let response: any = await http(true).post(urlApi.licence.list, payload)
    return response.data
  } catch (error) {
    console.error(error);
    return null
  }
}