import React from 'react'
import { Avatar, Flex } from '@chakra-ui/react'
import { JTypography } from '../../../components'

interface IMemberItem {
    data: any
    onClick?: any
}

const MemberItem: React.FC<IMemberItem> = (props: IMemberItem) => {
    const {
        data,
        onClick,
    } = props

    return (
        <Flex
            width='100%'
            gap='12px'
            cursor='pointer'
            alignItems='center'
            onClick={onClick}
        >
            <Avatar src={data?.user?.photo_url} name={data?.user?.username} />
            <Flex color='onSurface' direction='column' gap='8px'>
                <JTypography size='body-medium'>
                    {data?.user?.display_name}
                </JTypography>
                <JTypography size='body-small'>
                    {data?.user?.username}
                </JTypography>
            </Flex>
        </Flex>
    )
}

export default MemberItem