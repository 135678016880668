import { generateErrorMessage } from "../helpers";
import http from "./http";
import urlApi from "./url";

export interface inviteMemberProps {
  recipient: any,
  license_id: number,
  privilege_template_id: number,
  group_member_id: number,
  toast: any
}
export interface kickMemberProps {
  member_id: number
}

interface IdeleteFaceRecogApi {
  member_id: any
}

export interface Get_Member_Props {
  order_by?: string;
  order_type?: string;
  show?: number;
  page?: number;
  search?: any;
  
  is_active?: any,
  status?: any,
  nip?: any;
  active_device?: any;
  active_geofence?: any;
  app_version?: any;
  face_recognition?: any;
  live_tracking?: any;
}

export const memberPrivilageList = async (team: any, page: any, show: any, is_active: any, search: any, with_columns: any) => {
  try {
    const bodyPayload = new FormData()
    team && bodyPayload.set('profile_id', team)
    page && bodyPayload.set('page', page)
    show && bodyPayload.set('show', show)
    is_active && bodyPayload.set('is_active', is_active)
    search && bodyPayload.set('search', search)
    with_columns && bodyPayload.set('with_columns', with_columns)

    let data: any = {}
    let response: any = await http(true).post(urlApi.member_privilage_list, bodyPayload)
    data.data = response.data
    data.metadata = response.metadata
    return data
  } catch (error) {
    console.error(error);
    return null
  }
}

export const memberList = async (
  team: any,
  page: any,
  show: any,
  is_active: any,
  status: any,
  search: any,
  with_columns: any,
  order_type: any,
  order_by: any,
  nip: any,
  active_device: any,
  active_geofence: any,
  app_version: any,
  face_recognition: any,
  live_tracking: any
) => {
  try {
    const bodyPayload = new FormData();
    team && bodyPayload.set("profile_id", team);
    page && bodyPayload.set("page", page);
    show && bodyPayload.set("show", show);
    is_active && bodyPayload.set("is_active", is_active);
    status && bodyPayload.set("status", status);
    search && bodyPayload.set("search", search);
    with_columns && bodyPayload.set("with_columns", with_columns);
    order_by && bodyPayload.set("order_by", order_by);
    order_type && bodyPayload.set("order_type", order_type);
    nip && bodyPayload.set("nip", nip);
    active_device && bodyPayload.set("active_device", active_device);
    active_geofence && bodyPayload.set("active_geofence", active_geofence);
    app_version && bodyPayload.set("app_version", app_version);
    face_recognition && bodyPayload.set("face_recognition", face_recognition);
    live_tracking && bodyPayload.set("live_tracking", live_tracking);

    let data: any = {};
    let response: any = await http(true).post(urlApi.memberCms, bodyPayload);
    data.data = response.data;
    data.metadata = response.metadata;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getVersion = async () => {
  try{
    const team = localStorage.getItem("current_team");
    const payload = { profile_id: team};
    let response: any = await http(true).post(urlApi.memberVersion, payload);
    return response.data;
  } catch (error) {
    console.error(error);
    return null
  }
}  

export async function deleteFaceRecogApi(props: IdeleteFaceRecogApi) {
  try {
    const team = localStorage.getItem('current_team') || ''
      const payload = { profile_id: team, ...props }
      const response: any = await http(true).post(urlApi?.memberMaster?.deleteFaceRecog, payload)
      if (response.code !== '200') throw generateErrorMessage(response?.message, 'Error delete face recognition')
      return response.data
  } catch (error: any) {
      throw generateErrorMessage(error, 'Error delete face recognition')
  }
}

export async function inviteMember(props: inviteMemberProps) {
  try {
    const team = localStorage.getItem('current_team') || ''
      const payload = { 
        ...props,
        profile_id: team, 
        
      }
      const response: any = await http(true).post(urlApi?.memberInvite, payload)
      if(response.code == '200'){
        props.toast('200', 'master_anggota_invited')
      } else if (response.code == '210'){
        props.toast('210', 'master_anggota_already')
      } else if(response.code == '110'){
        props.toast('110', 'master_anggota_not_active_lisensi')
      } else {
        props.toast('0', 'master_anggota_not_invited')
      }
      return response.data
  } catch (error: any) {
      throw generateErrorMessage(error, 'Error add member')
  }
}

export async function kickMember(props: kickMemberProps) {
  try {
    const team = localStorage.getItem('current_team') || ''
      const payload = { 
        ...props,
        profile_id: team, 
      }
      const response: any = await http(true).post(urlApi?.memberKick, payload)
      return response.data
  } catch (error: any) {
      throw generateErrorMessage(error, 'Error delete member')
  }
}

