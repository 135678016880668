import { Avatar, Button, Center, Divider, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { acceptInvitationApi, rejectInvitationApi } from '../../../apis/invitationApi'
import { JToast } from '../../../components'

function ListItem({ list, length, inviteMutate, disclosure }: any) {
    const { end_time, profile, sender, token } = list
    const { t } = useTranslation()
    const toast = useToast()
    // const token = localStorage.getItem('access_token');

    function showToast(type: 'error' | 'success', message: any) {
        toast({
            position: 'top-right',
            render: () => (
                <JToast type={type} message={message} />
            ),
        })
    }

    const acceptInvitation = useMutation(acceptInvitationApi, {
        onError: (resp) => {
            showToast('error', resp)
        },
        onSuccess: () => {
            disclosure.onClose()
            inviteMutate()
        },
    })
    const rejectInvitation = useMutation(rejectInvitationApi, {
        onError: (resp) => {
            showToast('error', resp)
        },
        onSuccess: () => {
            inviteMutate()
        },
    })

    return (
        <Flex flexDir='column' w='100%' >
            <Flex justify='space-between' align='center' p='16px 24px'>
                <Flex gap='16px' align='center'>
                    <Avatar src={profile?.profile_globalconfig?.logo} name={profile?.profile_globalconfig?.display_name} />
                    <Flex flexDir='column'>
                        <Heading size='chakra_body_small'>{end_time || '-'}</Heading>
                        <Heading size='chakra_label_large'>{profile?.profile_globalconfig?.display_name}</Heading>
                        <Heading size='chakra_body_medium'>{sender?.display_name}</Heading>
                    </Flex>
                </Flex>
                <Flex gap='12px'>
                    <Button p='12px 16px' border='1px solid var(--colorError)' bg='surface' _hover={{}} _active={{}}
                        onClick={() => { rejectInvitation?.mutate({ token_invitation: token || '' }) }}
                    >
                        <Heading size='chakra_label_medium' color='error'>
                            {t('tim_pilih_invite_decline')}
                        </Heading>
                    </Button>
                    <Button p='12px 16px' bg='primary' _hover={{}} _active={{}}
                        onClick={() => acceptInvitation?.mutate({ token_invitation: token || '' })}
                    >
                        <Heading size='chakra_label_medium' color='onPrimary'>
                            {t('tim_pilih_invite_accept')}
                        </Heading>
                    </Button>
                </Flex>
            </Flex>
            <Divider w='100%' />
        </Flex>
    )
}

function ModalInviteList(props: any) {
    const { lists, disclosure, inviteMutate } = props
    const { t } = useTranslation()

    return (
        <Modal size='lg' isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
            <ModalOverlay />
            <ModalContent bg='surface'>
                <ModalHeader></ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Center mb='32px'>
                        <Heading size='chakra_title_large'>{t('tim_pilih_invite_title')}</Heading>
                    </Center>
                    <Flex flexDir='column' h='300px' overflow='auto'>
                        {lists?.length > 0 ? lists?.map((list: any) => {
                            return (
                                <ListItem list={list} length={lists?.length} inviteMutate={inviteMutate} disclosure={disclosure} />
                            )
                        }) :
                            <Center>
                                <Heading size='chakra_label_large'>{t('tim_pilih_invite_empty')}</Heading>
                            </Center>
                        }
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default ModalInviteList