import { 
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Avatar,
    Box,
    Divider, 
    Flex, 
    Heading, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalHeader, 
    ModalOverlay, 
} from '@chakra-ui/react'
import { Fragment, useEffect, useState, useRef } from 'react'
import { 
    Circle, 
    GoogleMap, 
    MarkerF, 
} from '@react-google-maps/api'
import { useTranslation } from 'react-i18next'
import {  
    JGoogleIcon,
    JTypography,
} from '../../../components'

interface DetailsProps {
    is_open: boolean
    on_close: any
    initial_area: any
    set_initial_area: any
    size: number
}

const Details = (props: DetailsProps) => {
    const { t } = useTranslation()
    const {
        is_open,
        on_close,
        initial_area,
        size
    } = props
    const [count, setCount] = useState(0)
    const ref = useRef<any>();
    useEffect(()=>{
        setCount(0)
        initial_area.member_profile_id.map((p:any)=> p.members.map((check: any)=> check.checked === true && setCount((prev:any)=>prev+1)))
    }, [initial_area])
    return (
        <Modal
            isOpen={is_open}
            onClose={on_close}
            scrollBehavior={'outside'}
            closeOnOverlayClick={false}
            // size={size > 1 ? '4xl' : 'xl'}
            size='6xl'
            blockScrollOnMount={true}
            isCentered
        >
            <ModalOverlay />
            <ModalContent backgroundColor={'background'} margin='12px' borderRadius='24px'>
                <ModalHeader
                    as={Flex}
                    flexDirection={'column'}
                    alignItems={'center'}
                    gap={'12px'}
                    pt={8}
                >
                    <JTypography size={'title-large'}>{t('pengaturan_absen_detail')}</JTypography>
                </ModalHeader>
                <ModalCloseButton onClick={on_close} />
                <ModalBody
                    as={Flex}
                    flexDirection={'column'}
                    gap={'24px'}
                    p={8}
                >
                    <Flex gap={'24px'}>
                        <Flex
                            direction={'column'}
                            gap={'16px'}
                            width='100%'
                        >
                            <JTypography size='title-medium'>{initial_area.label}</JTypography>
                            <GoogleMap
                                mapContainerStyle={{
                                    height: '286px',
                                    width: '100%',
                                    borderRadius: '16px',
                                }}
                                center={{
                                        lat: initial_area.latitude,
                                        lng: initial_area.longitude
                                    }}
                                zoom={15}
                                clickableIcons={false}
                            >
                                <MarkerF position={{
                                    lat: initial_area.latitude,
                                    lng: initial_area.longitude
                                }} />
                                <Circle
                                    center={{
                                        lat: initial_area.latitude,
                                        lng: initial_area.longitude
                                    }}
                                    radius={initial_area.radius}
                                    options={{
                                        strokeColor: 'transparent',
                                        fillColor: '#0079D2',
                                        fillOpacity: 0.19,
                                        zIndex: 2,
                                    }}
                                />
                                {initial_area.radiusTolerance && (
                                    <Circle
                                        center={{
                                            lat: initial_area.latitude,
                                            lng: initial_area.longitude
                                        }}
                                        radius={initial_area.radius + initial_area.radiusTolerance}
                                        options={{
                                            strokeColor: 'transparent',
                                            fillColor: '#D27E00',
                                            fillOpacity: 0.19,
                                            zIndex: 1,
                                        }}
                                    />
                                )}
                            </GoogleMap>
                            <Flex alignItems='center' gap='8px'>
                                <JGoogleIcon name='location_on' />
                                <JTypography width={'85%'} color={'onSurfaceVariant'} size='body-medium'>{initial_area.address}</JTypography>
                            </Flex>
                            <Flex gap={1.5} mt={2} direction={'column'}>
                                <JTypography size='body-medium'>
                                    {t('detail_pengaturan_anggota_radius_sebesar', {radius: initial_area.radius})}
                                </JTypography>
                            </Flex>
                        </Flex>
                        <Flex
                            direction={'column'}
                            gap={'16px'}
                            width='100%'
                        >
                            <Flex w={'100%'}>
                                <Flex direction={'column'} ref={ref} minHeight="400px" w={'100%'} position={'relative'}>
                                    <JTypography size={'title-medium'}>{`${t('pengaturan_anggota_di_tracking')} (${count})`}</JTypography>
                                    <Flex 
                                        flexWrap={'wrap'} 
                                        overflowY={'scroll'} 
                                        direction={'row'} 
                                        borderRadius={"18px"} 
                                        height={"100%"} 
                                        mt='16px' 
                                        backgroundColor={'surfacePlus3'}
                                        padding='12px'
                                    >
                                        <Accordion allowMultiple allowToggle width='100%'>
                                            {initial_area.member_profile_id.filter((p:any)=> p.checked === true).map((member: any, index: number) => {
                                                return (
                                                    <AccordionItem 
                                                        key={index} 
                                                        marginTop={index > 0 ? '12px' : '0px'}
                                                        borderTop={index > 0 ? 'unset' : 'none'}
                                                        borderColor='primary'
                                                    >
                                                        <h2>
                                                            <AccordionButton padding='10px 12px'>
                                                                <Box as='span' flex='1' textAlign='left'>
                                                                    <Heading size='chakra_label_large'>{member.display_name} ({member.members.filter((check:any)=>check.checked===true).length})</Heading>
                                                                </Box>
                                                                <AccordionIcon />
                                                            </AccordionButton>
                                                        </h2>
                                                        <Divider borderColor='primary' opacity='1' />
                                                        <AccordionPanel pb={4}>
                                                            <Flex direction='column' gap='4px'>
                                                                {member.members.map((members: any) => (
                                                                    <>
                                                                        {members.checked === true &&
                                                                            <Flex
                                                                                gap={'24px'}
                                                                                mt={3}
                                                                                alignItems={'center'}
                                                                            >
                                                                                <Avatar name={members.display_name} src={members?.photo_url} />
                                                                                <Flex
                                                                                    direction={'column'}
                                                                                    gap={'4px'}
                                                                                >
                                                                                    <JTypography
                                                                                        size='title-medium'
                                                                                        wordBreak={'break-word'}
                                                                                    >
                                                                                        {members.display_name}
                                                                                    </JTypography>
                                                                                    <JTypography
                                                                                        size='body-medium'
                                                                                        wordBreak={'break-word'}
                                                                                        color={'onSurfaceVariant'}
                                                                                    >
                                                                                        {members.username}
                                                                                    </JTypography>
                                                                                </Flex>

                                                                            </Flex>
                                                                        }
                                                                    </>
                                                                ))}
                                                            </Flex>
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                )
                                            })}

                                            {/* <AccordionItem>
                                                <h2>
                                                    <AccordionButton>
                                                        <Box as='span' flex='1' textAlign='left'>
                                                            Section 2 title
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                                    commodo consequat.
                                                </AccordionPanel>
                                            </AccordionItem> */}
                                        </Accordion>
                                    </Flex>
                                </Flex>
                            </Flex>

                        </Flex>
                        
                    </Flex>
                </ModalBody>
                    
            </ModalContent>
        </Modal>
    )
}

export default Details