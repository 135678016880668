import axios from "axios"
import URL_API from "../apis/urlApi"
import fileDownload from "js-file-download"
import { handleErrorMessage, handleGetTeamId } from "./helpers"
import { http } from "../apis"

interface ExportTaskTypeTemplateApiProps {
    task_type_id: string
    filename: string
}

interface ITaskTypeListApiProps {
    page: any
    show: any
    order_by?: any
    order_type?: any
    search?: any
    is_active: any
    is_limited_access?: any
}

export async function exportTaskTypeTemplateApi(props: ExportTaskTypeTemplateApiProps) {
    try {
        const payload = {
            profile_id: handleGetTeamId(),
            task_type_id: props?.task_type_id,
        }
        const endpoint = `${process.env.REACT_APP_BASE_URL}${URL_API?.TASK_TYPE_EXPORT_TEMPLATE}`
        const response: any = await axios({
            url: endpoint,
            method: 'POST',
            data: payload,
            headers: {
                'Content-Type': 'multipart/form-data', 
                'Accept' : '*/*', 
                'Token' : `${localStorage.getItem('access_token')}`
            },
            responseType: 'blob',
        })
        if (response.headers['content-type'] === "application/json"){
            alert('error download task type template')
        } else {
            fileDownload(response.data, `${props?.filename}.csv`)
        }
        return response.data
    } catch (error) {
        throw 'error download template'
    }
}

export async function taskTypeList(props: ITaskTypeListApiProps) {
    try {
        const payload = { 
            profile_id: handleGetTeamId(), 
            ...props,
        }
        const response: any = await http(true).post(URL_API?.TASK_TYPE_LIST, payload)
        if (response.code !== '200') throw handleErrorMessage(response?.message, 'error get task type')
        return {
            data: response?.data,
            metadata: response?.metadata
        }
    } catch (error) {
        throw error
    }
}