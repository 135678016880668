import http from "./http";
import urlApi from "./url";

export interface userSearchProps {
    keyword: string
}

export const userSearch = async (props : userSearchProps) => {
  try {
    const payload = {
        ...props
    }
    let response: any = await http(true).post(urlApi.user.search, payload)
    return response.data
  } catch (error) {
    console.error(error);
    return null
  }
}