import { useState, useEffect } from 'react'
import SearchbarMember from './components/SearchbarMember'
import JourneyLegend from './components/JourneyLegend'
import MemberItem from './components/MemberItem'
import SkeletonMemberItem from './components/SkeletonMemberItem'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { getMembersApi } from '../../apis/memberJourneyApi'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { GoogleMap, useLoadScript } from '@react-google-maps/api'
import { JGoogleIcon, PageTitle } from '../../components'
import {
    Flex,
    Button,
    Grid,
    GridItem,
    Skeleton,
    Heading,
} from '@chakra-ui/react'
import { urlRouter } from '../../router'
import { format, startOfToday } from 'date-fns'

const MemberJourney = () => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyD14zNix-s-MJSfWwLwAWFaOF05vKhEI_4',
    })
    const defaultCenter = { lat: -6.2213866, lng: 106.7796252 }
    const defaultZoom = 10

    const { t } = useTranslation()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    const [search, setSearch] = useState<any>(searchParams?.get('search') ?? '')
    const [modalLegend, setModalLegend] = useState<boolean>(false)

    const members = useMutation(getMembersApi, {
        onSuccess: (data: any) => handleRedirectFromNotification({ data }),
    })

    function handleClearJourneyLocalStorage() {
        localStorage.removeItem('journeyDate')
        localStorage.removeItem('journeyUser')
    }

    function handleRedirectFromNotification(props: { data: any[] }) {
        const { data } = props
        const user = localStorage.getItem('journeyUser')
        const date = localStorage.getItem('journeyDate')
        if (!user || !date) return handleClearJourneyLocalStorage()
        const member = data.find((m: any) => m.user.id === Number(user))
        if (!member) return handleClearJourneyLocalStorage()
        handleClearJourneyLocalStorage()
    }

    function handleClickMemberItem(memberArg: any) {
        const memberId = memberArg?.user?.id
        const journeyDate = format(new Date(startOfToday()), 'yyyy-MM-dd')
        navigate(
            `${urlRouter?.memberJourneyDetail}?member_id=${memberId}&journey_date=${journeyDate}`
        )
    }

    function handleChangeSearch(eventArg: any) {
        const value = eventArg?.target?.value
        if (value) {
            setSearchParams({ search: value })
        } else {
            setSearchParams({})
        }
        setSearch(value)
        members.mutate({ search: value })
    }

    useEffect(() => {
        members.mutate({ search: searchParams?.get('search') ?? '' })
    }, [])

    return (
        <Flex
            direction="column"
            width="100%"
            gap="16px"
            backgroundColor="background"
        >
            <Flex alignItems="center" justifyContent="space-between">
                <PageTitle text={t('perjalanan_judul')} />

                <Flex>
                    <Button
                        variant="j-text"
                        leftIcon={<JGoogleIcon name="info" />}
                        onClick={() => setModalLegend(true)}
                    >
                        {t('perjalanan_legenda')}
                    </Button>
                </Flex>
            </Flex>

            <Grid
                gridTemplateColumns="repeat(3, 1fr)"
                width="100%"
                gridGap="24px"
            >
                {/* MEMBER LIST */}
                <GridItem>
                    <Flex
                        direction="column"
                        borderRadius="16px"
                        backgroundColor="surfacePlus1"
                        padding="24px"
                        gap="24px"
                        overflowY="auto"
                        height="calc(100vh - 53px - 34px - 33px - 24px)"
                    >
                        <SearchbarMember
                            value={search}
                            onChange={(event: any) => handleChangeSearch(event)}
                            onSubmit={() => members.mutate({ search })}
                        />

                        {members?.isLoading ? (
                            [...Array(3)]?.map((_, index: number) => {
                                return <SkeletonMemberItem key={index} />
                            })
                        ) : members?.data?.length > 0 ? (
                            members?.data?.map((member: any, index: number) => {
                                return (
                                    <MemberItem
                                        key={index}
                                        data={member}
                                        onClick={() =>
                                            handleClickMemberItem(member)
                                        }
                                    />
                                )
                            })
                        ) : (
                            <Flex
                                alignItems="center"
                                gap="8px"
                                justifyContent="center"
                            >
                                <JGoogleIcon name="info" fontSize="20px" />
                                <Heading
                                    size="chakra_body_medium"
                                    textAlign="center"
                                >
                                    {t('perjalanan_search_member_not_found')}
                                </Heading>
                            </Flex>
                        )}
                    </Flex>
                </GridItem>

                {/* MAPS */}
                <GridItem colSpan={2}>
                    <Flex
                        direction="column"
                        borderRadius="16px"
                        backgroundColor="surfacePlus1"
                        padding="24px"
                        gap="24px"
                        height="calc(100vh - 53px - 34px - 33px - 24px)"
                    >
                        {isLoaded ? (
                            <GoogleMap
                                mapContainerStyle={{
                                    height: '100%',
                                    width: '100%',
                                    borderRadius: '16px',
                                }}
                                center={defaultCenter}
                                zoom={defaultZoom}
                                clickableIcons={false}
                            ></GoogleMap>
                        ) : (
                            <Skeleton
                                width="100%"
                                height="calc(100vh - 66px - 34px - 33px - 24px)"
                                borderRadius="16px"
                            />
                        )}
                    </Flex>
                </GridItem>
            </Grid>

            <JourneyLegend
                isOpen={modalLegend}
                onClose={() => setModalLegend(false)}
            />
        </Flex>
    )
}

export default MemberJourney
