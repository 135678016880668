import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { JButton, JTypography } from '../../../components'
import { 
    Flex, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalFooter, 
    ModalHeader, 
    ModalOverlay, 
} from '@chakra-ui/react'
import { useMutation } from 'react-query'
import { deleteFaceRecogApi } from '../../../apis/memberApi'

interface IModalFaceRecognitionDelete {
    disclosure: any
    data: any
    modalDetailDisclosure: any
    refetch: any
}

const ModalFaceRecognitionDelete: React.FC<IModalFaceRecognitionDelete> = (props: IModalFaceRecognitionDelete) => {
    const { 
        disclosure, 
        data, 
        modalDetailDisclosure,
        refetch,
    } = props
    const { t } = useTranslation()

    const deleteFaceRecogMutation = useMutation(deleteFaceRecogApi, {
        onSuccess: () => {
            modalDetailDisclosure?.onClose()
            disclosure?.onClose()
            refetch()
        }
    })

    return (
        <Modal
            isOpen={disclosure?.isOpen}
            onClose={disclosure?.onClose}
            size={"lg"}
        >
            <ModalOverlay />
            <ModalContent
                borderRadius={'16px'}
                backgroundColor={"background"}
                padding={"24px"}
            >
                <ModalHeader padding={"0px"} mt={'10px'} mb={2} />
                <ModalCloseButton top={"24px"} right={"24px"} />
                <ModalBody
                    as={Flex}
                    flexDirection={"column"}
                    gap={"16px"}
                    padding={"0px"}
                    marginTop={"24px"}
                >
                    <Flex direction={'column'}>
                        <JTypography size="title-large" mb={4} > {t("master_anggota_face_recognition_remove_face_confirmation_title")} </JTypography>
                        <JTypography size="body-medium" color={'onSurface'}> {t("master_anggota_face_recognition_remove_face_confirmation_subtitle")} </JTypography>
                    </Flex>
                </ModalBody>
                <ModalFooter
                    gap={"12px"}
                    padding={"0px"}
                    marginTop={"24px"}
                    justifyContent={"end"}
                >
                    <Flex direction={'row'}>
                        <JButton
                            size="normal"
                            variant="j-text"
                            mb={2}
                            mr={4}
                            onClick={disclosure?.onClose}
                        > {t("master_anggota_face_recognition_remove_face_cancel")} </JButton>
                        <JButton
                            size="normal"
                            variant="j-primary"
                            backgroundColor={'errorContainer'}
                            color={'error'}
                            _focus={{ opacity: '1.5' }}
                            _hover={{ opacity: '0.7' }}
                            isLoading={deleteFaceRecogMutation?.isLoading}
                            onClick={() => {
                                deleteFaceRecogMutation?.mutate({ member_id: data?.id })
                            }}
                        > {t("master_anggota_face_recognition_remove_face_submit")} </JButton>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default memo(ModalFaceRecognitionDelete)