import { useMemo } from 'react'
import { 
    Flex, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalFooter, 
    ModalHeader, 
    ModalOverlay,
    Skeleton,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { 
    ApiGetGroupMembersProps,
    ApiUpdateGroupMemberProps,
    ApiUpdateGroupMemberAccessProps,
    apiGetGroupMembers,
    apiGetMembers,
    apiGetGroupMember,
    apiUpdateGroupMember,
    apiGetGroupMemberAccess,
    apiUpdateGroupMemberAccess,
    ApiCreateGroupMemberProps,
    apiCreateGroupMember,
    apiGetCustomers,
    apiGetCustomerGroup,
    apiUpdateCustomerGroup
} from '../../apis/memberGroupApi'
import { 
    JButton, 
    JGoogleIcon, 
    JInput, 
    JTable, 
    JTypography 
} from '../../components'
import { Select as ChakraReactSelect } from 'chakra-react-select'
import { useMutation, useQuery } from 'react-query'

const MemberGroup = () => {
    const { t } = useTranslation()

    const [getGroupMembersConfig, setGetGroupMembersConfig] = useState<ApiGetGroupMembersProps>({
        page: 1,
        search: '',
    })

    const [groupMember, setGroupMember] = useState<any>(null)
    const [groupMemberAccess, setGroupMemberAccess] = useState<any>(null)
    const [customerGroup, setCustomerGroup] = useState<any>(null)

    const [modalCreate, setModalCreate] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [modalApply, setModalApply] = useState(false)
    const [modalCustomerGroup, setModalCustomerGroup] = useState(false)

    const groupMembers = useQuery('member-group-get-group-members', handleGetGroupMembers)
    const members = useQuery('member-group-get-members', handleGetMembers)
    const createGroupMember = useMutation(handleCreateGroupMember)
    const groupMemberDetail = useMutation(handleGetGroupMemberDetail, {
        onError: () => {
            console.error('error get group member detail')
            setModalEdit(false)
        }
    })
    const updateGroupMember = useMutation(handleUpdateGroupMember)
    const groupMemberAccessDetail = useMutation(handleGetGroupMemberAccessDetail, {
        onError: () => {
            console.error('error get group member access detail')
            setModalApply(false)
        }
    })

    const updateGroupMemberAccess = useMutation(handleUpdateGroupMemberAccess)
    const customers = useMutation(handleGetCustomers)
    const customerGroupDetail = useMutation(handleGetCustomerGroupDetail)
    const updateCustomerGroup = useMutation(handleUpdateCustomerGroup)

    async function handleGetGroupMembers() {
        const payload: ApiGetGroupMembersProps = {
            page: getGroupMembersConfig.page,
            search: getGroupMembersConfig.search,
        }
        return await apiGetGroupMembers(payload)
    }

    async function handleGetMembers() {
        return await apiGetMembers({})
    }

    async function handleCreateGroupMember() {
        const payload: ApiCreateGroupMemberProps = {
            display_name: groupMember?.display_name,
            detail: JSON.stringify(groupMember?.members.map((member: any) => {
                return {
                    user_mapping_profile_id: member.value
                }
            })),
        }
        const response = await apiCreateGroupMember(payload)
        await groupMembers.refetch()
        await members.refetch()
        setModalCreate(false)
        return response
    }

    async function handleGetGroupMemberDetail(props: any) {
        const response = await apiGetGroupMember({
            group_member_id: props.group_member_id
        })
        setGroupMember({
            ...props.data,
            members: response.map((member: any) => {
                return {
                    value: member.user_mapping_profile.id,
                    label: member.user_mapping_profile.user.display_name
                }
            })
        })
        return response
    }

    async function handleUpdateGroupMember() {
        const payload: ApiUpdateGroupMemberProps = {
            group_member_id: groupMember?.id,
            display_name: groupMember?.display_name,
            detail: JSON.stringify(groupMember?.members.map((member: any) => {
                return {
                    user_mapping_profile_id: member.value
                }
            })),
        }
        const response = await apiUpdateGroupMember(payload)
        await groupMembers.refetch()
        await members.refetch()
        setModalEdit(false)
        return response
    }

    async function handleGetGroupMemberAccessDetail(props: {data: any, group_member_id: any}) {
        const response = await apiGetGroupMemberAccess({
            group_member_id: props.group_member_id
        })
        setGroupMemberAccess({
            id: props.data.id,
            access: response.map((member: any) => {
                return {
                    value: member.user_mapping_profile.id,
                    label: member.user_mapping_profile.user.display_name
                }
            })
        })
        return response
    }

    async function handleUpdateGroupMemberAccess() {
        const payload: ApiUpdateGroupMemberAccessProps = {
            group_member_id: groupMemberAccess?.id,
            access: JSON.stringify(groupMemberAccess?.access.map((member: any) => {
                return {
                    user_mapping_profile_id: member.value
                }
            })),
        }
        const response = await apiUpdateGroupMemberAccess(payload)
        await groupMembers.refetch()
        await members.refetch()
        setModalApply(false)
        return response
    }

    async function handleGetCustomers(group_member_id: string) {
        const response = await apiGetCustomers({
            group_member_id: group_member_id,
            page: 1,
            order_by: 'created_at',
            order_type: 'desc',
            search: '',
            is_active: 1
        })
        return response
    }

    async function handleGetCustomerGroupDetail(group_member_id: number) {
        const response = await apiGetCustomerGroup({
            group_member_id: group_member_id
        })
        setCustomerGroup({
            id: group_member_id,
            customer: response.map((customer: any) => {
                return {
                    value: customer.id,
                    label: customer.name
                }
            })
        })
        return response
    }

    async function handleUpdateCustomerGroup() {
        const selectedCustomer: any = []
        customerGroup?.customer.map((customer: any) => {
            return selectedCustomer.push({
                id: customer.value,
                is_selected: 1
            })
        })
        customerGroupDetail.data.map((customer: any) => {
            if (customerGroup.customer.find((cust: any) => cust.value === customer.id) === undefined) return selectedCustomer.push({
                id: customer.id,
                is_selected: 0
            })
        })
        const payload = {
            group_member_id: customerGroup.id,
            customer: JSON.stringify(selectedCustomer),
        }
        const response = await apiUpdateCustomerGroup(payload)
        await groupMembers.refetch()
        await members.refetch()
        setModalCustomerGroup(false)
        return response
    }

    const data = useMemo(() => groupMembers?.data?.data, [groupMembers])
    const columns = useMemo (() => [
        {
            Header: t('grup_anggota_tabel_nama'),
            accessor: 'display_name',
        },
    ], [t, members])
    const tableHooks = (hooks: any) => {
        hooks.visibleColumns.push((columns: any) => [
            {
                id: t('grup_anggota_tabel_no'),
                Header: t('grup_anggota_tabel_no'),
                Cell: ({ row }: any) => (
                    row.index + 1
                ),
            },
            ...columns,
            {
                id: t('grup_anggota_tabel_status'),
                Header: t('grup_anggota_tabel_status'),
                Cell: ({ row }: any) => (
                    row.original.is_active === 1
                        ?   t('grup_anggota_tabel_status_aktif').toUpperCase()
                        :   t('grup_anggota_tabel_status_tidak_aktif').toUpperCase()
                ),
            },
            {
                id: t('grup_anggota_tabel_aksi'),
                Header: t('grup_anggota_tabel_aksi'),
                Cell: ({ row }: any) => (
                    members && 
                    <Flex>
                        <JButton
                            size={'small'}
                            variant={'j-primary'}
                            onClick={() => {
                                groupMemberDetail.mutate({
                                    data: row.original, 
                                    group_member_id: row.original.id
                                })
                                setModalEdit(true)
                            }}
                        >
                            {t('grup_anggota_tabel_tombol_aksi_ubah_group')}
                        </JButton>
                        <JButton
                            size={'small'}
                            variant={'j-primary'}
                            marginLeft={'10px'}
                            onClick={() => {
                                groupMemberAccessDetail.mutate({
                                    data: row.original,
                                    group_member_id: row.original.id
                                })
                                groupMemberDetail.mutate({
                                    data: row.original, 
                                    group_member_id: row.original.id
                                })
                                setModalApply(true)
                            }}
                        >
                            {t('grup_anggota_tabel_tombol_aksi_berlakukan')}
                        </JButton>
                        <JButton
                            size={'small'}
                            variant={'j-primary'}
                            marginLeft={'10px'}
                            onClick={() => {
                                customers.mutate(row.original.id)
                                customerGroupDetail.mutate(row.original.id)
                                setModalCustomerGroup(true)
                            }}
                        >
                            {t('grup_anggota_tabel_tombol_aksi_grup_pelanggan')}
                        </JButton>
                    </Flex>
                ),
            },
        ])
    }

    return (
        <Flex 
            direction={'column'} 
            gap={'24px'} 
            width={'100%'}
        >
            <JTypography size={'title-large'}>
                {t('grup_anggota_judul')}
            </JTypography>
            <Flex 
                width={'100%'} 
                justifyContent={'space-between'} 
                alignItems={'center'}
            >
                <JInput
                    type={'search'}
                    leftIcon={<JGoogleIcon name={'search'} />}
                    placeholder={t('grup_anggota_placeholder_pencarian')}
                    wrapperWidth={'max-content'}
                    value={getGroupMembersConfig.search}
                    onChange={(e: any) => {
                        setGetGroupMembersConfig((prev: any) => {
                            return {
                                ...prev,
                                search: e.target.value
                            }
                        })
                        if (e.target.value === '') {
                            setTimeout(() => {
                                groupMembers.refetch()
                            }, 1)
                        }
                    }}
                    onKeyDown={(e: any) => {
                        if (e.code !== 'Enter') return
                        groupMembers.refetch() 
                    }}
                />
                <JButton 
                    size={'normal'} 
                    variant={'j-primary'}
                    onClick={() => {
                        setGroupMember({
                            display_name: '',
                            members: []
                        })
                        setModalCreate(true)
                    }}
                >
                    {t('grup_anggota_buat_baru')}
                </JButton>
            </Flex>

            {groupMembers?.data &&
                <JTable
                    data={data}
                    columns={columns}
                    hooks={tableHooks}
                />
            }

            {groupMembers?.data?.metadata &&
                <Flex justifyContent={'space-between'} alignItems={'center'}>
                    <JTypography size={'body-medium'}>
                        {t('grup_anggota_tabel_keterangan', { 
                            from: groupMembers?.data?.metadata?.from, 
                            to: groupMembers?.data?.metadata?.to, 
                            total: groupMembers?.data?.metadata?.total
                        })}
                    </JTypography>
                    <Flex gap={'10px'}>
                        <JButton 
                            size={'normal'} 
                            variant={'j-primary'}
                            isDisabled={groupMembers?.data?.metadata?.current_page === 1}
                        >
                            {t('grup_anggota_tabel_sebelumnya')}
                        </JButton>
                        <JButton 
                            size={'normal'} 
                            variant={'j-primary'}
                            isDisabled={groupMembers?.data?.metadata?.current_page === groupMembers?.data?.metadata?.last_page}
                        >
                            {t('grup_anggota_tabel_selanjutnya')}
                        </JButton>
                    </Flex>
                </Flex>
            }

            {/* modal create */}
            <Modal isOpen={modalCreate} onClose={() => setModalCreate(false)}>
                <ModalOverlay />
                <ModalContent
                    backgroundColor={'surfacePlus1'}
                    padding={'24px'}
                >
                    <ModalHeader margin={'0px auto'} padding={'0px'}>{t('group_anggota_modal_buat_judul')}</ModalHeader>
                    <ModalCloseButton
                        top={'24px'}
                        right={'24px'}
                        isDisabled={createGroupMember.isLoading}
                    />
                    <ModalBody
                        as={Flex}
                        flexDirection={'column'}
                        gap={'16px'}
                        padding={'0px'}
                        marginTop={'24px'}
                    >
                        <JTypography size={'label-medium'}>
                            {t('group_anggota_modal_buat_label_nama')}
                        </JTypography>
                        {groupMemberDetail.isLoading
                            ?   <Skeleton height={'40px'} width={'100%'} />
                            :   <JInput
                                    placeholder={t('group_anggota_modal_buat_placeholder_nama')}
                                    value={groupMember?.display_name}
                                    onChange={(e: any) => {
                                        setGroupMember((prev: any) => {
                                            return {
                                                ...prev,
                                                display_name: e.target.value
                                            }
                                        })
                                    }}
                                    isDisabled={createGroupMember.isLoading}
                                />
                        }
                        <JTypography size={'label-medium'}>
                            {t('group_anggota_modal_ubah_anggota')}
                        </JTypography>
                        {groupMemberDetail.isLoading
                            ?   <Skeleton height={'40px'} width={'100%'} />
                            :   <ChakraReactSelect
                                    isMulti
                                    useBasicStyles
                                    value={groupMember?.members}
                                    options={members?.data?.map((member: any) => {
                                        return {
                                            value: member.id,
                                            label: member.user.display_name
                                        }
                                    })}
                                    onChange={(e: any) => {
                                        setGroupMember((prev: any) => {
                                            return {
                                                ...prev,
                                                members: e
                                            }
                                        })
                                    }}
                                    placeholder={t('group_anggota_modal_buat_pilih')}
                                    closeMenuOnSelect={false}
                                    isDisabled={createGroupMember.isLoading}
                                />
                        }
                    </ModalBody>
                    <ModalFooter
                        gap={'33px'}
                        padding={'0px'}
                        marginTop={'24px'}
                    >
                        <JButton
                            margin={'0px auto'}
                            size={'normal'}
                            variant={'j-primary'}
                            onClick={() => createGroupMember.mutate()}
                            isLoading={createGroupMember.isLoading}
                        >
                            {t('group_anggota_modal_buat_simpan')}
                        </JButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* modal edit */}
            <Modal isOpen={modalEdit} onClose={() => setModalEdit(false)}>
                <ModalOverlay />
                <ModalContent
                    backgroundColor={'surfacePlus1'}
                    padding={'24px'}
                >
                    <ModalHeader margin={'0px auto'} padding={'0px'}>{t('group_anggota_modal_ubah_judul')}</ModalHeader>
                    <ModalCloseButton
                        top={'24px'}
                        right={'24px'}
                        isDisabled={updateGroupMember.isLoading}
                    />
                    <ModalBody
                        as={Flex}
                        flexDirection={'column'}
                        gap={'16px'}
                        padding={'0px'}
                        marginTop={'24px'}
                    >
                        <JTypography size={'label-medium'}>
                            {t('group_anggota_modal_ubah_label_nama')}
                        </JTypography>
                        {groupMemberDetail.isLoading
                            ?   <Skeleton height={'40px'} width={'100%'} />
                            :   <JInput
                                    placeholder={t('group_anggota_modal_ubah_placeholder_nama')}
                                    value={groupMember?.display_name}
                                    onChange={(e: any) => {
                                        setGroupMember((prev: any) => {
                                            return {
                                                ...prev,
                                                display_name: e.target.value
                                            }
                                        })
                                    }}
                                    isDisabled={updateGroupMember.isLoading}
                                />
                        }
                        <JTypography size={'label-medium'}>
                            {t('group_anggota_modal_ubah_anggota')}
                        </JTypography>
                        {groupMemberDetail.isLoading
                            ?   <Skeleton height={'40px'} width={'100%'} />
                            :   <ChakraReactSelect
                                    isMulti
                                    useBasicStyles
                                    value={groupMember?.members}
                                    options={members?.data?.map((member: any) => {
                                        return {
                                            value: member.id,
                                            label: member.user.display_name
                                        }
                                    })}
                                    onChange={(e: any) => {
                                        setGroupMember((prev: any) => {
                                            return {
                                                ...prev,
                                                members: e
                                            }
                                        })
                                    }}
                                    placeholder={t('group_anggota_modal_ubah_pilih')}
                                    closeMenuOnSelect={false}
                                    isDisabled={updateGroupMember.isLoading}
                                />
                        }
                    </ModalBody>
                    <ModalFooter
                        gap={'33px'}
                        padding={'0px'}
                        marginTop={'24px'}
                    >
                        <JButton
                            margin={'0px auto'}
                            size={'normal'}
                            variant={'j-primary'}
                            onClick={() => updateGroupMember.mutate()}
                            isDisabled={groupMemberDetail.isLoading}
                            isLoading={updateGroupMember.isLoading}
                        >
                            {t('group_anggota_modal_berlakukan_grop_pelanggan_simpan')}
                        </JButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* modal apply */}
            <Modal isOpen={modalApply} onClose={() => setModalApply(false)}>
                <ModalOverlay />
                <ModalContent
                    backgroundColor={'surfacePlus1'}
                    padding={'24px'}
                >
                    <ModalHeader margin={'0px auto'} padding={'0px'}>{t('group_anggota_modal_berlakukan_judul')}</ModalHeader>
                    <ModalCloseButton
                        top={'24px'}
                        right={'24px'}
                        isDisabled={updateGroupMemberAccess.isLoading}
                    />
                    <ModalBody
                        as={Flex}
                        flexDirection={'column'}
                        gap={'16px'}
                        padding={'0px'}
                        marginTop={'24px'}
                    >
                        <Flex gap='3px'>
                            <JTypography size={'label-medium'}>
                                {t('group_anggota_modal_berlakukan_beri_akss')}
                            </JTypography>
                            <JTypography size={'label-medium'} fontWeight='600'>
                                {groupMember?.display_name}
                            </JTypography>
                        </Flex>
                        {groupMemberAccessDetail.isLoading
                            ?   <Skeleton height={'40px'} width={'100%'} />
                            :   <ChakraReactSelect
                                    isMulti
                                    useBasicStyles
                                    value={groupMemberAccess?.access}
                                    options={members?.data?.map((member: any) => {
                                        return {
                                            value: member.id,
                                            label: member.user.display_name
                                        }
                                    })}
                                    onChange={(e: any) => {
                                        setGroupMemberAccess((prev: any) => {
                                            return {
                                                ...prev,
                                                access: e
                                            }
                                        })
                                    }}
                                    placeholder={t('group_anggota_modal_berlakukan_pilih')}
                                    closeMenuOnSelect={false}
                                    isDisabled={updateGroupMemberAccess.isLoading}
                                />
                        }
                    </ModalBody>
                    <ModalFooter
                        gap={'33px'}
                        padding={'0px'}
                        marginTop={'24px'}
                    >
                        <JButton
                            margin={'0px auto'}
                            size={'normal'}
                            variant={'j-primary'}
                            onClick={() => updateGroupMemberAccess.mutate()}
                            isLoading={updateGroupMemberAccess.isLoading}
                            isDisabled={groupMemberAccessDetail.isLoading}
                        >
                            {t('group_anggota_modal_berlakukan_simpan')}
                        </JButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* modal customer group */}
            <Modal isOpen={modalCustomerGroup} onClose={() => setModalCustomerGroup(false)}>
                <ModalOverlay />
                <ModalContent
                    backgroundColor={'surfacePlus1'}
                    padding={'24px'}
                >
                    <ModalHeader margin={'0px auto'} padding={'0px'}>{t('group_anggota_modal_berlakukan_grop_pelanggan_judul')}</ModalHeader>
                    <ModalCloseButton
                        top={'24px'}
                        right={'24px'}
                        isDisabled={updateCustomerGroup.isLoading}
                    />
                    <ModalBody
                        as={Flex}
                        flexDirection={'column'}
                        gap={'16px'}
                        padding={'0px'}
                        marginTop={'24px'}
                    >
                        <JTypography size={'label-medium'}>
                            {t('group_anggota_modal_berlakukan_grop_pelanggan_label')}
                        </JTypography>
                        {customerGroupDetail.isLoading || customers.isLoading
                            ?   <Skeleton height={'40px'} width={'100%'} />
                            :   <ChakraReactSelect
                                    isMulti
                                    useBasicStyles
                                    value={customerGroup?.customer}
                                    options={customers?.data?.data?.map((customer: any) => {
                                        return {
                                            value: customer.id,
                                            label: customer.name
                                        }
                                    })}
                                    onChange={(e: any) => {
                                        setCustomerGroup((prev: any) => {
                                            return {
                                                ...prev,
                                                customer: e
                                            }
                                        })
                                    }}
                                    placeholder={t('group_anggota_modal_berlakukan_grop_pelanggan_placeholder')}
                                    closeMenuOnSelect={false}
                                    isDisabled={updateCustomerGroup.isLoading}
                                />
                        }
                    </ModalBody>
                    <ModalFooter
                        gap={'33px'}
                        padding={'0px'}
                        marginTop={'24px'}
                    >
                        <JButton
                            margin={'0px auto'}
                            size={'normal'}
                            variant={'j-primary'}
                            onClick={() => updateCustomerGroup.mutate()}
                            isLoading={updateCustomerGroup.isLoading}
                            isDisabled={customerGroupDetail.isLoading || customers.isLoading}
                        >
                            {t('group_anggota_modal_berlakukan_grop_pelanggan_simpan')}
                        </JButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    )
}

export default MemberGroup